import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import * as Client from 'app/ts/clientDto/index';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import * as VariantHelper from 'app/ts/util/VariantHelper';
import { CabinetSection } from 'app/ts/clientDto/index';

import * as VariantNumbers from 'app/ts/VariantNumbers';
import { CollisionSpacePosition, ItemRotation } from 'app/ts/clientDto/Enums';
import { ErrorInfo } from 'app/ts/clientDto/ErrorInfo';
import { ItemVariant } from 'app/ts/clientDto/ItemVariant';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { InteriorLogic } from 'app/ts/services/ConfigurationLogic/InteriorLogic';
import { ConfigurationItemProperties } from 'app/ts/properties/ConfigurationItemProperties';
import { ConfigurationItemHelper } from '@Util/ConfigurationItemHelper';
import { VariantTypeNumbers } from '../VariantTypeNumbers';

type MaterialVariant = {
  itemVariant: Client.ItemVariant | null;
  material: Client.ProductMaterial | null;
  variantId: number;
  materialType: Interface_Enums.MaterialType;
};

export class ConfigurationItem {
  constructor(
    private dto: Interface_DTO.ConfigurationItem,
    public cabinetSection: CabinetSection,
  ) {
    this.VariantOptions = dto.VariantOptions.map(
      (dtoVo) =>
        new Client.ItemVariant(
          dtoVo,
          this.getProductVariant(
            dtoVo.VariantId,
            this.editorAssets.variantsDict,
          ),
          () => this.setDirty(),
        ),
    );
  }

  private cache: Partial<{
    product: Client.Product | null;
    allMaterials: MaterialVariant[];
    material: Client.ProductMaterial | null;
    material2: Client.ProductMaterial | null;
    material3: Client.ProductMaterial | null;
    productData: Interface_DTO.ProductData | null;
    userSelectableItemVariants: Client.ItemVariant[];
    collisions: Client.Collision[];
    rectangle: Interface_DTO_Draw.Rectangle;
    moduleParent: Client.ConfigurationItem | null;
    moduleChildren: Client.ConfigurationItem[];
    isMirrored: boolean;
    gripProduct: Client.Product | null;
    gripMaterial: Client.Material | null;
  }> = {};

  private _properties?: ConfigurationItemProperties;

  public swingModuleProduct?: Client.SwingModuleProduct;

  public VariantOptions: Client.ItemVariant[];

  private changeTrackingEnabled: boolean = true;
  public enableChangeTracking() {
    this.changeTrackingEnabled = true;
  }
  public disableChangeTracking() {
    this.changeTrackingEnabled = false;
  }

  public setDirty() {
    if (!this.changeTrackingEnabled) return;

    this.clearBackingVariables();
    this.cabinetSection.isDirty = true;
  }

  public clearBackingVariables() {
    this.cache = {};
  }

  public save(): Interface_DTO.ConfigurationItem {
    this.dto.CabinetSectionIndex = this.CabinetSectionIndex;
    this.dto.CabinetIndex = this.CabinetIndex;
    this.dto.ParentModuleItemIndex = this.moduleParent
      ? this.moduleParent.ConfigurationItemIndex
      : null;
    this.dto.PropertiesJson = this._properties
      ? JSON.stringify(this._properties)
      : null;
    return this.dto;
  }

  public restoreFrom(dtoItem: Interface_DTO.ConfigurationItem) {
    this.clearBackingVariables();
    this._properties = undefined;
    this.dto = dtoItem;
    this.VariantOptions = dtoItem.VariantOptions.map(
      (dtoVo) =>
        new Client.ItemVariant(
          dtoVo,
          this.getProductVariant(
            dtoVo.VariantId,
            this.editorAssets.variantsDict,
          ),
          () => this.setDirty(),
        ),
    );
  }

  private getProductVariant(
    variantId: number,
    fallbackVariantsDict: { [variantId: number]: Interface_DTO.Variant },
  ): Client.ProductVariant | undefined {
    if (this.Product) {
      let productVariants = this.Product.getVariants(
        this.cabinetSection.cabinet.ProductLineId,
      );
      for (let i = 0; i < productVariants.length; i++) {
        if (productVariants[i].Id === variantId) return productVariants[i];
      }
    }
    const fallbackVariant = fallbackVariantsDict?.[variantId];
    if (fallbackVariant) {
      return {
        ...fallbackVariantsDict[variantId],
        OnlyAdmin: true,
      };
    } else {
      return undefined;
    }
  }

  private isProbablyMirrored() {
    if (!this.snapRight) return false;
    let gablesInSection = this.cabinetSection.interior.items
      .filter((i) => i.isGable)
      .concat(this.cabinetSection.swing.items.filter((i) => i.isGable))
      .concat(this.cabinetSection.swingFlex.items.filter((i) => i.isGable));
    let gableRightPositions = [
      this.cabinetSection.interior.cube.X,
      ...gablesInSection.map((g) => g.rightX),
    ].filter((p) => p <= this.X);
    let gableLeftPositions = [
      this.cabinetSection.interior.cubeRightX,
      ...gablesInSection.map((g) => g.X),
    ].filter((p) => p >= this.rightX);

    let closestGableRightPos = ObjectHelper.best(
      gableRightPositions,
      (p) => -p,
    );
    let closestGableLeftPos = ObjectHelper.best(gableLeftPositions, (p) => p);

    if (typeof closestGableRightPos !== 'number') return true;
    if (!closestGableLeftPos) return false;

    let distLeft = this.X - closestGableRightPos;
    let distRight = closestGableLeftPos - this.rightX;
    return distRight < distLeft;
  }

  public rotation: ItemRotation = ItemRotation.None;

  //#region PropertiesJson

  private get properties(): ConfigurationItemProperties {
    if (!this._properties) {
      const propertiesJson = this.dto.PropertiesJson ?? '{}';
      const loadedProperties = JSON.parse(
        propertiesJson,
      ) as Partial<ConfigurationItemProperties>;
      this._properties = {
        ...ConfigurationItemProperties.defaultValue,
        ...loadedProperties,
      };
    }
    return this._properties;
  }

  public get swingFlexAreaIndex(): number | undefined {
    return this.properties.swingFlexAreaIndex;
  }
  public set swingFlexAreaIndex(i: number) {
    this.properties.swingFlexAreaIndex = i;
  }

  //#endregion PropertiesJson

  //#region DTO mappings
  /**
   * Height of the item when it arrives at customer (for items that require that the customer reduces height by themselves.)
   * This property is allso used as a placeholder for the procent value for custom products.
   */
  get ActualHeight() {
    return this.dto.ActualHeight;
  }
  set ActualHeight(val: number) {
    if (val === this.dto.ActualHeight) return;
    this.dto.ActualHeight = val;
    this.setDirty();
  }

  get CabinetIndex() {
    return this.cabinetSection.CabinetIndex;
  }
  get CabinetSectionIndex() {
    return this.cabinetSection.CabinetSectionIndex;
  }

  get Children() {
    return this.dto.Children;
  }
  set Children(val: Interface_DTO.ConfigurationItem[]) {
    if (val === this.dto.Children) return;
    this.dto.Children = val;
    this.setDirty();
  }

  get ConfigurationItemIndex() {
    return this.dto.ConfigurationItemIndex;
  }
  set ConfigurationItemIndex(val: number) {
    if (val === this.dto.ConfigurationItemIndex) return;
    this.dto.ConfigurationItemIndex = val;
    this.setDirty();
  }

  private _isMirrored?: boolean = undefined;
  public get isMirrored(): boolean {
    if (this.cache.isMirrored === undefined) {
      if (this._isMirrored === undefined) {
        this.cache.isMirrored = this.isProbablyMirrored();
      } else {
        this.cache.isMirrored = this._isMirrored;
      }
    }
    return this.cache.isMirrored;
  }
  public set isMirrored(val: boolean) {
    this._isMirrored = val;
    this.setDirty();
  }

  private _designerDepth: number | undefined;
  get Depth() {
    if (this._designerDepth !== undefined) {
      return this._designerDepth;
    }

    return this.dto.Depth;
  }
  set Depth(val: number) {
    if (val === this.dto.Depth) return;

    let validDepth = ObjectHelper.clamp(this.minDepth, val, this.maxDepth);

    if (val !== validDepth && this.mayOverrideDepth) {
      this._designerDepth = val;
    } else {
      this._designerDepth = undefined;
    }

    this.dto.Depth = validDepth;
    this.setDirty();
  }
  private get mayOverrideDepth(): boolean {
    return false;
  }

  get Description() {
    return this.dto.Description;
  }
  set Description(val: string) {
    if (val === this.dto.Description) return;
    this.dto.Description = val;
    this.setDirty();
  }

  /** Prevents the description from being changed during recalculation */
  isDescriptionLocked = false;

  get ExternalItemNo() {
    return this.dto.ExternalItemNo;
  }
  set ExternalItemNo(val: string) {
    if (val === this.dto.ExternalItemNo) return;
    this.dto.ExternalItemNo = val;
    this.setDirty();
  }

  get Height() {
    return this.dto.Height;
  }
  set Height(val: number) {
    let minHeightTemp =
      this.HeightReduction || this.heightReductionOverride
        ? this.minHeight
        : this.maxHeight;
    let validHeight = ObjectHelper.clamp(minHeightTemp, val, this.maxHeight);

    if (
      validHeight > val &&
      (this.HeightReduction || this.mayOverrideHeightDown)
    ) {
      validHeight = val;
    } else if (
      val > validHeight &&
      ((this.Product &&
        this.Product.canBeJoined2(this.cabinetSection.cabinet.ProductLineId)) ||
        this.mayOverrideHeightUp)
    ) {
      validHeight = val;
    }

    this.dto.Height = validHeight;
    this.setDirty();
  }
  private get mayOverrideHeightUp(): boolean {
    if (
      this.ItemType === Interface_Enums.ItemType.Corpus ||
      this.ItemType === Interface_Enums.ItemType.SwingCorpus ||
      this.ItemType === Interface_Enums.ItemType.SwingFlexCorpus
    ) {
      return true;
    }

    return false;
  }
  private get mayOverrideHeightDown(): boolean {
    if (
      this.ItemType === Interface_Enums.ItemType.Corpus ||
      this.ItemType === Interface_Enums.ItemType.SwingCorpus ||
      this.ItemType === Interface_Enums.ItemType.SwingFlexCorpus
    ) {
      return true;
    }

    if (
      this.ItemType === Interface_Enums.ItemType.Backing ||
      this.ItemType === Interface_Enums.ItemType.SwingBacking ||
      this.ItemType === Interface_Enums.ItemType.SwingFlexBacking
    ) {
      return true;
    }

    if (this.isGable) {
      return true;
    }

    if (this.isTemplate) {
      return true;
    }

    return false;
  }
  private get heightReductionOverride(): boolean {
    if (
      this.ItemType === Interface_Enums.ItemType.Door ||
      this.ItemType === Interface_Enums.ItemType.SwingDoor ||
      this.ItemType === Interface_Enums.ItemType.SwingFlexDoor
    ) {
      return true;
    }

    if (
      (this.ItemType === Interface_Enums.ItemType.Corpus ||
        this.ItemType === Interface_Enums.ItemType.SwingCorpus ||
        this.ItemType === Interface_Enums.ItemType.SwingFlexCorpus) &&
      this.maxWidth > this.maxHeight
    ) {
      return true;
    }

    if (
      this.isFlexHeight &&
      this.Product &&
      this.Product.useActualHeight2(this.cabinetSection.cabinet.ProductLineId)
    ) {
      return true;
    }

    return false;
  }

  get HeightReduction() {
    return this.dto.HeightReduction;
  }
  set HeightReduction(val: boolean) {
    val =
      val &&
      !!this.Product &&
      ProductHelper.isHeightReductionPossible(
        this.Product,
        this.productLineId,
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowHeightReduction,
      );

    if (val === this.dto.HeightReduction) return;

    this.dto.HeightReduction = val;
    this.moduleChildren.forEach((child) => (child.HeightReduction = val));

    this.setDirty();
  }

  get IsHidden() {
    return this.dto.IsHidden;
  }
  set IsHidden(val: boolean) {
    if (val === this.dto.IsHidden) return;
    this.dto.IsHidden = val;
    this.setDirty();
  }

  get IsLocked() {
    return this.dto.IsLocked;
  }
  set IsLocked(val: boolean) {
    if (val === this.dto.IsLocked) return;
    this.dto.IsLocked = val;
    this.setDirty();
  }

  get ItemNo() {
    return this.dto.ItemNo;
  }
  set ItemNo(val: string) {
    if (val === this.dto.ItemNo) return;
    this.dto.ItemNo = val;
    this.setDirty();
  }

  get ItemType() {
    return this.dto.ItemType;
  }
  set ItemType(val: Interface_Enums.ItemType) {
    if (val === this.dto.ItemType) return;
    this.dto.ItemType = val;
    this.setDirty();
  }

  get MaterialId() {
    return this.dto.MaterialId;
  }
  set MaterialId(val: number) {
    if (val === this.dto.MaterialId) return;
    this.dto.MaterialId = val;
    this.setDirty();
  }

  get ParentItemNo() {
    return this.dto.ParentItemNo;
  }
  set ParentItemNo(val: string) {
    if (val === this.dto.ParentItemNo) return;
    this.dto.ParentItemNo = val;
    this.setDirty();
  }

  get ParentModuleItemIndex(): number | null {
    return this.dto.ParentModuleItemIndex;
  }
  set ParentModuleItemIndex(val: number | null) {
    if (val === this.dto.ParentModuleItemIndex) return;
    this.dto.ParentModuleItemIndex = val;
    this.setDirty();
  }

  get ParentModuleProductId() {
    return this.dto.ParentModuleProductId;
  }
  set ParentModuleProductId(val: number) {
    if (val === this.dto.ParentModuleProductId) return;
    this.dto.ParentModuleProductId = val;
    this.setDirty();
  }

  get PositionNumber() {
    return this.dto.PositionNumber;
  }
  set PositionNumber(val: number) {
    if (val === this.dto.PositionNumber) return;
    this.dto.PositionNumber = val;
    this.setDirty();
  }

  get Price() {
    return this.dto.Price;
  }
  set Price(val: number) {
    if (val === this.dto.Price) return;
    this.dto.Price = val;
    this.setDirty();
  }

  get ProductId() {
    return this.dto.ProductId;
  }
  set ProductId(val: number) {
    if (val === this.dto.ProductId) return;
    this.dto.ProductId = val;
    this.setDirty();
  }

  get Quantity() {
    return this.dto.Quantity;
  }
  set Quantity(val: number) {
    if (val === this.dto.Quantity) return;
    this.dto.Quantity = val;
    this.setDirty();
  }

  /** The actual width of the item, when delivered to the customer.
   * Used when customer has the task of adapting the item to
   * the room where the cabinet is installed.
   * This number will be shown on PieceList, if set*/
  get actualWidth(): number | undefined {
    return this.properties.actualWidth;
  }

  set actualWidth(val: number | undefined) {
    this.properties.actualWidth = val;
    this.setDirty();
  }

  private _designerWidth: number | undefined;
  get Width() {
    if (this._designerWidth !== undefined) {
      return this._designerWidth;
    }

    return this.dto.Width;
  }
  set Width(val: number) {
    if (val === this.dto.Width) return;

    let validWidth = ObjectHelper.clamp(this.minWidth, val, this.maxWidth);
    if (
      val > validWidth &&
      this.Product &&
      this.Product.canBeJoined2(this.cabinetSection.cabinet.ProductLineId)
    ) {
      validWidth = val;
    }

    if (val !== validWidth && this.mayOverrideWidth) {
      this._designerWidth = val;
    } else {
      this._designerWidth = undefined;
    }

    this.dto.Width = validWidth;
    this.setDirty();
  }
  private get mayOverrideWidth(): boolean {
    if (!this.Product) {
      return false;
    }

    if (this.ItemType === Interface_Enums.ItemType.Corpus) {
      return true;
    }

    return false;
  }

  get X() {
    return this.dto.X;
  }
  set X(val: number) {
    let roundedVal = Math.round(val);
    if (roundedVal === this.dto.X) return;
    this.dto.X = roundedVal;
    this.setDirty();
  }

  get Y() {
    return this.dto.Y;
  }
  set Y(val: number) {
    let roundedVal = Math.round(val);
    if (roundedVal === this.dto.Y) return;
    this.dto.Y = roundedVal;
    this.setDirty();
  }

  get Z() {
    return this.dto.Z;
  }
  set Z(val: number) {
    let roundedVal = Math.round(val);
    if (roundedVal === this.dto.Z) return;
    this.dto.Z = roundedVal;
    this.setDirty();
  }

  get dtoItem(): Interface_DTO.ConfigurationItem {
    return this.dto;
  }

  //#endregion DTO mappings

  public get editorAssets() {
    return this.cabinetSection.editorAssets;
  }

  public get cabinetSectionPosition(): Interface_DTO_Draw.Vec3d {
    return {
      X: this.X,
      Y: this.Y,
      Z: this.Z,
    };
  }

  public get floorPlanPosition(): Interface_DTO_Draw.Vec3d {
    let pos = VectorHelper.rotate3d(
      this.cabinetSectionPosition,
      this.cabinetSection.floorPlanRotation,
    );
    let sectionFloorPlanPos = this.cabinetSection.floorPlanPosition;
    return VectorHelper.add(pos, sectionFloorPlanPos);
  }

  public get floorPlanRotation(): number {
    return this.cabinetSection.floorPlanRotation;
  }

  get Product(): Client.Product | null {
    if (this.cache.product === undefined) {
      this.cache.product = this.editorAssets.productsDict[this.ProductId];
      if (!this.cache.product) {
        console.error(
          'product not found: ' + this.ProductId,
          this.editorAssets,
        );
        this.cache.product = null;
        //throw new Error("Product not found: " + this.ProductId);
      }
    }
    return this.cache.product;
  }
  set Product(newProduct: Client.Product | null) {
    this.cache.product = newProduct;
    if (newProduct) {
      this.dto.ProductId = newProduct.Id;
      this.dto.ItemNo = newProduct.ProductNo;
      this.dto.Description = newProduct.Name;
      this.dto.Width = ProductHelper.defaultWidth(
        newProduct,
        this.Material || undefined,
      );

      let variantIdsToRemove: number[] = [];
      // remove varians if the new product doesn't support it
      for (let vo of this.VariantOptions) {
        if (vo.VariantId > 0) {
          if (
            !newProduct
              .getVariants(this.cabinetSection.cabinet.ProductLineId)
              .some((variant) => variant.Id === vo.VariantId)
          ) {
            variantIdsToRemove.push(vo.VariantId);
          }
        }
      }

      for (let variantId of variantIdsToRemove) {
        this.removeVariantOptionByVariantId(variantId);
      }
    }
    this.setDirty();
  }

  get Material(): Client.ProductMaterial | null {
    if (this.cache.material === undefined) {
      this.cache.material =
        this.Product?.materials.find((mat) => mat.Id === this.MaterialId) ??
        null;
    }
    return this.cache.material;
  }

  get Material2(): Client.ProductMaterial | null {
    if (this.cache.material2 === undefined) {
      this.cache.material2 = this.allMaterials[1]?.material || null;
    }
    return this.cache.material2;
  }

  set Material2(val: Client.ProductMaterial) {
    let materialVariant = this.allMaterials[1];
    if (!materialVariant) return;
    let vo = materialVariant.itemVariant?.variant?.VariantOptions.find(
      (o) => o.Number === val.Number,
    );
    if (!vo) return;
    this.setItemVariant(
      {
        ActualValue: '',
        VariantId: materialVariant.variantId,
        VariantOptionId: vo.Id,
      },
      true,
    );
    this.setDirty();
  }

  get Material3(): Client.ProductMaterial | null {
    if (this.cache.material3 === undefined) {
      this.cache.material3 = this.allMaterials[2]?.material || null;
    }
    return this.cache.material3;
  }
  set Material3(val: Client.ProductMaterial) {
    let materialVariant = this.allMaterials[2];
    if (!materialVariant) return;
    let vo = materialVariant.itemVariant?.variant?.VariantOptions.find(
      (o) => o.Number === val.Number,
    );
    if (!vo) return;
    this.setItemVariant(
      {
        ActualValue: '',
        VariantId: materialVariant.variantId,
        VariantOptionId: vo.Id,
      },
      true,
    );
    this.setDirty();
  }

  get allMaterials(): MaterialVariant[] {
    if (this.cache.allMaterials === undefined) {
      const variantTypes = [
        VariantTypeNumbers.NormalMaterial,
        VariantTypeNumbers.FrameMaterial,
        VariantTypeNumbers.GripMaterial,
      ];
      const r = Enumerable.from(this.Product?.getAllVariants() ?? [])
        .where((variant) => variantTypes.includes(variant.TypeNumber))
        .orderBy((variant) => variantTypes.indexOf(variant.TypeNumber))
        .select<MaterialVariant>((variant) => {
          const itemVariant =
            this.VariantOptions.find((iv) => iv.VariantId === variant.Id) ??
            null;
          const materialType =
            VariantTypeNumbers.materialTypeDict[variant.TypeNumber]!;
          const filteredMaterials =
            this.Product?.materials.filter(
              (mat) => mat.Type === materialType,
            ) ?? [];
          const material =
            filteredMaterials.find((mat) => mat.Id === this.MaterialId) ??
            filteredMaterials.find(
              (mat) => mat.Number === itemVariant?.variantOption?.Number,
            ) ??
            filteredMaterials[0] ??
            null;
          return {
            itemVariant: itemVariant,
            material: material,
            variantId: variant.Id,
            materialType: materialType,
          };
        });

      this.cache.allMaterials = r.toArray();
    }
    return this.cache.allMaterials;
  }

  get ProductData(): Interface_DTO.ProductData | null {
    if (this.cache.productData === undefined) {
      if (this.Product) {
        for (let pd of this.Product.ProductDataList) {
          if (pd.MaterialId === this.MaterialId) {
            this.cache.productData = pd;
            break;
          }
          if (pd.MaterialId === -1) {
            this.cache.productData = pd;
          }
        }
      }
      if (this.cache.productData === undefined) {
        this.cache.productData = null;
      }
    }
    return this.cache.productData;
  }

  get UserSelectableItemVariants(): Client.ItemVariant[] {
    if (!this.cache.userSelectableItemVariants) {
      let result: ItemVariant[] = [];
      if (this.Product) {
        let productVariants =
          this.cabinetSection && this.cabinetSection.CabinetIndex > 0
            ? this.Product.getUserSelectableVariants(
                this.cabinetSection.cabinet.ProductLineId,
              )
            : this.Product.getAllVariants().filter((v) =>
                VariantHelper.isUserSelectable(v),
              );

        for (let variant of productVariants) {
          let clientItemVariant: Client.ItemVariant | undefined =
            this.VariantOptions.filter((iv) => iv.VariantId === variant.Id)[0];
          let itemVariantDto: Interface_DTO.ItemVariant | undefined =
            clientItemVariant ? clientItemVariant.dto : undefined;

          if (!itemVariantDto) {
            let firstOption = variant.VariantOptions[0];
            let firstOptionId = firstOption ? firstOption.Id : -1;
            itemVariantDto = {
              ActualValue: '',
              VariantId: variant.Id,
              VariantOptionId: firstOptionId,
            };
            clientItemVariant = new Client.ItemVariant(
              itemVariantDto,
              variant,
              () => this.setDirty(),
            );
            this.dto.VariantOptions.push(itemVariantDto);
            this.VariantOptions.push(clientItemVariant);
          }
          result.push(
            new Client.ItemVariant(itemVariantDto, variant, () =>
              this.setDirty(),
            ),
          );
        }
      }
      this.cache.userSelectableItemVariants = result;
    }
    return this.cache.userSelectableItemVariants;
  }

  get collisions(): Client.Collision[] {
    if (!this.cache.collisions) {
      if (this.ItemType === Interface_Enums.ItemType.SwingFlexCorpus)
        this.cache.collisions = [];
      else {
        let itemCubes = this.cabinetSection.interior.itemCubes;
        if (this.cabinetSection.isSwingFlex) {
          itemCubes.push(
            ...this.cabinetSection.swingFlex.items
              .filter(
                (swingItem) =>
                  swingItem.ItemType ===
                  Interface_Enums.ItemType.SwingFlexCorpusMovable,
              )
              .map(ConfigurationItemHelper.getItemCube),
          );
        }

        this.cache.collisions = InteriorLogic.getCollidingCollisionAreas(
          this,
          this.isMirrored,
          itemCubes,
        );
      }
    }
    return this.cache.collisions;
  }

  get moduleChildren(): Client.ConfigurationItem[] {
    if (!this.cache.moduleChildren) {
      if (!this.isTemplate) {
        this.cache.moduleChildren = [];
      } else {
        this.cache.moduleChildren = this.cabinetSection.interior.items
          .filter(
            (i) => i.ParentModuleItemIndex === this.ConfigurationItemIndex,
          )
          .sort(
            (i1, i2) => i1.ConfigurationItemIndex - i2.ConfigurationItemIndex,
          );
      }
    }
    return this.cache.moduleChildren;
  }

  get moduleParent(): Client.ConfigurationItem | null {
    if (!this.cache.moduleParent) {
      if (this.ParentModuleItemIndex === null) {
        this.cache.moduleParent = null;
      } else {
        this.cache.moduleParent = Enumerable.from(
          this.cabinetSection.interior.items,
        ).single(
          (i) => i.ConfigurationItemIndex === this.ParentModuleItemIndex,
        );
      }
    }
    return this.cache.moduleParent;
  }

  set moduleParent(val: Client.ConfigurationItem | null) {
    let p: number | null;
    if (val === null) {
      p = null;
    } else {
      p = val.ConfigurationItemIndex;
    }
    if (p !== this.ParentModuleItemIndex) {
      this.ParentModuleItemIndex = p;
      this.setDirty();
    }
  }

  get moduleBaseItem(): Client.ConfigurationItem {
    return this.moduleParent ? this.moduleParent.moduleBaseItem : this;
  }

  get moduleDescendants(): Client.ConfigurationItem[] {
    return [
      this,
      ...Enumerable.from(this.moduleChildren)
        .selectMany((child) => child.moduleDescendants)
        .toArray(),
    ];
  }

  get leftX(): number {
    return this.X;
  }
  get rightX(): number {
    return this.X + this.Width;
  }
  get centerX(): number {
    return this.X + this.Width / 2;
  }

  get topY(): number {
    return this.Y + this.Height;
  }
  get centerY(): number {
    return this.Y + this.Height / 2;
  }
  get bottomY(): number {
    return this.Y;
  }

  get frontZ(): number {
    return this.Z + this.Depth;
  }
  get centerZ(): number {
    return (this.Z + this.frontZ) / 2;
  }

  get snapOffsetY(): number {
    if (!this.Product) return 0;
    return ProductHelper.getSnapOffsetY(this.Product);
  }

  get isFlexHeight(): boolean {
    if (!this.Product) return false;
    let productLineId =
      this.cabinetSection && this.cabinetSection.cabinet.ProductLineId;
    return ProductHelper.isFlexHeight(this.Product, productLineId);
  }
  get isFlexWidth(): boolean {
    if (!this.Product) return false;
    return ProductHelper.isFlexWidth(this.Product);
  }
  get isSemiFlexWidth(): boolean {
    if (!this.Product) return false;
    return (
      !ProductHelper.isFlexWidth(this.Product) &&
      this.Product.productGroup.some((p) => ProductHelper.isFlexWidth(p))
    );
  }
  get isFlexDepth(): boolean {
    if (!this.Product) return false;
    var productLineId =
      this.cabinetSection.CabinetIndex == 0
        ? undefined
        : this.cabinetSection.cabinet.ProductLineId;
    return ProductHelper.isFlexDepth(this.Product, productLineId);
  }

  get isDummy(): boolean {
    if (!this.Product) return false;
    return ProductHelper.isDummy(this.Product);
  }

  get isPullout(): boolean {
    if (!this.Product) return false;
    return ProductHelper.isPullout(this.Product);
  }

  get isGable(): boolean {
    if (!this.Product) return false;
    return ProductHelper.isGable(this.Product);
  }

  get isGrip(): boolean {
    if (!this.Product) return false;
    return (
      ProductHelper.isGripForDoor(this.Product) ||
      ProductHelper.isGripForDrawer(this.Product)
    );
  }

  get isSwingCorpusGable(): boolean {
    if (!this.swingModuleProduct) {
      return false;
    }

    return (
      this.swingModuleProduct.Placement ===
        Interface_Enums.SwingModuleProductPlacement.LeftGable ||
      this.swingModuleProduct.Placement ===
        Interface_Enums.SwingModuleProductPlacement.MiddleGable ||
      this.swingModuleProduct.Placement ===
        Interface_Enums.SwingModuleProductPlacement.RightGable
    );
  }

  get isSwingTopShelf(): boolean {
    if (!this.swingModuleProduct) {
      return false;
    }

    return (
      this.swingModuleProduct.Placement ===
      Interface_Enums.SwingModuleProductPlacement.TopShelf
    );
  }

  get isCoatHanger(): boolean {
    if (!this.Product) return false;
    return ProductHelper.isCoatHanger(this.Product);
  }

  get isTemplate(): boolean {
    if (!this.Product) return false;
    return this.Product.IsTemplate;
  }

  get isFittingPanel(): boolean {
    if (!this.Product) return false;
    return ProductHelper.isFittingPanel(this.Product);
  }

  get isShelf(): boolean {
    if (!this.Product) return false;
    return ProductHelper.isShelf(
      this.Product,
      this.cabinetSection.cabinet.ProductLineId,
    );
  }

  /**
   * Checks if the product is a shelf with a height between 19 and 22 mm. PLEASE NOTE: Is quick fix to ignore Glashylde and Skohylde.
   * @returns {boolean} True if the product is a shelf with height 19-22 mm, false otherwise.
   */
  get isShelf_19_22(): boolean {
    if (!this.Product) {
      return false;
    }
    return ProductHelper.isShelf_19_22(
      this.Product,
      this.cabinetSection.cabinet.ProductLineId,
    );
  }

  get isVerticalDivider(): boolean {
    if (!this.Product) return false;
    return ProductHelper.isVerticalDivider(this.Product);
  }

  isHeightReductionPossible(
    productLineId?: Interface_Enums.ProductLineId,
  ): boolean {
    return (
      !!this.Product &&
      ProductHelper.isHeightReductionPossible(
        this.Product,
        productLineId || this.productLineId,
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowHeightReduction,
      )
    );
  }

  warnAboutHeightReduction(
    productLineId?: Interface_Enums.ProductLineId,
  ): boolean {
    if (!this.Product) return false;
    return !ProductHelper.isHeightReductionPossible(
      this.Product,
      productLineId || this.productLineId,
      false,
    );
  }

  get productLineId(): Interface_Enums.ProductLineId | null {
    if (
      this.cabinetSection.cabinet.CabinetType ==
      Interface_Enums.CabinetType.SharedItems
    )
      return null;
    return this.cabinetSection.cabinet.ProductLineId;
  }

  get snapUnder(): boolean {
    if (!this.Product) return false;
    return ProductHelper.snapUnder(this.Product);
  }

  get snapCoatHanger(): boolean {
    if (!this.Product) return false;
    return ProductHelper.snapCoatHanger(this.Product);
  }

  get snapFront(): boolean {
    if (!this.Product) return false;
    return ProductHelper.snapFront(this.Product);
  }

  get isAllowedInCorner(): boolean {
    // !Pullout && !Drilling600Left && !Drilling600Right && !SnapAboveGables
    console.debug('TODO getter isAllowedInCorner');
    return true;
  }

  get isAllowedInCornerWithDiagonalCut(): boolean {
    // IsCornerDiagonalCut || (SnapLeftAndRight && SnapDepthMiddle)
    console.debug('TODO getter isAllowedInCornerWithCut');
    return true;
  }

  get drilledLeft(): boolean {
    if (!this.Product) return false;
    return ProductHelper.drilledLeft(this.Product);
  }
  get drilledRight(): boolean {
    if (!this.Product) return false;
    return ProductHelper.drilledRight(this.Product);
  }

  get drilling600Left(): boolean {
    if (!this.Product) return false;
    return ProductHelper.drilling600Left(this.Product);
  }
  get drilling600Right(): boolean {
    if (!this.Product) return false;
    return ProductHelper.drilling600Right(this.Product);
  }

  get isCorner2WayFlex(): boolean {
    if (!this.Product) return false;
    return ProductHelper.isCorner2WayFlex(this.Product);
  }
  get isCornerDiagonalCut(): boolean {
    if (!this.Product) return false;
    return ProductHelper.isCornerDiagonalCut(this.Product);
  }

  get isCornerRounded(): boolean {
    if (!this.Product) return false;
    return ProductHelper.isCornerRounded(this.Product);
  }

  get isCornerTwoPart(): boolean {
    if (!this.Product) return false;
    return ProductHelper.isCornerTwoPart(this.Product);
  }

  get snapsAgainstOpposite(): boolean {
    if (!this.Product) return false;
    return ProductHelper.snapsAgainstOpposite(this.Product);
  }

  get hasWidth2(): boolean {
    if (!this.Product) return false;
    return ProductHelper.hasWidth2(this.Product);
  }
  get hasDepth2(): boolean {
    if (!this.Product) return false;
    return ProductHelper.hasDepth2(this.Product);
  }

  get reductionDepth(): number {
    if (!this.Product) return 0;
    return ProductHelper.reductionDepth(this.Product);
  }

  get minWidth(): number {
    if (!this.Product) return this.Width;
    return ProductHelper.minWidth(this.Product, this.Material || undefined);
  }
  get maxWidth(): number {
    if (!this.Product) return this.Width;
    return ProductHelper.maxWidth(this.Product, this.Material || undefined);
  }
  get minHeight(): number {
    if (!this.Product) return this.Height;
    return ProductHelper.minHeight(this.Product, this.Material || undefined);
  }
  get maxHeight(): number {
    if (!this.Product) return this.Height;
    return ProductHelper.maxHeight(this.Product, this.Material || undefined);
  }
  get minDepth(): number {
    if (!this.Product) return this.Depth;
    var productLineId =
      this.cabinetSection.CabinetIndex == 0
        ? undefined
        : this.cabinetSection.cabinet.ProductLineId;
    return ProductHelper.minDepth(
      this.Product,
      productLineId,
      this.Material || undefined,
    );
  }
  get maxDepth(): number {
    if (!this.Product) return this.Depth;
    var productLineId =
      this.cabinetSection.CabinetIndex == 0
        ? undefined
        : this.cabinetSection.cabinet.ProductLineId;
    return ProductHelper.maxDepth(
      this.Product,
      productLineId,
      this.Material || undefined,
    );
  }
  get defaultDepth(): number {
    if (!this.Product) return this.Depth;
    return ProductHelper.defaultDepth(
      this.Product,
      this.cabinetSection.cabinet.ProductLineId,
      this.Material || undefined,
    );
  }

  get depth2(): number {
    for (let vo of this.VariantOptions) {
      if (!!vo.variant && vo.variant.Number === VariantNumbers.DepthRight)
        return parseFloat(vo.ActualValue);
    }
    return -1;
  }

  set depth2(val: number) {
    let dtoIV: Interface_DTO.ItemVariant = {
      ActualValue: val.toString(),
      VariantId:
        this.editorAssets.variantsByNumber[VariantNumbers.DepthRight].Id,
      VariantOptionId: -1,
    };
    this.setItemVariant(dtoIV, true);
  }
  get width2(): number {
    for (let vo of this.VariantOptions) {
      if (!!vo.variant && vo.variant.Number === VariantNumbers.WidthRight)
        return parseFloat(vo.ActualValue);
    }
    return -1;
  }

  set width2(val: number) {
    let dtoIV: Interface_DTO.ItemVariant = {
      ActualValue: val.toString(),
      VariantId:
        this.editorAssets.variantsByNumber[VariantNumbers.WidthRight].Id,
      VariantOptionId: -1,
    };
    this.setItemVariant(dtoIV, true);
  }

  public get depthIncludingExtra(): number {
    if (!this.Product) return this.Depth;
    return this.Depth + ProductHelper.extraDepth(this.Product);
  }

  public get snappedHoleY(): number {
    if (this.cabinetSection.isSwingFlex) {
      return (
        this.Y +
        this.snapOffsetY -
        this.cabinetSection.swingFlex.getGablePositionY()
      );
    }
    return this.Y + this.snapOffsetY - this.cabinetSection.interior.cube.Y;
  }

  public get mouseClickOffset(): Interface_DTO_Draw.Rectangle {
    return {
      X: this.isGable ? -15 : 0,
      Y: this.isGable ? 0 : -15,
      Width: this.Width + (this.isGable ? 30 : 0),
      Height: this.Height + (this.isGable ? 0 : 30),
    };
  }

  //#region interiorItem

  get recommendedLeft(): number {
    if (!this.Product) return 0;
    return (
      this.X -
      ProductHelper.getCollisionSpace(
        this.Product,
        true,
        CollisionSpacePosition.Side,
      )
    );
  }
  get recommendedRight(): number {
    if (!this.Product) return 0;
    return (
      this.X +
      this.Width +
      ProductHelper.getCollisionSpace(
        this.Product,
        true,
        CollisionSpacePosition.Side,
      )
    );
  }

  get recommendedTop(): number {
    if (!this.Product) return 0;
    return (
      this.X +
      this.Height +
      ProductHelper.getCollisionSpace(
        this.Product,
        true,
        CollisionSpacePosition.Top,
      )
    );
  }
  get recommendedBottom(): number {
    if (!this.Product) return 0;
    return (
      this.Y -
      ProductHelper.getCollisionSpace(
        this.Product,
        true,
        CollisionSpacePosition.Bottom,
      )
    );
  }

  get requiredLeft(): number {
    if (!this.Product) return 0;
    return (
      this.X -
      ProductHelper.getCollisionSpace(
        this.Product,
        false,
        CollisionSpacePosition.Side,
      )
    );
  }
  get requiredRight(): number {
    if (!this.Product) return 0;
    return (
      this.X +
      this.Width +
      ProductHelper.getCollisionSpace(
        this.Product,
        false,
        CollisionSpacePosition.Side,
      )
    );
  }

  get requiredTop(): number {
    if (!this.Product) return 0;
    return (
      this.X +
      this.Height +
      ProductHelper.getCollisionSpace(
        this.Product,
        false,
        CollisionSpacePosition.Top,
      )
    );
  }
  get requiredBottom(): number {
    if (!this.Product) return 0;
    return (
      this.Y -
      ProductHelper.getCollisionSpace(
        this.Product,
        false,
        CollisionSpacePosition.Bottom,
      )
    );
  }

  get snapLeft(): boolean {
    if (!this.Product) return false;
    return ProductHelper.snapLeft(this.Product);
  }
  get snapRight(): boolean {
    if (!this.Product) return false;
    return ProductHelper.snapRight(this.Product);
  }
  get snapLeftAndRight(): boolean {
    if (!this.Product) return false;
    return ProductHelper.snapLeftAndRight(this.Product);
  }
  get snapAboveGables(): boolean {
    if (!this.Product) return false;
    return ProductHelper.snapAboveGable(this.Product);
  }
  get snapDepthMiddle(): boolean {
    if (!this.Product) return false;
    return ProductHelper.snapDepthMiddle(this.Product);
  }
  get snapToWallMustWarn(): boolean {
    return (
      this.snapLeftAndRight &&
      !this.snapDepthMiddle &&
      !(this.isCorner2WayFlex || this.isCornerDiagonalCut)
    );
  }
  get ignoreSnapPointValidation(): boolean {
    if (!this.Product) return false;
    return ProductHelper.ignoreSnapPointValidation(this.Product);
  }
  get snapDepthLeft(): number {
    let snapDepth = this.Depth;

    if (this.Product) {
      let tempSnapDepth = ProductHelper.getSnapDepthLeft(this.Product);
      if (tempSnapDepth > 0) {
        snapDepth = tempSnapDepth;
      }
    }

    return snapDepth;
  }
  get snapDepthRight(): number {
    let snapDepth = this.Depth;

    if (this.Product) {
      let tempSnapDepth = ProductHelper.getSnapDepthRight(this.Product);
      if (tempSnapDepth > 0) {
        snapDepth = tempSnapDepth;
      }
    }

    return snapDepth;
  }
  get snapDepthDifferenceLeft(): number {
    return this.Depth - this.snapDepthLeft;
  }
  get snapDepthDifferenceRight(): number {
    return this.Depth - this.snapDepthRight;
  }

  get depthReduction(): number {
    if (!this.Product) return 0;
    return ProductHelper.getDepthReduction(this.Product);
  }

  get isSnapUnderTarget(): boolean {
    return (
      (!this.isGable &&
        this.snapLeftAndRight &&
        !this.isPullout &&
        !this.isCoatHanger) ||
      this.isSwingTopShelf
    );
  }

  /** Extra width for the front. For items such as drawers, where the front of the item extends over the gable (left side) */
  get frontExtraWidthLeft(): number {
    let result = 0;

    let extraWidthVariant: Client.ItemVariant | undefined;

    if (this.isPullout) {
      extraWidthVariant = this.VariantOptions.find(
        (vo) => vo.variant?.Number === VariantNumbers.DrawerFrontExtraWidth,
      );
    } else if (this.ItemType === Interface_Enums.ItemType.SwingFlexDoor) {
      extraWidthVariant = this.VariantOptions.find(
        (vo) => vo.variant?.Number === VariantNumbers.DoorExtraWidth,
      );
    }

    if (extraWidthVariant) {
      const sideOverlap =
        this.cabinetSection.swingFlex.productLineProperties
          .SwingFlexDoorSideOverlap;
      if (this.isPullout) result += sideOverlap;
      switch (extraWidthVariant.variantOption?.Number) {
        case VariantNumbers.DrawerFrontExtraWidthValues.Left:
        case VariantNumbers.DrawerFrontExtraWidthValues.LeftAndRight:
          result += sideOverlap;
      }
    }

    return result;
  }

  /** Extra width for the front. For items such as drawers, where the front of the item extends over the gable (right side) */
  get frontExtraWidthRight(): number {
    let result = 0;

    let extraWidthVariant: Client.ItemVariant | undefined;

    if (this.isPullout) {
      extraWidthVariant = this.VariantOptions.find(
        (vo) => vo.variant?.Number === VariantNumbers.DrawerFrontExtraWidth,
      );
    } else if (this.ItemType === Interface_Enums.ItemType.SwingFlexDoor) {
      extraWidthVariant = this.VariantOptions.find(
        (vo) => vo.variant?.Number === VariantNumbers.DoorExtraWidth,
      );
    }

    if (extraWidthVariant) {
      const sideOverlap =
        this.cabinetSection.swingFlex.productLineProperties
          .SwingFlexDoorSideOverlap;
      if (this.isPullout) result += sideOverlap;
      switch (extraWidthVariant.variantOption?.Number) {
        case VariantNumbers.DrawerFrontExtraWidthValues.Right:
        case VariantNumbers.DrawerFrontExtraWidthValues.LeftAndRight:
          result += sideOverlap;
      }
    }

    return result;
  }

  //#endregion

  // #region trySetSize

  public trySetWidth(
    newWidth: number,
    useProductGroupAlternatives: boolean = true,
    setClosestValid: boolean = true,
  ): boolean {
    return this.trySetSize(
      { X: newWidth },
      {
        setClosestValid,
        useProductGroupAlternatives,
      },
    );
  }

  public trySetHeight(
    newHeight: number,
    setClosestValid: boolean = true,
    useProductGroupAlternatives: boolean = true,
  ): boolean {
    if (this.IsLocked) return false;
    return this.trySetSize(
      { Y: newHeight },
      {
        setClosestValid,
        useProductGroupAlternatives,
      },
    );
  }

  public trySetDepth(
    newDepth: number,
    setClosestValid: boolean = true,
    useProductGroupAlternatives: boolean = true,
  ): boolean {
    return this.trySetSize(
      { Z: newDepth },
      {
        setClosestValid,
        useProductGroupAlternatives,
      },
    );
  }

  public trySetSize(
    dimensions: Partial<Interface_DTO_Draw.Vec3d>,
    options?: Partial<{
      setClosestValid: boolean;
      useProductGroupAlternatives: boolean;
    }>,
  ): boolean {
    //handle options
    let setClosestValid = options?.setClosestValid ?? true;
    let useProductGroupAlternatives =
      options?.useProductGroupAlternatives ?? true;

    if (!this.Product) return false;

    const newDimensions: Interface_DTO_Draw.Vec3d = {
      X: dimensions.X ?? this.Width,
      Y: dimensions.Y ?? this.Height,
      Z: dimensions.Z ?? this.Depth,
    };

    let allowAllProducts =
      this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts;
    let productLineId = this.cabinetSection.cabinet.ProductLineId;
    let allowAllMaterials =
      this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherMaterials;

    let newProduct: Client.Product | undefined = this.Product;

    if (
      !this.Product.supportsDimensions(
        newDimensions,
        productLineId,
        this.MaterialId,
      )
    ) {
      let selectableProducts = useProductGroupAlternatives
        ? this.Product.productGroup
        : [this.Product];

      // filter unavailable products
      if (!allowAllProducts) {
        selectableProducts = selectableProducts.filter(
          (product) =>
            !product.overrideChain &&
            product.ProductLineIds.includes(productLineId),
        );
      }

      // filter products not available in current material
      selectableProducts = selectableProducts.filter((product) => {
        let matIds = product.PossibleMaterialIds;
        if (matIds.length === 0) {
          return true;
        }
        if (!allowAllMaterials) {
          matIds = matIds.filter((mat) => mat.IsEnabled && !mat.IsOverride);
        }
        return matIds.some((matId) => matId.Id === this.MaterialId);
      });

      selectableProducts = selectableProducts.filter((product) =>
        product.supportsDimensions(
          newDimensions,
          this.cabinetSection.cabinet.ProductLineId,
          this.MaterialId,
        ),
      );

      newProduct = Enumerable.from(selectableProducts)
        .orderBy((p) => (p.overrideChain ? 2 : 1))
        .thenBy((p) => p.SortOrder)
        .firstOrDefault();
    }

    newProduct ??= this.Product; // could not find a suitable product, use current instead

    // clamp values to min/max allowable for newProduct
    if (setClosestValid) {
      newDimensions.X = ObjectHelper.clamp(
        ProductHelper.minWidth(newProduct, this.Material ?? undefined),
        newDimensions.X,
        ProductHelper.maxWidth(newProduct, this.Material ?? undefined),
      );
      newDimensions.Y = ObjectHelper.clamp(
        ProductHelper.minHeight(newProduct, this.Material ?? undefined),
        newDimensions.Y,
        ProductHelper.maxHeight(newProduct, this.Material ?? undefined),
      );
      newDimensions.Z = ObjectHelper.clamp(
        ProductHelper.minDepth(
          newProduct,
          productLineId,
          this.Material ?? undefined,
        ),
        newDimensions.Z,
        ProductHelper.maxDepth(
          newProduct,
          productLineId,
          this.Material ?? undefined,
        ),
      );
    }
    if (
      newProduct.supportsDimensions(
        newDimensions,
        productLineId,
        this.MaterialId,
      )
    ) {
      this.Product = newProduct;
      this.Width = newDimensions.X;
      this.Height = newDimensions.Y;
      this.Depth = newDimensions.Z;
      return true;
    }
    return false;
  }

  // #endregion trySetSize

  public moveToCabinetSection(section: Client.CabinetSection): void {
    let arrayIndex = this.cabinetSection.interior.items.indexOf(this);
    if (arrayIndex >= 0) {
      if (this.cabinetSection === section) {
        //item is already in section - no need to move it and risk messing up the item's index;
        return;
      }

      this.cabinetSection.interior.items.splice(arrayIndex, 1);
    }
    this.ConfigurationItemIndex =
      Math.max(
        0,
        ...section.configurationItems.map((ci) => ci.ConfigurationItemIndex),
      ) + 1;
    this.cabinetSection = section;
    section.interior.items.push(this);
  }

  /**
   *
   * @param dtoIV the item variant to set.
   * @param force forces the item variant to be set, even if the item's product does not have the variant.
   */
  public setItemVariant(dtoIV: Interface_DTO.ItemVariant, force: boolean) {
    if (!this.Product) {
      return;
    }
    //if (!force) {
    //    if ((dtoIV.VariantId > 0 && !this.Product.getVariants(this.cabinetSection.cabinet.ProductLineId).some(v => v.Id === dtoIV.VariantId))) {
    //        return;
    //    }
    //}

    let set = false;
    for (let vo of this.VariantOptions) {
      if (vo.variant && vo.variant.Id === dtoIV.VariantId) {
        vo.ActualValue = dtoIV.ActualValue;
        vo.VariantOptionId = dtoIV.VariantOptionId;
        set = true;
        break;
      }
    }

    if (!set) {
      let productVariant: Client.ProductVariant | undefined =
        this.Product.getVariants(
          this.cabinetSection.cabinet.ProductLineId,
        ).filter((v) => v.Id === dtoIV.VariantId)[0];

      if (!productVariant) {
        if (!force) return;

        let variant: Interface_DTO.Variant | undefined = undefined;

        for (let v of this.editorAssets.variants) {
          if (v.Id === dtoIV.VariantId) {
            variant = v;
            break;
          }
        }

        if (variant) {
          productVariant = {
            OnlyAdmin: true,
            ...variant,
          };
        } else {
          productVariant = undefined;
        }
      }

      let dtoVo: Interface_DTO.ItemVariant = {
        ActualValue: dtoIV.ActualValue,
        VariantId: dtoIV.VariantId,
        VariantOptionId: dtoIV.VariantOptionId,
      };

      this.dto.VariantOptions.push(dtoVo);
      this.VariantOptions.push(
        new Client.ItemVariant(dtoVo, productVariant, () => this.setDirty()),
      );
    }
  }

  public setDimensionVariantOption(itemVariant: Interface_DTO.ItemVariant) {
    let set = false;

    for (let vo of this.VariantOptions) {
      if (
        vo.variant &&
        vo.variant.Id === itemVariant.VariantId &&
        vo.VariantOptionId === itemVariant.VariantOptionId
      ) {
        vo.ActualValue = itemVariant.ActualValue;
        set = true;
        break;
      }
    }

    if (!set) {
      let newItemVariant: Interface_DTO.ItemVariant = {
        ActualValue: itemVariant.ActualValue,
        VariantId: itemVariant.VariantId,
        VariantOptionId: itemVariant.VariantOptionId,
      };

      this.dto.VariantOptions.push(newItemVariant);
      this.VariantOptions.push(new Client.ItemVariant(newItemVariant));
    }
  }

  public removeVariantOptionByVariantId(variantId: number) {
    this.dto.VariantOptions = this.dto.VariantOptions.filter(
      (vo) => vo.VariantId != variantId,
    );
    this.VariantOptions = this.VariantOptions.filter(
      (vo) => vo.VariantId != variantId,
    );
  }

  public removeVariantOptionBelowNumber(belowNumber: number) {
    this.dto.VariantOptions = this.dto.VariantOptions.filter(
      (vo) => vo.VariantOptionId > belowNumber,
    );
    this.VariantOptions = this.VariantOptions.filter(
      (vo) => vo.VariantOptionId > belowNumber,
    );
  }

  public errors: ErrorInfo[] = [];

  public get maxErrorLevel(): Interface_Enums.ErrorLevel {
    return Math.max(
      Interface_Enums.ErrorLevel.None,
      ...this.errors.map((err) => err.level),
    );
  }

  public get canHaveGrip(): boolean {
    if (!this.Product) return false;
    return ProductHelper.canHaveGrip(this.Product, this.Material);
  }

  /** a grip product mounted on this item, mainly used for drawers */
  public get gripProduct(): Client.Product | null {
    if (this.cache.gripProduct === undefined) {
      let id = this.properties.gripProductId;
      this.cache.gripProduct =
        id === null || id === undefined
          ? null
          : (this.editorAssets.productsDict[id] ?? null);
    }
    return this.cache.gripProduct;
  }

  public set gripProduct(product: Client.Product | null) {
    this.properties.gripProductId = product?.Id ?? undefined;
    this.setDirty();
  }

  /** The material used for gripProduct */
  public get gripMaterial(): Client.Material | null {
    if (this.cache.gripMaterial === undefined) {
      let id = this.properties.gripMaterialId;
      this.cache.gripMaterial =
        id === null || id === undefined
          ? null
          : (this.gripProduct?.materials.find((mat) => mat.Id === id) ?? null);
    }
    return this.cache.gripMaterial;
  }
  public set gripMaterial(mat: Client.Material | null) {
    this.properties.gripMaterialId = mat?.Id ?? undefined;
    this.setDirty();
  }

  // This is entirely UI-related, but there seems to be no better place
  // for it.
  public get isValid(): boolean {
    if (this.Product === null) return false;

    let hasDrillDepthVariant = ProductHelper.hasVariant(
      this.Product,
      VariantNumbers.DrillingMeasurement,
    );

    if (!hasDrillDepthVariant) return true;

    let hasDrillingVariant = ProductHelper.hasVariant(
      this.Product,
      VariantNumbers.Drilling,
    );

    if (!hasDrillingVariant) return true;

    let drillingValue = VariantHelper.getItemVariantValue(
      this.Product.getAllVariants(),
      this.VariantOptions,
      VariantNumbers.Drilling,
    );

    // We assume that no-value "" means No
    if (drillingValue === VariantNumbers.Values.No || drillingValue === '') {
      return true;
    }

    let drillDepthValue = VariantHelper.getItemVariantValueAsNumber(
      this.Product.getAllVariants(),
      this.VariantOptions,
      VariantNumbers.DrillingMeasurement,
    );

    return drillDepthValue != 0;
  }

  public getModel2DPath(): string | null {
    let allMaterials = this.allMaterials;
    return (
      this.Product?.getModel2DPath(
        allMaterials[0]?.material?.Id ?? null,
        allMaterials[1]?.material?.Id ?? null,
      ) ?? null
    );
  }

  public toString() {
    return `X ${this.X} Y ${this.Y} size ${this.Width}x${this.Height} pos ${this.PositionNumber} ${this.Description}`;
  }
}
