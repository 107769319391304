import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import { SuggestedPositionInfo } from 'app/ts/services/snap/GableSnapService';
import { ISnapInfoService } from 'app/ts/services/snap/ISnapInfoService';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { ConfigurationItemService } from 'app/ts/services/ConfigurationItemService';
import { InteriorLogic } from 'app/ts/services/ConfigurationLogic/InteriorLogic';
/**
 * A snapService for multiple items, where one or more are gables or templates.
 * The items are only moved horizontally
 */
export class MultipleHorizontalSnapService implements ISnapInfoService {
  static supportsItems(items: Client.ConfigurationItem[]): boolean {
    return (
      items.length > 1 &&
      (items.some((i) => i.isGable) || items.some((i) => i.isTemplate))
    );
  }

  private readonly firstGable: Client.ConfigurationItem;
  private readonly gapsBetweenGables: Client.GableGap[];
  private readonly suggestedPositions: SuggestedPositionInfo[];
  private readonly suggestedOffsets: Interface_DTO_Draw.Vec2d[];

  private lastSnapInfo: Client.SnapInfo | undefined;

  constructor(
    private readonly configurationItemService: ConfigurationItemService,
    private readonly cabinetSection: Client.CabinetSection,
    private readonly items: [Client.ConfigurationItem],
    private readonly dragStartPoint: Interface_DTO_Draw.Vec2d,
  ) {
    let orderedItems = Enumerable.from(items).orderBy((i) => i.X);
    this.firstGable = orderedItems.first((i) => i.isGable || i.isTemplate);

    let itemIncludingDescendants = Enumerable.from(items)
      .selectMany((i) => i.moduleDescendants)
      .toArray();
    this.gapsBetweenGables = InteriorLogic.getGableGaps(cabinetSection, {
      excludeItems: itemIncludingDescendants,
    });

    this.suggestedPositions = this.getSingleItemPositionSuggestions(
      this.firstGable,
    );
    this.suggestedOffsets = this.suggestedPositions.map((p) =>
      this.toOffset(p.position, this.firstGable),
    );
  }

  public getSnapInfo(pos: Interface_DTO_Draw.Vec2d): Client.SnapInfo {
    pos = {
      ...pos,
      X: pos.X - this.dragStartPoint.X + this.firstGable.X,
    };
    let newPos = {
      X: pos.X,
      Y: this.cabinetSection.interior.cube.Y,
      Z: this.cabinetSection.interior.cube.Z,
    };

    if (this.suggestedPositions.length > 0) {
      let bestSuggestion = ObjectHelper.best(
        this.suggestedPositions.map((s) => s.position) as [Interface_DTO.Vec3d],
        (sp) => Math.abs(sp.X - pos.X),
      );
      let distX = Math.abs(bestSuggestion.X - newPos.X);
      if (Constants.snapDist >= distX) {
        newPos = bestSuggestion;
      }
    }
    let rulers: Client.Ruler[];
    let activeGap = Enumerable.from(this.gapsBetweenGables).firstOrDefault(
      (gap) => gap.X <= newPos.X && gap.X + gap.Width >= newPos.X,
    );
    if (activeGap) {
      let leftRuler = new Client.Ruler(
        false,
        { X: activeGap.X, Y: pos.Y },
        newPos.X - activeGap.X,
        this.cabinetSection.Height,
        false,
      );
      let rightRuler = new Client.Ruler(
        false,
        { X: newPos.X + this.firstGable.Width, Y: pos.Y },
        activeGap.X + activeGap.Width - (newPos.X + this.firstGable.Width),
        this.cabinetSection.Height,
        false,
      );
      rulers = [leftRuler, rightRuler];
    } else {
      rulers = [];
    }

    let result: Client.SnapInfo = {
      dropOffset: {
        ...this.toOffset(newPos, this.firstGable),
      },
      pulloutRestriction: Enums.PulloutWarningSeverity.None,
      rulers: rulers,
      suggestedItemOffsets: this.firstGable.isTemplate
        ? []
        : this.suggestedOffsets,
      mirror: false,
      horizontalGuidelineHeights: [],
      snapsTo: [],
      snappingItems: [this.firstGable],
    };
    this.lastSnapInfo = result;
    return result;
  }

  public place(): {
    items: [Client.ConfigurationItem];
    recalculationMessages: Client.RecalculationMessage[];
  } {
    if (this.lastSnapInfo) {
      for (let item of this.items) {
        item.X += this.lastSnapInfo.dropOffset.X;
        item.Y += this.lastSnapInfo.dropOffset.Y;
        if (this.cabinetSection.interior.items.indexOf(item) < 0) {
          this.cabinetSection.interior.items.push(item);
        }
      }
      if (App.debug.showSnapInfo) {
        console.debug('Snapped by me: ', this);
      }
    }
    return {
      items: this.items,
      recalculationMessages: [],
    };
  }

  public dispose() {}

  //#region Private helpers

  private getSingleItemPositionSuggestions(
    item: Client.ConfigurationItem,
  ): SuggestedPositionInfo[] {
    return Enumerable.from(this.gapsBetweenGables)
      .where((gap) => gap.Y <= this.cabinetSection.interior.cube.Y) //only get gaps that start at the floor
      .selectMany((gap) => this.getGableSuggestionsInGap(gap, item.Width))
      .select<SuggestedPositionInfo>((pos) => {
        return { position: pos, width: item.Width, gap: pos.gap };
      })
      .toArray();
  }

  private getGableSuggestionsInGap(
    gap: Client.GableGap,
    itemWidth: number,
  ): (Interface_DTO_Draw.Vec3d & { gap: Client.GableGap })[] {
    let result: (Interface_DTO_Draw.Vec3d & { gap: Client.GableGap })[] = [];
    let moduleWidths = [602, 1202];
    let yz = {
      Y: this.cabinetSection.interior.cube.Y,
      Z: this.cabinetSection.interior.cube.Z,
      gap: gap,
    };

    if (
      this.firstGable.drilling600Left &&
      gap.leftGable &&
      gap.leftGable.drilling600Right
    ) {
      //module gaps for wash section
      for (let v of moduleWidths) {
        if (v >= gap.Width - this.firstGable.Width) break;
        result.push({
          ...yz,
          X: gap.X + v,
        });
      }
    }
    if (
      this.firstGable.drilling600Right &&
      gap.rightGable &&
      gap.rightGable.drilling600Left
    ) {
      //module gaps for wash section - other side
      for (let v of moduleWidths) {
        if (v >= gap.Width - this.firstGable.Width) break;
        result.push({
          ...yz,
          X: gap.X + gap.Width - v - itemWidth,
        });
      }
    }

    let niceValues = [0, 368, 568, 768];

    if (
      this.firstGable.drilledLeft &&
      (!gap.leftGable ||
        gap.leftGable.drilledRight ||
        gap.leftGable.drilling600Right)
    ) {
      for (let v of niceValues) {
        if (v >= gap.Width - this.firstGable.Width) break;

        if (this.firstGable.snapFront) {
          // Module support gables are pulled to the front of the outer module gable
          let frontZ;

          if (gap.leftGable !== null && gap.leftGable.drilling600Right) {
            frontZ = gap.leftGable.frontZ;
          } else if (
            gap.rightGable !== null &&
            gap.rightGable.drilling600Left
          ) {
            frontZ = gap.rightGable.frontZ;
          }

          if (frontZ) {
            let itemZ =
              frontZ - this.firstGable.depthReduction - this.firstGable.Depth;
            yz.Z = itemZ;
          }
        }

        result.push({
          ...yz,
          X: gap.X + v,
        });
      }
    }
    if (
      this.firstGable.drilledRight &&
      (!gap.rightGable ||
        gap.rightGable.drilledLeft ||
        gap.rightGable.drilling600Left)
    ) {
      for (let v of niceValues) {
        if (v >= gap.Width - this.firstGable.Width) break;

        if (this.firstGable.snapFront) {
          // Module support gables are pulled to the front of the outer module gable
          let frontZ;

          if (gap.leftGable !== null && gap.leftGable.drilling600Right) {
            frontZ = gap.leftGable.frontZ;
          } else if (
            gap.rightGable !== null &&
            gap.rightGable.drilling600Left
          ) {
            frontZ = gap.rightGable.frontZ;
          }

          if (frontZ) {
            let itemZ =
              frontZ - this.firstGable.depthReduction - this.firstGable.Depth;
            yz.Z = itemZ;
          }
        }

        result.push({
          ...yz,
          X: gap.X + gap.Width - v - itemWidth,
        });
      }
    }

    if (!gap.leftGable && gap.Width >= itemWidth) {
      result.push({
        ...yz,
        X: gap.X,
      });
    }

    if (!gap.rightGable && gap.Width >= itemWidth) {
      result.push({
        ...yz,
        X: gap.X + gap.Width - itemWidth,
      });
    }

    return result;
  }

  private toOffset(
    vec: Interface_DTO_Draw.Vec3d,
    item: Client.ConfigurationItem,
  ): Interface_DTO_Draw.Vec3d {
    return VectorHelper.subtract(vec, item);
  }

  //#endregion Private helpers
}
