import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import Enumerable from 'linq';
import * as Client from 'app/ts/clientDto/index';
import { ISnapInfoService } from 'app/ts/services/snap/ISnapInfoService';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { BaseSnapService } from './BaseSnapService';
import { BackingType } from 'app/ts/Interface_Enums';
/**
 * Helper to provide info about snap positions. Only expected to last from dragStart to dragEnd
 */
export class FittingPanelGableSnapService extends BaseSnapService<FittingPanelGableSnapService.SnapInfo> {
  static supportsItems(items: Client.ConfigurationItem[]): boolean {
    return items.length === 1 && items[0].isFittingPanel;
  }

  private readonly possiblePositions: FittingPanelGableSnapService.PositionInfo[];
  private readonly suggestedOffsets: Interface_DTO_Draw.Vec2d[];
  private readonly mouseOffset: Interface_DTO_Draw.Vec2d;

  private _previousRawItemPos?: Interface_DTO_Draw.Vec2d = undefined;
  private _fittingPanelSpacerWidth: number = 78;

  constructor(
    cabinetSection: Client.CabinetSection,
    item: Client.ConfigurationItem,
    dragStartPoint: Interface_DTO_Draw.Vec2d,
    fallbackSnapService: ISnapInfoService,
  ) {
    super(cabinetSection, item, dragStartPoint, fallbackSnapService);
    this.possiblePositions = this.cabinetSection.isSwingFlex
      ? this.getSwingFlexPossiblePositions(item)
      : this.getPossiblePositions(item);
    this.suggestedOffsets = this.possiblePositions.map((p) =>
      this.toOffset(p, item),
    );

    this.mouseOffset = VectorHelper.subtract(
      dragStartPoint,
      item as Interface_DTO_Draw.Vec2d,
    );
  }

  private getSwingFlexPossiblePositions(
    _item: Client.ConfigurationItem,
  ): FittingPanelGableSnapService.PositionInfo[] {
    let result: FittingPanelGableSnapService.PositionInfo[] = [];

    this._fittingPanelSpacerWidth = 0;

    let productLine = Enumerable.from(_item.editorAssets.productLines).single(
      (pl) => pl.Id === this.cabinetSection.cabinet.ProductLineId,
    );
    if (productLine) {
      this._fittingPanelSpacerWidth =
        productLine.Properties.FittingPanelSpacerWidth;
    }

    // Get all interior shelfs that has height 19/22. PLEASE NOTE: Is quick fix to ignore Glashylde and Skohylde.
    let _shelfs = Enumerable.from(this.cabinetSection.swingFlex.items)
      .concat(this.cabinetSection.interior.items)
      .where((i) => i.isShelf_19_22)
      .orderBy((i) => i.Y)
      .asEnumerable();

    let _gables = Enumerable.from(this.cabinetSection.swingFlex.items)
      .where((i) => i.isGable)
      .orderBy((i) => i.X)
      .asEnumerable();

    let _leftShow: boolean = true;
    let _rightShow: boolean = true;

    if (_item.isFittingPanel && _item.drilledLeft) {
      _leftShow = false;
    }

    if (_item.isFittingPanel && _item.drilledRight) {
      _rightShow = false;
    }

    let _subAreas = Enumerable.from(
      this.cabinetSection.swingFlex.areas,
    ).selectMany((c) => c.subAreas);

    _subAreas.forEach((_area) => {
      let _shelfsInArea = _shelfs.where(
        (c) =>
          c.centerX > _area.insideRect.X &&
          c.centerX < _area.insideRect.X + _area.insideRect.Width,
      );
      _shelfsInArea.forEach((_shelf) => {
        if (
          this.cabinetSection.swingFlex.cabinetSection.Height - _shelf.Y <
          _item.minHeight
        ) {
          return;
        }

        let _positionItems: Client.ConfigurationItem[] = [];
        _positionItems.push(_shelf);

        let _shelfAbove = _shelfsInArea.firstOrDefault(
          (s) => s.Y > _shelf.topY,
        );
        if (_shelfAbove) {
          _positionItems.push(_shelfAbove);
        }

        let _gablesLeft = _gables.firstOrDefault(
          (g) => Math.abs(g.rightX - _shelf.leftX) < 1,
        );
        let _gablesRight = _gables.firstOrDefault(
          (g) => Math.abs(g.leftX - _shelf.rightX) < 1,
        );

        if (_gablesLeft && _leftShow) {
          if (_gablesLeft.topY < _shelf.topY + 10) {
            // 10 is a magic number to verify that the gable is not a middle panel
            return;
          }

          let targetItemPos: FittingPanelGableSnapService.PositionInfo = {
            X:
              _gablesLeft.leftX +
              (this._fittingPanelSpacerWidth + _gablesLeft.Width),
            Y: _shelf.topY,
            Width: _gablesLeft.Width,
            Height: 0,
            snapsTo: {
              x: 0,
              width: 0,
              items: _positionItems,
            },
          };
          result.push(targetItemPos);
        }

        if (_gablesRight && _rightShow) {
          if (_gablesRight.topY < _shelf.topY + 10) {
            // 10 is a magic number to verify that the gable is not a middle panel
            return;
          }

          let targetItemPos: FittingPanelGableSnapService.PositionInfo = {
            X:
              _gablesRight.X -
              (this._fittingPanelSpacerWidth + _gablesRight.Width),
            Y: _shelf.topY,
            Width: 0,
            Height: 0,
            snapsTo: {
              x: 0,
              width: 0,
              items: _positionItems,
            },
          };
          result.push(targetItemPos);
        }
      });
    });

    return result;
  }

  private getPossiblePositions(
    _item: Client.ConfigurationItem,
  ): FittingPanelGableSnapService.PositionInfo[] {
    let result: FittingPanelGableSnapService.PositionInfo[] = [];

    let productLine = Enumerable.from(_item.editorAssets.productLines).single(
      (pl) => pl.Id === this.cabinetSection.cabinet.ProductLineId,
    );
    if (productLine) {
      this._fittingPanelSpacerWidth =
        productLine.Properties.FittingPanelSpacerWidth;
    }

    // Get all interior shelfs that has height 19/22. PLEASE NOTE: Is quick fix to ignore Glashylde and Skohylde.
    let _shelfs = Enumerable.from(this.cabinetSection.interior.items)
      .where((i) => i.isShelf_19_22)
      .orderBy((i) => i.Y)
      .asEnumerable();

    let _tempGables: Client.ConfigurationItem[] = [];

    _tempGables.push(
      ...this.cabinetSection.interior.items.filter(
        (i) => i.isGable && !i.isFittingPanel,
      ),
    );

    const firstGable: Client.ConfigurationItem | undefined = Enumerable.from(
      this.cabinetSection.interior.items,
    )
      .where((i) => i.isGable && !i.isFittingPanel)
      .orderBy((i) => i.X)
      .firstOrDefault();

    const lastGable: Client.ConfigurationItem | undefined = Enumerable.from(
      this.cabinetSection.interior.items,
    )
      .where((i) => i.isGable && !i.isFittingPanel)
      .orderByDescending((i) => i.X)
      .firstOrDefault();

    let corpusLeftGable: Client.ConfigurationItem | undefined = undefined;
    let corpusLeftGableSum = 0;

    const corpusLeftGableItems = Enumerable.from(
      this.cabinetSection.corpus.itemsLeft,
    );
    if (corpusLeftGableItems.any()) {
      corpusLeftGable = corpusLeftGableItems.firstOrDefault(
        (c) => Math.abs(c.Depth - this.cabinetSection.Depth) < 20,
      );

      corpusLeftGableSum = corpusLeftGableItems.sum((c) => c.Width);
      corpusLeftGableSum += this._fittingPanelSpacerWidth + _item.Width;
    }

    let corpusRightGable: Client.ConfigurationItem | undefined = undefined;

    const corpusRightGableItems = Enumerable.from(
      this.cabinetSection.corpus.itemsRight,
    );
    if (corpusRightGableItems.any()) {
      corpusRightGable = corpusRightGableItems.firstOrDefault(
        (c) => Math.abs(c.Depth - this.cabinetSection.Depth) < 20,
      );
    }

    if (
      firstGable &&
      corpusLeftGable &&
      firstGable.X - corpusLeftGable.rightX >= corpusLeftGableSum
    ) {
      _tempGables.push(
        ...this.cabinetSection.corpus.itemsLeft.filter(
          (c) => Math.abs(c.Depth - this.cabinetSection.Depth) < 20,
        ),
      );
    }

    if (
      lastGable &&
      corpusRightGable &&
      corpusRightGable.X - lastGable.rightX >= corpusLeftGableSum
    ) {
      _tempGables.push(
        ...this.cabinetSection.corpus.itemsRight.filter(
          (c) => Math.abs(c.Depth - this.cabinetSection.Depth) < 20,
        ),
      );
    }

    let _startY: number = 0;

    if (this.cabinetSection.corpus.itemsBottom.length > 0) {
      _startY = this.cabinetSection.corpus.itemsBottom[0].topY;
    }

    let _topY: number | undefined = undefined;

    if (this.cabinetSection.corpus.itemsTop.length > 0) {
      _topY = this.cabinetSection.corpus.itemsTop[0].bottomY;
    }

    let _gables = Enumerable.from(_tempGables)
      .orderBy((i) => i.X)
      .asEnumerable();

    let _gablesArray = _gables.toArray();

    let _subAreas: Interface_DTO_Draw.Rectangle[] = [];

    for (let i = 0; i < _gablesArray.length; i++) {
      let _gable = _gablesArray[i];

      if (i === _gablesArray.length - 1) {
        break;
      }

      let _nextGable = _gablesArray[i + 1];

      if (!_topY) {
        _topY = _gable.topY > _nextGable.topY ? _gable.topY : _nextGable.topY;
      }

      if (_nextGable) {
        _subAreas.push({
          Height: _topY - _startY,
          Width: _nextGable.X - _gable.rightX,
          X: _gable.rightX,
          Y: _startY,
        });
      }
    }

    let _leftShow: boolean = true;
    let _rightShow: boolean = true;

    if (_item.isFittingPanel && _item.drilledLeft) {
      _leftShow = false;
    }

    if (_item.isFittingPanel && _item.drilledRight) {
      _rightShow = false;
    }

    _subAreas.forEach((_subArea) => {
      let _shelfsInArea = _shelfs.where(
        (c) =>
          c.centerX > _subArea.X && c.centerX < _subArea.X + _subArea.Width,
      );

      if (this.cabinetSection.corpus.itemsBottom.length > 0) {
        _shelfsInArea = _shelfsInArea.concat(
          this.cabinetSection.corpus.itemsBottom,
        );
      }

      if (this.cabinetSection.corpus.itemsTop.length > 0) {
        _shelfsInArea = _shelfsInArea.concat(
          this.cabinetSection.corpus.itemsTop,
        );
      }

      _shelfsInArea.forEach((_shelf) => {
        if (
          this.cabinetSection.swingFlex.cabinetSection.Height - _shelf.Y <
          _item.minHeight
        ) {
          return;
        }

        let _positionItems: Client.ConfigurationItem[] = [];
        _positionItems.push(_shelf);

        let _shelfAbove = _shelfsInArea.firstOrDefault(
          (s) => s.Y > _shelf.topY,
        );

        if (_shelfAbove) {
          _positionItems.push(_shelfAbove);
        }

        let _gablesLeft = _gables.firstOrDefault(
          (g) => Math.abs(g.rightX - _subArea.X) < 1,
        );

        let _gablesRight = _gables.firstOrDefault(
          (g) => Math.abs(g.leftX - (_subArea.X + _subArea.Width)) < 1,
        );

        if (_gablesLeft && _leftShow) {
          if (_gablesLeft.topY < _shelf.topY + 10) {
            // 10 is a magic number to verify that the gable is not a middle panel
            return;
          }

          let targetItemPos: FittingPanelGableSnapService.PositionInfo = {
            X:
              _gablesLeft.leftX +
              (this._fittingPanelSpacerWidth + _gablesLeft.Width),
            Y: _shelf.topY,
            Width: _gablesLeft.Width,
            Height: 0,
            snapsTo: {
              x: 0,
              width: 0,
              items: _positionItems,
            },
          };
          result.push(targetItemPos);
        }

        if (_gablesRight && _rightShow) {
          if (_gablesRight.topY < _shelf.topY + 10) {
            // 10 is a magic number to verify that the gable is not a middle panel
            return;
          }

          let targetItemPos: FittingPanelGableSnapService.PositionInfo = {
            X:
              _gablesRight.X -
              (this._fittingPanelSpacerWidth + _gablesRight.Width),
            Y: _shelf.topY,
            Width: 0,
            Height: 0,
            snapsTo: {
              x: 0,
              width: 0,
              items: _positionItems,
            },
          };
          result.push(targetItemPos);
        }
      });
    });
    return result;
  }

  protected overriddenGetSnapInfo(
    position: Interface_DTO_Draw.Vec2d,
  ): Client.SnapInfo | undefined {
    let rawItemPos = VectorHelper.subtract(position, this.mouseOffset);

    if (!this._previousRawItemPos) {
      this._previousRawItemPos = rawItemPos;
    }

    if (!this.possiblePositions || this.possiblePositions.length === 0) {
      return;
    }

    let sortedSuggestions = Enumerable.from(this.possiblePositions)
      .orderBy((p) => Math.abs(p.X - rawItemPos.X))
      .thenBy((p) => Math.abs(p.Y - rawItemPos.Y));

    let bestSuggestion = sortedSuggestions.first();

    let _dropOffset = {
      ...VectorHelper.subtract(bestSuggestion, this.item),
      Z: 0,
    };

    let _result: FittingPanelGableSnapService.SnapInfo = {
      dropOffset: _dropOffset,
      pulloutRestriction: Enums.PulloutWarningSeverity.None,
      rulers: [],
      suggestedItemOffsets: this.item.isTemplate ? [] : this.suggestedOffsets,
      mirror: false,
      horizontalGuidelineHeights: [],
      snapsTo: bestSuggestion.snapsTo.items,
      snappingItems: [this.item],
    };

    return _result;
  }

  protected overriddenPlace(
    lastSnapInfo: Client.SnapInfo,
  ): [Client.ConfigurationItem] {
    //do nothing, base service handles everything
    let topShelf = Enumerable.from(lastSnapInfo.snapsTo).maxBy((c) => c.Y);

    let bottomShelf = Enumerable.from(lastSnapInfo.snapsTo).minBy((c) => c.Y);

    if (Math.abs(this.item.topY - topShelf.bottomY) > 2) {
      this.item.trySetHeight(topShelf.bottomY - this.item.Y, true);
    }

    if (Math.abs(this.item.bottomY - bottomShelf.topY) > 2) {
      this.item.Y = bottomShelf.topY;
      this.item.trySetHeight(bottomShelf.topY - this.item.Y, true);
    }

    for (let i = 0; i < this.cabinetSection.swingFlex.areas.length; i++) {
      let area = this.cabinetSection.swingFlex.areas[i];

      if (this.lastSnapInfo) {
        let rawItemPos = VectorHelper.subtract(area.insideRect, this.item);
        if (
          area.insideRect.Width - (area.insideRect.Width + rawItemPos.X) ===
            this._fittingPanelSpacerWidth ||
          rawItemPos.X + area.insideRect.Width - this.item.Width ===
            this._fittingPanelSpacerWidth
        ) {
          this.item.swingFlexAreaIndex = area.index;
        }
      }
    }

    this.addFittingPanelSpacer(lastSnapInfo);
    return [this.item];
  }

  toOffset(
    position: Interface_DTO_Draw.Rectangle,
    item: Client.ConfigurationItem,
  ): any {
    let _result = VectorHelper.subtract(position, item);
    _result.X = Math.round(_result.X);
    _result.Y = Math.round(_result.Y);
    return _result;
  }

  removeFittingPanelSpacer(): void {
    this.cabinetSection.backing.setBackingType(BackingType.Hidden, false);
  }

  addFittingPanelSpacer(lastSnapInfo: Client.SnapInfo): void {
    this.cabinetSection.backing.setBackingType(BackingType.Visible, false);
  }
}

export module FittingPanelGableSnapService {
  export interface SnapInfo extends Client.SnapInfo {}

  export interface PositionInfo extends Interface_DTO_Draw.Rectangle {
    snapsTo: {
      x: number;
      width: number;
      items: Client.ConfigurationItem[];
    };
  }
}
