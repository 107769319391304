import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import * as Client from 'app/ts/clientDto/index';
import { BackingValidationService } from 'app/ts/services/Validation/BackingValidationService';
import { CorpusValidationService } from 'app/ts/services/Validation/CorpusValidationService';
import { DoorValidationService } from 'app/ts/services/Validation/DoorValidationService';
import { InteriorValidationService } from 'app/ts/services/Validation/InteriorValidationService';
import { SwingValidationService } from 'app/ts/services/Validation/SwingValidationService';
import { SwingFlexValidationService } from '@Services/Validation/SwingFlexValidationService';
export class CabinetSectionValidationService {
  public static readonly Name = 'cabinetSectionValidationService';

  constructor() {}

  public accessTest() {
    return 'Cabinet Section Validation Service access test function';
  }

  public static validate(section: Client.CabinetSection): Client.ErrorInfo[] {
    this.clearItemErrors(section);

    let errors: Client.ErrorInfo[] = new Array<Client.ErrorInfo>();

    errors.push(...CorpusValidationService.validate(section));
    errors.push(...DoorValidationService.validate(section));
    errors.push(...SwingValidationService.validate(section));
    errors.push(...SwingFlexValidationService.validate(section));
    errors.push(...InteriorValidationService.validate(section));
    errors.push(...BackingValidationService.validate(section));
    errors.push(
      ...CabinetSectionValidationService.validateAdditionalProductInfo(section),
    );

    return errors;
  }

  private static clearItemErrors(section: Client.CabinetSection) {
    section.corpus.allItems.forEach((item) => (item.errors.length = 0));
    section.doors.doorItems.forEach((item) => (item.errors.length = 0));
    section.doors.railItems.forEach((item) => (item.errors.length = 0));
    section.interior.items.forEach((item) => (item.errors.length = 0));
    section.backing.items.forEach((item) => (item.errors.length = 0));
    section.swingFlex.items.forEach((item) => (item.errors.length = 0));
  }

  private static validateAdditionalProductInfo(
    section: Client.CabinetSection,
  ): Client.ErrorInfo[] {
    let errorInfos = this.validateAdditionalProductInfo2(
      section,
      Enums.EditorSection.Doors,
      section.doors.railItems,
    )
      .concat(
        this.validateAdditionalProductInfo2(
          section,
          Enums.EditorSection.Corpus,
          section.corpus.itemsTop,
        ),
      )
      .concat(
        this.validateAdditionalProductInfo2(
          section,
          Enums.EditorSection.Corpus,
          section.corpus.itemsBottom,
        ),
      )
      .concat(
        this.validateAdditionalProductInfo2(
          section,
          Enums.EditorSection.Corpus,
          section.corpus.itemsLeft,
        ),
      )
      .concat(
        this.validateAdditionalProductInfo2(
          section,
          Enums.EditorSection.Corpus,
          section.corpus.itemsRight,
        ),
      )
      .concat(
        this.validateAdditionalProductInfo2(
          section,
          Enums.EditorSection.Swing,
          section.swing.items,
        ),
      )
      .concat(
        this.validateAdditionalProductInfo2(
          section,
          Enums.EditorSection.Interior,
          section.interior.items,
        ),
      )
      .concat(
        this.validateAdditionalProductInfo2(
          section,
          Enums.EditorSection.Backing,
          section.backing.items,
        ),
      )
      .concat(
        this.validateAdditionalProductInfo2(
          section,
          Enums.EditorSection.PieceList,
          section.ManuallyAddedItems,
        ),
      )
      .concat(
        this.validateAdditionalProductInfo2(
          section,
          Enums.EditorSection.PieceList,
          section.CustomItems,
        ),
      )
      .concat(
        this.validateAdditionalProductInfo2(
          section,
          Enums.EditorSection.PieceList,
          section.AddedByServiceItems,
        ),
      );
    let result = errorInfos.toArray();
    return result;
  }

  private static validateAdditionalProductInfo2(
    section: Client.CabinetSection,
    subsection: Enums.EditorSection,
    items: Client.ConfigurationItem[],
  ): Enumerable.IEnumerable<Client.ErrorInfo> {
    return this.validateAdditionalProductInfo3(
      section,
      subsection,
      items,
    ).concat(
      this.validateAdditionalProductGroupInfo2(section, subsection, items),
    );
  }

  private static validateAdditionalProductInfo3(
    section: Client.CabinetSection,
    subsection: Enums.EditorSection,
    items: Client.ConfigurationItem[],
  ): Enumerable.IEnumerable<Client.ErrorInfo> {
    let productsNeedingToolTip = Enumerable.from(items)
      .where((item) => !!item.Product)
      .select((item) => item.Product!)
      .groupBy((product) => product.ProductNo)
      .select((productGroup) => productGroup.first())
      .where((product) => {
        let pd = product.getProductData();
        if (!pd) return false;
        return !!(
          pd.Type & Interface_Enums.ProductDataType.AdditionalProductInfo
        );
      });

    let errorInfos = productsNeedingToolTip.select(
      (p) =>
        new Client.ErrorInfo(
          subsection,
          Interface_Enums.ErrorLevel.Info,
          new Client.Translatable(
            'validate_additionalProductInfo_short_' + p.ProductNo,
            'Additional product info',
          ),
          new Client.Translatable(
            'validate_additionalProductInfo_long_' + p.ProductNo,
            'Additional product info for product ' + p.ProductNo,
          ),
          section,
        ),
    );

    return errorInfos;
  }

  private static validateAdditionalProductGroupInfo2(
    section: Client.CabinetSection,
    subsection: Enums.EditorSection,
    items: Client.ConfigurationItem[],
  ): Enumerable.IEnumerable<Client.ErrorInfo> {
    let productsNeedingToolTip = Enumerable.from(items)
      .where((item) => !!item.Product && !!item.Product.ProductGroupingNo)
      .select((item) => item.Product!)
      .groupBy((product) => product.ProductGroupingNo)
      .select((productGroup) => productGroup.first())
      .where((product) => {
        let pd = product.getProductData();
        if (!pd) return false;
        return !!(
          pd.Type & Interface_Enums.ProductDataType.AdditionalGroupInfo
        );
      });

    let errorInfos = productsNeedingToolTip.select(
      (p) =>
        new Client.ErrorInfo(
          subsection,
          Interface_Enums.ErrorLevel.Info,
          new Client.Translatable(
            'additionalGroupInfo_short_' + p.ProductGroupingNo,
            'Additional product group info',
          ),
          new Client.Translatable(
            'additionalGroupInfo_long_' + p.ProductGroupingNo,
            'Additional product group info for product ' + p.ProductGroupingNo,
          ),
          section,
        ),
    );

    return errorInfos;
  }
}
