import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'ka-link',
  templateUrl: './kaLink.html',
  styleUrls: ['../../../../style/kaLink.scss'],
})
export class KaLinkVm extends BaseVm implements OnChanges {
  private static iPaperRegex = /^http(?:s?)\:\/\/ipaper\.ipapercms\.dk\/(.*)/;
  private static youtubeRegex =
    /^http(?:s?)\:\/\/www\.youtube\.com\/watch\?v\=([^\&]+)/;
  private static vimeo2Regex = /https\:\/\/vimeo.com\/(\d+)\/([\d\w]+)/;
  private static vimeoRegex = /https\:\/\/vimeo.com\/(\d+)/;

  constructor(
    baseVmService: BaseVmService,
    private readonly $sce: DomSanitizer,
  ) {
    super(baseVmService);
  }

  // #region AngularJS bindings
  @Input()
  public link!: Interface_DTO.Link;

  // #endregion

  public linkVm: KaLinkVm.LinkType | null = null;

  public ngOnChanges(changes: SimpleChanges): void {
    this.linkVm = this.getLinkType(this.link);
  }

  private getLinkType(link: Interface_DTO.Link): KaLinkVm.LinkType | null {
    if (!link) return null;

    let iPaperResult = KaLinkVm.iPaperRegex.exec(link.Url);
    if (iPaperResult) {
      let src =
        'https://ipaper.ipapercms.dk/' +
        iPaperResult[1].trim() +
        '-/MiniPapers/Static.aspx?page=1';
      return {
        type: 'IPaperLink',
        src: this.$sce.bypassSecurityTrustResourceUrl(src),
        name: link.Name,
        href: link.Url,
      };
    }
    const vimeo2Result = KaLinkVm.vimeo2Regex.exec(link.Url);
    if (vimeo2Result) {
      let videoId = vimeo2Result[1];
      let hId = vimeo2Result[2];
      let src = `https://player.vimeo.com/video/${videoId}?h=${hId}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`;
      return {
        type: 'Vimeo2Link',
        name: link.Name,
        src: this.$sce.bypassSecurityTrustResourceUrl(src),
        href: link.Url,
      };
    }

    let vimeoRegResult = KaLinkVm.vimeoRegex.exec(link.Url);
    if (vimeoRegResult) {
      let videoId = vimeoRegResult[1];
      let src = 'https://player.vimeo.com/video/' + videoId;
      return {
        type: 'VimeoLink',
        name: link.Name,
        src: this.$sce.bypassSecurityTrustResourceUrl(src),
        href: link.Url,
      };
    }

    let youtubeRegResult = KaLinkVm.youtubeRegex.exec(link.Url);
    if (youtubeRegResult) {
      let src =
        'https://www.youtube.com/embed/' +
        youtubeRegResult[1] +
        '?rel=0&showinfo=0';
      return {
        type: 'YoutubeLink',
        src: this.$sce.bypassSecurityTrustResourceUrl(src),
        name: link.Name,
        href: link.Url,
      };
    }
    return {
      type: 'NormalLink',
      href: link.Url,
      name: link.Name,
    };
  }
}

export module KaLinkVm {
  export interface YoutubeLink {
    type: 'YoutubeLink';
    src: SafeResourceUrl;
    name: string;
    href: string;
  }
  export interface NormalLink {
    type: 'NormalLink';
    href: string;
    name: string;
  }
  export interface IPaperLink {
    type: 'IPaperLink';
    src: SafeResourceUrl;
    name: string;
    href: string;
  }
  export interface VimeoLink {
    type: 'VimeoLink';
    src: SafeResourceUrl;
    name: string;
    href: string;
  }
  export interface Vimeo2Link {
    type: 'Vimeo2Link';
    src: SafeResourceUrl;
    name: string;
    href: string;
  }
  export type LinkType =
    | YoutubeLink
    | IPaperLink
    | NormalLink
    | VimeoLink
    | Vimeo2Link;
}
