import * as Interface_DTO_FloorPlan from 'app/ts/Interface_DTO_FloorPlan';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { BackingLogic } from 'app/ts/services/ConfigurationLogic/BackingLogic';
import { ConfigurationItemService } from 'app/ts/services/ConfigurationItemService';
import { InteriorLogic } from 'app/ts/services/ConfigurationLogic/InteriorLogic';
import { ScreenshotService } from 'app/screenshooting/screenshot.service';
import { CorpusLogic } from 'app/ts/services/ConfigurationLogic/CorpusLogic';
import { DimensionLogic } from 'app/ts/services/ConfigurationLogic/DimensionLogic';
import { DoorLogic } from 'app/ts/services/ConfigurationLogic/DoorLogic';
import { SwingLogic } from 'app/ts/services/ConfigurationLogic/SwingLogic';
import { SwingFlexLogic } from '@Services/ConfigurationLogic/SwingFlexLogic';
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class CabinetSectionService {
  public static readonly Name = 'cabinetSectionService';

  private localId = -2;

  constructor(
    public readonly configurationItemService: ConfigurationItemService,
    private readonly dimensionLogic: DimensionLogic,
    private readonly corpusLogic: CorpusLogic,
    private readonly doorLogic: DoorLogic,
    private readonly swingLogic: SwingLogic,
    private readonly swingFlexLogic: SwingFlexLogic,
    private readonly interiorLogic: InteriorLogic,
    private readonly backingLogic: BackingLogic,
  ) {}

  public createCabinetSections(
    cabinet: Client.Cabinet,
    item: Interface_DTO_FloorPlan.Item,
  ): Client.CabinetSection[] {
    if (cabinet.CabinetType === Interface_Enums.CabinetType.SingleCabinet) {
      let dtoSection: Interface_DTO.CabinetSection = this.getStandardSection(
        this.localId,
        cabinet,
        cabinet.CabinetType,
      );

      dtoSection.CabinetSectionIndex = 1;
      dtoSection.Width = item.Width;
      let result = new Client.CabinetSection(dtoSection, cabinet);

      return [result];
    } else if (
      cabinet.CabinetType === Interface_Enums.CabinetType.CornerCabinet
    ) {
      let leftDto = this.getStandardSection(
        this.localId,
        cabinet,
        cabinet.CabinetType,
      );
      leftDto.CabinetSectionIndex = 1;
      leftDto.Width = item.Width;
      leftDto.Rotation = 0;
      let leftClient = new Client.CabinetSection(leftDto, cabinet);

      let rightDto = this.getStandardSection(
        this.localId,
        cabinet,
        cabinet.CabinetType,
      );
      rightDto.CabinetSectionIndex = 2;
      rightDto.Width = item.Height;
      rightDto.Rotation = -90;
      let rightClient = new Client.CabinetSection(rightDto, cabinet);

      return [leftClient, rightClient];
    } else if (cabinet.CabinetType === Interface_Enums.CabinetType.WalkIn) {
      let leftDto = this.getStandardSection(
        this.localId,
        cabinet,
        cabinet.CabinetType,
      );
      leftDto.CabinetSectionIndex = 1;
      leftDto.Width = item.Height;
      leftDto.Rotation = 90;
      let leftClient = new Client.CabinetSection(leftDto, cabinet);

      let middleDto = this.getStandardSection(
        this.localId,
        cabinet,
        cabinet.CabinetType,
      );
      middleDto.CabinetSectionIndex = 2;
      middleDto.Width = item.Width;
      middleDto.Rotation = 0;
      let middleClient = new Client.CabinetSection(middleDto, cabinet);

      let rightDto = this.getStandardSection(
        this.localId,
        cabinet,
        cabinet.CabinetType,
      );
      rightDto.CabinetSectionIndex = 3;
      rightDto.Width = item.Height;
      rightDto.Rotation = -90;
      let rightClient = new Client.CabinetSection(rightDto, cabinet);

      return [leftClient, middleClient, rightClient];
    } else if (cabinet.CabinetType === Interface_Enums.CabinetType.Doors) {
      let dto = this.getStandardSection(
        this.localId,
        cabinet,
        cabinet.CabinetType,
      );
      dto.CabinetSectionIndex = 1;
      dto.Width = item.Width;
      let result = new Client.CabinetSection(dto, cabinet);

      return [result];
    } else if (
      cabinet.CabinetType === Interface_Enums.CabinetType.SharedItems
    ) {
      let dto = this.getStandardSection(
        this.localId,
        cabinet,
        cabinet.CabinetType,
      );
      dto.CabinetSectionIndex = 1;
      dto.Width = item.Width;
      let result = new Client.CabinetSection(dto, cabinet);

      return [result];
    } else if (cabinet.CabinetType === Interface_Enums.CabinetType.Swing) {
      let dto = this.getStandardSection(
        this.localId,
        cabinet,
        cabinet.CabinetType,
      );
      dto.CabinetSectionIndex = 1;
      dto.Width = item.Width;
      let result = new Client.CabinetSection(dto, cabinet);

      return [result];
    } else if (cabinet.CabinetType === Interface_Enums.CabinetType.SwingFlex) {
      let dto = this.getStandardSection(
        this.localId,
        cabinet,
        cabinet.CabinetType,
      );
      dto.CabinetSectionIndex = 1;
      dto.Width = item.Width;
      let result = new Client.CabinetSection(dto, cabinet);

      return [result];
    } else {
      throw new Error(
        'Not Implemented - creating cabinet sections for cabinet type ' +
          cabinet.CabinetType +
          ' not implemented',
      );
    }
  }

  private getStandardSection(
    id: number,
    cabinet: Client.Cabinet,
    cabinetType: Interface_Enums.CabinetType,
  ): Interface_DTO.CabinetSection {
    let result: Interface_DTO.CabinetSection = {
      Id: -1,
      CabinetIndex: cabinet.CabinetIndex,
      CabinetType: cabinetType,
      BackingMaterialId: null,
      BackingType: Interface_Enums.BackingType.None,
      BottomHeight: 0,
      CorpusMaterialId: null,
      CorpusMaterialNumber: '',
      CorpusPanelIdTop: 13, //IDs that happens to match in my local DB - they will probably be reset if they don't match
      CorpusPanelIdBottom: 26,
      CorpusPanelIdLeft: 1,
      CorpusPanelIdRight: 1,
      Depth: cabinet.productLine.DefaultDepth,
      Description: '',
      ExtraRailWidthLeft: 0,
      ExtraRailWidthRight: 0,
      Height: this.getStandardSectionHeight(cabinet),
      HeightReductionBacking: false,
      HeightReductionCorpusLeft: false,
      HeightReductionCorpusRight: false,
      HeightReductionInterior: false,
      InteriorDepth: cabinet.productLine.DefaultDepthInterior,
      InteriorFreeSpaceLeft: 0,
      InteriorFreeSpaceRight: 0,
      InteriorHeight: 0,
      InteriorMaterialId: null,
      InteriorMaterialNumber: '',
      InteriorWidth: 0,
      LeftWidth: 0,
      LightingProductId: null,
      ManualFishJointPositioning: false,
      Name: '',
      NumberOfDoors: 0,
      NumberOfOverlaps: 0,
      OverlapWidth: 0,
      PositionX: 0,
      PositionY: 0,
      PreviousConfigurationId: 0,
      RightWidth: 0,
      Rotation: 0,
      SightHeight: 0,
      SightWidth: 0,
      Width: cabinet.productLine.DefaultWidth,
      CabinetSectionIndex: 1,
      AddedByServiceItems: [],
      BackingItems: [],
      CorpusBottomItems: [],
      CorpusLeftItems: [],
      CorpusRightItems: [],
      CorpusTopItems: [],
      CustomItems: [],
      DoorItems: [],
      InteriorItems: [],
      LightingItems: [],
      ManuallyAddedItems: [],
      RailItems: [],
      SwingItems: [],
      ConfigurationItems: [],
      Images: [],
    };
    return result;
  }

  private getStandardSectionHeight(cabinet: Client.Cabinet): number {
    let ceilingHeight = cabinet.floorPlan.Size.Z;
    return Math.max(
      Constants.minCabinetHeight,
      Math.min(Constants.maxCabinetHeight, ceilingHeight),
    );
  }

  public upgrade(cabinetSection: Client.CabinetSection, ruleSet: number) {
    this.backingLogic.upgrade(cabinetSection, ruleSet);
  }

  public cleanupBeforeCalculations(section: Client.CabinetSection): void {
    this.interiorLogic.cleanupBeforeCalculations(section);
  }

  public recalculate(
    section: Client.CabinetSection,
    initialLoad: boolean,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];

    result.push(...this.dimensionLogic.recalculate(section));

    result.push(...this.corpusLogic.recalculate(section, initialLoad));
    result.push(...this.doorLogic.recalculate(section));

    result.push(...this.swingLogic.recalculate(section));
    result.push(...this.swingFlexLogic.recalculate(section));

    result.push(...this.interiorLogic.recalculatePartOne(section));
    result.push(...this.backingLogic.recalculatePartOne(section));

    result.push(...this.corpusLogic.recalculatePartTwo(section));

    result.push(...this.interiorLogic.recalculatePartTwo(section));
    result.push(...this.backingLogic.recalculatePartTwo(section));
    result.push(...this.dimensionLogic.recalculatePartTwo(section));

    result.push(...this.backingLogic.recalculatePartThree(section));

    if (App.useDebug && App.debug.showTimings)
      console.time('Recalculate Configuration items');

    for (let item of section.configurationItems)
      result.push(...this.configurationItemService.recalculate(item));

    if (App.useDebug && App.debug.showTimings)
      console.timeEnd('Recalculate Configuration items');

    return result;
  }

  public recalculatePartTwo(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];

    result.push(...this.doorLogic.recalculatePartTwo(section));

    result.push(...this.recalculateImages(section));

    return result;
  }

  private recalculateImages(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];

    section.Images = ScreenshotService.interestingSubjects(
      section,
    ).map<Interface_DTO.CabinetSectionImage>((sub) => {
      return { Subject: sub };
    });

    return result;
  }
}
