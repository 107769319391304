import * as Interface_DTO_FloorPlan from 'app/ts/Interface_DTO_FloorPlan';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { DateHelper } from 'app/ts/util/DateHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { CabinetSectionService } from 'app/ts/services/CabinetSectionService';
import { TranslationService } from 'app/ts/services/TranslationService';
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class CabinetService {
  public static readonly Name = 'cabinetService';

  constructor(
    private readonly translationService: TranslationService,
    private readonly cabinetSectionService: CabinetSectionService,
  ) {}

  public createCabinet(
    floorPlan: Client.FloorPlan,
    item: Interface_DTO_FloorPlan.Item,
    productLineId: number,
  ): Client.Cabinet {
    if (item.CabinetType === null) {
      throw new Error('Cabinet type must be set');
    }

    let newCabinetIndex =
      Math.max(0, ...floorPlan.cabinets.map((cab) => cab.CabinetIndex)) + 1;

    let dtoCabinet: Interface_DTO.Cabinet = {
      CabinetType: item.CabinetType,
      CreatedDate: DateHelper.toIsoString(new Date(Date.now())),
      Name: this.translationService.translate(
        'cabinet_default_name_{0}',
        'Cabinet {0}',
        newCabinetIndex.toString(),
      ),
      UserId: floorPlan.UserId,
      ProductLineId: productLineId,
      Description: '',
      OverrideChain: false,
      SupporterId: null,
      CabinetIndex: newCabinetIndex,
      FloorPlanId: floorPlan.Id,
      CabinetSections: [],
      Rotation:
        item.CabinetType === Interface_Enums.CabinetType.WalkIn ? 90 : 0,
      EndUserSetupId: null,
      Alignment: Interface_Enums.CabinetAlignment.FreeStanding,
      UnevenFloor: null,
      UnevenLeftWall: null,
      UnevenRightWall: null,
      UseRoof: null,
    };
    item.CabinetIndex = dtoCabinet.CabinetIndex;

    let clientCabinet: Client.Cabinet = new Client.Cabinet(
      floorPlan,
      dtoCabinet,
    );

    let clientSections = this.cabinetSectionService.createCabinetSections(
      clientCabinet,
      item,
    );
    clientCabinet.cabinetSections = clientSections;
    clientCabinet.cabinetSections[0].corpus.setDefaultMaterial();

    let ceilingHeight = floorPlan.Size.Z;
    clientCabinet.cabinetSections[0].Height = ceilingHeight;

    floorPlan.cabinets.push(clientCabinet);
    this.recalculate(clientCabinet, false);
    return clientCabinet;
  }

  upgrade(cabinet: Client.Cabinet, ruleSet: number) {
    for (let cabinetSection of cabinet.cabinetSections)
      this.cabinetSectionService.upgrade(cabinetSection, ruleSet);
  }

  /**
   * Moves the cabinet inside the floorplan, and resizes the floorplan.
   * Currently only works for single cabinets placed along the first wall
   */
  setAlignment(
    cabinet: Client.Cabinet,
    alignment: Interface_Enums.CabinetAlignment,
  ) {
    this.setAlignment2(cabinet, alignment);
    this.setUnevenWallLeft2(cabinet, cabinet.UnevenLeftWall);
    this.setUnevenWallRight2(cabinet, cabinet.UnevenRightWall);
  }
  private setAlignment2(
    cabinet: Client.Cabinet,
    alignment: Interface_Enums.CabinetAlignment,
  ) {
    cabinet.Alignment = alignment;

    let x: number;
    let extraSpaces: number;
    let keepUnevenWalls = {
      left: true,
      right: true,
    };

    if (alignment === Interface_Enums.CabinetAlignment.LeftAligned) {
      x = 0;
      extraSpaces = 1;
      keepUnevenWalls.right = false;
    } else if (alignment === Interface_Enums.CabinetAlignment.RightAligned) {
      x = 1;
      extraSpaces = 1;
      keepUnevenWalls.left = false;
    } else if (alignment === Interface_Enums.CabinetAlignment.WallToWall) {
      x = 0;
      extraSpaces = 0;
    } else if (alignment === Interface_Enums.CabinetAlignment.FreeStanding) {
      x = 1;
      extraSpaces = 2;
      keepUnevenWalls.left = false;
      keepUnevenWalls.right = false;
    } else if (alignment === Interface_Enums.CabinetAlignment.Unknown) {
      //Do nothing
      return;
    } else {
      throw new Error('Unknown cabinet alignment: ' + alignment);
    }

    const spaceBetweenCabinetAndWalls = 1500;

    cabinet.item.X = x * spaceBetweenCabinetAndWalls;
    cabinet.floorPlan.Size = {
      ...cabinet.floorPlan.Size,
      X:
        cabinet.actualCabinetSections[0].Width +
        extraSpaces * spaceBetweenCabinetAndWalls,
    };
    if (!keepUnevenWalls.left) {
      this.setUnevenWallLeft2(cabinet, false);
    }
    if (!keepUnevenWalls.right) {
      this.setUnevenWallRight2(cabinet, false);
    }
  }

  setUnevenFloor(cabinet: Client.Cabinet, val: boolean) {
    cabinet.UnevenFloor = val;

    const panelNumber = val
      ? Constants.floorUnevenCorpusPanel
      : Constants.floorEvenCorpusPanel;

    let panel =
      cabinet.actualCabinetSections[0].corpus.availablePanelsBottom.filter(
        (panel) => panel.Number === panelNumber,
      )[0];
    if (!panel) return;
    cabinet.actualCabinetSections[0].corpus.panelBottom = panel;
  }

  setUseRoof(cabinet: Client.Cabinet, val: boolean) {
    cabinet.UseRoof = val;

    const panelNumber = val
      ? Constants.defaultRoofPanel
      : Constants.defaultNonRoofPanel;

    let panel =
      cabinet.actualCabinetSections[0].corpus.availablePanelsTop.filter(
        (panel) => panel.Number === panelNumber,
      )[0];
    if (!panel) return;
    cabinet.actualCabinetSections[0].corpus.panelTop = panel;
  }

  setUnevenWallLeft(cabinet: Client.Cabinet, val: boolean | null) {
    this.setUnevenWallLeft2(cabinet, val);
  }
  private setUnevenWallLeft2(cabinet: Client.Cabinet, val: boolean | null) {
    cabinet.UnevenLeftWall = val;
    if (val === null) return;

    const hasWall =
      cabinet.Alignment === Interface_Enums.CabinetAlignment.LeftAligned ||
      cabinet.Alignment === Interface_Enums.CabinetAlignment.WallToWall;

    let panelNumber = this.getPanelNumber(hasWall, !val);

    let panel =
      cabinet.actualCabinetSections[0].corpus.availablePanelsLeft.filter(
        (panel) => panel.Number === panelNumber,
      )[0];
    if (!panel) return;
    cabinet.actualCabinetSections[0].corpus.panelLeft = panel;
  }

  private getPanelNumber(hasWall: boolean, isEven: boolean) {
    if (!hasWall) {
      return Constants.WallNonePanel;
    } else if (isEven) {
      return Constants.WallEvenPanel;
    }
    return Constants.WallUnevenPanel;
  }

  setUnevenWallRight(cabinet: Client.Cabinet, val: boolean | null) {
    this.setUnevenWallRight2(cabinet, val);
  }
  private setUnevenWallRight2(cabinet: Client.Cabinet, val: boolean | null) {
    cabinet.UnevenRightWall = val;
    if (val === null) return;

    const hasWall =
      cabinet.Alignment === Interface_Enums.CabinetAlignment.RightAligned ||
      cabinet.Alignment === Interface_Enums.CabinetAlignment.WallToWall;

    let panelNumber = this.getPanelNumber(hasWall, !val);

    let panel =
      cabinet.actualCabinetSections[0].corpus.availablePanelsRight.filter(
        (panel) => panel.Number === panelNumber,
      )[0];
    if (!panel) return;
    cabinet.actualCabinetSections[0].corpus.panelRight = panel;
  }

  public resetCorpusFeatures(cabinet: Client.Cabinet) {
    this.setUnevenFloor(cabinet, cabinet.UnevenFloor || false);
    this.setUseRoof(cabinet, cabinet.UseRoof || false);
    this.setUnevenWallLeft(cabinet, cabinet.UnevenLeftWall || false);
    this.setUnevenWallRight(cabinet, cabinet.UnevenRightWall || false);
  }

  public recalculate(
    cabinet: Client.Cabinet,
    initialLoad: boolean,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];

    cabinet.Name = ObjectHelper.truncateString(
      cabinet.Name,
      Constants.maxCabinetNameLength,
    );
    this.recalculatePositions(cabinet);
    //do some cleanup/clearing of variants
    for (let section of cabinet.cabinetSections) {
      this.cabinetSectionService.cleanupBeforeCalculations(section);
    }
    //recalculate all subsections one by one
    for (let section of cabinet.cabinetSections) {
      result.push(
        ...this.cabinetSectionService.recalculate(section, initialLoad),
      );
    }

    // Do some cabinet wide cleanup
    cabinet.cabinetSections.forEach(
      (section) => (section.doors.copyDoor = undefined),
    );

    for (let section of cabinet.cabinetSections) {
      result.push(...this.cabinetSectionService.recalculatePartTwo(section));
    }

    return result;
  }

  //#region cabinet recalculation stuff

  private recalculatePositions(cabinet: Client.Cabinet) {
    cabinet.Rotation = ((cabinet.Rotation % 360) + 360) % 360; //a number >= 0 and < 360

    let quarterTurns = cabinet.Rotation / 90;
    if (!(quarterTurns % 1 === 0)) {
      console.error('cabinet has a strange rotation:' + cabinet.Rotation);
      quarterTurns = 0;
    }

    if (cabinet.CabinetType === Interface_Enums.CabinetType.CornerCabinet) {
      let s0 = cabinet.cabinetSections[0];
      let s1 = cabinet.cabinetSections[1];
      s0.Rotation = cabinet.Rotation;
      s1.Rotation = cabinet.Rotation - 90;
      switch (quarterTurns) {
        default:
        case 0:
          s0.PositionX = 0;
          s0.PositionY = 0;
          s1.PositionX = s0.Width;
          s1.PositionY = 0;
          break;
        case 1:
          s0.PositionX = 0;
          s0.PositionY = s0.Width;
          s1.PositionX = 0;
          s1.PositionY = 0;
          break;
        case 2:
          s0.PositionX = s0.Width;
          s0.PositionY = s1.Width;
          s1.PositionX = 0;
          s1.PositionY = s1.Width;
          break;
        case 3:
          s0.PositionX = s1.Width;
          s0.PositionY = 0;
          s1.PositionX = s1.Width;
          s1.PositionY = s0.Width;
          break;
      }
    } else if (cabinet.CabinetType === Interface_Enums.CabinetType.WalkIn) {
      let sleft = cabinet.cabinetSections[0];
      let smiddle = cabinet.cabinetSections[1];
      let sright = cabinet.cabinetSections[2];

      sleft.Rotation = cabinet.Rotation;
      smiddle.Rotation = cabinet.Rotation - 90;
      sright.Rotation = cabinet.Rotation - 180;

      switch (quarterTurns) {
        default:
        case 0:
          sleft.PositionX = 0;
          sleft.PositionY = 0;
          smiddle.PositionX = sleft.Width;
          smiddle.PositionY = 0;
          sright.PositionX = sleft.Width;
          sright.PositionY = smiddle.Width;
          break;
        case 1:
          sleft.PositionX = 0;
          sleft.PositionY = sleft.Width;
          smiddle.PositionX = 0;
          smiddle.PositionY = 0;
          sright.PositionX = smiddle.Width;
          sright.PositionY = 0;
          break;
        case 2:
          sleft.PositionX = sleft.Width;
          sleft.PositionY = smiddle.Width;
          smiddle.PositionX = 0;
          smiddle.PositionY = smiddle.Width;
          sright.PositionX = 0;
          sright.PositionY = 0;
          break;
        case 3:
          let y = Math.max(sleft.Width, sright.Width);
          sleft.PositionX = smiddle.Width;
          sleft.PositionY = y - sleft.Width;
          smiddle.PositionX = smiddle.Width;
          smiddle.PositionY = y;
          sright.PositionX = 0;
          sright.PositionY = y;
          break;
      }
    } else {
      //single cabinets, door, etc
      let s0 = cabinet.cabinetSections[0];
      s0.Rotation = cabinet.Rotation;
      switch (quarterTurns) {
        default:
        case 0:
          s0.PositionX = 0;
          s0.PositionY = 0;
          break;
        case 1:
          s0.PositionX = 0;
          s0.PositionY = s0.Width;
          break;
        case 2:
          s0.PositionX = s0.Width;
          s0.PositionY = s0.Depth;
          break;
        case 3:
          s0.PositionY = 0;
          s0.PositionX = s0.Depth;
          break;
      }
    }
  }

  //#endregion
}
