import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { BaseSnapService } from 'app/ts/services/snap/BaseSnapService';
import { ISnapInfoService } from 'app/ts/services/snap/ISnapInfoService';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { InteriorLogic } from 'app/ts/services/ConfigurationLogic/InteriorLogic';
/**
 * Service for snapping items on the side of gables
 */
export class SnapSideService extends BaseSnapService<SnapSideService.SnapInfo> {
  static supportsItems(items: Client.ConfigurationItem[]): boolean {
    return items.length === 1 && (items[0].snapLeft || items[0].snapRight);
  }

  private readonly possiblePositions: SnapSideService.PossiblePositionInfo[];
  private readonly otherItemRectangles: Interface_DTO_Draw.Rectangle[];
  private readonly mouseOffset: Interface_DTO_Draw.Vec2d;

  constructor(
    cabinetSection: Client.CabinetSection,
    item: Client.ConfigurationItem,
    dragStartPoint: Interface_DTO_Draw.Vec2d,
    fallbackSnapService: ISnapInfoService,
  ) {
    super(cabinetSection, item, dragStartPoint, fallbackSnapService);

    this.otherItemRectangles = cabinetSection.interior.items.filter(
      (i) => i !== this.item && !i.isDummy && !i.isTemplate,
    );
    if (cabinetSection.leftNeighbor) {
      let leftNeighbor = cabinetSection.leftNeighbor;
      this.otherItemRectangles.push(
        ...leftNeighbor.interior.items
          .filter((i) => i !== this.item && !i.isDummy && !i.isTemplate)
          .filter(
            (i) =>
              i.rightX >=
              leftNeighbor.interior.cubeRightX - cabinetSection.InteriorDepth,
          )
          .map((i) => {
            return {
              X: i.Z,
              Width: i.Depth,
              Y: i.Y,
              Height: i.Height,
            };
          }),
      );
    }

    if (cabinetSection.rightNeighbor) {
      let rightNeighbor = cabinetSection.rightNeighbor;
      this.otherItemRectangles.push(
        ...rightNeighbor.interior.items
          .filter((i) => i !== this.item && !i.isDummy && !i.isTemplate)
          .filter((i) => i.X <= cabinetSection.InteriorDepth)
          .map((i) => {
            return {
              X: cabinetSection.interior.cubeRightX - i.Z,
              Width: i.Depth,
              Y: i.Y,
              Height: i.Height,
            };
          }),
      );
    }

    if (cabinetSection.isSwing) {
      this.otherItemRectangles.push(
        ...cabinetSection.swing.items.filter(
          (i) => i.ItemType === Interface_Enums.ItemType.SwingCorpus,
        ),
      );
    }

    if (cabinetSection.isSwingFlex) {
      this.otherItemRectangles.push(
        ...cabinetSection.swingFlex.items.filter(
          (i) => i.ItemType === Interface_Enums.ItemType.SwingFlexCorpus,
        ),
      );
    }

    this.possiblePositions = this.getPossiblePositions();
    this.mouseOffset = VectorHelper.subtract(
      dragStartPoint,
      item as Interface_DTO_Draw.Vec2d,
    );
  }

  protected overriddenGetSnapInfo(
    pos: Interface_DTO_Draw.Vec2d,
  ): SnapSideService.SnapInfo | undefined {
    let rawItemPos = VectorHelper.subtract(pos, this.mouseOffset);

    let sortedSuggestions = this.possiblePositions.sort(
      (s1, s2) =>
        VectorHelper.dist(rawItemPos, { X: s1.X, Y: s1.Y }) -
        VectorHelper.dist(rawItemPos, { X: s2.X, Y: s2.Y }),
    );
    let bestSuggestion = sortedSuggestions[0];

    if (bestSuggestion) {
      if (VectorHelper.dist(pos, bestSuggestion) < Constants.snapDist) {
        let rulerX = bestSuggestion.X + bestSuggestion.Width / 2;
        let distToAboveRuler = new Client.Ruler(
          true,
          {
            X: rulerX,
            Y: bestSuggestion.Y + bestSuggestion.Height,
          },
          (bestSuggestion.itemAbove
            ? bestSuggestion.itemAbove.Y
            : this.cabinetSection.Height) -
            bestSuggestion.Y -
            bestSuggestion.Height,
          this.cabinetSection.Height,
          false,
        );
        let distToBelowRuler = new Client.Ruler(
          true,
          {
            X: rulerX,
            Y: bestSuggestion.itemBelow
              ? bestSuggestion.itemBelow.Y + bestSuggestion.itemBelow.Height
              : this.cabinetSection.sightOffsetY,
          },
          bestSuggestion.Y -
            (bestSuggestion.itemBelow
              ? bestSuggestion.itemBelow.Y + bestSuggestion.itemBelow.Height
              : this.cabinetSection.sightOffsetY),
          this.cabinetSection.Height,
          false,
        );

        let pulloutSeverity = this.item.isPullout
          ? bestSuggestion.pulloutSeverity
          : Enums.PulloutWarningSeverity.None;

        let result: SnapSideService.SnapInfo = {
          dropOffset: VectorHelper.subtract(bestSuggestion, this.item),
          pulloutRestriction: pulloutSeverity,
          rulers: [distToAboveRuler, distToBelowRuler],
          suggestedItemOffsets: [], //No suggestions
          newSize: {
            X: bestSuggestion.Width,
            Y: bestSuggestion.Height,
            Z: bestSuggestion.Depth,
          },
          mirror: !bestSuggestion.mountLeft,
          collisionAreas: bestSuggestion.collisionAreas,
          mountLeft: bestSuggestion.mountLeft,
          horizontalGuidelineHeights: [
            bestSuggestion.Y,
            bestSuggestion.Y + bestSuggestion.Height,
          ],
          snapsTo: bestSuggestion.snapsTo ? [bestSuggestion.snapsTo] : [],
          snappingItems: [this.item],
        };
        return result;
      }
    }
    return;
  }

  protected overriddenPlace(
    lastSnapInfo: SnapSideService.SnapInfo,
  ): [Client.ConfigurationItem] {
    return [this.item];
  }

  private getPossiblePositions(): SnapSideService.PossiblePositionInfo[] {
    let result: SnapSideService.PossiblePositionInfo[] = [];

    let gableGaps = InteriorLogic.getGableGaps(this.cabinetSection, {
      excludeItems: [this.item],
    }).filter((gap) => this.item.Width <= gap.Width);

    if (this.cabinetSection.isSwingFlex && !this.item.isShelf) {
      gableGaps = gableGaps.concat(
        InteriorLogic.getFittingPanelGaps(this.cabinetSection),
      );
    }

    let maxTopY =
      this.cabinetSection.interior.cube.Y +
      this.cabinetSection.interior.cube.Height;

    for (let gap of gableGaps) {
      let backZ = 0;
      let frontZ = this.cabinetSection.InteriorDepth;

      if (!!gap.leftGable) {
        backZ = Math.max(backZ, gap.leftGable.Z);
        frontZ = Math.min(frontZ, gap.leftGable.snapDepthRight);
      }
      if (!!gap.rightGable) {
        backZ = Math.max(backZ, gap.rightGable.Z);
        frontZ = Math.min(frontZ, gap.rightGable.snapDepthLeft);
      }

      let depth = frontZ - backZ - this.item.depthReduction;
      depth = Math.max(this.item.minDepth, Math.min(this.item.maxDepth, depth));

      let z = frontZ - this.item.depthReduction - depth;
      if (this.item.snapDepthMiddle) {
        z = (frontZ + backZ) / 2 - this.item.Depth / 2;
      }

      for (let drillStop of gap.drillStops) {
        let itemY = drillStop - this.item.snapOffsetY;
        if (itemY < 0 || itemY + this.item.Height > maxTopY) continue;

        let otherItemRectangles = this.otherItemRectangles;

        if (this.item.snapLeft) {
          if (
            !gap.leftGable ||
            itemY + this.item.Height <= gap.leftGable.topY
          ) {
            let cube: Interface_DTO_Draw.Cube = {
              X: gap.X,
              Y: itemY,
              Z: z,

              Width: this.item.Width,
              Height: this.item.Height,
              Depth: depth,
            };
            let candidate = this.getCandidate(cube, true, gap.leftGable);
            if (candidate) result.push(candidate);
          }
        }
        if (this.item.snapRight) {
          if (
            !gap.rightGable ||
            itemY + this.item.Height <= gap.rightGable.topY
          ) {
            let cube: Interface_DTO_Draw.Cube = {
              X: gap.X + gap.Width - this.item.Width,
              Y: itemY,
              Z: z,
              Width: this.item.Width,
              Height: this.item.Height,
              Depth: depth,
            };
            let candidate = this.getCandidate(cube, false, gap.rightGable);
            if (candidate) result.push(candidate);
          }
        }
      }
    }

    if (this.cabinetSection.isSwingFlex) {
      let fittingPanelGaps = result.filter((r) => r.snapsTo?.isFittingPanel);
      if (fittingPanelGaps.length > 0) {
        for (let index = 0; index < fittingPanelGaps.length; index++) {
          const element = fittingPanelGaps[index];

          let sameStartX = result.findIndex(
            (c) =>
              !c.snapsTo?.isFittingPanel &&
              c.Y === element.Y &&
              c.X === element.X,
          );
          if (sameStartX > -1) {
            result.splice(sameStartX, 1);
            continue;
          }

          let withSpacerX = result.findIndex(
            (c) =>
              !c.snapsTo?.isFittingPanel &&
              c.Y === element.Y &&
              Math.abs(element.X - c.X) === 100,
          );
          if (withSpacerX > -1) {
            result.splice(withSpacerX, 1);
            continue;
          }
        }
      }
    }

    return result;
  }

  private getCandidate(
    candCube: Interface_DTO_Draw.Cube,
    mountLeft: boolean,
    gable: Client.ConfigurationItem | null,
  ): SnapSideService.PossiblePositionInfo | null {
    let otherItemsInLane = this.otherItemRectangles
      .filter((otherItem) => VectorHelper.overlapsX(otherItem, candCube))
      .concat(this.cabinetSection.interior.boundingBoxes);

    let overlap = otherItemsInLane.some((oir) =>
      VectorHelper.overlaps(candCube, oir),
    );
    if (overlap) {
      return null;
    }
    let pulloutSeverity = Enums.PulloutWarningSeverity.None;
    if (this.item.isPullout) {
      let relevantPulloutAreas =
        this.cabinetSection.doors.pulloutWarningAreas.filter((pwa) =>
          pwa.overlaps(candCube.X, candCube.X + candCube.Width),
        );
      let severities = relevantPulloutAreas.map((pwa) => pwa.severity);
      pulloutSeverity = Math.max(
        Enums.PulloutWarningSeverity.None,
        ...severities,
      );
    }

    let otherItemsAbove = otherItemsInLane.filter(
      (otherItem) => otherItem.Y >= candCube.Y + candCube.Height,
    );
    let otherItemsBelow = otherItemsInLane.filter(
      (otherItem) => otherItem.Y + otherItem.Height <= candCube.Y,
    );

    let collisionAreas = InteriorLogic.getCollisionAreas(
      candCube,
      this.item,
      !mountLeft,
    );

    let candidate: SnapSideService.PossiblePositionInfo = {
      ...candCube,
      pulloutSeverity: pulloutSeverity,
      mountLeft: mountLeft,
      itemAbove: otherItemsAbove.sort((r1, r2) => r1.Y - r2.Y)[0],
      itemBelow: otherItemsBelow.sort(
        (r1, r2) => r2.Y + r2.Height - (r1.Y + r1.Height),
      )[0],
      collisionAreas: collisionAreas.filter((collisionArea) =>
        this.otherItemRectangles
          .concat(this.cabinetSection.interior.boundingBoxes)
          .some((otherItem) => VectorHelper.overlaps(collisionArea, otherItem)),
      ),
      snapsTo: gable,
    };

    return candidate;
  }
}

export module SnapSideService {
  export interface PossiblePositionInfo extends Interface_DTO_Draw.Cube {
    pulloutSeverity: Enums.PulloutWarningSeverity;
    itemAbove: Interface_DTO_Draw.Rectangle | undefined;
    itemBelow: Interface_DTO_Draw.Rectangle | undefined;
    mountLeft: boolean;
    collisionAreas: Client.CollisionArea[];
    snapsTo: Client.ConfigurationItem | null;
  }

  export interface SnapInfo extends Client.SnapInfo {
    mountLeft: boolean;
  }
}
