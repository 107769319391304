import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import { GeometryHelper } from 'app/ts/util/GeometryHelper';
import { CabinetValidationService } from 'app/ts/services/Validation/CabinetValidationService';
import { PartitionPlanQueryService } from 'app/partition/partition-plan-query.service';
import { PartitionValidationCalculationService } from 'app/partition/partition-validation-calculation.service';
import { Door, Module } from 'app/partition/module';
import { Section } from 'app/partition/section';
import { inject } from '@angular/core';
import { ProductHelper } from '@Util/ProductHelper';
import * as InterfaceConstants from 'app/ts/InterfaceConstants';

export class FloorplanValidationService {
  public static readonly Name = 'floorplanValidationService';

  public accessTest() {
    return 'Floorplan Validation Service access test function';
  }

  public static validate(
    floorPlan: Client.FloorPlan,
    includeChildren: boolean = true,
    includePartition: boolean = false,
  ): Client.ErrorInfo[] {
    let errorInfos: Client.ErrorInfo[] = new Array<Client.ErrorInfo>();

    errorInfos.push(
      ...FloorplanValidationService.validateCabinetAreInsideTheFloorplan(
        floorPlan,
      ),
    );
    errorInfos.push(
      ...FloorplanValidationService.validateCollisionBetweenCabinets(floorPlan),
    );
    errorInfos.push(
      ...FloorplanValidationService.validateCabinetPositionInRelationToWindowsAndDoors(
        floorPlan,
      ),
    );
    errorInfos.push(
      ...FloorplanValidationService.validateErrorMuting(floorPlan),
    );

    if (includeChildren) {
      for (let cabinet of floorPlan.actualCabinets) {
        errorInfos.push(...CabinetValidationService.validate(cabinet));
      }
    }

    if (includePartition)
      errorInfos.push(...this.validatePartitions(floorPlan));

    return errorInfos;
  }

  public static validatePartitions(
    floorPlan: Client.FloorPlan,
    includeChildren: boolean = true,
  ): Client.ErrorInfo[] {
    // Reset errors
    floorPlan.partitionServices.query
      .getAllSections()
      .forEach((section) => ((section as Section).hasError = false));
    floorPlan.partitionServices.query
      .getAllModules()
      .forEach((module) => ((module as Module).hasError = false));

    let errorInfos: Client.ErrorInfo[] = new Array<Client.ErrorInfo>();

    errorInfos.push(
      ...FloorplanValidationService.validatePartitionsAreInsideTheFloorPlan(
        floorPlan,
      ),
    );
    errorInfos.push(
      ...FloorplanValidationService.validateCollisionBetweenPartitions(
        floorPlan,
      ),
    );
    errorInfos.push(
      ...FloorplanValidationService.validatePartitionInRelationToWindowsAndDoors(
        floorPlan,
      ),
    );
    errorInfos.push(
      ...FloorplanValidationService.validateCollionBetweenPartitionsAndCabinets(
        floorPlan,
      ),
    );
    errorInfos.push(
      ...FloorplanValidationService.validateDoorOpeningDirection(floorPlan),
    );
    errorInfos.push(
      ...FloorplanValidationService.validatePartitionDoorCollisions(floorPlan),
    );
    errorInfos.push(
      ...FloorplanValidationService.validatePartitionItemDimensions(floorPlan),
    );

    floorPlan.partitionServices.data.triggerNextPlan();

    return errorInfos;
  }

  public static validateCabinetAreInsideTheFloorplan(
    floorPlan: Client.FloorPlan,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    // If any point of a cabinet is not inside the floorplan, an error must be added
    for (let i = 0; i < floorPlan.actualCabinets.length; i++) {
      let cabinet = floorPlan.actualCabinets[i];

      // Check if any part if the cabinet is outside the floorplan
      if (
        !GeometryHelper.areAllPointsInPolygon(
          cabinet.getPoints(false, false),
          floorPlan.getPoints(),
        )
      ) {
        result.push(
          new Client.ErrorInfo(
            Enums.EditorSection.FloorPlan,
            Interface_Enums.ErrorLevel.Critical,
            'Cabinet outside floorplan',
            'The cabinet is partly or fully outside the floorplan',
            cabinet.cabinetSections[0],
          ),
        );
      }
      // Check if any part if the cabinet is outside the floorplan
      else if (
        !GeometryHelper.areAllPointsInPolygon(
          cabinet.getPoints(true, false),
          floorPlan.getPoints(),
        )
      ) {
        result.push(
          new Client.ErrorInfo(
            Enums.EditorSection.Corpus,
            Interface_Enums.ErrorLevel.Critical,
            'Rails outside floorplan',
            'The rail is partly or fully outside the floorplan',
            cabinet.cabinetSections[0],
          ),
        );
      }
    }

    return result;
  }

  public static validatePartitionsAreInsideTheFloorPlan(
    floorPlan: Client.FloorPlan,
  ): Client.ErrorInfo[] {
    let {
      query: partitionQuery,
      validationCalculation: partitionValidationCalculation,
    } = floorPlan.partitionServices;

    let allSections = partitionQuery.getAllSections();
    let result: Client.ErrorInfo[] = [];
    for (let i = 0; i < allSections.length; i++) {
      let section = partitionQuery.getSection(allSections[i].id);

      if (
        !GeometryHelper.areAllPointsInPolygon(
          partitionValidationCalculation.getPointsForSection(section.id, false),
          floorPlan.getPoints(),
        )
      ) {
        result.push(
          new Client.ErrorInfo(
            Enums.EditorSection.FloorPlan,
            Interface_Enums.ErrorLevel.Critical,
            'Section outside floorplan',
            'The section is partly or fully outside the floorplan',
            {
              FloorPlanId: floorPlan.Id!,
              CabinetIndex: section.id,
              CabinetSectionIndex: section.id,
            },
            undefined,
            floorPlan.editorAssets.translationService,
          ),
        );

        (section as Section).hasError = true;
      }
    }

    return result;
  }

  public static validateErrorMuting(
    floorPlan: Client.FloorPlan,
  ): Client.ErrorInfo[] {
    if (floorPlan.FullCatalogMuteErrors) {
      try {
        return [
          new Client.ErrorInfo(
            Enums.EditorSection.Validation,
            Interface_Enums.ErrorLevel.Warning,
            'Errors are muted',
            'Solution will be orderable even if there are critical errors',
            {
              FloorPlanId: floorPlan.Id ?? 0,
              CabinetIndex: 1,
              CabinetSectionIndex: 1,
            },
          ),
        ];
      } catch (e: any) {
        console.log(e);
      }
    }
    return [];
  }

  public static validateCollisionBetweenCabinets(
    floorPlan: Client.FloorPlan,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];
    // If any cabinets collide, an error must be added
    for (let i = 0; i < floorPlan.actualCabinets.length - 1; i++) {
      for (let j = i + 1; j < floorPlan.actualCabinets.length; j++) {
        let cabinet1 = floorPlan.actualCabinets[i];
        let cabinet2 = floorPlan.actualCabinets[j];

        // Check if the cabinets collide
        if (
          GeometryHelper.polygonsCollideSimple(
            cabinet1.getPoints(false, true),
            cabinet2.getPoints(false, true),
          )
        ) {
          result.push(
            new Client.ErrorInfo(
              Enums.EditorSection.FloorPlan,
              Interface_Enums.ErrorLevel.Critical,
              'Sections collide',
              'Two sections in the floorplan collide',
              cabinet1.cabinetSections[0],
            ),
          );
        }
        // Check if the rails collide
        else if (
          GeometryHelper.polygonsCollideSimple(
            cabinet1.getPoints(true, true),
            cabinet2.getPoints(true, true),
          )
        ) {
          result.push(
            new Client.ErrorInfo(
              Enums.EditorSection.Doors,
              Interface_Enums.ErrorLevel.Critical,
              'Rails collide',
              'Two cabinet sections in the floorplan collide',
              cabinet1.cabinetSections[0],
            ),
          );
        }
      }
    }
    return result;
  }

  public static validateCollionBetweenPartitionsAndCabinets(
    floorPlan: Client.FloorPlan,
  ): Client.ErrorInfo[] {
    let {
      query: partitionQuery,
      validationCalculation: partitionValidationCalculation,
    } = floorPlan.partitionServices;

    let errorInfos: Client.ErrorInfo[] = [];
    let allSections = partitionQuery.getAllSections();

    let partitionProductLineName = this.getPartitionProductLineName(floorPlan);
    for (let i = 0; i < allSections.length; i++) {
      let section = allSections[i];

      for (let j = 0; j < floorPlan.actualCabinets.length; j++) {
        let cabinet = floorPlan.actualCabinets[j];

        // Check if the cabinets collide
        if (
          GeometryHelper.polygonsCollideSimple(
            cabinet.getPoints(false, true),
            partitionValidationCalculation.getPointsForSection(
              section.id,
              false,
            ),
          )
        ) {
          errorInfos.push(
            new Client.ErrorInfo(
              Enums.EditorSection.FloorPlan,
              Interface_Enums.ErrorLevel.Critical,
              new Client.Translatable( // short description
                'val_err_short_Section and partition collide {0}',
                'A section and a {0} in the floorplan collide',
                partitionProductLineName,
              ),
              new Client.Translatable( // long description
                'val_err_full_A section and a partition in the floorplan collide {0}',
                'A section and a {0} in the floorplan collide',
                partitionProductLineName,
              ),
              cabinet.cabinetSections[0],
            ),
          );

          (section as Section).hasError = true;
        }
        // Check if the rails collide
        else if (
          GeometryHelper.polygonsCollideSimple(
            cabinet.getPoints(true, true),
            partitionValidationCalculation.getPointsForSection(
              section.id,
              false,
            ),
          )
        ) {
          errorInfos.push(
            new Client.ErrorInfo(
              Enums.EditorSection.FloorPlan,
              Interface_Enums.ErrorLevel.Critical,
              new Client.Translatable( // short description
                'val_err_short_Partition rails collide {0}',
                '{0} rails collide',
                partitionProductLineName,
              ),
              new Client.Translatable( // long description
                'val_err_full_The rails of two partition sections collide {0}',
                'The rails of two {0} sections collide',
                partitionProductLineName,
              ),
              cabinet.cabinetSections[0],
            ),
          );

          (section as Section).hasError = true;
        }
      }
    }

    return errorInfos;
  }

  public static validateDoorOpeningDirection(
    floorPlan: Client.FloorPlan,
  ): Client.ErrorInfo[] {
    let errorInfos: Client.ErrorInfo[] = [];
    let { query: partitionQuery } = floorPlan.partitionServices;

    let partitionProductLineName = this.getPartitionProductLineName(floorPlan);

    partitionQuery.getAllSections().forEach((section) => {
      let doors = partitionQuery
        .getModulesForSection(section.id)
        .filter((md) => md.isDoor)
        .map((md) => md as Door);

      if (doors.length == 2) {
        if (
          !(
            doors[0].openDirection == Enums.DoorOpenSide.Left &&
            doors[1].openDirection == Enums.DoorOpenSide.Right
          ) &&
          doors[0].placement == doors[1].placement
        ) {
          let partition = partitionQuery.getPartitionForSection(section.id);

          errorInfos.push(
            new Client.ErrorInfo(
              Enums.EditorSection.FloorPlan,
              Interface_Enums.ErrorLevel.Warning,
              'Door Rails May Collide',
              new Client.Translatable( // long description
                'val_err_full_Two doors in a partition section does not open away from each other {0}',
                'Two doors in a {0} section does not open away from each other',
                partitionProductLineName,
              ),
              {
                FloorPlanId: floorPlan.Id!,
                CabinetIndex: partition.id,
                CabinetSectionIndex: section.id,
              },
            ),
          );
        }
      }
    });

    return errorInfos;
  }

  public static validatePartitionDoorCollisions(
    floorPlan: Client.FloorPlan,
  ): Client.ErrorInfo[] {
    let errorInfos: Client.ErrorInfo[] = [];
    let {
      query: partitionQuery,
      validationCalculation: partitionValidationCalculation,
    } = floorPlan.partitionServices;

    let partitionProductLineName = this.getPartitionProductLineName(floorPlan);

    let doors = partitionQuery.getAllModules().filter((md) => md.isDoor);

    let doorRects = doors.map((dr) => {
      return {
        module: dr,
        sectionId: dr.sectionId,
        points: partitionValidationCalculation.getDoorPoints(dr.id),
      };
    });

    for (let i = 0; i < doorRects.length - 1; i++) {
      let door1 = doorRects[i];
      for (let j = i + 1; j < doorRects.length; j++) {
        let door2 = doorRects[j];
        if (door1.module.id == door2.module.id) continue;

        //skip double doors
        if (
          door1.module.references.previousSectionModuleId == door2.module.id ||
          door1.module.references.nextSectionModuleId == door2.module.id
        )
          continue;

        if (GeometryHelper.polygonsCollideSimple(door1.points, door2.points)) {
          errorInfos.push(
            new Client.ErrorInfo(
              Enums.EditorSection.FloorPlan,
              Interface_Enums.ErrorLevel.Critical,
              new Client.Translatable( // short description
                'val_err_short_Partition doors collide {0}',
                '{0} doors collide',
                partitionProductLineName,
              ),
              new Client.Translatable( // long description
                'val_err_full_Two partition doors in the floorplan collide {0}',
                'Two {0} doors in the floorplan collide',
                partitionProductLineName,
              ),
              {
                FloorPlanId: floorPlan.Id!,
                CabinetIndex: door1.sectionId,
                CabinetSectionIndex: door1.sectionId,
              },
              undefined,
              floorPlan.editorAssets.translationService,
            ),
          );

          (door1.module as Module).hasError = true;
          (door2.module as Module).hasError = true;
        }
      }
    }

    return errorInfos;
  }

  public static validateCollisionBetweenPartitions(
    floorPlan: Client.FloorPlan,
  ): Client.ErrorInfo[] {
    let {
      query: partitionQuery,
      validationCalculation: partitionValidationCalculation,
    } = floorPlan.partitionServices;

    let partitionProductLineName = this.getPartitionProductLineName(floorPlan);

    let result: Client.ErrorInfo[] = [];
    let allSections = partitionQuery.getAllSections();

    for (let i = 0; i < allSections.length - 1; i++) {
      for (let j = i + 1; j < allSections.length; j++) {
        let section1 = allSections[i];
        let section2 = allSections[j];

        if (
          partitionQuery.getPartitionForSection(section1.id).id ==
          partitionQuery.getPartitionForSection(section2.id).id
        )
          continue;
        // Do not check collision for sectitions in the same partition

        let pointsSec1 = partitionValidationCalculation.getPointsForSection(
          section1.id,
          false,
        );
        let pointsSec2 = partitionValidationCalculation.getPointsForSection(
          section2.id,
          false,
        );
        if (GeometryHelper.polygonsCollideSimple(pointsSec1, pointsSec2)) {
          result.push(
            new Client.ErrorInfo(
              Enums.EditorSection.FloorPlan,
              Interface_Enums.ErrorLevel.Critical,
              new Client.Translatable( // short description
                'val_err_short_Partition sections collide {0}',
                '{0} sections collide',
                partitionProductLineName,
              ),
              new Client.Translatable( // long description
                'val_err_full_Two partition sections in the floorplan collide {0}',
                'Two {0} sections in the floorplan collide',
                partitionProductLineName,
              ),
              {
                FloorPlanId: floorPlan.Id!,
                CabinetIndex: section1.id,
                CabinetSectionIndex: section1.id,
              },
              undefined,
              floorPlan.editorAssets.translationService,
            ),
          );

          (section1 as Section).hasError = true;
          (section2 as Section).hasError = true;
        }
      }
    }

    return result;
  }

  public static validateCabinetPositionInRelationToWindowsAndDoors(
    floorPlan: Client.FloorPlan,
  ): Client.ErrorInfo[] {
    let result: Client.ErrorInfo[] = [];

    // Loop through all wall sections
    for (let wallIndex = 0; wallIndex < floorPlan.walls.length; wallIndex++) {
      let wall = floorPlan.walls[wallIndex];
      let items = Enumerable.from(wall.Items)
        .where((i) => !i.CabinetIndex)
        .toArray();

      // Loop through all doors and windows
      for (var itemIndex = 0; itemIndex < items.length; itemIndex++) {
        let item = items[itemIndex];
        let startPoint = GeometryHelper.getPointOnLine(
          wall.Begin,
          wall.End,
          item.X,
        );
        let endPoint = GeometryHelper.getPointOnLine(
          wall.Begin,
          wall.End,
          item.X + item.Width,
        );

        // Check if any cabinet is in front of the door/window (within xx cm)
        for (
          var cabinetIndex = 0;
          cabinetIndex < floorPlan.actualCabinets.length;
          cabinetIndex++
        ) {
          let isBlocking = false;

          let cabinet = floorPlan.actualCabinets[cabinetIndex];
          let points = cabinet.getPoints(false, false);

          // Calculate projections for all cabinet points
          let projectionInfos = Array<Client.ProjectionInfo>();
          for (var pointIndex = 0; pointIndex < points.length; pointIndex++) {
            projectionInfos.push(
              GeometryHelper.getProjectionInfo(
                startPoint,
                endPoint,
                points[pointIndex],
              ),
            );
          }

          // Check if any points are directly in front, and closer than minimum distance
          let enumerableProjectionInfos = Enumerable.from(projectionInfos);

          if (
            enumerableProjectionInfos
              .where(
                (pi) =>
                  pi.projectionLocation == Enums.ProjectionLocation.SegmentAB,
              )
              .any(
                (element) =>
                  GeometryHelper.distanceBetweenPoints(
                    element.projectedPoint,
                    element.projectionPoint,
                  ) < Constants.minimumSpaceForDoorsAndWindows,
              )
          ) {
            isBlocking = true;
            result.push(
              new Client.ErrorInfo(
                Enums.EditorSection.FloorPlan,
                Interface_Enums.ErrorLevel.Warning,
                'Section position partly',
                'Section is positioned in front of door or window',
                cabinet.cabinetSections[0],
              ),
            );
          }
        }
      }
    }

    return result;
  }

  public static validatePartitionInRelationToWindowsAndDoors(
    floorPlan: Client.FloorPlan,
  ): Client.ErrorInfo[] {
    let {
      query: partitionQuery,
      validationCalculation: partitionValidationCalculation,
    } = floorPlan.partitionServices;

    let partitionProductLineName = this.getPartitionProductLineName(floorPlan);

    let result: Client.ErrorInfo[] = [];
    let allSections = partitionQuery.getAllSections();

    for (let wallIndex = 0; wallIndex < floorPlan.walls.length; wallIndex++) {
      let wall = floorPlan.walls[wallIndex];
      let items = Enumerable.from(wall.Items)
        .where((i) => !i.CabinetIndex)
        .toArray();

      for (let itemIndex = 0; itemIndex < allSections.length; itemIndex++) {
        let item = items[itemIndex];
        if (item != null && item.X != null) {
          let startPoint = GeometryHelper.getPointOnLine(
            wall.Begin,
            wall.End,
            item.X,
          );
          let endPoint = GeometryHelper.getPointOnLine(
            wall.Begin,
            wall.End,
            item.X + item.Width,
          );

          for (
            let sectionIndex = 0;
            sectionIndex < allSections.length;
            sectionIndex++
          ) {
            let section = allSections[sectionIndex];
            let points = partitionValidationCalculation.getPointsForSection(
              section.id,
              false,
            );

            let projectionInfos = Array<Client.ProjectionInfo>();
            for (let pointIndex = 0; pointIndex < points.length; pointIndex++) {
              projectionInfos.push(
                GeometryHelper.getProjectionInfo(
                  startPoint,
                  endPoint,
                  points[pointIndex],
                ),
              );
            }

            let enumerableProjectionInfos = Enumerable.from(projectionInfos);

            if (
              enumerableProjectionInfos
                .where(
                  (pi) =>
                    pi.projectionLocation == Enums.ProjectionLocation.SegmentAB,
                )
                .any(
                  (element) =>
                    GeometryHelper.distanceBetweenPoints(
                      element.projectedPoint,
                      element.projectionPoint,
                    ) < Constants.minimumSpaceForDoorsAndWindows,
                )
            ) {
              result.push(
                new Client.ErrorInfo(
                  Enums.EditorSection.FloorPlan,
                  Interface_Enums.ErrorLevel.Warning,
                  new Client.Translatable( // short description
                    'val_err_short_Partition section position partly {0}',
                    '{0} section position partly',
                    partitionProductLineName,
                  ),
                  'Section is positioned in front of door or window',
                  {
                    FloorPlanId: floorPlan.Id!,
                    CabinetIndex: section.id,
                    CabinetSectionIndex: section.id,
                  },
                  undefined,
                  floorPlan.editorAssets.translationService,
                ),
              );

              (section as Section).hasError = true;
            }
          }
        }
      }
    }

    return result;
  }

  private static getPartitionProductLineName(
    floorPlan: Client.FloorPlan,
  ): string {
    let partitionProductLine = floorPlan.editorAssets.productLines.find(
      (pl) => pl.Id == Interface_Enums.ProductLineId.Partition,
    );
    return partitionProductLine?.Name ?? 'Partition';
  }

  public static validatePartitionItemDimensions(
    floorPlan: Client.FloorPlan,
  ): Client.ErrorInfo[] {
    const result: Client.ErrorInfo[] = [];

    const partitionProductLineName =
      this.getPartitionProductLineName(floorPlan);

    let { query: partitionQuery } = floorPlan.partitionServices;

    for (const partition of partitionQuery.getAllPartitions()) {
      let hasReportedError = false; // only log one error per partition
      for (const sectionId of partition.sections) {
        const section = partitionQuery.getSection(sectionId);
        for (const moduleId of section.modules) {
          const module = partitionQuery.getModule(moduleId);

          const productId = module.references.productId;
          const product = floorPlan.editorAssets.productsDict[productId];
          if (!product) continue;
          for (const filling of module.fillings) {
            if (
              !product.supportsDimensions(
                {
                  X: module.width,
                  Y: module.height,
                  Z: module.depth,
                },
                InterfaceConstants.ProductLineIds.Partition,
                filling.materialId,
              )
            ) {
              module.hasError = true;
              if (!hasReportedError) {
                result.push(
                  new Client.ErrorInfo(
                    Enums.EditorSection.FloorPlan,
                    Interface_Enums.ErrorLevel.Critical,
                    new Client.Translatable(
                      'validation_floorplan_partition_item_dimensions_short',
                      'A {0} item is too big or too small.',
                      partitionProductLineName,
                      partition.name,
                    ),

                    new Client.Translatable(
                      'validation_floorplan_partition_item_dimensions_long',
                      'An item in the {0} "{1}" is too big or too small. Try resizing the {0}.',
                      partitionProductLineName,
                      partition.name,
                    ),
                    {
                      FloorPlanId: floorPlan.Id ?? 0,
                      CabinetIndex: 1,
                      CabinetSectionIndex: 1,
                    },
                    undefined, //configurationItem
                    floorPlan.editorAssets.translationService,
                  ),
                );
                hasReportedError = true;
              }
            }
          }
        }
      }
    }

    return result;
  }
}
