import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Client from 'app/ts/clientDto/Product';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { Pickable } from 'app/ts/interfaces/Pickable';
import { Material } from '@ClientDto/Material';
export class ProductHelper {
  public static getProductData(
    product?: Interface_DTO.Product,
    material?: Interface_DTO.Material | null,
  ): Interface_DTO.ProductData | null {
    if (!product) return null;

    // If material is not given, return default ProductData
    if (!material)
      return product.ProductDataList.filter((pd) => pd.MaterialId < 0)[0];

    // If material is given, return material specific ProductData
    let productData: Interface_DTO.ProductData | undefined =
      product.ProductDataList.filter((pd) => pd.MaterialId == material.Id)[0];
    if (!productData) {
      // If there is no productdata for the specific material, take the default productdata
      productData = product.ProductDataList.filter(
        (pd) => pd.MaterialId == -1,
      )[0];
    }
    if (productData) return productData;
    return null;
  }

  public static isDiscontinued(product: Interface_DTO.Product): boolean {
    var productData = ProductHelper.getProductData(product);
    return (
      !!productData &&
      productData.Status === Interface_Enums.ProductDataStatus.Discontinued
    );
  }

  public static picturePath(product: Interface_DTO.Product): string {
    var productData = ProductHelper.getProductData(product);
    if (!productData) return '';
    return productData.PicturePath;
  }

  public static minHeight(
    product: Interface_DTO.Product,
    material?: Interface_DTO.Material,
  ): number {
    var productData = ProductHelper.getProductData(product, material);
    if (!productData) return 0;
    return productData.MinHeight;
  }
  public static maxHeight(
    product: Interface_DTO.Product,
    material?: Interface_DTO.Material,
  ): number {
    var productData = ProductHelper.getProductData(product, material);
    if (!productData) return 0;
    return productData.MaxHeight;
  }
  public static isFlexHeight(
    product: Interface_DTO.Product,
    productLineId?: Interface_Enums.ProductLineId,
  ): boolean {
    //if (productLineId != undefined
    //    && product instanceof Client.Product
    //    && product.productCategory
    //    && !product.productCategory.fittingsEnabled(productLineId)
    //) {
    //    return false;
    //}
    var productData = ProductHelper.getProductData(product);
    return !!productData && productData.MinHeight != productData.MaxHeight;
  }
  public static fittinsEnabled(
    product: Interface_DTO.Product,
    productLineId?: Interface_Enums.ProductLineId,
  ): boolean {
    return (
      (productLineId != undefined &&
        product instanceof Client.Product &&
        product.productCategory &&
        product.productCategory.fittingsEnabled(productLineId)) ||
      false
    );
  }
  public static defaultHeight(
    product: Interface_DTO.Product,
    material?: Interface_DTO.Material,
  ): number {
    var productData = ProductHelper.getProductData(product, material);
    if (!productData) return 0;
    return productData.DefaultHeight;
  }
  public static supportsHeight(
    product: Interface_DTO.Product,
    potentialHeight: number,
    material?: Interface_DTO.Material,
  ): boolean {
    var productData = ProductHelper.getProductData(product, material);
    if (productData) {
      return (
        potentialHeight >= productData.MinHeight &&
        potentialHeight <= productData.MaxHeight
      );
    }
    return false;
  }

  public static minWidth(
    product: Interface_DTO.Product,
    material?: Interface_DTO.Material,
  ): number {
    var productData = ProductHelper.getProductData(product, material);
    if (!productData) return 0;
    return productData.MinWidth;
  }
  public static maxWidth(
    product: Interface_DTO.Product,
    material?: Interface_DTO.Material,
  ): number {
    var productData = ProductHelper.getProductData(product, material);
    if (!productData) return 0;
    return productData.MaxWidth;
  }
  public static isFlexWidth(product: Interface_DTO.Product): boolean {
    var productData = ProductHelper.getProductData(product);
    return !!productData && productData.MinWidth != productData.MaxWidth;
  }
  public static defaultWidth(
    product: Interface_DTO.Product,
    material?: Interface_DTO.Material,
  ): number {
    var productData = ProductHelper.getProductData(product, material);
    if (!productData) return 0;
    return productData.DefaultWidth;
  }
  public static supportsWidth(
    product: Interface_DTO.Product,
    potentialWidth: number,
    material?: Interface_DTO.Material,
  ): boolean {
    var productData = ProductHelper.getProductData(product, material);
    if (productData) {
      return (
        potentialWidth >= productData.MinWidth &&
        potentialWidth <= productData.MaxWidth
      );
    }
    return false;
  }

  public static minDepth(
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId | undefined,
    material?: Interface_DTO.Material,
  ): number {
    var productData = ProductHelper.getProductData(product, material);
    if (!productData) return 0;
    if (productLineId) {
      let depthVariant = product
        .getVariants(productLineId)
        .filter((pv) => pv.Number == VariantNumbers.Depth)[0];
      if (depthVariant && depthVariant.ProductVariantExclude) {
        try {
          let d = depthVariant.ProductVariantExclude.Value;
          return parseInt(d);
        } catch (e: any) {
          console.warn('Could not set depth', depthVariant);
        }
      }
    }
    if (
      productLineId != undefined &&
      product instanceof Client.Product &&
      product.productCategory &&
      !product.productCategory.fittingsEnabled(productLineId)
    ) {
      return productData.DefaultDepth;
    }
    return productData.MinDepth;
  }
  public static maxDepth(
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId | undefined | null,
    material?: Interface_DTO.Material,
  ): number {
    var productData = ProductHelper.getProductData(product, material);
    if (!productData) return 0;
    let depthVariant =
      productLineId &&
      product
        .getVariants(productLineId)
        .filter((pv) => pv.Number == VariantNumbers.Depth)[0];
    if (depthVariant && depthVariant.ProductVariantExclude) {
      try {
        let d = depthVariant.ProductVariantExclude.Value;
        return parseInt(d);
      } catch (e: any) {
        console.warn('Could not set depth', depthVariant);
      }
    }
    if (
      productLineId != undefined &&
      product instanceof Client.Product &&
      product.productCategory &&
      !product.productCategory.fittingsEnabled(productLineId)
    ) {
      return productData.DefaultDepth;
    }
    return productData.MaxDepth;
  }
  public static isFlexDepth(
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId | undefined,
  ): boolean {
    if (
      productLineId != undefined &&
      product instanceof Client.Product &&
      product.productCategory &&
      !product.productCategory.fittingsEnabled(productLineId)
    ) {
      return false;
    }
    if (productLineId) {
      let depthVariant = product
        .getVariants(productLineId)
        .filter((pv) => pv.Number == VariantNumbers.Depth)[0];
      if (depthVariant && depthVariant.ProductVariantExclude) return false;
    }

    var productData = ProductHelper.getProductData(product);
    return !!productData && productData.MinDepth != productData.MaxDepth;
  }
  public static defaultDepth(
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId | undefined | null,
    material?: Interface_DTO.Material | null,
  ): number {
    var productData = ProductHelper.getProductData(product, material);
    if (!productData) {
      return 0;
    }

    if (!productLineId) {
      return 0;
    }

    let depthVariant = product
      .getVariants(productLineId)
      .filter((pv) => pv.Number == VariantNumbers.Depth)[0];
    if (depthVariant && depthVariant.ProductVariantExclude) {
      try {
        let d = depthVariant.ProductVariantExclude.Value;
        return parseInt(d);
      } catch (e: any) {
        console.warn('Could not set depth', depthVariant);
      }
    }
    return productData.DefaultDepth;
  }
  public static extraDepth(product: Interface_DTO.Product): number {
    var productData = ProductHelper.getProductData(product);
    return !!productData ? productData.SnapPoint : 0;
  }
  public static supportsDepth(
    product: Interface_DTO.Product,
    potentialDepth: number,
    material?: Interface_DTO.Material,
  ): boolean {
    var productData = ProductHelper.getProductData(product, material);
    if (productData) {
      return (
        potentialDepth >= productData.MinDepth &&
        potentialDepth <= productData.MaxDepth
      );
    }
    return false;
  }

  public static supportsDimensions(
    product: Interface_DTO.Product,
    height: number | null,
    width: number | null,
    depth: number | null,
    material?: Interface_DTO.Material,
  ): boolean {
    var productData = ProductHelper.getProductData(product, material);
    if (!productData) return false;

    if (
      height !== null &&
      (height < productData.MinHeight || height > productData.MaxHeight)
    )
      return false;

    if (
      width !== null &&
      (width < productData.MinWidth || width > productData.MaxWidth)
    )
      return false;

    if (
      depth !== null &&
      (depth < productData.MinDepth || depth > productData.MaxDepth)
    )
      return false;

    return true;
  }

  public static isStyling(
    product: Interface_DTO.Product | null | undefined,
  ): boolean {
    if (!product) return false;
    return (
      product.ProductType >= Interface_Enums.ProductType.StylingStart &&
      product.ProductType <= Interface_Enums.ProductType.StylingEnd
    );
  }

  public static reductionDepth(
    product: Interface_DTO.Product,
    material?: Interface_DTO.Material,
  ): number {
    var productData = ProductHelper.getProductData(product, material);
    if (!productData) return 0;
    return productData.ReductionDepth;
  }

  public static isGable(product: Interface_DTO.Product): boolean {
    for (let pd of product.ProductDataList) {
      if (pd.Type & Interface_Enums.ProductDataType.Gable)
        //Find flag
        return true;
    }
    return false;
  }

  public static isHeightReductionPossible(
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId | null,
    override: boolean,
  ): boolean {
    if (product.productCategory && productLineId) {
      if (!product.productCategory.fittingsEnabled(productLineId)) return false;
    }
    let variants =
      productLineId == null
        ? product.getAllVariants()
        : product.getVariants(productLineId);
    return variants.some((v) => {
      if (v.Number !== VariantNumbers.Trimming) return false;
      if (override) return true;
      return !v.OnlyAdmin;
    });
  }

  public static isPullout(product: Interface_DTO.Product): boolean {
    var productData = ProductHelper.getProductData(product);
    return !!productData && productData.Pullout;
  }

  public static isCoatHanger(product: Interface_DTO.Product): boolean {
    var productData = ProductHelper.getProductData(product);
    return (
      !!productData &&
      ObjectHelper.hasFlag(
        productData.Type,
        Interface_Enums.ProductDataType.CoatHanger,
      )
    );
  }

  public static isDummy(product: Interface_DTO.Product): boolean {
    return product.ProductType >= 900 && product.ProductType < 1000;
  }

  public static snapLeft(product: Interface_DTO.Product): boolean {
    return this.productHasSnapType(
      product,
      Interface_Enums.SnapPlacementType.Left,
    );
  }
  public static snapRight(product: Interface_DTO.Product): boolean {
    return this.productHasSnapType(
      product,
      Interface_Enums.SnapPlacementType.Right,
    );
  }
  public static snapLeftAndRight(product: Interface_DTO.Product): boolean {
    return this.productHasSnapType(
      product,
      Interface_Enums.SnapPlacementType.LeftAndRight,
    );
  }
  public static snapDepthMiddle(product: Interface_DTO.Product): boolean {
    return this.productHasSnapType(
      product,
      Interface_Enums.SnapPlacementType.DepthMiddle,
    );
  }
  public static ignoreSnapPointValidation(
    product: Interface_DTO.Product,
  ): boolean {
    return this.productHasSnapType(
      product,
      Interface_Enums.SnapPlacementType.IgnoreSnapPointValidation,
    );
  }

  public static snapBottomTopOrAboveGables(
    product: Interface_DTO.Product,
  ): boolean {
    return this.productHasSnapType(
      product,
      Interface_Enums.SnapPlacementType.BottomTopOrAboveGables,
    );
  }

  public static snapAboveGable(product: Interface_DTO.Product): boolean {
    return this.productHasSnapType(
      product,
      Interface_Enums.SnapPlacementType.AboveGables,
    );
  }

  public static snapCoatHanger(product: Interface_DTO.Product): boolean {
    return this.productHasSnapType(
      product,
      Interface_Enums.SnapPlacementType.CoatHanger,
    );
  }
  public static snapUnder(product: Interface_DTO.Product): boolean {
    return this.productHasSnapType(
      product,
      Interface_Enums.SnapPlacementType.Top,
    );
  }

  public static snapFront(product: Interface_DTO.Product): boolean {
    return this.productHasSnapType(
      product,
      Interface_Enums.SnapPlacementType.Front,
    );
  }

  public static getSnapOffsetY(product: Interface_DTO.Product): number {
    var productData = ProductHelper.getProductData(product);
    return !!productData ? productData.SnapPoint : 0;
  }
  public static getSnapDepthLeft(product: Interface_DTO.Product): number {
    var productData = ProductHelper.getProductData(product);
    return !!productData ? productData.SnapDepthLeft : 0;
  }
  public static getSnapDepthRight(product: Interface_DTO.Product): number {
    var productData = ProductHelper.getProductData(product);
    return !!productData ? productData.SnapDepthRight : 0;
  }
  public static productHasSnapType(
    product: Interface_DTO.Product,
    snapType: Interface_Enums.SnapPlacementType,
  ): boolean {
    var productData = ProductHelper.getProductData(product);
    return (
      !!productData && ObjectHelper.hasFlag(productData.SnapPlacement, snapType)
    );
  }

  public static getCollisionSpace(
    product: Interface_DTO.Product,
    recommended: boolean,
    position: Enums.CollisionSpacePosition,
  ): number {
    var productData = ProductHelper.getProductData(product);
    if (productData == null) return 0;
    if (
      !recommended &&
      productData.Type &
        Interface_Enums.ProductDataType.RequiredSpaceOnlyOnPullout
    ) {
      return 0;
    }

    switch (position) {
      case Enums.CollisionSpacePosition.Top:
        return recommended
          ? productData.RecommendedSpaceTop
          : productData.RequiredSpaceTop;

      case Enums.CollisionSpacePosition.Bottom:
        return recommended
          ? productData.RecommendedSpaceBottom
          : productData.RequiredSpaceBottom;

      case Enums.CollisionSpacePosition.Side:
        return recommended
          ? productData.RecommendedSpaceSide
          : productData.RequiredSpaceSide;

      default:
        return 0;
    }
  }

  public static getDepthReduction(product: Interface_DTO.Product): number {
    var productData = ProductHelper.getProductData(product);
    return !!productData ? productData.ReductionDepth : 0;
  }

  public static hasWidth2(product: Client.Product): boolean {
    return product
      .getAllVariants()
      .some((v) => v && v.Number === VariantNumbers.WidthRight);
  }
  public static hasDepth2(product: Client.Product): boolean {
    return product
      .getAllVariants()
      .some((v) => v && v.Number === VariantNumbers.DepthRight);
  }

  public static drilledLeft(product: Interface_DTO.Product): boolean {
    var productData = ProductHelper.getProductData(product);
    return (
      !!productData &&
      !!(productData.Type & Interface_Enums.ProductDataType.DrilledLeft)
    );
  }
  public static drilledRight(product: Interface_DTO.Product): boolean {
    var productData = ProductHelper.getProductData(product);
    return (
      !!productData &&
      !!(productData.Type & Interface_Enums.ProductDataType.DrilledRight)
    );
  }

  public static drilling600Left(product: Interface_DTO.Product): boolean {
    var productData = ProductHelper.getProductData(product);
    return (
      !!productData &&
      !!(productData.Type & Interface_Enums.ProductDataType.Drilling600Left)
    );
  }
  public static drilling600Right(product: Interface_DTO.Product): boolean {
    var productData = ProductHelper.getProductData(product);
    return (
      !!productData &&
      !!(productData.Type & Interface_Enums.ProductDataType.Drilling600Right)
    );
  }

  public static isCorner2WayFlex(product: Interface_DTO.Product): boolean {
    var productData = ProductHelper.getProductData(product);
    return (
      !!productData &&
      !!(productData.Type & Interface_Enums.ProductDataType.Corner2WayFlex)
    );
  }
  public static isCornerDiagonalCut(product: Interface_DTO.Product): boolean {
    var productData = ProductHelper.getProductData(product);
    return (
      !!productData &&
      !!(productData.Type & Interface_Enums.ProductDataType.CornerDiagonalCut)
    );
  }

  public static isCornerRounded(product: Interface_DTO.Product): boolean {
    var productData = ProductHelper.getProductData(product);
    return (
      !!productData &&
      !!(productData.Type & Interface_Enums.ProductDataType.CornerRounded)
    );
  }

  public static isCornerTwoPart(product: Interface_DTO.Product): boolean {
    var productData = ProductHelper.getProductData(product);
    return (
      !!productData &&
      !!(productData.Type & Interface_Enums.ProductDataType.CornerTwoPart)
    );
  }

  public static snapsAgainstOpposite(product: Interface_DTO.Product): boolean {
    var productData = ProductHelper.getProductData(product);
    return (
      !!productData &&
      !!(
        productData.SnapPlacement &
        Interface_Enums.SnapPlacementType.AgainstOpposite
      )
    );
  }

  public static supportsMiter(product: Interface_DTO.Product): boolean {
    var productData = ProductHelper.getProductData(product);
    return (
      !!productData &&
      !!(productData.Type & Interface_Enums.ProductDataType.SupportsMiter)
    );
  }

  public static isFittingPanel(product: Interface_DTO.Product): boolean {
    for (let pd of product.ProductDataList) {
      if (pd.Type & Interface_Enums.ProductDataType.FittingPanel)
        //Find flag
        return true;
    }
    return false;
  }

  public static isGripForDoor(product: Interface_DTO.Product): boolean {
    var productData = ProductHelper.getProductData(product);
    return (
      !!productData &&
      !!(productData.Type & Interface_Enums.ProductDataType.GripForDoor)
    );
  }

  public static isGripForDrawer(product: Interface_DTO.Product): boolean {
    var productData = ProductHelper.getProductData(product);
    return (
      !!productData &&
      !!(productData.Type & Interface_Enums.ProductDataType.GripForDrawer)
    );
  }

  public static isGripForDoorAndDrawer(
    product: Interface_DTO.Product,
  ): boolean {
    return this.isGripForDoor(product) && this.isGripForDrawer(product);
  }

  public static rotateOnDoor(product: Interface_DTO.Product) {
    return this.productHasSnapType(
      product,
      Interface_Enums.SnapPlacementType.RotateOnDoor,
    );
  }

  public static edgeMounted(product: Interface_DTO.Product) {
    return this.productHasSnapType(
      product,
      Interface_Enums.SnapPlacementType.Top,
    );
  }

  public static isShelf(
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId,
  ): boolean {
    return (
      !ProductHelper.isPullout(product) &&
      ProductHelper.snapLeftAndRight(product) &&
      !ProductHelper.isCoatHanger(product) &&
      ProductHelper.defaultHeight(product) <
        ProductHelper.defaultDepth(product, productLineId)
    );
  }

  /**
   * Checks if the product is a shelf with a default height of 19 or 22. PLEASE NOTE: Is quick fix to ignore Glashylde and Skohylde.
   * @param product The product to check.
   * @param productLineId The product line ID.
   * @returns True if the product is a shelf with a height of 19 or 22, false otherwise.
   */
  public static isShelf_19_22(
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId,
  ): boolean {
    return (
      ProductHelper.isShelf(product, productLineId) &&
      (ProductHelper.defaultHeight(product) === 19 ||
        ProductHelper.defaultHeight(product) === 22)
    );
  }

  public static isPlinth(
    product: Client.Product,
    productLineId: Interface_Enums.ProductLineId,
  ): boolean {
    return (
      !ProductHelper.isPullout(product) &&
      ProductHelper.snapLeftAndRight(product) &&
      !ProductHelper.isCoatHanger(product) &&
      ProductHelper.defaultHeight(product) >
        ProductHelper.defaultDepth(product, productLineId)
    );
  }

  public static isProductDataType(
    productDataType: Interface_Enums.ProductDataType,
    product: Interface_DTO.Product,
  ): boolean {
    var productData = ProductHelper.getProductData(product);
    return (
      !!productData && (productData.Type & productDataType) === productDataType
    );
  }

  public static isVerticalDivider(product: Interface_DTO.Product): boolean {
    for (let pd of product.ProductDataList) {
      if (pd.Type & Interface_Enums.ProductDataType.VerticalDivider)
        //Find flag
        return true;
    }
    return false;
  }

  public static canHaveGrip(
    product: Interface_DTO.Product,
    material: Interface_DTO.Material | null,
  ): boolean {
    let pd = ProductHelper.getProductData(product, material);
    if (!pd) return false;
    return ObjectHelper.hasFlag(
      pd.Type,
      Interface_Enums.ProductDataType.CanHaveGrip,
    );
  }

  public static supportsDrillingPattern(
    product: Interface_DTO.Product,
    drillingPattern: Interface_Enums.DrillingPattern,
  ): boolean {
    let pd = ProductHelper.getProductData(product);
    if (!pd) return false;
    return ObjectHelper.hasFlag(pd.DrillingPattern, drillingPattern);
  }

  public static getPickable(product: Client.Product): Pickable<Client.Product> {
    return {
      item: product,
      disabledReason: null,
      name: product.Name,
      id: product.ProductNo,
      groupName: product.ProductGroupingName,
      description: '',
      imageUrl: ProductHelper.picturePath(product),
      isSelected: false,
      override: product.overrideChain,
    };
  }

  public static getPickableAsProductGroup(
    product: Client.Product,
  ): Pickable<Client.Product> {
    return {
      item: product,
      disabledReason: null,
      name: product.ProductGroupingName,
      //id: product.ProductNo,
      description: '',
      imageUrl: ProductHelper.picturePath(product),
      isSelected: false,
      override: product.overrideChain,
    };
  }

  public static hasVariant(
    product: Client.Product,
    variantNumber: string,
  ): boolean {
    return product.getAllVariants().some((v) => v.Number === variantNumber);
  }

  public static getVariantOptionByNumber(
    product: Client.Product,
    variantNumber: string,
    variantOptionNumber: string,
  ): Interface_DTO.VariantOption | undefined {
    var variantList = product
      .getAllVariants()
      .filter((v) => v.Number === variantNumber);
    if (variantList.length == 0) return undefined;

    var variant = variantList[0];
    return variant.VariantOptions.find(
      (vo) => vo.Number == variantOptionNumber,
    );
  }

  public static getOptimalProduct(
    product: Client.Product,
    width: number,
    depth: number,
    productLineId: Interface_Enums.ProductLineId,
    drillingPattern?: Interface_Enums.DrillingPattern,
  ): Client.Product | undefined {
    let potentialProducts = product.productGroup.filter((p) =>
      ProductHelper.supportsWidth(p, width),
    );
    if (!!drillingPattern)
      potentialProducts = potentialProducts.filter((p) =>
        ProductHelper.supportsDrillingPattern(p, drillingPattern),
      );

    let bestMatch: Client.Product | undefined;
    let bestMatchSupportsDepth: boolean = false;

    for (let product of potentialProducts) {
      if (ProductHelper.defaultDepth(product, productLineId) === depth) {
        // Depth matches exactly, so we take it.
        return product;
      }

      if (!bestMatchSupportsDepth) {
        // We have not yet found a flex depth product, that supports the depth, so we try to find one that does.
        if (ProductHelper.supportsDepth(product, depth)) {
          bestMatch = product;
          bestMatchSupportsDepth = true;
        } else if (ProductHelper.minDepth(product, productLineId) <= depth) {
          if (!!bestMatch) {
            // Check if the current product is a better match than the current best match.
            if (
              ProductHelper.maxDepth(product, productLineId) >
              ProductHelper.maxDepth(bestMatch, productLineId)
            ) {
              bestMatch = product;
            }
          } else {
            // There is no best match yet, so the current product must be the best so far.
            bestMatch = product;
          }
        }
      }
    }

    return bestMatch;
  }
}
