import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import { ProductHelper } from 'app/ts/util/ProductHelper';

import * as VariantNumbers from 'app/ts/VariantNumbers';
import { CabinetSectionHelper } from 'app/ts/util/CabinetSectionHelper';
import { ConfigurationItemHelper } from 'app/ts/util/ConfigurationItemHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { ConfigurationItemService } from 'app/ts/services/ConfigurationItemService';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CorpusLogic {
  constructor(
    private readonly configurationItemService: ConfigurationItemService,
  ) {}

  public accessTest() {
    return 'Corpus logic access test function';
  }

  public recalculate(
    section: Client.CabinetSection,
    initialLoad: boolean,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];

    if (section.CabinetType === Interface_Enums.CabinetType.Swing) {
      // For swing cabinets, corpus is handled elsewhere.
      return result;
    }

    if (App.useDebug && App.debug.showTimings)
      console.time('Recalculate Corpus');

    let editorAssets = section.editorAssets;

    // Ensure we recalculate everything.
    section.corpus.clearBackingVariables();

    // Ensure all values are set and valid
    result.push(...this.ensureValuesAreValid(section, initialLoad));

    // The actual calculations start here...

    // Deductions
    this.calculateDeductions(section);

    this.removeSideItemsNotAtEnd(section, editorAssets);

    // Top and bottom items
    this.calculateItemsTopAndBottom(section, true);
    this.calculateItemsTopAndBottom(section, false);

    // Left and right items
    this.calculateItemsLeftAndRight(section, true, editorAssets);
    this.calculateItemsLeftAndRight(section, false, editorAssets);

    // Set variants
    this.setUserChoiceVariants(section);
    this.setMaterialVariants(section);

    // Lighting
    this.calculateLights(section);
    this.setLightingVariants(section);

    // Update cabinet setion values
    this.updateCabinetSectionDto(section);

    if (App.useDebug && App.debug.showTimings)
      console.timeEnd('Recalculate Corpus');
    return result;
  }

  public recalculatePartTwo(
    section: Client.CabinetSection,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];

    // Joints, sides
    this.calculateFishJointPositionsForCorpusSide(section, true);
    this.calculateFishJointPositionsForCorpusSide(section, false);

    // Joints, top and bottom
    // Allways calculate fish positions by corpus only (gives the correct number of joints)
    this.calculateFishJointPositionsForCorpus(section, true);
    this.calculateFishJointPositionsForCorpus(section, false);

    // For automatic positioning, adjust by interior gables
    if (!section.ManualFishJointPositioning) {
      this.adjustFishJointsByGables(section);
    }
    // For manuel positioning, calculate possible positions, and update actual joints on subsection

    this.calculatePossibleJointPositions(section, true);
    this.calculatePossibleJointPositions(section, false);

    if (section.ManualFishJointPositioning) {
      this.setManualJointPositions(section);
    }

    // Joints, side

    // Add joint variants
    this.setJointVariants(section, Interface_Enums.Direction.Top);
    this.setJointVariants(section, Interface_Enums.Direction.Bottom);
    this.setJointVariants(section, Interface_Enums.Direction.Left);
    this.setJointVariants(section, Interface_Enums.Direction.Right);

    // Update cabinet setion values
    this.updateCabinetSectionDto(section);

    return result;
  }

  //#region Validation of values set by user

  private ensureValuesAreValid(
    section: Client.CabinetSection,
    initialLoad: boolean,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];
    // Material

    //if (!this.isMaterialValid(section.corpus)) {
    //    this.setDefaultMaterial(section.corpus);
    //    if (initialLoad && section.CabinetSectionIndex > 0 && section.CabinetIndex > 0) {
    //        result.push({
    //            cabinetSection: section,
    //            editorSection: Enums.EditorSection.Corpus,
    //            severity: Enums.RecalculationMessageSeverity.Warning,
    //            key: "corpus_material_invalid",
    //            defaultValue: "The Corpus material has been discontinued, and the selection has been reset. Please ensure the solution is as expected."
    //        });
    //    }
    //}

    let panelInvalid = false;
    // CorpusPanels (top, bottom, left, right)
    if (!this.isPanelValid(section.corpus, Interface_Enums.Direction.Top)) {
      this.setDefaultPanel(section.corpus, Interface_Enums.Direction.Top);
      panelInvalid = true;
    }
    if (!this.isPanelValid(section.corpus, Interface_Enums.Direction.Bottom)) {
      this.setDefaultPanel(section.corpus, Interface_Enums.Direction.Bottom);
      panelInvalid = true;
    }
    if (!this.isPanelValid(section.corpus, Interface_Enums.Direction.Left)) {
      this.setDefaultPanel(section.corpus, Interface_Enums.Direction.Left);
      panelInvalid = true;
    }
    if (!this.isPanelValid(section.corpus, Interface_Enums.Direction.Right)) {
      this.setDefaultPanel(section.corpus, Interface_Enums.Direction.Right);
      panelInvalid = true;
    }

    if (panelInvalid && initialLoad) {
      result.push({
        cabinetSection: section,
        editorSection: Enums.EditorSection.Corpus,
        severity: Enums.RecalculationMessageSeverity.Warning,
        key: 'corpus_panel_invalid',
        defaultValue:
          'a corpus panel has been discontinued, and the selection has been reset. Please ensure the solution is as expected.',
      });
    }

    // Dimensions(height / width - top, bottom, left, right)
    if (!this.isDimensionValid(section.corpus, Interface_Enums.Direction.Top)) {
      this.setClosestDimension(section.corpus, Interface_Enums.Direction.Top);
    }
    if (
      !this.isDimensionValid(section.corpus, Interface_Enums.Direction.Bottom)
    ) {
      this.setClosestDimension(
        section.corpus,
        Interface_Enums.Direction.Bottom,
      );
    }
    if (
      !this.isDimensionValid(section.corpus, Interface_Enums.Direction.Left)
    ) {
      this.setClosestDimension(section.corpus, Interface_Enums.Direction.Left);
    }
    if (
      !this.isDimensionValid(section.corpus, Interface_Enums.Direction.Right)
    ) {
      this.setClosestDimension(section.corpus, Interface_Enums.Direction.Right);
    }

    // Height reductions (left, right)
    if (!this.isHeightReductionValid(section.corpus, true)) {
      this.setDefaultHeightReduction(section.corpus, true);
    }
    if (!this.isHeightReductionValid(section.corpus, false)) {
      this.setDefaultHeightReduction(section.corpus, false);
    }

    // Lighting
    if (!this.isLightingProductValid(section.corpus)) {
      this.setDefaultLightingProduct(section.corpus);
    }
    if (!this.isLightingMaterialValid(section.corpus)) {
      this.setDefaultLightingMaterial(section.corpus);
    }

    // Joints

    // ConfigurationItems

    return result;
  }

  private removeSideItemsNotAtEnd(
    section: Client.CabinetSection,
    editorAssets: Client.EditorAssets,
  ) {
    if (section.CabinetSectionIndex > 1) {
      section.corpus.CorpusPanelLeftId = editorAssets.corpusPanels.filter(
        (cp) => cp.isNone,
      )[0].Id;
    }
    if (
      section.CabinetSectionIndex < section.cabinet.actualCabinetSections.length
    ) {
      section.corpus.CorpusPanelRightId = editorAssets.corpusPanels.filter(
        (cp) => cp.isNone,
      )[0].Id;
    }
  }

  private isPanelValid(
    corpus: Client.Corpus,
    direction: Interface_Enums.Direction,
  ): boolean {
    let panel: Client.CorpusPanel | null | undefined;
    let availablePanels;

    switch (direction) {
      case Interface_Enums.Direction.Top:
        panel = corpus.panelTop;
        availablePanels = corpus.availablePanelsTop;
        break;
      case Interface_Enums.Direction.Bottom:
        panel = corpus.panelBottom;
        availablePanels = corpus.availablePanelsBottom;
        break;
      case Interface_Enums.Direction.Left:
        panel = corpus.panelLeft;
        availablePanels = corpus.availablePanelsLeft;
        break;
      case Interface_Enums.Direction.Right:
        panel = corpus.panelRight;
        availablePanels = corpus.availablePanelsRight;
        break;
    }

    if (panel === null) {
      return true;
    }

    if (panel !== undefined && availablePanels !== undefined) {
      let temp = panel;
      return availablePanels.some((p) => ObjectHelper.equals(p, temp));
    }

    return false;
  }
  private setDefaultPanel(
    corpus: Client.Corpus,
    direction: Interface_Enums.Direction,
  ) {
    switch (direction) {
      case Interface_Enums.Direction.Top:
        corpus.CorpusPanelTopId = corpus.availablePanelsTop[0].Id;
        break;
      case Interface_Enums.Direction.Bottom:
        corpus.CorpusPanelBottomId = corpus.availablePanelsBottom[0].Id;
        break;
      case Interface_Enums.Direction.Left:
        corpus.CorpusPanelLeftId = corpus.availablePanelsLeft[0].Id;
        break;
      case Interface_Enums.Direction.Right:
        corpus.CorpusPanelRightId = corpus.availablePanelsRight[0].Id;
        break;
    }
    corpus.clearBackingVariables();
  }

  private isDimensionValid(
    corpus: Client.Corpus,
    direction: Interface_Enums.Direction,
  ): boolean {
    switch (direction) {
      case Interface_Enums.Direction.Top:
        return (
          corpus.heightTop >= corpus.minHeightTop &&
          corpus.heightTop <= corpus.maxHeightTop
        );
      case Interface_Enums.Direction.Bottom:
        return (
          corpus.heightBottom >= corpus.minHeightBottom &&
          corpus.heightBottom <= corpus.maxHeightBottom
        );
      case Interface_Enums.Direction.Left:
        return (
          corpus.widthLeft >= corpus.minWidthLeft &&
          corpus.widthLeft <= corpus.maxWidthLeft
        );
      case Interface_Enums.Direction.Right:
        return (
          corpus.widthRight >= corpus.minWidthRight &&
          corpus.widthRight <= corpus.maxWidthRight
        );
    }

    return false;
  }
  private setDefaultDimension(
    corpus: Client.Corpus,
    direction: Interface_Enums.Direction,
  ) {
    switch (direction) {
      case Interface_Enums.Direction.Top:
        corpus.heightTop = corpus.defaultHeightTop;
        break;
      case Interface_Enums.Direction.Bottom:
        corpus.heightBottom = corpus.defaultHeightBottom;
        break;
      case Interface_Enums.Direction.Left:
        corpus.widthLeft = corpus.defaultWidthLeft;
        break;
      case Interface_Enums.Direction.Right:
        corpus.widthRight = corpus.defaultWidthRight;
        break;
    }
  }

  private isHeightReductionValid(
    corpus: Client.Corpus,
    left: boolean,
  ): boolean {
    if (left) {
      if (corpus.heightReductionLeft && !corpus.heightReductionLeftPossible) {
        return false;
      }
    } else {
      if (corpus.heightReductionRight && !corpus.heightReductionRightPossible) {
        return false;
      }
    }

    return true;
  }
  private setDefaultHeightReduction(corpus: Client.Corpus, left: boolean) {
    if (left) {
      corpus.heightReductionLeft = false;
    } else {
      corpus.heightReductionRight = false;
    }
  }

  private isLightingProductValid(corpus: Client.Corpus): boolean {
    return (
      corpus.lightingProduct === null ||
      corpus.availableLightingProducts.some((p) =>
        ObjectHelper.equals(p, corpus.lightingProduct),
      )
    );
  }
  private setDefaultLightingProduct(corpus: Client.Corpus) {
    corpus.lightingProduct =
      Enumerable.from(corpus.availableLightingProducts).firstOrDefault() ??
      null;
  }

  private isLightingMaterialValid(corpus: Client.Corpus): boolean {
    if (corpus.lightingProduct === null) {
      return corpus.lightingMaterial === null;
    }

    return corpus.availableLightingMaterials.some(
      (mat) =>
        mat.Id ===
        (corpus.lightingMaterial ? corpus.lightingMaterial.Id : null),
    );
  }
  private setDefaultLightingMaterial(corpus: Client.Corpus) {
    if (corpus.lightingProduct === null) {
      corpus.lightingMaterial = null;
    }

    corpus.lightingMaterial =
      Enumerable.from(corpus.availableLightingMaterials).firstOrDefault() ??
      null;
  }

  private getCornerOwners(
    cabinet: Client.Cabinet,
    top: boolean,
  ): Client.CorpusCorner[] {
    let sections = cabinet.actualCabinetSections;
    let numCorners = sections.length - 1;
    let firstSection = sections[0];
    let panel = top
      ? firstSection.corpus.panelTop
      : firstSection.corpus.panelBottom;
    //let otherPanel = top ? otherSection.corpus.panelTop : otherSection.corpus.panelBottom;
    let panelProduct = panel.products.filter(
      (p) => !ProductHelper.isFlexHeight(p, cabinet.ProductLineId),
    )[0];
    if (!panelProduct) {
      panelProduct = panel.products[0];
    }

    //if (!panelProduct || ProductHelper.supportsMiter(panelProduct)) {
    if (panel.products.every((p) => ProductHelper.supportsMiter(p))) {
      let corners: Client.CorpusCorner[] = [];
      for (let corn = 0; corn < numCorners; corn++) {
        corners.push(new Client.CorpusCorner(true, true));
      }
      return corners;
    }
    let maxWidth = ProductHelper.maxWidth(panelProduct);
    let minWidth = ProductHelper.minWidth(panelProduct);

    let numCombinations = 1 << numCorners;

    let bestCombinationBitfield = this.preferredCornerOwnerBitfieldRanking[0];
    let fewestItemsNeeded = Infinity;

    //test all combination of corner owners. Go for the combination that results in the fewest number of items.
    //the cornerOwnerBitfield represents who owns each corner. cornerOwnerBitfield bit 0 is the corner between the first two sections,
    //bit 1 is the corner between the next two sections etc.
    //If the bit is 1, the corner is owned by the left section. Otherwise it's owned by the right section.
    for (
      let cornerOwnerBitfield = 0;
      cornerOwnerBitfield < numCombinations;
      cornerOwnerBitfield++
    ) {
      let totalItemsNeeded = 0;
      let cornerOwnerBitfieldIsValid = true;

      for (
        let sectionNumber = 0;
        sectionNumber < sections.length;
        sectionNumber++
      ) {
        let testSection = sections[sectionNumber];
        let ownsLeftCorner: boolean;
        if (sectionNumber === 0) {
          ownsLeftCorner = true;
        } else {
          ownsLeftCorner =
            (cornerOwnerBitfield & (1 << (sectionNumber - 1))) === 0;
        }

        let ownsRightCorner: boolean;
        if (sectionNumber === sections.length - 1) {
          ownsRightCorner = true;
        } else {
          ownsRightCorner = (cornerOwnerBitfield & (1 << sectionNumber)) !== 0;
        }
        let deduction =
          this.getPanelDeduction(testSection, top, true) +
          this.getPanelDeduction(testSection, top, false);

        let widthNeeded = testSection.Width - deduction;
        if (!ownsLeftCorner)
          widthNeeded -= this.getCorpusPanelDepth(
            sections[sectionNumber - 1],
            top,
          );
        if (!ownsRightCorner)
          widthNeeded -= this.getCorpusPanelDepth(
            sections[sectionNumber + 1],
            top,
          );

        if (widthNeeded < minWidth) {
          //current section is too narrow for this configuration.
          cornerOwnerBitfieldIsValid = false;
          break;
        }

        let itemsNeeded = Math.ceil(widthNeeded / maxWidth);
        totalItemsNeeded += itemsNeeded;
      }
      if (!cornerOwnerBitfieldIsValid) {
        continue;
      }

      if (
        totalItemsNeeded < fewestItemsNeeded || //this combo results in fewer parts needed
        (totalItemsNeeded === fewestItemsNeeded && //or this combo results in the same number of parts,
          //but the combo is preferred over the previous one
          this.preferredCornerOwnerBitfieldRanking.indexOf(
            cornerOwnerBitfield,
          ) <
            this.preferredCornerOwnerBitfieldRanking.indexOf(
              bestCombinationBitfield,
            ))
      ) {
        fewestItemsNeeded = totalItemsNeeded;
        bestCombinationBitfield = cornerOwnerBitfield;
      }
    }
    let result: Client.CorpusCorner[] = [];
    for (let corner = 0; corner < numCorners; corner++) {
      let ownedByLeft = !!(bestCombinationBitfield & (1 << corner));
      result.push(new Client.CorpusCorner(ownedByLeft, !ownedByLeft));
    }
    return result;
  }

  private readonly preferredCornerOwnerBitfieldRanking: number[] = [
    0b10, 0b01, 0b11, 0b00,
  ];

  private getCorpusPanelDepth(
    section: Client.CabinetSection,
    top: boolean,
  ): number {
    if (!section.corpus.material) return section.Depth;

    let panel = top ? section.corpus.panelTop : section.corpus.panelBottom;
    return ObjectHelper.clamp(
      panel.minDepth(section.corpus.material, section.cabinet.ProductLineId),
      section.Depth,
      panel.maxDepth(section.corpus.material, section.cabinet.ProductLineId),
    );
  }

  //#endregion Validation of values set by user

  //#region Calculations

  /**
   * Calculates corpus item reductions for all four corners in both directions
   * @param section
   */
  private calculateDeductions(section: Client.CabinetSection) {
    // If there is no corpus material, reset all deductions
    if (!section.corpus.material) {
      section.corpus.resetDeductions();
      return;
    }

    let extraCornerTopDeductionLeft = 0;
    let extraCornerTopDeductionRight = 0;
    let extraCornerBottomDeductionLeft = 0;
    let extraCornerBottomDeductionRight = 0;
    let productLineId = section.cabinet.ProductLineId;

    // Calculate extra corner deductions
    if (CabinetSectionHelper.hasCorner(section)) {
      let topCornerOwners = this.getCornerOwners(section.cabinet, true);
      let bottomCornerOwners = this.getCornerOwners(section.cabinet, false);

      let calculateExtraDeduction = function (
        panel: Client.CorpusPanel | null,
        left: boolean,
      ): number {
        if (!section.corpus.material) {
          return 0;
        }

        let temp = Number.MAX_VALUE;

        if (panel !== null && !panel.isFullDepth(section)) {
          let fittingDepth =
            CabinetSectionHelper.getAdjacentSectionDepth(section, left) +
            panel.extraDepth(
              section.cabinet.ProductLineId,
              section.corpus.material,
            ); // Use of panel from this section is okay, because all sections have the same top/bottom panels

          for (let product of panel.products) {
            let depth = ProductHelper.isFlexDepth(product, productLineId)
              ? fittingDepth
              : ProductHelper.defaultDepth(
                  product,
                  section.cabinet.ProductLineId,
                  section.corpus.material,
                );
            temp = Math.min(
              temp,
              fittingDepth -
                depth -
                ProductHelper.reductionDepth(product, section.corpus.material),
            );
          }
        }
        let deduction = temp < Number.MAX_VALUE ? temp : 0;

        //adjust for backing of neighbor section
        if (panel != null && !panel.IsFullSize) {
          let neighbor = left ? section.leftNeighbor : section.rightNeighbor;
          if (neighbor) {
            if (
              neighbor.backing.backingType ===
              Interface_Enums.BackingType.Visible
            ) {
              deduction += neighbor.backing.frontZ;
            } else if (
              neighbor.backing.backingType ===
              Interface_Enums.BackingType.Hidden
            ) {
              deduction += neighbor!.backing.backZ;
            }
          }
        }
        return deduction;
      };

      if (section.leftNeighbor) {
        extraCornerTopDeductionLeft = topCornerOwners[
          section.CabinetSectionIndex - 2
        ].ownedByRightSection
          ? calculateExtraDeduction(section.corpus.panelTop, true)
          : section.leftNeighbor.Depth;
        extraCornerBottomDeductionLeft = bottomCornerOwners[
          section.CabinetSectionIndex - 2
        ].ownedByRightSection
          ? calculateExtraDeduction(section.corpus.panelBottom, true)
          : section.leftNeighbor.Depth;
      }
      if (section.rightNeighbor) {
        extraCornerTopDeductionRight = topCornerOwners[
          section.CabinetSectionIndex - 1
        ].ownedByLeftSection
          ? calculateExtraDeduction(section.corpus.panelTop, false)
          : section.rightNeighbor.Depth;
        extraCornerBottomDeductionRight = bottomCornerOwners[
          section.CabinetSectionIndex - 1
        ].ownedByLeftSection
          ? calculateExtraDeduction(section.corpus.panelBottom, false)
          : section.rightNeighbor.Depth;
      }
    }

    section.corpus.topDeductionLeft =
      extraCornerTopDeductionLeft + this.getPanelDeduction(section, true, true);
    section.corpus.bottomDeductionLeft =
      extraCornerBottomDeductionLeft +
      this.getPanelDeduction(section, false, true);
    section.corpus.topDeductionRight =
      extraCornerTopDeductionRight +
      this.getPanelDeduction(section, true, false);
    section.corpus.bottomDeductionRight =
      extraCornerBottomDeductionRight +
      this.getPanelDeduction(section, false, false);

    if (section.corpus.panelLeft != null) {
      if (
        section.corpus.panelTop != null &&
        (section.corpus.panelTop.isFullSize() ||
          section.corpus.panelTop.defaultDepth(
            section.cabinet.ProductLineId,
            section.corpus.material,
          ) >
            section.corpus.panelLeft.defaultDepth(
              section.cabinet.ProductLineId,
              section.corpus.material,
            ))
      ) {
        section.corpus.leftDeductionTop = section.corpus.heightTop;
      } else {
        section.corpus.leftDeductionTop = 0;
      }

      if (
        section.corpus.panelBottom != null &&
        (section.corpus.panelBottom.isFullSize() ||
          section.corpus.panelBottom.defaultDepth(
            section.cabinet.ProductLineId,
            section.corpus.material,
          ) >
            section.corpus.panelLeft.defaultDepth(
              section.cabinet.ProductLineId,
              section.corpus.material,
            ))
      ) {
        section.corpus.leftDeductionBottom = section.corpus.heightBottom;
      } else {
        section.corpus.leftDeductionBottom = 0;
      }
    } else {
      section.corpus.leftDeductionTop =
        section.corpus.panelTop != null ? section.corpus.heightTop : 0;
      section.corpus.leftDeductionBottom =
        section.corpus.panelBottom != null ? section.corpus.heightBottom : 0;
    }

    if (section.corpus.panelRight != null) {
      if (
        section.corpus.panelTop != null &&
        (section.corpus.panelTop.isFullSize() ||
          section.corpus.panelTop.defaultDepth(
            section.cabinet.ProductLineId,
            section.corpus.material,
          ) >
            section.corpus.panelRight.defaultDepth(
              section.cabinet.ProductLineId,
              section.corpus.material,
            ))
      ) {
        section.corpus.rightDeductionTop = section.corpus.heightTop;
      } else {
        section.corpus.rightDeductionTop = 0;
      }

      if (
        section.corpus.panelBottom != null &&
        (section.corpus.panelBottom.isFullSize() ||
          section.corpus.panelBottom.defaultDepth(
            section.cabinet.ProductLineId,
            section.corpus.material,
          ) >
            section.corpus.panelRight.defaultDepth(
              section.cabinet.ProductLineId,
              section.corpus.material,
            ))
      ) {
        section.corpus.rightDeductionBottom = section.corpus.heightBottom;
      } else {
        section.corpus.rightDeductionBottom = 0;
      }
    } else {
      section.corpus.rightDeductionTop =
        section.corpus.panelTop != null ? section.corpus.heightTop : 0;
      section.corpus.rightDeductionBottom =
        section.corpus.panelBottom != null ? section.corpus.heightBottom : 0;
    }
  }

  private getPanelDeduction(
    section: Client.CabinetSection,
    top: boolean,
    left: boolean,
  ): number {
    if (!section.corpus.material) return 0;
    let panel = top ? section.corpus.panelTop : section.corpus.panelBottom;
    let sidePanel = left ? section.corpus.panelLeft : section.corpus.panelRight;
    if (!panel) {
      return 0;
    }
    if (
      panel.isFullSize() ||
      panel.defaultDepth(
        section.cabinet.ProductLineId,
        section.corpus.material,
      ) >
        sidePanel.defaultDepth(
          section.cabinet.ProductLineId,
          section.corpus.material,
        )
    ) {
      return 0;
    } else {
      return left ? section.corpus.widthLeft : section.corpus.widthRight;
    }
  }

  /**
   * Calculates top and bottom corpus panels
   */
  private calculateItemsTopAndBottom(
    section: Client.CabinetSection,
    top: boolean,
  ) {
    let items = top ? section.corpus.itemsTop : section.corpus.itemsBottom;
    let itemVariants = Enumerable.from(items)
      .selectMany((item) => item.UserSelectableItemVariants)
      .toArray();
    let productLineId = section.cabinet.ProductLineId;

    // Clear existing items
    items.length = 0;

    // If there is no material, we do nothing further
    if (!section.corpus.material) {
      return;
    }

    // If there is no corpus panel, we are also done
    let corpusPanel = top
      ? section.corpus.panelTop
      : section.corpus.panelBottom;
    if (corpusPanel == null) {
      return;
    }

    let shouldYieldToBacking =
      !section.backing.isHidden && !corpusPanel.IsFullSize;
    let posY = top ? section.Height : 0;
    let fixedHeight = 0;
    let fittingDepth =
      section.Depth +
      corpusPanel.extraDepth(
        section.cabinet.ProductLineId,
        section.corpus.material,
      );
    if (section.CabinetType === Interface_Enums.CabinetType.SwingFlex) {
      // In SwingFlex, corpus depth should not be changed
    } else if (shouldYieldToBacking) {
      fittingDepth -= section.backing.frontZ;
    } else if (
      section.backing.backingType == Interface_Enums.BackingType.Hidden
    ) {
      //roof should not cover the fitting lists on the back
      fittingDepth -= section.backing.backZ;
    }
    let residualHeight = top
      ? section.corpus.heightTop
      : section.corpus.heightBottom;

    // Find the fixed part
    let fixedProduct = Enumerable.from(corpusPanel.products).firstOrDefault(
      (p) => !ProductHelper.isFlexHeight(p),
    );
    if (fixedProduct != null) {
      fixedHeight = ProductHelper.defaultHeight(fixedProduct);
      residualHeight -= fixedHeight;
    }

    // Flexible part
    let flexProduct = Enumerable.from(corpusPanel.products).firstOrDefault(
      (p) => ProductHelper.isFlexHeight(p),
    );
    if (flexProduct != null) {
      let flexItem = this.configurationItemService.createConfigurationItem(
        Interface_Enums.ItemType.Corpus,
        flexProduct.Id,
        section.corpus.material.Id,
        section,
      );
      flexItem.Height = residualHeight;
      if (top) {
        posY -= residualHeight;
      }
      flexItem.Y = posY;
      if (!top) {
        posY += residualHeight;
      }

      if (ProductHelper.isFlexDepth(flexProduct, productLineId)) {
        flexItem.Depth = fittingDepth;
      }

      flexItem.X = top
        ? section.corpus.topDeductionLeft
        : section.corpus.bottomDeductionLeft;
      let posZ = fittingDepth - flexItem.Depth - flexItem.reductionDepth;
      if (section.CabinetType === Interface_Enums.CabinetType.SwingFlex) {
        // In SwingFlex, corpus should go up against the wall.
      } else if (shouldYieldToBacking) {
        posZ += section.backing.frontZ;
      } else if (
        section.backing.backingType == Interface_Enums.BackingType.Hidden
      ) {
        posZ += section.backing.backZ;
      }
      flexItem.Z = posZ;
      flexItem.ParentItemNo = corpusPanel.Number;
      items.push(flexItem);
    }

    // Create item for fixed part
    if (fixedProduct != null) {
      let fixedItem = this.configurationItemService.createConfigurationItem(
        Interface_Enums.ItemType.Corpus,
        fixedProduct.Id,
        section.corpus.material.Id,
        section,
      );

      if (top) {
        posY -= fixedHeight;
      }
      fixedItem.Y = posY;

      fixedItem.Height = fixedHeight;
      if (ProductHelper.isFlexDepth(fixedProduct, productLineId)) {
        fixedItem.Depth = fittingDepth;
      }

      fixedItem.X = top
        ? section.corpus.topDeductionLeft
        : section.corpus.bottomDeductionLeft;
      let posZ = fittingDepth - fixedItem.Depth - fixedItem.reductionDepth;
      if (section.CabinetType === Interface_Enums.CabinetType.SwingFlex) {
        // In SwingFlex, corpus should go up against the wall.
      } else if (shouldYieldToBacking) {
        posZ += section.backing.frontZ;
      } else if (
        section.backing.backingType == Interface_Enums.BackingType.Hidden
      ) {
        posZ += section.backing.backZ;
      }
      fixedItem.Z = posZ;
      fixedItem.ParentItemNo = corpusPanel.Number;
      items.push(fixedItem);
    }
    this.setVariantOptions(items, itemVariants);
  }

  private setVariantOptions(
    items: Client.ConfigurationItem[],
    itemVariants: Client.ItemVariant[],
  ) {
    for (let item of items) {
      for (let oldVariant of itemVariants) {
        item.setItemVariant(oldVariant.dto, false);
      }
      //for (let newItemVariant of item.UserSelectableItemVariants) {
      //    for (let oldItemVariant of itemVariants) {
      //        if (newItemVariant.VariantId === oldItemVariant.VariantId) {
      //            newItemVariant.variantOption = oldItemVariant.variantOption;
      //        }
      //    }
      //}
    }
  }

  /**
   * Calculates left and right corpus panels
   * @param section
   * @param left
   * @param editorAssets
   */
  private calculateItemsLeftAndRight(
    section: Client.CabinetSection,
    left: boolean,
    editorAssets: Client.EditorAssets,
  ) {
    // Clear existing items
    let items = left ? section.corpus.itemsLeft : section.corpus.itemsRight;
    let itemVariants = Enumerable.from(items)
      .selectMany((item) => item.UserSelectableItemVariants)
      .toArray();
    items.length = 0;

    let productLineId = section.cabinet.ProductLineId;

    // If there is no material, we do nothing further
    if (!section.corpus.material) {
      return;
    }

    // If there is no corpus panel, we are also done
    let corpusPanel = left
      ? section.corpus.panelLeft
      : section.corpus.panelRight;
    if (corpusPanel == null) {
      return;
    }

    //find leftRightVariant and leftRightVariantOption
    let leftRightVariant: Interface_DTO.Variant | undefined = undefined;
    let leftRightVariantOption: Interface_DTO.VariantOption | undefined =
      undefined;
    {
      let leftRightVariantNumber = VariantNumbers.SidePanelLeftRight;
      let leftRightvariantOptionNumber = left
        ? VariantNumbers.Values.SidePanelLeftRight_Left
        : VariantNumbers.Values.SidePanelLeftRight_Right;
      for (let v of editorAssets.variants) {
        if (v.Number === leftRightVariantNumber) {
          leftRightVariant = v;
          break;
        }
      }
      if (!leftRightVariant) throw new Error('LeftRight Variant missing');
      for (let vo of leftRightVariant.VariantOptions) {
        if (vo.Number === leftRightvariantOptionNumber) {
          leftRightVariantOption = vo;
          break;
        }
      }
      if (!leftRightVariantOption)
        throw new Error('leftRightVariantOption missing');
    }

    let shouldYieldToBacking = !section.backing.isHidden;
    let posX = left ? 0 : section.Width;
    let fixedWidth = 0;
    let fittingDepth =
      section.Depth +
      corpusPanel.extraDepth(
        section.cabinet.ProductLineId,
        section.corpus.material,
      );
    if (section.CabinetType === Interface_Enums.CabinetType.SwingFlex) {
      // In SwingFlex, corpus depth should not be changed
    } else if (shouldYieldToBacking) {
      fittingDepth -= section.backing.frontZ;
    } else {
      fittingDepth -= section.backing.backZ;
    }
    let fittingHeight =
      section.Height -
      (left
        ? section.corpus.leftDeductionTop + section.corpus.leftDeductionBottom
        : section.corpus.rightDeductionTop +
          section.corpus.rightDeductionBottom);
    let residualWidth = left
      ? section.corpus.widthLeft
      : section.corpus.widthRight;

    let heightReduction =
      (left && section.HeightReductionCorpusLeft) ||
      (!left && section.HeightReductionCorpusRight);

    // Find the fixed part
    let fixedProduct = Enumerable.from(corpusPanel.products).firstOrDefault(
      (p) => !ProductHelper.isFlexWidth(p),
    );
    if (fixedProduct != null) {
      fixedWidth = ProductHelper.defaultWidth(fixedProduct);
      residualWidth -= fixedWidth;
    }

    // Flexible width part
    let flexProduct = Enumerable.from(corpusPanel.products).firstOrDefault(
      (p) => ProductHelper.isFlexWidth(p),
    );
    if (flexProduct != null) {
      let flexItem = this.configurationItemService.createConfigurationItem(
        Interface_Enums.ItemType.Corpus,
        flexProduct.Id,
        section.corpus.material.Id,
        section,
        1,
        undefined,
        true,
      );
      flexItem.Width = residualWidth;

      // flexItems are always delivered at maxWidth.
      // It is the customer's/technician's responsibility to cut the item down to size.
      const flexProductData =
        flexProduct.getProductData(section.corpus.material.Id) ??
        flexProduct.getProductData();
      if (!!flexProductData) {
        flexItem.actualWidth = flexProductData.MaxWidth;
      }

      if (!left) {
        posX -= residualWidth;
      }
      flexItem.X = posX;
      if (left) {
        posX += residualWidth;
      }

      if (ProductHelper.isFlexDepth(flexProduct, productLineId)) {
        flexItem.Depth = fittingDepth;
      }

      flexItem.HeightReduction = heightReduction;
      flexItem.Height = fittingHeight;

      flexItem.Y = left
        ? section.corpus.leftDeductionBottom
        : section.corpus.rightDeductionBottom;
      flexItem.Z = fittingDepth - flexItem.Depth - flexItem.reductionDepth;
      flexItem.ParentItemNo = corpusPanel.Number;
      flexItem.setItemVariant(
        {
          VariantId: leftRightVariant.Id,
          ActualValue: '',
          VariantOptionId: leftRightVariantOption.Id,
        },
        true,
      );
      //for (let itemVariant of flexItem.VariantOptions) {
      //    if (itemVariant.VariantId === leftRightVariant.Id) {
      //        itemVariant.VariantOptionId = leftRightVariantOption.Id;
      //        break;
      //    }
      //}
      items.push(flexItem);
    }

    // Create item for fixed part
    if (fixedProduct != null) {
      let fixedItem = this.configurationItemService.createConfigurationItem(
        Interface_Enums.ItemType.Corpus,
        fixedProduct.Id,
        section.corpus.material.Id,
        section,
        1,
        undefined,
        true,
      );

      if (!left) {
        posX -= fixedWidth;
      }
      fixedItem.X = posX;

      fixedItem.Width = fixedWidth;
      if (ProductHelper.isFlexDepth(fixedProduct, productLineId)) {
        fixedItem.Depth = fittingDepth;
      }

      fixedItem.HeightReduction = heightReduction;
      fixedItem.Height = fittingHeight;

      fixedItem.Y = left
        ? section.corpus.leftDeductionBottom
        : section.corpus.rightDeductionBottom;
      let fixedZ = fittingDepth - fixedItem.Depth - fixedItem.reductionDepth;
      if (section.CabinetType === Interface_Enums.CabinetType.SwingFlex) {
        // In SwingFlex, corpus should go up against the wall.
      } else if (shouldYieldToBacking) {
        fixedZ += section.backing.frontZ;
      } else {
        fixedZ += section.backing.backZ;
      }

      fixedItem.Z = fixedZ;
      fixedItem.ParentItemNo = corpusPanel.Number;
      fixedItem.setItemVariant(
        {
          VariantId: leftRightVariant.Id,
          ActualValue: '',
          VariantOptionId: leftRightVariantOption.Id,
        },
        true,
      );

      items.push(fixedItem);
    }
    this.setVariantOptions(items, itemVariants);
  }

  private setMaterialVariants(section: Client.CabinetSection) {
    section.corpus.setMaterialVariants();
  }

  /**
   * Set variants chosen by the user + other variants based on them
   * @param section
   */
  private setUserChoiceVariants(section: Client.CabinetSection) {
    // Make sure all variants are present
    for (let item of section.corpus.allCorpusItems) {
      this.configurationItemService.setMissingVariants(item);
    }

    // Set user selected variants on all items
    //section.corpus.setAllItemVariants();

    // Calculate and set other variants based on user selected variants...

    // Gable drilling measurements
    let gables = section.interior.gables;
    {
      let drillingMeasurementLeft =
        gables && gables.length > 0
          ? this.getDrillingMeasurement(gables[0], true)
          : section.InteriorDepth;

      section.corpus.itemsLeft.forEach((corpusItem) => {
        if (ConfigurationItemHelper.hasDrilling(corpusItem)) {
          ConfigurationItemHelper.addDimensionVariantByNumber(
            corpusItem,
            VariantNumbers.DrillingMeasurement,
            drillingMeasurementLeft,
          );
        }
      });
    }

    {
      let drillingMeasurementRight =
        gables && gables.length > 0
          ? this.getDrillingMeasurement(gables[gables.length - 1], false)
          : section.InteriorDepth;
      section.corpus.itemsRight.forEach((corpusItem) => {
        if (ConfigurationItemHelper.hasDrilling(corpusItem)) {
          ConfigurationItemHelper.addDimensionVariantByNumber(
            corpusItem,
            VariantNumbers.DrillingMeasurement,
            drillingMeasurementRight,
          );
        }
      });
    }
  }
  private getDrillingMeasurement(
    gable: Client.ConfigurationItem,
    isLeft: boolean,
  ): number {
    let adjustForModuleGables = isLeft
      ? gable.drilling600Right && gable.drilledLeft
      : gable.drilling600Left && gable.drilledRight;

    let offset = adjustForModuleGables ? 505 - 600 : 0;
    return gable.Depth + offset;
  }

  /**
   * Calculates positions for lighting and creates configuration items
   * @param section
   */
  private calculateLights(section: Client.CabinetSection) {
    // Clear existing items
    section.corpus.resetLighting();

    // If there is no top panel, no lighting is selected or the number of lights is zero, we are done...
    if (
      !section.corpus.panelTop ||
      !section.corpus.lightingProduct ||
      section.corpus.numberOfLights <= 0 ||
      !section.corpus.material ||
      !section.corpus.lightingMaterial
    ) {
      return;
    }

    let lightingProduct = section.corpus.lightingProduct;

    let offsetY = ProductHelper.getSnapOffsetY(lightingProduct);
    let offsetZ = ProductHelper.reductionDepth(lightingProduct);

    let positionY =
      section.Height -
      section.corpus.heightTop +
      offsetY -
      ProductHelper.defaultHeight(lightingProduct);
    let distanceBetweenCenterOfLights =
      CabinetSectionHelper.getSightWidth(section) /
      section.corpus.numberOfLights;
    let lightWidth = ProductHelper.defaultWidth(lightingProduct);

    let positionZ = section.Depth;
    if (section.corpus.panelTop.isFullDepth(section)) {
      positionZ += section.corpus.panelTop.extraDepth(
        section.cabinet.ProductLineId,
        section.corpus.material,
      );
    }
    positionZ -= Math.max(
      0,
      ...section.corpus.panelTop.products.map((p) =>
        ProductHelper.reductionDepth(p),
      ),
    );
    positionZ -= offsetZ;

    let offsetLeft = section.corpus.widthLeft;
    let sectionLeft = CabinetSectionHelper.getSectionLeft(section);
    if (sectionLeft) {
      offsetLeft =
        sectionLeft.Depth -
        CabinetSectionHelper.lightOffsetForCorner(sectionLeft);
    }

    for (var i = 0; i < section.corpus.numberOfLights; i++) {
      let lightingItem = this.configurationItemService.createConfigurationItem(
        Interface_Enums.ItemType.Corpus,
        lightingProduct.Id,
        section.corpus.lightingMaterial.Id,
        section,
      );

      lightingItem.X =
        offsetLeft +
        distanceBetweenCenterOfLights / 2 +
        i * distanceBetweenCenterOfLights -
        lightWidth / 2;
      lightingItem.Y = positionY;
      lightingItem.Z = positionZ;

      section.corpus.lightingItems.push(lightingItem);
    }
  }

  /**
   * Sets lighting variants on top corpus panel items
   * @param section
   */
  private setLightingVariants(section: Client.CabinetSection) {
    // If no lighting is selected or the number of lights is zero, we are done...
    if (!section.corpus.lightingProduct || section.corpus.numberOfLights <= 0) {
      return;
    }

    let lightingProduct = section.corpus.lightingProduct;

    let spotType = '';
    let corpusPanelLighting = Enumerable.from(
      section.corpus.panelTop.LightingProducts,
    ).firstOrDefault((cpl) => cpl.ProductId === lightingProduct.Id);
    if (corpusPanelLighting) {
      spotType = corpusPanelLighting.SpotType;
    }

    let makeRecess = ProductHelper.getSnapOffsetY(lightingProduct) > 0;

    section.corpus.itemsTop.forEach((item) => {
      ConfigurationItemHelper.addVariantOptionByNumbers(
        item,
        VariantNumbers.RecessSpot,
        makeRecess ? VariantNumbers.Values.Yes : VariantNumbers.Values.No,
      );
      ConfigurationItemHelper.addVariantOptionByNumbers(
        item,
        VariantNumbers.Spot,
        makeRecess ? spotType : VariantNumbers.Values.No,
      );
      ConfigurationItemHelper.addDimensionVariantByNumber(
        item,
        VariantNumbers.SpotCount,
        makeRecess ? section.corpus.numberOfLights : 0,
      );
    });
  }

  private calculateFishJointPositionsForCorpusSide(
    section: Client.CabinetSection,
    left: boolean,
  ) {
    if (!section.corpus.material) {
      return;
    }

    let joints = left ? section.corpus.jointsLeft : section.corpus.jointsRight;
    joints.length = 0;

    let corpusPanel = left
      ? section.corpus.panelLeft
      : section.corpus.panelRight;
    if (corpusPanel == null) {
      return;
    }

    let corpusItems = left
      ? section.corpus.itemsLeft
      : section.corpus.itemsRight;
    if (corpusItems === null || corpusItems.length <= 0) {
      return;
    }

    let deduction = left
      ? section.corpus.leftDeductionBottom + section.corpus.leftDeductionTop
      : section.corpus.rightDeductionBottom + section.corpus.rightDeductionTop;
    let requestedHeight = section.Height - deduction;

    let maxPieceHeight = corpusPanel.maxHeight(section.corpus.material);
    if (requestedHeight <= maxPieceHeight)
      //no joints needed
      return;

    //every product must have the FISK_POSIT variant
    if (
      !corpusItems.every(
        (item) =>
          (item.Product &&
            item.Product.getVariants(section.cabinet.ProductLineId).some(
              (variant) => variant.Number === VariantNumbers.JointPosition,
            )) ||
          false,
      )
    ) {
      //This is an error, caught in validation.
      return;
    }
    let minPieceHeight = corpusPanel.minHeight(section.corpus.material);

    let remainingHeight = requestedHeight;
    let jointPosition = 0;

    while (remainingHeight > 0) {
      if (jointPosition > 0) {
        joints.push(jointPosition);
      }
      let pieceHeight = Math.min(maxPieceHeight, remainingHeight);
      if (
        remainingHeight - pieceHeight < minPieceHeight &&
        remainingHeight - pieceHeight > 0
      ) {
        pieceHeight = remainingHeight - minPieceHeight;
      }

      remainingHeight -= pieceHeight;
      jointPosition += pieceHeight;
    }
  }

  /**
   * Calculates joints based on corpus only (corpus panel + corners)
   * This calculates the correct number of joints, even though they may be repositioned later
   * @param section
   * @param top Top or bottom corpus panel
   */
  private calculateFishJointPositionsForCorpus(
    section: Client.CabinetSection,
    top: boolean,
  ) {
    if (!section.corpus.material) {
      return;
    }

    let joints = top ? section.corpus.jointsTop : section.corpus.jointsBottom;
    joints.length = 0;

    let corpusPanel = top
      ? section.corpus.panelTop
      : section.corpus.panelBottom;
    if (corpusPanel == null) {
      return;
    }

    let corpusItems = top
      ? section.corpus.itemsTop
      : section.corpus.itemsBottom;
    if (corpusItems === null || corpusItems.length <= 0) {
      return;
    }

    let residualWidth =
      section.Width -
      (top
        ? section.corpus.topDeductionLeft + section.corpus.topDeductionRight
        : section.corpus.bottomDeductionLeft +
          section.corpus.bottomDeductionRight);

    let lastPartMinWidth = this.calculateMinimumItemWidth(section, top, 'last');
    let maxWidth = corpusPanel.maxWidth(section.corpus.material);

    let jointPosition = 0;
    let newItems: Client.ConfigurationItem[] = [];

    while (residualWidth > 0) {
      let itemWidth;

      if (residualWidth >= maxWidth + lastPartMinWidth) {
        // There is room for one more in full width
        itemWidth = maxWidth;
      } else if (residualWidth > maxWidth) {
        // There is room for one more, but not in full width
        itemWidth = corpusPanel.isFlexWidth
          ? residualWidth - lastPartMinWidth
          : maxWidth;
      } else {
        itemWidth = residualWidth; // There is only room for a part
      }

      if (itemWidth <= 0) {
        itemWidth = residualWidth;
      }

      for (let item of corpusItems) {
        if (item.isFlexWidth) {
          if (jointPosition <= 0) {
            // This is the first item, no joints needed yet. Just set the width...
            item.Width = residualWidth;
          } else {
            // There is an item already. Add joint...
            joints.push(jointPosition);
          }
        } else {
          if (jointPosition <= 0) {
            item.Width = itemWidth;
          } else {
            let previousItem =
              newItems.length > 0 ? newItems[newItems.length - 1] : item;

            let newItem = this.configurationItemService.createConfigurationItem(
              Interface_Enums.ItemType.Corpus,
              item.Product!.Id,
              section.corpus.material.Id,
              section,
            );

            newItem.Height = previousItem.Height;
            newItem.Width = itemWidth;
            newItem.Depth = previousItem.Depth;

            newItem.X = previousItem.rightX;
            newItem.Y = previousItem.Y;
            newItem.Z = previousItem.Z;

            newItem.ParentItemNo = corpusPanel.Number;
            newItems.push(newItem);
          }
        }
      }

      jointPosition += itemWidth;
      residualWidth -= itemWidth;
    }

    corpusItems.push(...newItems);
  }

  /**
   * Adjusts joints in relation to gables.
   * @param section
   */
  private adjustFishJointsByGables(section: Client.CabinetSection) {
    // Without a corpus material, there can't be any corpus panels
    if (!section.corpus.material) return;

    // With no full depth corpus panels, joints are not adjusted by gables
    if (
      (section.corpus.panelTop === null ||
        !section.corpus.panelTop.isFullDepth(section)) &&
      (section.corpus.panelBottom === null ||
        !section.corpus.panelBottom.isFullDepth(section))
    ) {
      return;
    }

    // With no joints, there is nothing to adjust...
    let jointCount = Math.max(
      section.corpus.jointsTop.length,
      section.corpus.jointsBottom.length,
    );
    if (jointCount < 1) {
      return;
    }

    // Okay, let's do some adjusting...
    let minWidthFirst = Math.max(
      this.calculateMinimumItemWidth(section, true, 'first'),
      this.calculateMinimumItemWidth(section, false, 'first'),
    );
    let minWidthMiddle = Math.max(
      this.calculateMinimumItemWidth(section, true, 'middle'),
      this.calculateMinimumItemWidth(section, false, 'middle'),
    );
    let minWidthLast = Math.max(
      this.calculateMinimumItemWidth(section, true, 'last'),
      this.calculateMinimumItemWidth(section, false, 'last'),
    );
    let maxWidth = 0;
    if (section.corpus.panelTop != null && !section.corpus.panelTop.isNone) {
      maxWidth = section.corpus.panelTop.maxWidth(section.corpus.material);
    }
    if (
      section.corpus.panelBottom != null &&
      !section.corpus.panelBottom.isNone
    ) {
      if (maxWidth > 0)
        maxWidth = Math.min(
          maxWidth,
          section.corpus.panelBottom.maxWidth(section.corpus.material),
        );
      else
        maxWidth = section.corpus.panelBottom.maxWidth(section.corpus.material);
    }

    let absoluteMaxJointPosition =
      section.Width -
      Math.max(
        section.corpus.topDeductionRight +
          this.calculateMinimumItemWidth(section, true, 'last'),
        section.corpus.bottomDeductionRight +
          this.calculateMinimumItemWidth(section, false, 'last'),
      );
    let minDeductionRight = Math.min(
      section.corpus.topDeductionRight,
      section.corpus.bottomDeductionRight,
    );

    let gables = Enumerable.from(
      section.interior.items.filter((ii) => ii.isGable),
    ).orderBy((g) => g.centerX);

    let calculateBounds = (
      jointNumber: number,
      position: number,
    ): { min: number; max: number } => {
      let minWidth: number;
      if (jointNumber === 0) {
        minWidth = minWidthFirst;
      } else if (jointNumber === jointCount - 1) {
        minWidth = minWidthLast;
      } else {
        minWidth = minWidthMiddle;
      }

      let numRemainingPieces = jointCount - jointNumber;
      let numRemainingJoints = numRemainingPieces - 1;
      let min = Math.max(
        position + minWidth,
        section.Width - minDeductionRight - numRemainingPieces * maxWidth,
      );

      let max = Math.min(
        position + maxWidth,
        absoluteMaxJointPosition - numRemainingJoints * minWidthMiddle,
      );
      if (min > max) {
        return { min: max, max: min };
      }
      return { min, max };
    };

    let calculatePossibleJoints = function (
      gableType: 'tall' | 'low' | 'none',
    ): number[] {
      let position = Math.min(
        section.corpus.topDeductionLeft,
        section.corpus.bottomDeductionLeft,
      );
      let gablePositions = new Array<number>();

      for (var jointNumber = 0; jointNumber < jointCount; jointNumber++) {
        let bounds = calculateBounds(jointNumber, position);
        let gable = null;

        if (gableType == 'none') {
          gablePositions.push(bounds.max);
          continue;
        }

        if (gableType == 'tall') {
          gable = gables.lastOrDefault(
            (g) =>
              g.Height >= section.InteriorHeight &&
              g.centerX >= bounds.min &&
              g.centerX <= bounds.max,
          );
        }
        if (gable == null) {
          gable = gables.lastOrDefault(
            (g) => g.centerX >= bounds.min && g.centerX <= bounds.max,
          );
        }

        if (gable != null) {
          position = gable.centerX;
        } else {
          position = bounds.max;
        }

        gablePositions.push(position);
      }

      return gablePositions;
    };

    let tempJoints: number[];

    if (gables.any()) {
      tempJoints = calculatePossibleJoints('tall'); // First try by prefering tall gables
      if (
        !tempJoints.every((tj) =>
          gables.any((g) => Math.abs(g.centerX - tj) < 1),
        )
      ) {
        // Prefering tall gables didn't work. Now try with any gable
        tempJoints = calculatePossibleJoints('low');
      }
    } else {
      tempJoints = calculatePossibleJoints('none');
    }

    // Now update the actual joints

    let jointCountTop = section.corpus.jointsTop.length;
    let jointCountBottom = section.corpus.jointsBottom.length;

    section.corpus.jointsTop = new Array<number>();
    section.corpus.jointsBottom = new Array<number>();

    // Adjust top and/or bottom joints by adding the appropriate deductions
    for (let tempJoint of tempJoints) {
      section.corpus.jointsTop.push(
        tempJoint - section.corpus.topDeductionLeft,
      );
      section.corpus.jointsBottom.push(
        tempJoint - section.corpus.bottomDeductionLeft,
      );
    }

    // If the number of needed joints is not the same, remove the extra joint (must be in the corner...)
    let jointCountDifference = jointCountTop - jointCountBottom;
    if (jointCountDifference !== 0) {
      let removeLast = !!section.rightNeighbor;
      let indexToRemove = removeLast ? tempJoints.length - 1 : 0;

      if (jointCountDifference > 0) {
        section.corpus.jointsBottom.splice(indexToRemove, 1);
      } else {
        section.corpus.jointsTop.splice(indexToRemove, 1);
      }
    }
  }

  /**
   * Calculates the minimum width a top or bottom corpus panel may have.
   * Cabinet corners affect this value.
   * @param section
   * @param top
   */
  private calculateMinimumItemWidth(
    section: Client.CabinetSection,
    top: boolean,
    position: 'first' | 'middle' | 'last',
  ): number {
    if (!section.corpus.material) return 0;

    let corpusPanel = top
      ? section.corpus.panelTop
      : section.corpus.panelBottom;
    if (corpusPanel == null /*|| !corpusPanel.isFullDepth(section)*/) {
      return 0;
    }

    let minWidth = corpusPanel.minWidth(section.corpus.material);

    let isAtLeftCorner =
      position === 'first' && CabinetSectionHelper.hasCornerLeft(section);
    let isAtRightCorner =
      position === 'last' && CabinetSectionHelper.hasCornerRight(section);

    //Fishjoints should not be placed too close to a corner.

    if (isAtRightCorner) {
      let nextDepth = Math.max(
        CabinetSectionHelper.getAdjacentCorpusProductDepth(section, false, top),
        CabinetSectionHelper.getAdjacentSectionDepth(section, false),
      );

      let deduction = top
        ? section.corpus.topDeductionRight
        : section.corpus.bottomDeductionRight;
      let cornerMin = nextDepth - deduction + Constants.CornerJointOffset;

      minWidth = Math.max(minWidth, cornerMin);
    } else if (isAtLeftCorner) {
      let prevDepth = Math.max(
        CabinetSectionHelper.getAdjacentCorpusProductDepth(section, true, top),
        CabinetSectionHelper.getAdjacentSectionDepth(section, true),
      );

      let deduction = top
        ? section.corpus.topDeductionLeft
        : section.corpus.bottomDeductionLeft;

      let cornerMin = prevDepth - deduction + Constants.CornerJointOffset;

      minWidth = Math.max(minWidth, cornerMin);
    }

    return minWidth;
  }

  /**
   * Calculates possible joint positions for manual positioning.
   * @param section
   * @param top
   */
  private calculatePossibleJointPositions(
    section: Client.CabinetSection,
    top: boolean,
  ) {
    let corpusPanel = top
      ? section.corpus.panelTop
      : section.corpus.panelBottom;
    let constrainedJoints = top
      ? section.corpus.manualJointPositionsTop
      : section.corpus.manualJointPositionsBottom;
    let actualJoints = top
      ? section.corpus.jointsTop
      : section.corpus.jointsBottom;

    if (!corpusPanel || !section.corpus.material) {
      constrainedJoints.length = 0;
      actualJoints.length = 0;
      return;
    }

    let deductionLeft = top
      ? section.corpus.topDeductionLeft
      : section.corpus.bottomDeductionLeft;
    let deductionRight = top
      ? section.corpus.topDeductionRight
      : section.corpus.bottomDeductionRight;

    let totalWidth = section.Width - (deductionLeft + deductionRight);
    let maxWidth = corpusPanel.maxWidth(section.corpus.material);
    let minWidthLast = this.calculateMinimumItemWidth(section, top, 'last');

    // Ensure the number of constrained joints is correct.
    if (section.ManualFishJointPositioning) {
      for (var i = 0; i < actualJoints.length; i++) {
        if (constrainedJoints.length === i) {
          constrainedJoints.push(new Client.ConstrainedNumber(actualJoints[i]));
        }
      }

      while (constrainedJoints.length > actualJoints.length) {
        constrainedJoints.splice(actualJoints.length);
      }
    } else {
      constrainedJoints.length = 0;
      for (let joint of actualJoints) {
        constrainedJoints.push(new Client.ConstrainedNumber(joint));
      }
    }

    // Calculate the min and max values
    for (var i = 0; i < constrainedJoints.length; i++) {
      // Maximum value
      let maxValue =
        i === 0 ? maxWidth : constrainedJoints[i - 1].actualValue + maxWidth;

      let minWidth =
        i === 0
          ? this.calculateMinimumItemWidth(section, top, 'first')
          : i === constrainedJoints.length - 1
            ? this.calculateMinimumItemWidth(section, top, 'last')
            : this.calculateMinimumItemWidth(section, top, 'middle');

      if (i < constrainedJoints.length - 1) {
        //this is not the last joint
        maxValue = Math.min(
          maxValue,
          constrainedJoints[i + 1].actualValue - minWidth,
        );
      }

      constrainedJoints[i].maxValue = Math.min(
        maxValue,
        totalWidth - minWidthLast,
      );

      // Minimum value
      let minValue =
        i == constrainedJoints.length - 1
          ? totalWidth - maxWidth
          : constrainedJoints[i + 1].actualValue - maxWidth;
      if (i > 0) {
        minValue = Math.max(
          minValue,
          constrainedJoints[i - 1].actualValue + minWidth,
        );
      }
      constrainedJoints[i].minValue = Math.max(minValue, minWidth);
    }
  }

  /**
   * Sets the actual joints to manually adjusted positions
   * @param section
   */
  private setManualJointPositions(section: Client.CabinetSection) {
    // Clear existing joints
    section.corpus.jointsTop.length = 0;
    section.corpus.jointsBottom.length = 0;

    // Set joints based on constrained joints
    for (let joint of section.corpus.manualJointPositionsTop) {
      section.corpus.jointsTop.push(joint.actualValue);
    }
    for (let joint of section.corpus.manualJointPositionsBottom) {
      section.corpus.jointsBottom.push(joint.actualValue);
    }
  }

  /**
   * Sets joint variants on items
   * @param section
   * @param direction
   */
  private setJointVariants(
    section: Client.CabinetSection,
    direction: Interface_Enums.Direction,
  ) {
    let corpusItems: Client.ConfigurationItem[] = [];
    let actualJoints: number[] = [];

    switch (direction) {
      case Interface_Enums.Direction.Top:
        corpusItems = section.corpus.itemsTop;
        actualJoints = section.corpus.jointsTop;
        break;

      case Interface_Enums.Direction.Bottom:
        corpusItems = section.corpus.itemsBottom;
        actualJoints = section.corpus.jointsBottom;
        break;

      case Interface_Enums.Direction.Left:
        corpusItems = section.corpus.itemsLeft;
        actualJoints = section.corpus.jointsLeft;
        break;

      case Interface_Enums.Direction.Right:
        corpusItems = section.corpus.itemsRight;
        actualJoints = section.corpus.jointsRight;
        break;

      default:
    }

    //if (corpusItems.length < 1 || actualJoints.length < 1) {
    //    return;
    //}

    corpusItems.forEach((item) => {
      // Reset any existing joint positions
      ConfigurationItemHelper.removeItemVariantByVariantNumber(
        item,
        VariantNumbers.JointPosition,
      );

      if (actualJoints.length > 0) {
        ConfigurationItemHelper.addVariantOptionByNumbers(
          item,
          VariantNumbers.Joint,
          VariantNumbers.Values.Yes,
        );
        for (var i = 0; i < actualJoints.length; i++) {
          ConfigurationItemHelper.addDimensionVariantOptionByNumber(
            item,
            VariantNumbers.JointPosition,
            i,
            actualJoints[i],
          );
        }
      } else {
        // Set joint variant to "no"
        ConfigurationItemHelper.addVariantOptionByNumbers(
          item,
          VariantNumbers.Joint,
          VariantNumbers.Values.No,
        );
      }
    });
  }

  //#endregion Calculations

  //#region Calculations, Cabinet section

  /**
   * Updates values, that are dependent on corpus, on cabinet section
   * @param section
   */
  private updateCabinetSectionDto(section: Client.CabinetSection) {
    this.setCorpusDimensions(section);
    this.setInteriorDimensions(section);
  }

  /**
   *
   * @param section
   */
  private setCorpusDimensions(section: Client.CabinetSection) {
    section.BottomHeight = section.corpus.heightBottom;
    section.LeftWidth = section.corpus.widthLeft;
    section.RightWidth = section.corpus.widthRight;
  }

  /**
   * Calculates and sets interior height and width
   * @param section
   */
  private setInteriorDimensions(section: Client.CabinetSection) {
    section.InteriorHeight =
      section.Height -
      (CabinetSectionHelper.getInteriorDeductionTop(section) +
        CabinetSectionHelper.getInteriorDeductionBottom(section));
    section.InteriorWidth =
      section.Width -
      (CabinetSectionHelper.getInteriorDeductionLeft(section) +
        CabinetSectionHelper.getInteriorDeductionRight(section));
  }

  /**
   * Sets the closest dimension for the corpus based on the specified direction.
   * Ensures that the dimensions do not exceed their defined minimum and maximum limits.
   * @param corpus - The corpus object containing dimensions to be adjusted.
   * @param direction - The direction in which to set the closest dimension.
   */
  private setClosestDimension(
    corpus: Client.Corpus,
    direction: Interface_Enums.Direction,
  ) {
    switch (direction) {
      case Interface_Enums.Direction.Top:
        if (corpus.heightTop < corpus.minHeightTop) {
          corpus.heightTop = corpus.minHeightTop;
        } else if (corpus.heightTop > corpus.maxHeightTop) {
          corpus.heightTop = corpus.maxHeightTop;
        }
        break;
      case Interface_Enums.Direction.Bottom:
        if (corpus.heightBottom < corpus.minHeightBottom) {
          corpus.heightBottom = corpus.minHeightBottom;
        } else if (corpus.heightBottom > corpus.maxHeightBottom) {
          corpus.heightBottom = corpus.maxHeightBottom;
        }
        break;
      case Interface_Enums.Direction.Left:
        if (corpus.widthLeft < corpus.minWidthLeft) {
          corpus.widthLeft = corpus.minWidthLeft;
        } else if (corpus.widthLeft > corpus.maxWidthLeft) {
          corpus.widthLeft = corpus.maxWidthLeft;
        }
        break;
      case Interface_Enums.Direction.Right:
        if (corpus.widthRight < corpus.minWidthRight) {
          corpus.widthRight = corpus.minWidthRight;
        } else if (corpus.widthRight > corpus.maxWidthRight) {
          corpus.widthRight = corpus.maxWidthRight;
        }
        break;
    }
  }

  //#endregion Calculations, Cabinet section

  //#region TEMP...

  /*

  ---------------------------------------------------

  - Varianter på paneler...

  ---------------------------------------------------

  ResetJointCalculationToAuto() - Brugeren skal adviseres

  SetDefaultMaterial() - kun første gang, når der oprettes et nyt skab

  Load(ConfigurationEx configuration)


  ??? ------------------------------------------------------------------

  UpdateValuesFromSibling(ConfigurationSuper configuration)

  */

  //#endregion TEMP...
}
