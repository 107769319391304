<div
  class="error-info"
  [attr.errorLevel]="errorInfo.level"
  [ngClass]="{'has-url': errorInfo.url}"
  [attr.original-long-key]="errorInfo.originalLongKey"
>
  <div class="description">
    <a
      class="short-description"
      *ngIf="showLink"
      [textContent]="errorInfo.shortDescription"
      [routerLink]="url"
      [queryParams]="{selected: fragment}"
    ></a>
    <span
      class="short-description"
      *ngIf="!showLink"
      [textContent]="errorInfo.shortDescription"
    ></span>

    <div
      class="long-description"
      [textContent]="errorInfo.longDescription"
    ></div>

    <a
      class="error-link"
      style="text-decoration: none"
      *ngIf="showLink"
      [routerLink]="url"
      [queryParams]="{selected: fragment}"
      [textContent]="translate('errorinfo_go_to_error_tab', 'Go to tab')"
    ></a>
  </div>
  <div
    class="logo floorplan-errorlevel-cell floorplan-errorlevel-cell-{{errorInfo.level}}"
  ></div>
</div>
