import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService as TranslateService } from 'app/ts/services/TranslationService';
import { Side, DoorOpenSide } from 'app/ts/clientDto/Enums';
import { FloorPlanService } from 'app/ts/services/FloorPlanService';
import { EditorTypeService } from 'app/ts/viewmodels/editor-type.service';
import { Door, DoorPlacement, Wall } from '../module';
import { PartitionPlanCommandService } from '../partition-plan-command.service';
import { PartitionPlanQueryService } from '../partition-plan-query.service';

@Component({
  selector: 'module-properties',
  templateUrl: 'module-properties.component.html',
  styleUrls: ['../../../style/editor.scss', 'property-sheet.scss'],
})
export class ModulePropertiesComponent {
  protected router: Router = inject(Router);

  public sectionHeader: string;
  constructor(
    private readonly partitionQueryService: PartitionPlanQueryService,
    private readonly partitionCommandService: PartitionPlanCommandService,
    private readonly floorPlanService: FloorPlanService,
    private readonly editorTypeService: EditorTypeService,
    private readonly translateService: TranslateService,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.sectionHeader = translateService.translate('module', 'Module');
  }

  ngOnInit(): void {
    this.translateService.translationsUpdated$.subscribe(() => {
      this.sectionHeader = this.translateService.translate('module', 'Module');
      this.changeDetector.detectChanges();
    });
  }

  public get isWallSelected(): boolean {
    return this.partitionQueryService.selectedModule instanceof Wall;
  }

  public get isDoorSelected(): boolean {
    return this.partitionQueryService.selectedModule instanceof Door;
  }

  public get isDoorModuleTypeDisabled(): boolean {
    let modules = this.partitionQueryService.getModulesForSection(
      this.partitionQueryService.selectedSection!.id,
    );
    let wallCount = modules
      .map((md) => (md.isDoor ? 0 : 1) as number)
      .reduce((acc, i) => acc + i);
    let doorCount = modules
      .map((md) => (md.isDoor ? 1 : 0) as number)
      .reduce((acc, i) => acc + i);

    return (
      this.isWallSelected &&
      (wallCount <= 1 ||
        doorCount >= 2 ||
        this.partitionQueryService.doesCreateOutsideDoubleDoors(
          this.partitionQueryService.selectedModule!.id,
        ) ||
        this.partitionQueryService.hasTwoDoorsBeside(
          this.partitionQueryService.selectedModule!.id,
          DoorOpenSide.Left,
        ) ||
        this.partitionQueryService.hasTwoDoorsBeside(
          this.partitionQueryService.selectedModule!.id,
          DoorOpenSide.Right,
        ))
    );
  }

  public get isDoorPlacedFront(): boolean {
    let door = this.partitionQueryService.selectedModule as Door;
    return door.placement == DoorPlacement.Front;
  }

  public get isDoorPlacedBack(): boolean {
    let door = this.partitionQueryService.selectedModule as Door;
    return door.placement == DoorPlacement.Back;
  }

  public get isDoorOpenSideChangeDisabled(): boolean {
    return (
      this.partitionQueryService.hasDoorAsNeighborModule(
        this.partitionQueryService.selectedModule!.id,
      ) ||
      this.partitionQueryService.isEdgdeModule(
        this.partitionQueryService.selectedModule!.id,
      )
    );
  }

  public get isDoorOpenBothSidesChangeDisabled(): boolean {
    let door = this.partitionQueryService.selectedModule as Door;
    let leftNeighbor = this.partitionQueryService.getDoorNeighborModule(
      this.partitionQueryService.selectedModule!.id,
      DoorOpenSide.Left,
    );
    let rightNeighbor = this.partitionQueryService.getDoorNeighborModule(
      this.partitionQueryService.selectedModule!.id,
      DoorOpenSide.Right,
    );
    if (
      !leftNeighbor ||
      !rightNeighbor ||
      leftNeighbor.isDoor ||
      rightNeighbor.isDoor
    )
      return true;

    let nextLeftNeighbor = this.partitionQueryService.getDoorNeighborModule(
      leftNeighbor.id,
      DoorOpenSide.Left,
    );
    if (nextLeftNeighbor?.isDoor) {
      let leftDoor = nextLeftNeighbor as Door;
      if (leftDoor.placement == door.placement) return true;
    }
    let nextRightNeighbor = this.partitionQueryService.getDoorNeighborModule(
      rightNeighbor.id,
      DoorOpenSide.Right,
    );
    if (nextRightNeighbor?.isDoor) {
      let rightDoor = nextRightNeighbor as Door;
      if (rightDoor.placement == door.placement) return true;
    }
    return false;
  }

  public get isDoorOpeningLeft(): boolean {
    let door = this.partitionQueryService.selectedModule as Door;
    return door.openDirection == DoorOpenSide.Left;
  }

  public get isDoorOpeningRight(): boolean {
    let door = this.partitionQueryService.selectedModule as Door;
    return door.openDirection == DoorOpenSide.Right;
  }

  public get isDoorOpeningBothWays(): boolean {
    let door = this.partitionQueryService.selectedModule as Door;
    return door.openDirection == DoorOpenSide.Both;
  }

  public get leftSlideExtensionAmount(): number {
    let door = this.partitionQueryService.selectedModule as Door;
    return Math.round(door.leftSlideExtensionAmount);
  }

  public get rightSlideExtensionAmount(): number {
    let door = this.partitionQueryService.selectedModule as Door;
    return Math.round(door.rightSlideExtensionAmount);
  }

  public get minSlideExtensionAmount(): number {
    return this.partitionQueryService.getDoorMinSlideExtensionAmount(
      this.partitionQueryService.selectedModule!.id,
    );
  }

  public get maxLeftSlideExtensionAmount(): number {
    let door = this.partitionQueryService.selectedModule! as Door;
    return this.partitionQueryService.getDoorMaxSlideExtensionAmount(
      door.id,
      DoorOpenSide.Left,
    );
  }

  public get maxRightSlideExtensionAmount(): number {
    let door = this.partitionQueryService.selectedModule! as Door;
    return this.partitionQueryService.getDoorMaxSlideExtensionAmount(
      door.id,
      DoorOpenSide.Right,
    );
  }

  public get doorHasLeftEndStop(): boolean {
    return this.partitionQueryService.getEndStopLeftOnAny();
  }

  public get doorHasRightEndStop(): boolean {
    return this.partitionQueryService.getEndStopRightOnAny();
  }

  public setEndStopState(side: string, state: boolean) {
    this.partitionCommandService.setDoorEndStop(
      this.partitionQueryService.selectedModule!.id,
      side as Side,
      state,
    );
    this.floorPlanService.setChanged(this.editorTypeService.floorPlan);
  }

  public setLeftDoorSlideExtensionAmount(newAmount: number) {
    if (Number.isNaN(newAmount)) newAmount = 0;
    this.partitionCommandService.updateDoorSlideExtensionAmount(
      this.partitionQueryService.selectedModule!.id,
      newAmount,
      DoorOpenSide.Left,
    );
    this.floorPlanService.setChanged(this.editorTypeService.floorPlan);
  }

  public setRightDoorSlideExtensionAmount(newAmount: number) {
    if (Number.isNaN(newAmount)) newAmount = 0;
    this.partitionCommandService.updateDoorSlideExtensionAmount(
      this.partitionQueryService.selectedModule!.id,
      newAmount,
      DoorOpenSide.Right,
    );
    this.floorPlanService.setChanged(this.editorTypeService.floorPlan);
  }

  public setDoorPlacement(placement: string) {
    this.partitionCommandService.setDoorPlacement(
      this.partitionQueryService.selectedModule!.id,
      placement as DoorPlacement,
    );
    this.floorPlanService.setChanged(this.editorTypeService.floorPlan);
  }

  public setDoorOpenDirection(side: string) {
    this.partitionCommandService.setDoorOpenDirection(
      this.partitionQueryService.selectedModule!.id,
      side as DoorOpenSide,
    );
    this.floorPlanService.setChanged(this.editorTypeService.floorPlan);
  }

  public ModuleTypeChange(moduleType: string) {
    this.partitionCommandService.updateSelectedModuleType(moduleType);
    this.floorPlanService.setChanged(this.editorTypeService.floorPlan);
  }

  public routeToFillings() {
    let path =
      this.router.url +
      `/partition/${this.partitionQueryService.selectedSection?.id}/fillings`;
    this.router.navigateByUrl(path);
  }
}
