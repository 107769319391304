import {
  Component,
  Inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  ActiveStore,
  ActiveStoreInjector,
} from 'app/functional-core/ambient/stores/ActiveStore';
import { PermissionService } from 'app/identity-and-access/permission.service';
import * as Client from 'app/ts/clientDto/index';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { FormattingService } from 'app/ts/services/FormattingService';
import { DateHelper } from 'app/ts/util/DateHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'floorplan-overview-box',
  templateUrl: './floorPlanOverviewBox.html',
  styleUrls: [
    '../../../../style/floorplanOverviewBox.scss',
    '../../../../style/overviews.scss',
    '../../../../style/rightMenu.scss',
    '../../../../style/widgets.scss',
  ],
})
export class FloorplanOverviewBoxVm extends BaseVm implements OnChanges {
  public deliveryWeek: string = '';
  public deliveryWeekdays: string = '';
  public plannedDeliveryDay: string = '';
  public editDeadline: string = '';
  public numCabinets: string = '';
  public freightPrice: string = '';
  public totalPrice: string = '';
  public orderNo: string = '';
  public showProductionDrawingLink: boolean = false;

  private store: Interface_DTO.Store | null = null;

  constructor(
    baseVmService: BaseVmService,
    private readonly formattingService: FormattingService,
    private readonly permissions: PermissionService,
    @Inject(ActiveStoreInjector) public readonly activeStore: ActiveStore,
  ) {
    super(baseVmService);
    super.ensureUnsubscribe(
      activeStore.subscribe((store) => {
        this.store = store;
      }),
    );
    this.showProductionDrawingLink = permissions.canDownloadProductionDrawings;
  }

  //AngularJS bindings
  @Input()
  floorplans: Client.FloorPlanOverview[] | undefined = undefined;

  public ngOnChanges(changes: SimpleChanges): void {
    this.deliveryWeek = this.sameOrEmptyString((fpo) =>
      fpo.DeliveryWeek.toString(),
    );
    this.numCabinets = this.sum((fpo) => fpo.NumCabinets).toString();
    this.freightPrice = ObjectHelper.roundMoney(
      this.addVAT(this.sum((fpo) => fpo.TotalFreightAndFees)),
    );
    this.totalPrice = ObjectHelper.roundMoney(
      this.addVAT(this.sum((fpo) => fpo.TotalPrice)),
    );

    let ddays = this.sameOrEmptyString((fpo) => fpo.ExpectedDeliveryDays);
    let activeDeliveryDays = ObjectHelper.getWeekDays(ddays);
    let translatedDayNames = activeDeliveryDays.map((add) =>
      this.translate('weekday_' + add, add),
    );
    this.deliveryWeekdays = translatedDayNames.join('/');

    let deadlineIso = this.sameOrEmptyString(
      (fpo) => fpo.CorrectionDeadlineDate || 'unknown',
    );
    if (deadlineIso && deadlineIso !== 'unknown') {
      let deadlineDate = DateHelper.fromIsoString(deadlineIso);
      this.editDeadline = this.formattingService.formatDate(
        deadlineDate,
        false,
      );
    } else if (deadlineIso === 'unknown') {
      this.editDeadline = this.translate(
        'floorplan_overview_edit_deadline_unknown',
        'Unknown',
      );
    } else {
      //deadlines aren't the same
      this.editDeadline = '';
    }

    let plannedDeliveryDayIso = this.sameOrEmptyString(
      (fpo) => fpo.PlannedDeliveryDate || 'unknown',
    );
    if (plannedDeliveryDayIso && plannedDeliveryDayIso !== 'unknown') {
      let pdd = DateHelper.fromIsoString(plannedDeliveryDayIso);
      if (
        pdd > DateHelper.earliestSaneDate &&
        pdd < DateHelper.latestSaneDate
      ) {
        this.plannedDeliveryDay = this.formattingService.formatDate(pdd, false);
      } else {
        this.plannedDeliveryDay = this.translate(
          'floorplan_overview_planned_delivery_day_unknown',
          'Unknown',
        );
      }
    } else if (plannedDeliveryDayIso === 'unknown') {
      this.plannedDeliveryDay = this.translate(
        'floorplan_overview_planned_delivery_day_unknown',
        'Unknown',
      );
    } else {
      this.plannedDeliveryDay = '';
    }

    this.orderNo = !this.floorplans
      ? ''
      : this.floorplans
          .map((fpo) => (fpo.OrderNo ? fpo.OrderNo : ' -- '))
          .join('\n');
  }

  private sameOrEmptyString(
    func: (fpo: Client.FloorPlanOverview) => string,
  ): string {
    if (!this.floorplans || this.floorplans.length < 1) return '';
    return this.floorplans
      .map(func)
      .reduce((prev, curr) => (prev === curr ? prev : ''));
  }

  private sum(func: (a: Client.FloorPlanOverview) => number): number {
    let sum = 0;
    if (this.floorplans)
      for (let n of this.floorplans.map(func)) {
        sum += n;
      }
    return sum;
  }

  private addVAT(price: number): number {
    if (!this.store) return price;
    return price * (1 + this.store.SalesChain.VatPercent / 100);
  }
}
