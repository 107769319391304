import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
// import * as Interface_DTO from 'app/ts/Interface_DTO'
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import { ISnapInfoService } from 'app/ts/services/snap/ISnapInfoService';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { ConfigurationItemService } from 'app/ts/services/ConfigurationItemService';
import { InteriorLogic } from 'app/ts/services/ConfigurationLogic/InteriorLogic';
/**
 * Helper to provide info about snap positions. Only expected to last from dragStart to dragEnd
 */
export class GableSnapService implements ISnapInfoService {
  static supportsItems(items: Client.ConfigurationItem[]): boolean {
    return (
      items.length === 1 &&
      (items[0].isGable || items[0].isTemplate) &&
      !items[0].isFittingPanel &&
      !items[0].isVerticalDivider
    );
  }

  private static readonly moduleItemSpacings = [602, 1202];
  public static readonly normalSpacings = [0, 368, 568, 768, 968, 1168];

  private readonly gapsBetweenGables: Client.GableGap[];
  private readonly suggestedPositions: SuggestedPositionInfo[];
  private readonly suggestedOffsets: Interface_DTO_Draw.Vec2d[];

  private lastSnapInfo: Client.SnapInfo | undefined;

  constructor(
    private readonly configurationItemService: ConfigurationItemService,
    private readonly cabinetSection: Client.CabinetSection,
    private readonly item: Client.ConfigurationItem,
    private readonly dragStartPoint: Interface_DTO_Draw.Vec2d,
  ) {
    if (cabinetSection.isSwing) {
      let items = cabinetSection.swing.items
        .filter(
          (swingItem) =>
            swingItem.ItemType === Interface_Enums.ItemType.SwingCorpus,
        )
        .concat(cabinetSection.interior.items)
        .filter((i) => i !== item);
      this.gapsBetweenGables = InteriorLogic.getFreeGableGaps(
        items,
        [item],
        cabinetSection.cabinet.productLine,
      );
    } else {
      this.gapsBetweenGables = InteriorLogic.getGableGaps(cabinetSection, {
        excludeItems: item.moduleDescendants,
      });
    }

    this.suggestedPositions = this.getSingleItemPositionSuggestions(item);
    this.suggestedOffsets = this.suggestedPositions.map((p) =>
      this.toOffset(p.position, item),
    );
  }

  public getSnapInfo(pos: Interface_DTO_Draw.Vec2d): Client.SnapInfo {
    pos = {
      ...pos,
      X: pos.X - this.dragStartPoint.X + this.item.X,
    };
    let newPos = {
      X: pos.X,
      Y: this.cabinetSection.interior.cube.Y,
      Z: this.cabinetSection.interior.cube.Z,
    };
    let snapSuggestion = null;
    let bestSuggestion = null;

    if (this.suggestedPositions.length > 0) {
      bestSuggestion = ObjectHelper.best(
        this.suggestedPositions as [SuggestedPositionInfo],
        (sp) => Math.abs(sp.position.X - pos.X),
      );
      let distX = Math.abs(bestSuggestion.position.X - newPos.X);
      if (Constants.snapDist >= distX) {
        snapSuggestion = bestSuggestion;
        newPos = bestSuggestion.position;
      }
    }
    let rulers: Client.Ruler[];
    let activeGap = snapSuggestion ? snapSuggestion.gap : null;
    if (activeGap) {
      let leftLength = newPos.X - activeGap.X;
      let leftRuler = new Client.Ruler(
        false,
        {
          X: activeGap.X,
          //Y: activeGap.Y + (activeGap.Height / 2)
          Y: pos.Y,
        },
        leftLength,
        this.cabinetSection.Height,
        GableSnapService.normalSpacings.indexOf(leftLength) < 0,
      );

      let rightLength =
        activeGap.X + activeGap.Width - (newPos.X + this.item.Width);
      let rightRuler = new Client.Ruler(
        false,
        {
          X: newPos.X + this.item.Width,
          //Y: activeGap.Y + (activeGap.Height / 2)
          Y: pos.Y,
        },
        rightLength,
        this.cabinetSection.Height,
        GableSnapService.normalSpacings.indexOf(rightLength) < 0,
      );
      rulers = [leftRuler, rightRuler];
    } else {
      activeGap = bestSuggestion ? bestSuggestion.gap : null;
      if (activeGap) {
        let leftRuler = new Client.Ruler(
          false,
          {
            X: activeGap.X,
            Y: pos.Y,
          },
          newPos.X - activeGap.X,
          this.cabinetSection.Height,
          true,
        );
        let rightRuler = new Client.Ruler(
          false,
          {
            X: newPos.X + this.item.Width,
            Y: pos.Y,
          },
          activeGap.X + activeGap.Width - (newPos.X + this.item.Width),
          this.cabinetSection.Height,
          true,
        );
        rulers = [leftRuler, rightRuler];
      } else {
        rulers = [];
      }
    }

    let result: Client.SnapInfo = {
      dropOffset: {
        ...this.toOffset(newPos, this.item),
      },
      pulloutRestriction: Enums.PulloutWarningSeverity.None,
      rulers: rulers,
      suggestedItemOffsets: this.item.isTemplate ? [] : this.suggestedOffsets,
      mirror: false,
      horizontalGuidelineHeights: [],
      snapsTo: [],
      snappingItems: [this.item],
    };
    this.lastSnapInfo = result;
    return result;
  }

  public place(): {
    items: [Client.ConfigurationItem];
    recalculationMessages: Client.RecalculationMessage[];
  } {
    if (this.lastSnapInfo) {
      this.item.X += this.lastSnapInfo.dropOffset.X;
      this.item.Y += this.lastSnapInfo.dropOffset.Y;
      this.item.Z += this.lastSnapInfo.dropOffset.Z;
      this.item.X = Math.round(this.item.X);
      this.item.Y = Math.round(this.item.Y);
      this.item.Z = Math.round(this.item.Z);

      if (!this.item.IsLocked) {
        this.item.Height = this.cabinetSection.interior.cube.Height;
      }

      if (this.cabinetSection.interior.items.indexOf(this.item) < 0) {
        let actualItems =
          this.configurationItemService.createInteriorItemFromOtherItem(
            this.item,
            this.cabinetSection,
          );
        this.cabinetSection.interior.items.push(...actualItems.items);
        return actualItems;
      }
      if (App.debug.showSnapInfo) {
        console.debug('Snapped by me: ', this);
      }
    }
    return { items: [this.item], recalculationMessages: [] };
  }

  public dispose() {}

  private getSingleItemPositionSuggestions(
    item: Client.ConfigurationItem,
  ): SuggestedPositionInfo[] {
    return (
      Enumerable.from(this.gapsBetweenGables)
        //.where(gap => gap.Y <= this.cabinetSection.interior.cube.Y) //only get gaps that start at the floor //Task414B
        .selectMany((gap) => this.getGableSuggestionsInGap(gap, item.Width))
        .select<SuggestedPositionInfo>((pos) => {
          return { position: pos, width: item.Width, gap: pos.gap };
        })
        .toArray()
    );
  }

  private getGableSuggestionsInGap(
    gap: Client.GableGap,
    itemWidth: number,
  ): (Interface_DTO_Draw.Vec3d & { gap: Client.GableGap })[] {
    let result: (Interface_DTO_Draw.Vec3d & { gap: Client.GableGap })[] = [];
    if (gap.Y >= this.item.topY) {
      return result;
    }

    let yz = {
      Y: this.cabinetSection.interior.cube.Y,
      Z: this.cabinetSection.interior.cube.Z,
    };

    //check the left side of the gap
    if (!gap.leftGable && this.cabinetSection.interior.leftCorner) {
      //left side of the section is part of a corner - do the corner snaps
      result.push({
        ...yz,
        X:
          this.cabinetSection.interior.leftCorner.minimumSpace +
          this.cabinetSection.interior.cube.X,
        gap: gap,
      });
      result.push({
        ...yz,
        X:
          this.cabinetSection.interior.leftCorner.acceptableSpace +
          this.cabinetSection.interior.cube.X,
        gap: gap,
      });
    } else {
      let useModuleSpacingsLeft =
        this.item.drilling600Left &&
        gap.leftGable &&
        gap.leftGable.drilling600Right;
      if (useModuleSpacingsLeft) {
        //module gaps for wash section
        for (let v of GableSnapService.moduleItemSpacings) {
          if (v >= gap.Width - this.item.Width) break;
          result.push({
            ...yz,
            X: gap.X + v,
            gap: gap,
          });
        }
      }

      let useNormalSpacingsLeft =
        this.item.drilledLeft &&
        (!gap.leftGable ||
          gap.leftGable.drilledRight ||
          gap.leftGable.drilling600Right);
      useNormalSpacingsLeft = useNormalSpacingsLeft || this.item.isTemplate;

      if (useNormalSpacingsLeft) {
        for (let v of GableSnapService.normalSpacings) {
          if (v >= gap.Width - this.item.Width) break;

          if (this.item.snapFront) {
            // Module support gables are pulled to the front of the outer module gable
            let frontZ;

            if (gap.leftGable !== null && gap.leftGable.drilling600Right) {
              frontZ = gap.leftGable.frontZ;
            } else if (
              gap.rightGable !== null &&
              gap.rightGable.drilling600Left
            ) {
              frontZ = gap.rightGable.frontZ;
            }

            if (frontZ) {
              let itemZ = frontZ - this.item.depthReduction - this.item.Depth;
              yz.Z = itemZ;
            }
          }

          result.push({
            ...yz,
            X: gap.X + v,
            gap: gap,
          });
        }
      }
    }

    //check the right side of the gap

    if (!gap.rightGable && this.cabinetSection.interior.rightCorner) {
      //right side of the cabinetSection is part of a corner - do the corner things
      result.push({
        ...yz,
        X:
          gap.X +
          gap.Width -
          this.cabinetSection.interior.rightCorner.minimumSpace -
          this.item.Width,
        gap: gap,
      });
      result.push({
        ...yz,
        X:
          gap.X +
          gap.Width -
          this.cabinetSection.interior.rightCorner.acceptableSpace -
          this.item.Width,
        gap: gap,
      });
    } else {
      let useModuleSpacingsRight =
        this.item.drilling600Right &&
        gap.rightGable &&
        gap.rightGable.drilling600Left;
      if (useModuleSpacingsRight) {
        //module gaps for wash section - other side
        for (let v of GableSnapService.moduleItemSpacings) {
          if (v >= gap.Width - this.item.Width) break;
          result.push({
            ...yz,
            X: gap.X + gap.Width - v - itemWidth,
            gap: gap,
          });
        }
      }

      let useNormalSpacingsRight =
        this.item.drilledRight &&
        (!gap.rightGable ||
          gap.rightGable.drilledLeft ||
          gap.rightGable.drilling600Left);
      useNormalSpacingsRight = useNormalSpacingsRight || this.item.isTemplate;

      if (useNormalSpacingsRight) {
        for (let v of GableSnapService.normalSpacings) {
          if (v >= gap.Width - this.item.Width) break;

          if (this.item.snapFront) {
            // Module support gables are pulled to the front of the outer module gable
            let frontZ;

            if (gap.leftGable !== null && gap.leftGable.drilling600Right) {
              frontZ = gap.leftGable.frontZ;
            } else if (
              gap.rightGable !== null &&
              gap.rightGable.drilling600Left
            ) {
              frontZ = gap.rightGable.frontZ;
            }

            if (frontZ) {
              let itemZ = frontZ - this.item.depthReduction - this.item.Depth;
              yz.Z = itemZ;
            }
          }

          result.push({
            ...yz,
            X: gap.X + gap.Width - v - itemWidth,
            gap: gap,
          });
        }
      }
    }

    if (!gap.leftGable && gap.Width >= itemWidth) {
      result.push({
        ...yz,
        X: gap.X,
        gap: gap,
      });
    }

    if (!gap.rightGable && gap.Width >= itemWidth) {
      result.push({
        ...yz,
        X: gap.X + gap.Width - itemWidth,
        gap: gap,
      });
    }

    return result;
  }

  private toOffset(
    vec: Interface_DTO_Draw.Vec3d,
    item: Client.ConfigurationItem,
  ): Interface_DTO_Draw.Vec3d {
    let result = VectorHelper.subtract(vec, item);
    result.X = Math.round(result.X);
    result.Y = Math.round(result.Y);
    result.Z = Math.round(result.Z);
    return result;
  }
}

export interface SuggestedPositionInfo {
  position: Interface_DTO_Draw.Vec3d;
  width: number;
  gap: Client.GableGap;
}
