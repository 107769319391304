import * as THREE from 'three';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_DTO_FloorPlan from 'app/ts/Interface_DTO_FloorPlan';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import { ClickedItemInfo } from 'app/ts/Client/ClickedItemInfo';
import { IItemGrab } from 'app/ts/Client.IItemGrab';
import { ISnapInfoService } from 'app/ts/services/snap/ISnapInfoService';
import { FloorPlanHelper } from 'app/ts/util/FloorPlanHelper';
import { ISubscription } from 'app/ts/util/Observable';
import { VectorHelper } from 'app/ts/util/VectorHelper';
import { D3RulerService } from 'app/ts/services/D3RulerService';
import { ErrorService } from 'app/ts/services/ErrorService';
import { Injectable } from '@angular/core';
import { GUI } from 'lil-gui';
import { BoxHelper, Mesh, Box3, Vector2, Vector3 } from 'three';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { PartitionPlan } from 'app/partition/partition-plan-data.service';
import { Section } from 'app/partition/section';
import { Door, DoorPlacement, Module, ModuleType } from 'app/partition/module';
import { PartitionPlanQueryService } from 'app/partition/partition-plan-query.service';
import { calculateSections, D3ViewSection } from 'app/partition/3d/section';
import { D3ViewDoor, D3ViewModule, D3ViewWall } from 'app/partition/3d/module';
import { AssetService } from './AssetService';
import { Profile, ProfileType } from 'app/partition/profile';
import { MathUtils } from 'three/src/math/MathUtils.js';
import { Rail, RailPlacement } from 'app/partition/rail';
import { PartitionPlanGeometryQueryService } from 'app/partition/partition-plan-geometry-query.service';
import { Partition } from 'app/partition/partition';
import { loadObj } from '@Services/d3';
import * as VariantNumbers from 'app/ts/VariantNumbers';

@Injectable({ providedIn: 'root' })
export class D3Service {
  /** Previously created renderers not in use any more.
   * Renderers are expensive to create, and some platforms will crash if more than one renderer is created.
   * So Renderers should be reused whenever possible.
   * **/
  private readonly renderers: D3Service.Renderer[] = [];

  constructor(
    public readonly errorService: ErrorService,
    private readonly partitionQueryService: PartitionPlanQueryService,
    private readonly partitionGeometryQueryService: PartitionPlanGeometryQueryService,
    private readonly assetService: AssetService,
  ) {}

  getRenderer(
    floorPlan: Client.FloorPlan,
    cabinetSection?: Client.CabinetSection,
    partitionPlan: PartitionPlan | null = null,
  ): D3Service.Renderer {
    let result: D3Service.Renderer | undefined;
    result = this.renderers.pop();
    if (!result) {
      let onDispose = () => this.returnRenderer(result);
      result = new D3Service.Renderer(
        this.errorService,
        this.partitionQueryService,
        this.partitionGeometryQueryService,
        this.assetService.editorAssets,
        onDispose,
        floorPlan,
      );
      App.log('creating new renderer');
      // console.log("creating new renderer");
    } else {
      // console.log("reusing existing renderer")
      App.log('reusing existing renderer');
    }

    result.setSelection(cabinetSection, partitionPlan, floorPlan);

    return result;
  }

  private returnRenderer(result: D3Service.Renderer | undefined) {
    if (result && this.renderers.indexOf(result) < 0) {
      this.renderers.push(result);
    }
  }

  public clearRenderer(): void {
    let renderer = this.renderers.pop();
    if (renderer) renderer.dispose();
  }
}

export module D3Service {
  export type ItemGroupName =
    | 'backing'
    | 'corpus'
    | 'doors'
    | 'interior'
    | 'lighting'
    | 'rails'
    | 'modules'
    | 'thisCabinet'
    | 'otherCabinets'
    | 'newItem'
    | 'rulers'
    | 'pullout'
    | 'collision'
    | 'errorLevel'
    | 'partitions';

  class NullRenderer implements THREE.Renderer {
    public domElement: HTMLCanvasElement;
    constructor() {
      this.domElement = document.createElement('canvas');
    }
    public render() {}
    public setSize(x: number, y: number) {}
  }

  export class Renderer implements ISubscription {
    public static readonly Name = 'd3Service';

    private static readonly defaultCameraFov = 45;

    public readonly canvas: HTMLCanvasElement;

    private readonly useHqLights = true;
    private readonly useHqTextures = true;

    private readonly specialMaterials = {
      steel: new THREE.MeshPhongMaterial({
        color: 0xbbbbbb,
        specular: 0x42467d,
        shininess: 45,
        reflectivity: 0.3,
      }),
      glas: new THREE.MeshPhongMaterial({
        color: 0xdddddd,
        shininess: 98,
        specular: 0xc1d7ff,
      }),
      wall: new THREE.MeshPhongMaterial({
        color: 0xfafafa,
      }),
    };

    private readonly scene: THREE.Scene;
    private readonly camera: THREE.PerspectiveCamera;
    private readonly cameraControls: OrbitControls;
    private readonly renderer: THREE.Renderer;
    private readonly raycaster = new THREE.Raycaster();

    private readonly lqLights = {
      ambientLight: new THREE.AmbientLight(0xffffff, 0.9),
      pointLight: new THREE.PointLight(0xffffff, 0, 10000),
      directionalLightForSelfShadow: new THREE.DirectionalLight(0xffffff, 0),
      directionalLightForCastShadow: new THREE.DirectionalLight(0xffffff, 0),
    };
    private readonly hqLights: {
      hemiLight: THREE.HemisphereLight;
      ambientLight: THREE.AmbientLight;
      pointLight: THREE.PointLight;
      spotLights: SpotLightInfo[];
    } = {
      hemiLight: new THREE.HemisphereLight(0xddeeff, 0x0f0e0d, 0.285),
      ambientLight: new THREE.AmbientLight(0xffffff, 0.725),
      pointLight: new THREE.PointLight(0xffffff, 0.1, 10000),
      spotLights: [
        {
          // spot in same position as spotLights[1]. Does not
          // produce shadow, so the shadow from [1] is less harsh.
          spot: new THREE.SpotLight(0xffffff, 0, 10000),
          pos: { X: 2500, Y: 2500, Z: 2000 },
          targetPos: { X: 0, Y: -2000, Z: 0 },
          angle: 1.56,
          decay: 1.8,
          penumbra: 0.1,
          castShadow: false,
        },
        // {
        //   // Spot for creating shadow.
        //   // Creates strange inconsistent lighting - see #641
        //   // https://dev.azure.com/KAInterior/KA%20Online/_workitems/edit/641
        //   spot: new THREE.SpotLight(0xffffff, 0.225, 10000),
        //   pos: { X: 4000, Y: 2500, Z: 5520 },
        //   targetPos: { X: -6800, Y: -4650, Z: -15690 },
        //   castShadow: true,
        //   penumbra: 0.2,
        //   decay: 2,
        //   angle: 1.01,
        //   mapWidth: 4096,
        //   mapHeight: 4096,
        //   shadowBias: 1e-6, //removes strange artifacts on some surfaces
        // },
        {
          //Spot behind the cabinet. Creates reflection on shelves.
          spot: new THREE.SpotLight(0xffffff, 0.0, 10000),
          pos: { X: -830, Y: 2860, Z: -2720 },
          targetPos: { X: 6080, Y: -2720, Z: 5250 },
          castShadow: false,
          penumbra: 0.1,
          decay: 2,
          angle: 1.5,
        },
        {
          //Spot opposite the camera. Creates reflection on doors.
          spot: new THREE.SpotLight(0xffffff, 0.13, 10000),
          pos: { X: -830, Y: 1030, Z: 2760 },
          targetPos: { X: 6080, Y: 3290, Z: -7740 },
          castShadow: false,
          penumbra: 0.475,
          decay: 2,
          angle: 1.08,
        },
      ],
    };

    private readonly d3RulerService = new D3RulerService();

    private readonly materials = {
      //for debug only
      intersect: new THREE.MeshLambertMaterial({
        color: 0xccff77,
        opacity: 0.2,
        visible: App.debug.showIntersectBoxes,
        transparent: App.debug.showIntersectBoxes,
      }),
      //for debug only
      sectionIntersect: new THREE.MeshLambertMaterial({
        color: 0xbb33bb,
        opacity: 0.2,
        visible: App.debug.showIntersectBoxes,
        transparent: App.debug.showIntersectBoxes,
      }),

      select: new THREE.MeshLambertMaterial({
        color: 0x33bb33,
        opacity: 0.2,
        visible: true,
        transparent: true,
      }),

      errorLevelInfo: {
        material: new THREE.MeshLambertMaterial({
          color: 0x3333cc,
          opacity: 0.2,
          visible: true,
          transparent: true,
        }),
        severity: Interface_Enums.ErrorLevel.Info,
      },
      errorLevelWarning: {
        material: new THREE.MeshLambertMaterial({
          color: 0xffdd22,
          opacity: 0.2,
          visible: true,
          transparent: true,
        }),
        severity: Interface_Enums.ErrorLevel.Warning,
      },
      errorLevelCritical: {
        material: new THREE.MeshLambertMaterial({
          color: 0xff2222,
          opacity: 0.2,
          visible: true,
          transparent: true,
        }),
        severity: Interface_Enums.ErrorLevel.Critical,
      },
      pulloutWarning: new THREE.MeshLambertMaterial({
        color: 0xffdd22,
        opacity: 0.2,
        visible: true,
        transparent: true,
      }),
      pulloutCritical: new THREE.MeshLambertMaterial({
        color: 0xff2222,
        opacity: 0.2,
        visible: true,
        transparent: true,
      }),
      suggestedPlacement: new THREE.MeshLambertMaterial({
        color: 0x888888,
        opacity: 0.15,
        visible: true,
        transparent: true,
      }),
      collisionRecommended: new THREE.MeshLambertMaterial({
        color: 0xffff44,
        opacity: 0.3,
        visible: true,
        transparent: true,
      }),
      collisionRequired: new THREE.MeshLambertMaterial({
        color: 0xff4444,
        opacity: 0.3,
        visible: true,
        transparent: true,
      }),
      floor: new THREE.MeshPhongMaterial({
        //map: await floorTex,
        shininess: 75,
      }),
      ceiling: new THREE.MeshPhongMaterial({
        //map: await ceilingTex,
        shininess: 5,
        color: 0x777777,
        emissive: 0x101010,
      }),
    };

    private static readonly itemNameRegex = /item\:(\d+)\:(\d+)\:(\d+)/;
    private static readonly itemPartNames = {
      intersectBox: 'ItemIntersectBox',
      selectBox: 'ItemSelectBox',
      errorLevel: 'ErrorLevel',
    };

    private floorPlanGroup: THREE.Group | null = null;
    private cabinetSectionIntersectees: {
      obj: THREE.Object3D;
      cabinetIndex: number;
      cabinetSectionIndex: number;
    }[] = [];

    private partitionPlan: PartitionPlan | null = null;

    private lowQualityTimer: Promise<void> | null = null;

    /**
     * Triggered every time there's a change in the image. The resulting string describes the change.
     * */
    public get changes$(): Observable<string> {
      return this._changes$;
    }
    private _changes$ = new BehaviorSubject<string>('');

    //public get changes(): IObservable<string> { return this._changes; }

    private itemVisibilities: { [groupName: string]: boolean } = {};
    private isDisposed = false;
    private cabinetSection: Client.CabinetSection | null = null;
    private floorPlanSubscription: Subscription | null = null;

    private ceilingHeight: number = 2200; //pooma

    constructor(
      private readonly errorService: ErrorService,
      private readonly partitionQueryService: PartitionPlanQueryService,
      private readonly partitionGeometryQueryService: PartitionPlanGeometryQueryService,
      private readonly editorAssets: Client.EditorAssets,
      private readonly onDispose: () => void,
      private floorPlan: Client.FloorPlan,
    ) {
      this.renderer = this.createThreeRenderer();
      this.canvas = this.renderer.domElement;
      this.canvas.style.maxWidth = '100%';

      this.camera = new THREE.PerspectiveCamera(
        Renderer.defaultCameraFov,
        1,
        50,
        1000000,
      );

      this.camera.near = 100;
      this.camera.receiveShadow = true;

      this.cameraControls = new OrbitControls(this.camera, this.canvas);
      this.cameraControls.screenSpacePanning = true;
      // 26_01_2023: Does not exist?
      //this.cameraControls.enableKeys = false;
      this.cameraControls.addEventListener('change', async () => {
        let promise = this.activateLowQuality();
        this._changes$.next('camera');
        try {
          await promise;
          this.saveCameraAngle();
        } catch (e: any) {
          // highQuality was not activated because a new camera movement was triggered - do nothing
        }
      });

      this.scene = new THREE.Scene();

      if (this.useHqLights) {
        this.addHqLights();
        this.addHqGui();
      } else {
        this.addLqLights();
      }
    }

    private addLqLights() {
      let lights = this.lqLights;
      {
        let ambientLight = lights.ambientLight;
        ambientLight.intensity = 0.88;
        this.scene.add(ambientLight);
      }

      {
        lights.pointLight.intensity = 0.1;
        this.scene.add(lights.pointLight);
      }

      {
        lights.directionalLightForSelfShadow.intensity = 0.1;
        lights.directionalLightForSelfShadow.castShadow = false;
        this.scene.add(lights.directionalLightForSelfShadow);
        this.scene.add(lights.directionalLightForSelfShadow.target);
      }

      {
        lights.directionalLightForCastShadow.intensity = 0.05;
        lights.directionalLightForCastShadow.castShadow = false;
        let sh = lights.directionalLightForCastShadow.shadow;
        sh.camera.near = 1000;
        sh.camera.far = 18000;
        sh.mapSize.x = 1 << 11;
        sh.mapSize.y = 1 << 11;

        let anySh = sh.camera as any;
        anySh.left = -3000;
        anySh.right = 3000;
        anySh.top = 3000;
        anySh.bottom = -3000;
        this.scene.add(lights.directionalLightForCastShadow);
        this.scene.add(lights.directionalLightForCastShadow.target);
      }
    }

    private addHqLights() {
      let lights = this.hqLights;

      this.scene.add(lights.hemiLight);
      this.scene.add(lights.ambientLight);
      this.scene.add(lights.pointLight);

      for (let spot of lights.spotLights) {
        spot.spot.castShadow = spot.castShadow;
        spot.spot.penumbra = spot.penumbra;
        spot.spot.decay = spot.decay;
        spot.spot.angle = spot.angle;
        if (spot.mapWidth) spot.spot.shadow.mapSize.width = spot.mapWidth;
        if (spot.mapHeight) spot.spot.shadow.mapSize.height = spot.mapHeight;
        if (spot.shadowBias) spot.spot.shadow.bias = spot.shadowBias;

        let targetObject = new THREE.Object3D();
        this.scene.add(targetObject);
        spot.spot.target = targetObject;
        this.scene.add(spot.spot);

        this.setSpotLightRelative(spot.spot, spot.pos, spot.targetPos);
        let helper = new THREE.SpotLightHelper(spot.spot);
        spot.helper = helper;
        helper.visible = false;
        this.scene.add(helper);
      }
    }

    private addHqGui() {
      if (!App.debug.useDatGui) return;

      let gui = new GUI({ width: 400 });
      let lightFolder = gui.addFolder('Ambient lights');
      let settings: any = {};

      settings['hemi light intensity'] = this.hqLights.hemiLight.intensity;
      lightFolder
        .add(settings, 'hemi light intensity', 0, 0.7, 0.005)
        .onChange((i: number) => {
          this.hqLights.hemiLight.intensity = i;
          this.render();
        });

      settings['ambi light intensity'] = this.hqLights.ambientLight.intensity;
      lightFolder
        .add(settings, 'ambi light intensity', 0, 1.5, 0.005)
        .onChange((i: number) => {
          this.hqLights.ambientLight.intensity = i;
          this.render();
        });
      settings['ambi color'] = this.hqLights.ambientLight.color.getHex();
      lightFolder.addColor(settings, 'ambi color').onChange((c: number) => {
        this.hqLights.ambientLight.color.setHex(c);
        this.render();
      });

      const addSpot = (s: SpotLightInfo, name: string) => {
        let folder = gui.addFolder(name);
        const update = () => {
          s.helper && s.helper.update();
          this.render();
        };
        const updatePos = () => {
          this.resetLights();
          update();
        };
        let posFolder = folder.addFolder('Position');
        posFolder.add(s.pos, 'X', -20000, 20000, 10).onChange(updatePos);
        posFolder.add(s.pos, 'Y', -20000, 20000, 10).onChange(updatePos);
        posFolder.add(s.pos, 'Z', -20000, 20000, 10).onChange(updatePos);
        let targetFolder = folder.addFolder('Target pos');
        targetFolder
          .add(s.targetPos, 'X', -20000, 20000, 10)
          .onChange(updatePos);
        targetFolder
          .add(s.targetPos, 'Y', -20000, 20000, 10)
          .onChange(updatePos);
        targetFolder
          .add(s.targetPos, 'Z', -20000, 20000, 10)
          .onChange(updatePos);

        let data = {
          color: s.spot.color.getHex(),
        };
        folder.addColor(data, 'color').onChange((c: any) => {
          s.spot.color.setHex(c);
          update();
        });
        folder.add(s.spot, 'intensity', 0, 1, 0.005).onChange(() => update());
        folder.add(s.spot, 'penumbra', 0, 1, 0.005).onChange(() => update());
        folder.add(s.spot, 'castShadow').onChange(() => update());
        folder.add(s.spot, 'decay', 0, 10, 0.005).onChange(() => update());
        folder
          .add(s.spot, 'angle', 0, Math.PI / 2, 0.01)
          .onChange(() => update());

        if (s.helper) {
          folder.add(s.helper!, 'visible').onChange(() => update());
        }
      };
      for (let i = 0; i < this.hqLights.spotLights.length; i++) {
        addSpot(this.hqLights.spotLights[i], 'Spot ' + i);
      }

      {
        let ceilingFolder = gui.addFolder('Ceiling');
        ceilingFolder
          .add(this.materials.ceiling, 'shininess', 0, 10, 0.005)
          .onChange(() => {
            this.materials.ceiling.needsUpdate = true;
            this.render();
          });
        let data = {
          color: this.materials.ceiling.color.getHex(),
          emissive: this.materials.ceiling.emissive.getHex(),
        };
        ceilingFolder.addColor(data, 'color').onChange((c: number) => {
          this.materials.ceiling.color.setHex(c);
          this.materials.ceiling.needsUpdate = true;
          this.render();
        });
        ceilingFolder.addColor(data, 'emissive').onChange((c: number) => {
          this.materials.ceiling.emissive.setHex(c);
          this.materials.ceiling.needsUpdate = true;
          this.render();
        });
      }
    }

    private createThreeRenderer(): THREE.Renderer {
      try {
        let renderer = new THREE.WebGLRenderer({
          antialias: true,
        });
        renderer.setClearColor(0xffffff);
        renderer.outputColorSpace = THREE.LinearSRGBColorSpace;
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;

        return renderer;
      } catch (e: any) {
        this.errorService.reportError('Failed to init WebGLRenderer', e);
      }

      return new NullRenderer();
    }

    public offsetCameraRight(dist: number) {
      let camDirection = this.camera.position
        .clone()
        .sub(this.cameraControls.target);
      let moveVector = new THREE.Vector3(
        camDirection.z,
        0,
        -camDirection.x,
      ).setLength(dist);
      this.camera.position.add(moveVector);
      this.cameraControls.target.add(moveVector);
      this.cameraControls.update();
      this._changes$.next('offsetCameraRight');
    }

    public offsetCameraUp(dist: number) {
      this.camera.translateOnAxis(new THREE.Vector3(0, 1, 0), dist);
      this.cameraControls.target.setY(this.cameraControls.target.y + dist);
      this.cameraControls.update();
      this._changes$.next('offsetCameraUp');
    }

    public zoom(amount: number) {
      let moveVector = this.camera.position
        .clone()
        .sub(this.cameraControls.target)
        .setLength(-amount);
      this.camera.position.add(moveVector);
      this.cameraControls.target.add(moveVector);
      this.cameraControls.update();
    }

    private _showErrorBoxes = true;
    public get showErrorBoxes() {
      return this._showErrorBoxes;
    }
    public set showErrorBoxes(val: boolean) {
      if (this._showErrorBoxes === val) return;
      this._showErrorBoxes = val;
      if (!this.floorPlanGroup) return;
      this.floorPlanGroup.traverse((obj) => {
        if (obj.name !== Renderer.itemPartNames.errorLevel) return;
        obj.visible = val;
      });
    }

    public setSelection(
      cabinetSection: Client.CabinetSection | null | undefined,
      partitionPlan: PartitionPlan | null | undefined,
      floorPlan: Client.FloorPlan,
    ): Promise<D3Service.Renderer> {
      if (this.floorPlanSubscription) {
        this.floorPlanSubscription.unsubscribe();
        this.floorPlanSubscription = null;
      }
      this.cabinetSection = cabinetSection ?? null;
      this.partitionPlan = partitionPlan ?? null;

      this.ceilingHeight = floorPlan.Size.Z;
      this.restoreCamera();
      this.isDisposed = false;

      let p = new Promise<D3Service.Renderer>((resolve, reject) => {
        this.floorPlanSubscription = floorPlan.floorPlan$.subscribe((fp) => {
          this.setSceneContents(fp).then(() => resolve(this));
        });
      });
      return p;
    }

    /**
     * Translates a position on the canvas to a position in a cabinetSection
     * @param clientPos the position on canvas (ie: {X: mouseEvent.offsetX, Y: mouseEvent.offsetY} )
     * @param depth distance from the back of a cabinetsection to the cursor
     */
    public getCabinetSectionPos(
      clientPos: Interface_DTO_Draw.Vec2d,
      depth: number = 0,
    ): Client.CabinetSectionPos | undefined {
      if (!this.floorPlan) return;

      const canvasCoords = this.getCanvasCoords(clientPos);
      for (let i of this.cabinetSectionIntersectees) {
        i.obj.position.setZ(depth);
        i.obj.updateWorldMatrix(true, false);
      }
      this.camera.updateMatrixWorld(false);
      this.raycaster.setFromCamera(canvasCoords, this.camera);

      for (let i of this.cabinetSectionIntersectees) {
        let intersections = this.raycaster.intersectObject(i.obj);
        if (!intersections || intersections.length === 0) continue;
        const isec = intersections[0];
        const local = new THREE.Vector3();
        local.copy(isec.point);
        i.obj.worldToLocal(local);
        return {
          cabinetIndex: i.cabinetIndex,
          cabinetSectionIndex: i.cabinetSectionIndex,
          worldPos: {
            X: isec.point.x,
            Y: isec.point.y,
          },
          sectionPos: {
            X: local.x,
            Y: local.y,
          },
        };
      }

      //nothing intersects
      return;
    }

    private getCanvasCoords(clientPos: Interface_DTO_Draw.Vec2d): Vector2 {
      const canvasWidth: number = this.canvas.width;
      const canvasHeight: number = this.canvas.height;
      let pixelRatio: number = 1;
      if ((this.renderer as THREE.WebGLRenderer).getPixelRatio) {
        pixelRatio = (this.renderer as THREE.WebGLRenderer).getPixelRatio();
      }
      const nonNormalized = {
        x: (clientPos.X * pixelRatio) / canvasWidth,
        y: (clientPos.Y * pixelRatio) / canvasHeight,
      };
      return {
        x: nonNormalized.x * 2 - 1,
        y: -(nonNormalized.y * 2 - 1),
      } as Vector2;
    }

    private getTempGroups(section: Client.CabinetSection): {
      global: THREE.Group;
      cabinet: THREE.Group;
      section: THREE.Group;
      dispose: () => void;
    } {
      const globalGrp = new THREE.Group();
      this.scene.add(globalGrp);

      const cabinet = section.cabinet;
      const cabinetGrp = new THREE.Group();
      cabinetGrp.position.set(
        cabinet.floorPlanPosition.X,
        cabinet.floorPlanPosition.Y,
        cabinet.floorPlanPosition.Z,
      );
      cabinetGrp.rotateY(-cabinet.floorPlanRotation);
      globalGrp.add(cabinetGrp);

      let sectionGrp = new THREE.Group();
      sectionGrp.rotateY((section.Rotation / 180) * Math.PI);
      sectionGrp.position.set(section.PositionX, 0, section.PositionY);
      cabinetGrp.add(sectionGrp);

      const dispose = () => {
        this.scene.remove(globalGrp);
      };

      return {
        global: globalGrp,
        cabinet: cabinetGrp,
        section: sectionGrp,
        dispose,
      };
    }

    public grabItem(
      clickedItemInfo: ClickedItemInfo,
      snapService: ISnapInfoService,
    ): IItemGrab {
      if (this.cabinetSection == null)
        throw new Error('grabItem: this.cabinetSection == null');

      this.cameraControls.enabled = false;
      let isDisposed = false;
      let rulers = new THREE.Group();
      let suggestedPlacements = new THREE.Group();
      const tempGroups = this.getTempGroups(this.cabinetSection);

      const getDragObject = (item: Client.ConfigurationItem) => {
        let name = this.getName(item);
        let d3Obj = this.floorPlanGroup!.getObjectByName(name);
        if (!d3Obj) {
          // item doesn't exist in scene already - add it to scene.
          // getting a 3dObject is an async task. to overcome this, create a new
          // empty group to use as a placeholder,
          // then insert the actual object into the group once it loads.

          let placeHolderGroup = new THREE.Group();
          tempGroups.section.add(placeHolderGroup);
          d3Obj = placeHolderGroup;
          this.getObject3D(item, 'newItem').then((actualObj) => {
            if (isDisposed) {
              return;
            }
            d3Obj!.add(actualObj);
            this._changes$.next('object loaded');
          });
        }

        let result = {
          d3Obj,
          name,
          item,
          originalPos: {
            X: d3Obj.position.x,
            Y: d3Obj.position.y,
            Z: d3Obj.position.z,
            Width: d3Obj.scale.x,
            Height: d3Obj.scale.y,
            Depth: d3Obj.scale.z,
          },
        };
        return result;
      };

      //TODO multi-selection
      //let dragObjects = this.currentSelection.map(getDragObject)
      //    .filter(dragObj => !!dragObj);

      let dragObjects = [getDragObject(clickedItemInfo.item)];

      const itemGrab: IItemGrab = {
        setMouse: (mousePos: Interface_DTO_Draw.Vec2d) => {
          let pos = this.getCabinetSectionPos(mousePos, clickedItemInfo.pos.Z);
          if (!pos) return;
          if (pos.cabinetIndex !== clickedItemInfo.item.CabinetIndex) return;
          if (
            pos.cabinetSectionIndex !== clickedItemInfo.item.CabinetSectionIndex
          )
            return;
          let snapInfo = snapService.getSnapInfo(pos.sectionPos);
          for (let dragObj of dragObjects) {
            if (!dragObj) continue;
            let newPos = VectorHelper.add(
              clickedItemInfo.item,
              snapInfo.dropOffset,
            );

            dragObj.d3Obj.position.set(newPos.X, newPos.Y, newPos.Z);
            let newScale = snapInfo.newSize
              ? {
                  X: snapInfo.newSize.X
                    ? snapInfo.newSize.X / dragObj.item.Width
                    : 1,
                  Y: snapInfo.newSize.Y
                    ? snapInfo.newSize.Y / dragObj.item.Height
                    : 1,
                  Z: snapInfo.newSize.Z
                    ? snapInfo.newSize.Z / dragObj.item.Depth
                    : 1,
                }
              : { X: 1, Y: 1, Z: 1 };
            dragObj.d3Obj.scale.set(newScale.X, newScale.Y, newScale.Z);

            //TODO PulloutSeverity
            //for (let severity of [Enums.PulloutWarningSeverity.Warning, Enums.PulloutWarningSeverity.Critical]) {
            //    let severityName = (severity === Enums.PulloutWarningSeverity.Warning)
            //        ? Renderer.itemPartNames.pulloutWarningBox
            //        : Renderer.itemPartNames.pulloutErrorBox;
            //    let pulloutBox = dragObj.d3Obj.getObjectByName(severityName);
            //    if (pulloutBox) {
            //        pulloutBox.visible = severity === snapInfo.pulloutRestriction;
            //    }

            //}
          }

          tempGroups.section.remove(rulers);
          rulers = this.d3RulerService.getSnapRulers(snapInfo, clickedItemInfo);
          tempGroups.section.add(rulers);

          tempGroups.section.remove(suggestedPlacements);
          suggestedPlacements = this.getItemSuggestions(
            dragObjects,
            snapInfo.suggestedItemOffsets,
          );
          tempGroups.section.add(suggestedPlacements);

          this._changes$.next('itemMoved');
        },

        dispose: () => {
          snapService.dispose();
          tempGroups.dispose();
          this.cameraControls.enabled = true;
          for (let dragObj of dragObjects) {
            if (!dragObj) continue;
            dragObj.d3Obj.position.set(
              dragObj.originalPos.X,
              dragObj.originalPos.Y,
              dragObj.originalPos.Z,
            );
            dragObj.d3Obj.scale.set(
              dragObj.originalPos.Width,
              dragObj.originalPos.Height,
              dragObj.originalPos.Depth,
            );
          }
          this._changes$.next('itemMoveCancelled');
        },

        place: () => {
          return snapService.place();
        },
      };
      return itemGrab;
    }
    private getItemSuggestions(
      dragObjects: {
        item: Client.ConfigurationItem;
        originalPos: Interface_DTO_Draw.Cube;
      }[],
      suggestedItemOffsets: Interface_DTO_Draw.Vec2d[],
    ): THREE.Group {
      let result = new THREE.Group();
      for (let obj of dragObjects) {
        for (let offset of suggestedItemOffsets) {
          let SuggestionDimensions: Interface_DTO_Draw.Cube = {
            X: obj.originalPos.X + offset.X,
            Y: obj.originalPos.Y + offset.Y,
            Z: obj.originalPos.Z,
            Width: obj.item.Width,
            Height: 120, //obj.item.Height
            Depth: obj.item.Depth,
          };
          let suggestionBox = this.getSurroundingBox(
            SuggestionDimensions,
            this.materials.suggestedPlacement,
            { setPosition: true },
          );
          //suggestionBox.position.set(SuggestionDimensions.X, SuggestionDimensions.Y, SuggestionDimensions.Z);
          result.add(suggestionBox);
        }
      }
      return result;
    }

    public getClickedItemInfo(
      clientPos: Interface_DTO_Draw.Vec2d,
    ): ClickedItemInfo | undefined {
      if (!this.floorPlan) return;
      if (!this.floorPlanGroup) return;

      const canvasCoords = this.getCanvasCoords(clientPos);
      this.camera.updateMatrixWorld(false);
      this.raycaster.setFromCamera(canvasCoords, this.camera);
      let intersections = this.raycaster.intersectObject(
        this.floorPlanGroup,
        true,
      ); //IMPROVE only check for intersection with intersectBoxes
      for (let intersect of intersections) {
        if (intersect.object.name !== Renderer.itemPartNames.intersectBox)
          continue;
        let itemGroup = intersect.object.parent;
        if (!itemGroup) continue;
        let objectName = itemGroup.name;
        let nameMatch = Renderer.itemNameRegex.exec(objectName);
        if (!nameMatch) continue;

        let cabinetIndex = parseInt(nameMatch[1]);
        let cabinetSectionIndex = parseInt(nameMatch[2]);
        let configurationItemIndex = parseInt(nameMatch[3]);
        let item = Enumerable.from(this.floorPlan.actualCabinets)
          .selectMany((cab) => cab.actualCabinetSections)
          .selectMany((sec) => sec.configurationItems)
          .firstOrDefault(
            (ci) =>
              ci.CabinetIndex === cabinetIndex &&
              ci.CabinetSectionIndex === cabinetSectionIndex &&
              ci.ConfigurationItemIndex === configurationItemIndex,
          );
        if (!item) continue;
        let globalPos = intersect.point;
        let localPos = new THREE.Vector3();
        localPos.copy(globalPos);
        intersect.object.worldToLocal(localPos);
        return {
          item,
          pos: {
            X: localPos.x,
            Y: localPos.y,
            Z: localPos.z,
          },
        };
      }

      return undefined;
    }

    private async activateLowQuality(): Promise<void> {
      return;
      // if (this.renderer instanceof THREE.WebGLRenderer)
      //     this.renderer.setPixelRatio(window.devicePixelRatio / 3);
      // if (this.lowQualityTimer !== null) {
      //     Promise.reject(this.lowQualityTimer)
      //     this.lowQualityTimer = null;
      // }

      // let timeoutPeriod = 200;
      // this.lowQualityTimer = this.$timeout.setTimeout(timeoutPeriod);

      // await this.lowQualityTimer;
      // this.activateHighQuality();
    }

    public activateHighQuality(): void {
      this.lowQualityTimer = null;
      if (this.renderer instanceof THREE.WebGLRenderer)
        this.renderer.setPixelRatio(window.devicePixelRatio);

      this._changes$.next('highQuality');
    }

    public setRendererSize(size: Interface_DTO_Draw.Vec2d) {
      let aspect = size.X / size.Y;
      this.camera.aspect = aspect;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(size.X, size.Y);
      //this.resetCamera();
      this.resetLights();
    }

    public getGltfModel(): Promise<ArrayBuffer> {
      let l = new GLTFExporter();
      let result = new Promise<ArrayBuffer>((resolve, reject) => {
        let scene = this.scene;
        //let scene = new THREE.Scene();
        //let geo = Renderer.getBoxGeometry({ X: 500, Y: 500, Z: 500 });
        //let mat = new THREE.MeshPhongMaterial({
        //    color: 0x00ffff,
        //});
        //let mesh = new THREE.Mesh(geo, mat);
        //scene.add(mesh);
        l.parse(
          scene,
          (gltf) => resolve(<ArrayBuffer>(<any>gltf)),
          () => {},
          { binary: true, maxTextureSize: 256 },
        );
      });
      return result;
    }

    private resetLights() {
      let spotLightFocusWorld: Interface_DTO.Vec3d = { X: 0, Y: 0, Z: 0 };
      let spotLightPosWorld: Interface_DTO.Vec3d = { X: 0, Y: 0, Z: 0 };

      if (this.useHqLights) {
        this.resetHqLights();
      } else {
        this.resetLqLights();
      }
    }

    private resetHqLights() {
      if (!this.cabinetSection) return;

      for (let s of this.hqLights.spotLights) {
        this.setSpotLightRelative(s.spot, s.pos, s.targetPos);
      }
    }

    /**
     * Sets spotlight position and target,
     * @param spot spotlight to change
     * @param pos spotlight position, relative to current cabinet (not cabinetSection)
     * @param targetPos target of spotlight, relative to pos
     */
    private setSpotLightRelative(
      spot: THREE.SpotLight,
      pos: Interface_DTO.Vec3d,
      targetPos: Interface_DTO.Vec3d,
    ) {
      let cabinetSection =
        this.cabinetSection &&
        this.cabinetSection.cabinet.cabinetSections.length > 1
          ? this.cabinetSection.cabinet.cabinetSections[1]
          : this.cabinetSection;

      let targetPos2 = VectorHelper.add(targetPos, pos);
      Renderer.setVector(spot.position, cabinetSection, pos);
      Renderer.setVector(spot.target.position, cabinetSection, targetPos2);
    }

    private resetLqLights() {
      if (!this.cabinetSection) return;

      let lights = this.lqLights;
      let leftLightPos: Interface_DTO.Vec3d = {
        X: -700,
        Y: this.ceilingHeight + 500,
        Z: this.cabinetSection.Depth + 8000,
      };

      let leftLightTargetPos: Interface_DTO.Vec3d = {
        X: (this.cabinetSection.Width / 4) * 3,
        Y: this.cabinetSection.Height / 2,
        Z: this.cabinetSection.Depth,
      };
      for (let dirLight of [
        lights.directionalLightForCastShadow,
        lights.directionalLightForSelfShadow,
      ]) {
        Renderer.setVector(
          dirLight.position,
          this.cabinetSection,
          leftLightPos,
        );
        Renderer.setVector(
          dirLight.target.position,
          this.cabinetSection,
          leftLightTargetPos,
        );
      }

      let pointLightPos: Interface_DTO_Draw.Vec3d = {
        X: this.cabinetSection.Width / 2,
        Y: (this.cabinetSection.Height * 3) / 4,
        Z: -600, // this.cabinetSection.Depth + 900
      };
      Renderer.setVector(
        lights.pointLight.position,
        this.cabinetSection,
        pointLightPos,
      );
    }

    private static setVector(
      v: THREE.Vector3,
      section: Client.CabinetSection | null,
      pos: Interface_DTO_Draw.Vec3d,
    ) {
      let worldPos = section
        ? VectorHelper.add(
            VectorHelper.rotate3d(pos, section.floorPlanRotation),
            section.floorPlanPosition,
          )
        : { X: 0, Y: 0, Z: 0 };
      v.set(worldPos.X, worldPos.Y, worldPos.Z);
    }

    private saveCameraAngle() {
      if (this.cabinetSection != null) {
        this.cabinetSection.cabinet.cameraAngle =
          Client.CameraAngle.fromOrbitControls(this.cameraControls);
      }
    }

    public restoreCamera() {
      if (this.cabinetSection == null) {
        this.setCameraPartition();
        this._changes$.next('restoreCamera');
        return;
      }
      if (!this.cabinetSection.cabinet.cameraAngle) {
        this.cabinetSection.cabinet.cameraAngle =
          this.getDefaultCameraAngleForCabinet(this.cabinetSection.cabinet);
      }
      this.cabinetSection.cabinet.cameraAngle.toOrbitControls(
        this.cameraControls,
      );
      this._changes$.next('restoreCamera');
    }

    public setCameraPartition() {
      if (this.partitionPlan) {
        this.getDefaultPartitionCameraAngle().toOrbitControls(
          this.cameraControls,
        );
      }
    }

    private getDefaultCameraAngleForCabinet(
      cabinet: Client.Cabinet,
    ): Client.CameraAngle {
      switch (cabinet.CabinetType) {
        case Interface_Enums.CabinetType.CornerCabinet:
          return this.getDefaultCameraAngleForCornerCabinet(cabinet);
        case Interface_Enums.CabinetType.WalkIn:
          return this.getDefaultCameraAngleForWalkinCabinet(cabinet);
        case Interface_Enums.CabinetType.SingleCabinet:
        case Interface_Enums.CabinetType.Doors:
        case Interface_Enums.CabinetType.Swing:
        case Interface_Enums.CabinetType.SwingFlex:
          return this.getDefaultCameraAngleForSingleCabinet(
            cabinet.cabinetSections[0],
          );
        default:
          console.warn(
            "Don't know how to get a camera angle for the following cabinet type: " +
              cabinet.CabinetType +
              '. Defaulting to SingleCabinet',
          );
          return this.getDefaultCameraAngleForSingleCabinet(
            cabinet.cabinetSections[0],
          );
      }
    }

    private getDefaultCameraAngleForCornerCabinet(
      cabinet: Client.Cabinet,
    ): Client.CameraAngle {
      let leftSection = cabinet.actualCabinetSections[0];
      let rightSection = cabinet.actualCabinetSections[1];

      let y = leftSection.Height / 2;

      let leftEdge: Interface_DTO_Draw.Vec3d = {
        X: 0,
        Y: y,
        Z: leftSection.Depth,
      };
      let rightEdge: Interface_DTO_Draw.Vec3d = {
        X: leftSection.Width - rightSection.Depth,
        Y: y,
        Z: rightSection.Width,
      };
      let focusPoint: Interface_DTO_Draw.Vec3d = {
        X: (leftEdge.X + rightEdge.X) / 2,
        Y: (leftEdge.Y + rightEdge.Y) / 2,
        Z: (leftEdge.Z + rightEdge.Z) / 2,
      };

      let width = VectorHelper.dist(leftEdge, rightEdge);
      let aspectRatio = this.camera.aspect;
      let maxDim = Math.max(width / aspectRatio, leftSection.Height);
      let distance =
        maxDim / Math.sin((Renderer.defaultCameraFov / 180) * Math.PI);
      let angle = Math.atan(
        (rightEdge.Z - leftEdge.Z) / (rightEdge.X - leftEdge.X),
      );

      let cameraPoint: Interface_DTO_Draw.Vec3d = {
        X: focusPoint.X - Math.sin(angle) * distance,
        Y: focusPoint.Y,
        Z: focusPoint.Z + Math.cos(angle) * distance,
      };

      return this.toCameraAngle(focusPoint, cameraPoint, leftSection);
    }

    private getDefaultCameraAngleForWalkinCabinet(
      cabinet: Client.Cabinet,
    ): Client.CameraAngle {
      let leftSection = cabinet.cabinetSections[0];
      let middleSection = cabinet.cabinetSections[1];
      let rightSection = cabinet.cabinetSections[2];

      let cameraFocusPointSection: Interface_DTO.Vec3d = {
        X: middleSection.Width / 2,
        Y: middleSection.Height / 2,
        Z: middleSection.Depth,
      };
      //find camera pos from focus point, size of main section, and width of side sections
      let visibleCenterWidth =
        middleSection.Width - leftSection.Depth - rightSection.Depth;
      let maxDim = Math.max(visibleCenterWidth, middleSection.Height);
      let distanceFromFront =
        maxDim / Math.sin((Renderer.defaultCameraFov / 180) * Math.PI) +
        Math.max(leftSection.Width, rightSection.Width);
      let cameraPosSection: Interface_DTO.Vec3d = {
        X: cameraFocusPointSection.X,
        Y: cameraFocusPointSection.Y,
        Z: cameraFocusPointSection.Z + distanceFromFront,
      };

      return this.toCameraAngle(
        cameraFocusPointSection,
        cameraPosSection,
        middleSection,
      );
    }

    private getDefaultCameraAngleForSingleCabinet(
      cabinetSection: Client.CabinetSection,
    ): Client.CameraAngle {
      let cameraFocusPointSection: Interface_DTO.Vec3d = {
        X: cabinetSection.Width / 2,
        Y: cabinetSection.Height / 2,
        Z: cabinetSection.Depth,
      };
      //find camera pos from focus point, size of section, and aspect ratio of camera
      let maxDim = Math.max(cabinetSection.Width, cabinetSection.Height);
      let distanceFromFront =
        maxDim / Math.sin((Renderer.defaultCameraFov / 180) * Math.PI);
      let cameraPosSection: Interface_DTO.Vec3d = {
        X: cabinetSection.Width + 300, //right side of cabinet
        Y: (cabinetSection.Height * 7) / 8,
        Z: cameraFocusPointSection.Z + distanceFromFront,
      };
      return this.toCameraAngle(
        cameraFocusPointSection,
        cameraPosSection,
        cabinetSection,
      );
    }

    private getDefaultPartitionCameraAngle(): Client.CameraAngle {
      const roomSize = this.floorPlan.Size;
      let cameraFocusPointSection: Interface_DTO.Vec3d = {
        X: roomSize.X / 2,
        Y: roomSize.Z / 3.5,
        Z: roomSize.Y / 2,
      };
      //find camera pos from focus point, size of section, and aspect ratio of camera
      let distanceFromFront =
        3300 / Math.sin((Renderer.defaultCameraFov / 180) * Math.PI);
      let cameraPosSection: Interface_DTO.Vec3d = {
        X: roomSize.X * 0.85, //right side of cabinet
        Y: roomSize.Z * 1.2,
        Z: cameraFocusPointSection.Z + distanceFromFront,
      };
      return new Client.CameraAngle(
        cameraPosSection,
        cameraFocusPointSection,
        Renderer.defaultCameraFov,
      );
    }

    private toCameraAngle(
      cameraFocus: Interface_DTO.Vec3d,
      cameraPos: Interface_DTO.Vec3d,
      leftSection: Client.CabinetSection,
    ): Client.CameraAngle {
      let cameraFocusPointWorld = VectorHelper.add(
        VectorHelper.rotate3d(cameraFocus, leftSection.floorPlanRotation),
        leftSection.floorPlanPosition,
      );
      let cameraPosWorld = VectorHelper.add(
        VectorHelper.rotate3d(cameraPos, leftSection.floorPlanRotation),
        leftSection.floorPlanPosition,
      );
      return new Client.CameraAngle(
        cameraPosWorld,
        cameraFocusPointWorld,
        Renderer.defaultCameraFov,
      );
    }

    public resetCamera() {
      if (this.cabinetSection != null)
        this.cabinetSection.cabinet.cameraAngle = undefined;

      this.restoreCamera();
      this._changes$.next('resetCamera');
    }

    public render() {
      this.renderer.render(this.scene, this.camera);
      requestAnimationFrame((t) => t);
    }

    public dispose() {
      if (this.isDisposed) return;
      this.isDisposed = true;

      if (this.floorPlanSubscription) {
        this.floorPlanSubscription.unsubscribe();
        this.floorPlanSubscription = null;
      }
      this.clearSceneContents();

      this.onDispose();
    }

    private _currentSelection: Client.ConfigurationItem[] = [];
    public set currentSelection(items: Client.ConfigurationItem[]) {
      if (this._currentSelection.length === 0 && items.length === 0) return;
      if (this._currentSelection === items) return;
      //IMPROVE make a better equality check

      this._currentSelection = items;
      this.resetSelection();
    }

    public get currentSelection() {
      return this._currentSelection;
    }

    private resetSelection(): void {
      let selectNames = this.currentSelection.map((ci) => this.getName(ci));

      if (!this.floorPlanGroup) return;

      //set the selectBox to visible only if the object is selected
      this.floorPlanGroup.traverse((obj) => {
        if (obj.name !== Renderer.itemPartNames.selectBox) return;
        let parent = obj.parent;
        if (!parent) return;
        let isSelected = selectNames.some((sn) => sn === parent!.name);
        obj.visible = isSelected;
      });

      //set the errorBox visible iff the item is not selected
      this.floorPlanGroup.traverse((obj) => {
        if (obj.name !== Renderer.itemPartNames.errorLevel) return;
        if (!this.itemVisibilities['errorLevel']) {
          obj.visible = false;
          return;
        }
        let parent = obj.parent;
        if (!parent) return;
        let isSelected = selectNames.some((sn) => sn === parent!.name);
        obj.visible = !isSelected;
      });

      this._changes$.next('selectionChanged');
    }

    private async setSceneContents(floorPlan: Client.FloorPlan) {
      this.floorPlan = floorPlan;
      let floorPlanGroup = new THREE.Group();
      this.cabinetSectionIntersectees = [];

      let thisCabinetGroup = new THREE.Group();
      thisCabinetGroup.name = 'thisCabinet';
      floorPlanGroup.add(thisCabinetGroup);
      let otherCabinetsGroup = new THREE.Group();
      otherCabinetsGroup.name = 'otherCabinets';
      otherCabinetsGroup.visible =
        this.itemVisibilities['otherCabinets'] != false;
      floorPlanGroup.add(otherCabinetsGroup);

      for (let cabinet of floorPlan.actualCabinets) {
        let cabinetGroup = await this.getCabinetGroup(cabinet);
        if (cabinet.CabinetIndex == this.cabinetSection?.CabinetIndex) {
          thisCabinetGroup.add(cabinetGroup);
        } else {
          otherCabinetsGroup.add(cabinetGroup);
        }
      }

      if (this.partitionPlan) {
        const partitionPlan = this.partitionPlan;
        let partitionPromises = this.partitionPlan.partitions.map(
          async (partition) => {
            let partitionGroup = await this.getPartitionGroup(
              partition,
              calculateSections(
                partitionPlan,
                this.floorPlan,
                this.partitionQueryService.getDoorModuleProductId(),
                this.partitionQueryService.getWallModuleProductId(),
              ),
              partitionPlan.profiles,
              partitionPlan.rails,
            );

            if (this.partitionQueryService.isPartitionSelected(partition.id))
              thisCabinetGroup.add(partitionGroup);
            else otherCabinetsGroup.add(partitionGroup);
          },
        );

        await Promise.all(partitionPromises);
      }

      var wallTex = this.getTexture('staticAssets/images/Textures/wall.jpg');
      var ceilingTex = this.getTexture(
        'staticAssets/images/Textures/ceiling.jpg',
      );
      var floorTex = this.getTexture('staticAssets/images/Textures/floor.jpg');
      this.materials.ceiling = new THREE.MeshPhongMaterial({
        map: await ceilingTex,
        shininess: this.materials.ceiling.shininess,
        emissive: this.materials.ceiling.emissive,
      });
      this.materials.floor = new THREE.MeshPhongMaterial({
        map: await floorTex,
        shininess: 75,
      });

      floorPlanGroup.add(
        this.getRoom(
          await wallTex,
          this.materials.floor,
          this.materials.ceiling,
        ),
      );

      this.clearSceneContents();
      this.floorPlanGroup = floorPlanGroup;
      this.scene.add(floorPlanGroup);

      this.updateVisibility();
      this.resetSelection();

      this._changes$.next('contents updated');
    }

    private async getCabinetGroup(
      cabinet: Client.Cabinet,
    ): Promise<THREE.Group> {
      let cabinetGroup = new THREE.Group();
      cabinetGroup.position.set(
        cabinet.floorPlanPosition.X,
        cabinet.floorPlanPosition.Y,
        cabinet.floorPlanPosition.Z,
      );
      cabinetGroup.rotateY(-cabinet.floorPlanRotation);
      let sectionGroupPromises: Promise<THREE.Group>[] = [];
      for (let section of cabinet.cabinetSections) {
        sectionGroupPromises.push(this.getCabinetSectionGroup(section));
      }
      let sectionGroups = await Promise.all(sectionGroupPromises);
      for (let sectionGroup of sectionGroups) {
        cabinetGroup.add(sectionGroup);
      }

      cabinetGroup.name = 'cabinet-' + cabinet.CabinetIndex;
      cabinetGroup.updateMatrix();
      return cabinetGroup;
    }

    private async getCabinetSectionGroup(
      section: Client.CabinetSection,
    ): Promise<THREE.Group> {
      let sectionGroup = new THREE.Group();
      sectionGroup.rotateY((section.Rotation / 180) * Math.PI);
      sectionGroup.position.set(section.PositionX, 0, section.PositionY);
      sectionGroup.name = 'section-' + section.CabinetSectionIndex;

      let subGroupPromises: Promise<THREE.Group>[] = [];

      subGroupPromises.push(
        this.getItemGroup(section.backing.items, 'backing'),
      );
      subGroupPromises.push(
        this.getInteriorItemGroup(section.interior.items, 'interior'),
      );

      subGroupPromises.push(
        this.getLightingGroup(section.corpus.lightingItems, 'lighting'),
      );

      subGroupPromises.push(
        this.getItemGroup(section.doors.railItems, 'rails'),
      );
      subGroupPromises.push(
        this.getItemGroup(section.corpus.allCorpusItems, 'corpus'),
      );

      subGroupPromises.push(this.getDoorsGroup(section, 'doors'));
      subGroupPromises.push(this.getBackingFittingGroup(section, 'backing'));

      subGroupPromises.push(
        this.getItemGroup(
          section.swing.items.filter(
            (i) => i.ItemType === Interface_Enums.ItemType.SwingCorpus,
          ),
          'corpus',
        ),
      );
      subGroupPromises.push(
        this.getItemGroup(
          section.swing.items.filter(
            (i) => i.ItemType === Interface_Enums.ItemType.SwingDoor,
          ),
          'doors',
        ),
      );
      subGroupPromises.push(
        this.getItemGroup(
          section.swing.items.filter(
            (i) => i.ItemType === Interface_Enums.ItemType.SwingBacking,
          ),
          'backing',
        ),
      );

      subGroupPromises.push(
        this.getItemGroup(
          section.swingFlex.items.filter(
            (i) => i.ItemType === Interface_Enums.ItemType.SwingFlexCorpus,
          ),
          'corpus',
        ),
      );
      subGroupPromises.push(
        this.getInteriorItemGroup(
          section.swingFlex.items.filter(
            (i) =>
              i.ItemType === Interface_Enums.ItemType.SwingFlexCorpusMovable,
          ),
          'interior',
        ),
      );
      subGroupPromises.push(
        this.getItemGroup(
          section.swingFlex.items.filter(
            (i) => i.ItemType === Interface_Enums.ItemType.SwingFlexDoor,
          ),
          'doors',
        ),
      );
      subGroupPromises.push(
        this.getItemGroup(
          section.swingFlex.items.filter(
            (i) => i.ItemType === Interface_Enums.ItemType.SwingFlexBacking,
          ),
          'backing',
        ),
      );

      subGroupPromises.push(this.getIntersectee(section));
      subGroupPromises.push(this.getCabinetSectionRulers(section));
      subGroupPromises.push(this.getPulloutWarningAreas(section));

      let subGroups = await Promise.all(subGroupPromises);
      for (let group of subGroups) {
        sectionGroup.add(group);
      }

      sectionGroup.updateMatrix();
      return sectionGroup;
    }

    private async getCabinetSectionRulers(
      section: Client.CabinetSection,
    ): Promise<THREE.Group> {
      let result = this.d3RulerService.getCabinetSectionRulers(section);
      result.name = 'rulers';
      result.visible = this.getVisible('rulers');
      return result;
    }

    private async getPulloutWarningAreas(
      section: Client.CabinetSection,
    ): Promise<THREE.Group> {
      let result = new THREE.Group();
      let zStart = 505;
      for (let pullout of section.doors.pulloutWarningAreas) {
        if (pullout.severity === Enums.PulloutWarningSeverity.None) {
          continue;
        }
        let dimensions: Interface_DTO_Draw.Cube = {
          X: pullout.start,
          Width: pullout.end - pullout.start,
          Y: 0,
          Height: section.Height,
          Z: zStart,
          Depth: section.Depth - zStart,
        };
        let material =
          pullout.severity === Enums.PulloutWarningSeverity.Warning
            ? this.materials.pulloutWarning
            : this.materials.pulloutCritical;
        let box = this.getSurroundingBox(dimensions, material, {
          setPosition: true,
        });
        result.add(box);
      }

      result.name = 'pullout';
      result.visible = this.getVisible('pullout');
      return result;
    }

    private getIntersectee(
      section: Client.CabinetSection,
    ): Promise<THREE.Group> {
      let obj = new THREE.Mesh(
        Renderer.getPlaneGeometry({ X: section.Width, Y: section.Height }),
        this.materials.sectionIntersect,
      );
      obj.position.x = 0;
      obj.position.y = 0;
      obj.position.z = 0;
      this.cabinetSectionIntersectees.push({
        cabinetIndex: section.CabinetIndex,
        cabinetSectionIndex: section.CabinetSectionIndex,
        obj: obj,
      });
      const result = new THREE.Group();
      result.add(obj);
      return Promise.resolve(result);
    }

    private getRoom(
      wallTex: THREE.Texture,
      floorMat: THREE.Material,
      ceilingMat: THREE.Material,
    ): THREE.Group {
      let wallGroup = new THREE.Group();
      wallGroup.name = 'walls';

      for (let wall of this.floorPlan!.walls) {
        wallGroup.add(this.getWall(wall, wallTex));
      }

      {
        let ceilingMesh = this.getRoomGeometry(ceilingMat);
        ceilingMesh.receiveShadow = true;
        ceilingMesh.castShadow = false;
        ceilingMesh.rotateX(Math.PI / 2);
        ceilingMesh.position.setY(this.ceilingHeight);
        wallGroup.add(ceilingMesh);
      }
      {
        let fMat2 = floorMat.clone();
        fMat2.side = THREE.BackSide;
        let floorMesh = this.getRoomGeometry(fMat2);
        floorMesh.receiveShadow = true;
        floorMesh.castShadow = false;
        floorMesh.rotateX(Math.PI / 2);
        floorMesh.position.setY(0);
        //floorMesh.position.setZ(this.cabinetSection.cabinet.floorPlan.Size.Y);
        wallGroup.add(floorMesh);
      }

      return wallGroup;
    }

    private static scaleTexture<T extends THREE.Texture>(
      sourceTexture: T,
      textureSize: Interface_DTO_Draw.Vec2d,
      targetSize: Interface_DTO_Draw.Vec2d,
    ): T {
      let tex = sourceTexture.clone();
      tex.wrapT = tex.wrapS = THREE.MirroredRepeatWrapping;
      tex.repeat.set(
        targetSize.X / textureSize.X,
        targetSize.Y / textureSize.Y,
      );
      tex.needsUpdate = true;
      return tex;
    }

    private static getWallMesh(
      texture: THREE.Texture,
      size: Interface_DTO_Draw.Vec2d,
      pos: Interface_DTO_Draw.Vec2d,
    ) {
      let textureSize: Interface_DTO_Draw.Vec2d = { X: 1024, Y: 1024 }; //Size of wall texture, in mm
      let geometry = Renderer.getPlaneGeometry(size);
      let scaledTexture = Renderer.scaleTexture(texture, textureSize, size);
      let mat = new THREE.MeshPhongMaterial({
        map: scaledTexture,
        shininess: 5,
      });
      let mesh = new THREE.Mesh(geometry, mat);
      mesh.position.set(pos.X, pos.Y, 0);
      mesh.receiveShadow = true;
      return mesh;
    }

    private getWall(
      wall: Interface_DTO_FloorPlan.WallSection,
      wallTexture: THREE.Texture,
    ): THREE.Object3D {
      let wallBreakingItems = Enumerable.from(wall.Items)
        .where(
          (item) =>
            item.ItemType === Interface_DTO_FloorPlan.ItemType.Door ||
            item.ItemType === Interface_DTO_FloorPlan.ItemType.Window,
        )
        .orderBy((item) => item.X)
        .toArray();

      let wallGroup = new THREE.Group();

      let wallStart = 0;
      for (let item of wallBreakingItems) {
        if (wallStart < item.X) {
          //the piece of wall between two obstacles, or a wall-corner and an obstacle
          let leadingWallMesh = Renderer.getWallMesh(
            wallTexture,
            { X: item.X - wallStart, Y: this.ceilingHeight },
            { X: wallStart, Y: 0 },
          );
          wallGroup.add(leadingWallMesh);
        }

        if (item.ItemType === Interface_DTO_FloorPlan.ItemType.Window) {
          let windowLower = 1000;
          let windowUpper = 2100;
          {
            let lowerWallDim = { X: item.Width, Y: windowLower };
            let lowerWallMesh = Renderer.getWallMesh(
              wallTexture,
              lowerWallDim,
              { X: item.X, Y: 0 },
            );
            wallGroup.add(lowerWallMesh);
          }
          {
            let upperWallDim = {
              X: item.Width,
              Y: this.ceilingHeight - windowUpper,
            };
            let upperWallMesh = Renderer.getWallMesh(
              wallTexture,
              upperWallDim,
              { X: item.X, Y: windowUpper },
            );
            wallGroup.add(upperWallMesh);
          }
        } else if (item.ItemType === Interface_DTO_FloorPlan.ItemType.Door) {
          let doorUpper = 2100; //pooma
          let upperWallDim = {
            X: item.Width,
            Y: this.ceilingHeight - doorUpper,
          };
          let upperWallMesh = Renderer.getWallMesh(wallTexture, upperWallDim, {
            X: item.X,
            Y: doorUpper,
          });
          wallGroup.add(upperWallMesh);
        }

        wallStart = item.X + item.Width;
      }

      let totalWallLength = VectorHelper.dist(wall.Begin, wall.End);

      if (totalWallLength > wallStart) {
        let lastWallDim = {
          X: totalWallLength - wallStart,
          Y: this.ceilingHeight,
        };
        let lastWallMesh = Renderer.getWallMesh(wallTexture, lastWallDim, {
          X: wallStart,
          Y: 0,
        });
        wallGroup.add(lastWallMesh);
      }

      let rotation = FloorPlanHelper.getRotation(wall);

      wallGroup.rotateY(-rotation + Math.PI / 2);

      wallGroup.position.setX(wall.Begin.X);
      wallGroup.position.setZ(wall.Begin.Y);
      return wallGroup;
    }

    private getRoomGeometry(material: THREE.Material): THREE.Mesh {
      let floorPlan: Client.FloorPlan;
      if (this.floorPlan) floorPlan = this.floorPlan;
      else if (this.cabinetSection)
        floorPlan = this.cabinetSection.cabinet.floorPlan;
      else throw new Error('Unable to load floorplan - D3Service');

      let walls = floorPlan.walls;
      let firstWall = walls[0];

      //scale the geometry  so it fits into [0, 1], [0,1]
      //this is needed in order to scale the texture properly

      let xMin = Math.min(firstWall.Begin.X, firstWall.End.X);
      let yMin = Math.min(firstWall.Begin.Y, firstWall.End.Y);
      let xMax = Math.max(firstWall.Begin.X, firstWall.End.X);
      let yMax = Math.max(firstWall.Begin.Y, firstWall.End.Y);

      for (let wall of walls) {
        xMin = Math.min(xMin, wall.Begin.X, wall.End.X);
        yMin = Math.min(yMin, wall.Begin.Y, wall.End.Y);
        xMax = Math.max(xMax, wall.Begin.X, wall.End.X);
        yMax = Math.max(yMax, wall.Begin.Y, wall.End.Y);
      }

      let xRange = xMax - xMin;
      let yRange = yMax - yMin;

      let scaledWalls = walls.map((w) => {
        return {
          X: (w.Begin.X - xMin) / xRange,
          Y: (w.Begin.Y - yMin) / yRange,
        };
      });
      let shape = new THREE.Shape();

      shape.moveTo(scaledWalls[0].X, scaledWalls[0].Y);
      for (let wall of scaledWalls) {
        shape.lineTo(wall.X, wall.Y);
      }
      let geo = new THREE.ShapeGeometry(shape);
      let mesh = new THREE.Mesh(geo, material);
      mesh.scale.set(xRange, yRange, 1);
      return mesh;
    }

    private clearSceneContents() {
      if (this.floorPlanGroup) {
        this.scene.remove(this.floorPlanGroup);
        this.floorPlanGroup.traverse((o: any) => o.dispose && o.dispose());
      }
      this.floorPlanGroup = null;
    }

    private updateVisibility() {
      for (let groupName in this.itemVisibilities) {
        this.actualSetVisible(
          groupName as ItemGroupName,
          this.itemVisibilities[groupName],
        );
      }
    }

    public getVisible(groupName: ItemGroupName) {
      let v = this.itemVisibilities[groupName];
      if (v === undefined) return true;
      return v;
    }
    public setVisible(groupName: ItemGroupName, visible: boolean) {
      this.itemVisibilities[groupName] = visible;
      this.actualSetVisible(groupName, visible);
      this._changes$.next('visibility ' + groupName);
    }
    private actualSetVisible(groupName: ItemGroupName, visible: boolean) {
      this.scene.traverse((obj) => {
        if (obj.name === groupName) {
          obj.visible = visible;
        }
      });
    }

    private async getLightingGroup(
      items: Client.ConfigurationItem[],
      name: D3Service.ItemGroupName,
    ): Promise<THREE.Group> {
      let grp = new THREE.Group();
      grp.name = name;
      let visible = this.itemVisibilities[name];

      if (visible === undefined) {
        visible = true;
      }
      grp.visible = visible;

      for (let item of items) {
        if (item.isTemplate) continue;
        let obj = await this.getObject3D(item, 'lighting');

        await this.replaceMaterials(
          obj,
          item.allMaterials.map((mv) => mv.material),
        );

        grp.add(obj);
      }
      return grp;
    }

    private async getDoorsGroup(
      section: Client.CabinetSection,
      name: D3Service.ItemGroupName,
    ): Promise<THREE.Group> {
      let allDoorsGrp = new THREE.Group();
      allDoorsGrp.name = name;
      let visible = this.itemVisibilities[name];
      if (visible === undefined) visible = true;
      allDoorsGrp.visible = visible;

      for (let door of section.doors.doors) {
        allDoorsGrp.add(await this.getDoor(door));
      }

      // Corner moulding
      if (section.doors.cornerMoulding) {
        allDoorsGrp.add(
          await this.getCornerMoulding(section.doors.cornerMoulding),
        );
      }

      return allDoorsGrp;
    }

    private async getCornerMoulding(
      moulding: Client.ConfigurationItem,
    ): Promise<THREE.Group> {
      let mouldingGroup = new THREE.Group();
      mouldingGroup.name = 'door';

      let modelObj = await this.getBox(
        { X: moulding.Width, Y: moulding.Height, Z: moulding.Depth },
        { X: moulding.X, Y: moulding.Y, Z: moulding.Z },
        moulding.Material,
        1,
      );

      modelObj.name = 'cornerMoulding';
      mouldingGroup.add(modelObj);

      return mouldingGroup;
    }

    private async getDoor(door: Client.Door): Promise<THREE.Group> {
      let doorGrp = new THREE.Group();
      doorGrp.name = 'door';

      let profileScalingFactor = 1 / 1000;

      //fillings
      if (!App.debug.noDoorFillings) {
        for (let filling of door.fillings) {
          let modelObj;
          if (filling.isDesignFilling) {
            modelObj = await this.getBox(
              { X: filling.height, Y: filling.width, Z: filling.depth },
              {
                X: filling.positionX + filling.width,
                Y: filling.positionY,
                Z: filling.positionZ,
              },
              filling.material,
              0,
            );
            modelObj.rotateZ(Math.PI / 2);
          } else {
            modelObj = await this.getBox(
              { X: filling.width, Y: filling.height, Z: filling.depth },
              {
                X: filling.positionX,
                Y: filling.positionY,
                Z: filling.positionZ,
              },
              filling.material,
              0,
            );
          }
          modelObj.name = 'filling';
          doorGrp.add(modelObj);
        }
      }

      // Bars
      for (let bar of door.bars) {
        if (!bar.height) continue;
        let modelObj: THREE.Object3D;

        if (bar.model3dPath) {
          modelObj = await this.getModelObject(bar.model3dPath);
          await this.replaceMaterials(
            modelObj,
            bar.material ? [bar.material] : [],
          );

          let barModelWidth = bar.product ? bar.product.model3dWidth : null;
          if (!barModelWidth) {
            barModelWidth = Constants.D3.defaultBarModelWidth;
          }
          modelObj.scale.setX(bar.width / barModelWidth);
          let barZ: number;

          modelObj.position.set(bar.positionX, bar.position, bar.positionZ);
        } else {
          modelObj = await this.getBox(
            { X: bar.width, Y: bar.height || 0, Z: bar.depth },
            { X: bar.positionX, Y: bar.position, Z: bar.positionZ },
            bar.material,
            1,
          );
        }

        modelObj.name = 'bar';
        doorGrp.add(modelObj);
      }

      // Vertical bars
      for (let bar of door.verticalBars) {
        let modelObj: THREE.Object3D;

        if (bar.model3dPath) {
          modelObj = await this.getModelObject(bar.model3dPath);
          await this.replaceMaterials(
            modelObj,
            bar.material ? [bar.material] : [],
          );

          let barModelWidth = bar.product ? bar.product.model3dWidth : null;
          if (!barModelWidth) {
            barModelWidth = Constants.D3.defaultBarModelWidth;
          }

          modelObj.scale.setX(bar.height / barModelWidth);
          modelObj.scale.setZ(bar.depth / bar.model3dDepth);
          modelObj.position.set(
            bar.positionX + bar.width,
            bar.positionY,
            bar.positionZ,
          );
        } else {
          modelObj = await this.getBox(
            { X: bar.width, Y: bar.height || 0, Z: bar.depth },
            { X: bar.positionX, Y: bar.positionY, Z: bar.positionZ },
            bar.material,
            1,
          );
        }

        modelObj.rotateZ(Math.PI / 2);

        modelObj.name = 'bar';
        doorGrp.add(modelObj);
      }

      // Profiles
      let profilesGrp = new THREE.Group();
      profilesGrp.name = 'profiles';

      for (let profile of door.profiles) {
        let modelPath = profile.model3DPath;
        let modelObj: THREE.Object3D;
        if (modelPath) {
          modelObj = await this.getModelObject(modelPath);

          modelObj.position.set(
            profile.position.X,
            profile.position.Y,
            profile.position.Z,
          );
          await this.replaceMaterials(
            modelObj,
            profile.material ? [profile.material] : [],
          );

          if (
            profile.type === Enums.DoorProfilePosition.Left ||
            profile.type === Enums.DoorProfilePosition.Right
          ) {
            modelObj.scale.setY(
              profile.size.Y / Constants.D3.defaultSideProfileModelHeight,
            );
          } else {
            modelObj.scale.setX(
              profile.size.X / Constants.D3.defaultTopBottomProfileWidth,
            );
          }
        } else {
          modelObj = await this.getBox(
            profile.size,
            profile.position,
            profile.material,
            1,
          );
        }
        modelObj.name = 'profile';
        profilesGrp.add(modelObj);
      }
      doorGrp.add(profilesGrp);

      //wheels
      let addWheels = !App.useDebug || !App.debug.noDoorWheels;
      if (addWheels) {
        let wheelsGrp = new THREE.Group();
        wheelsGrp.name = 'wheels';
        let sectionDoors = door.doorSubSection;

        let product = door.doorSubSection.profile;
        if (product) {
          let productData = product.getProductData();
          let model3dPath = productData ? productData.Model3DPath : null;
          if (model3dPath) {
            let wheelPath = model3dPath + '_Wheel';
            let offset: Interface_DTO_Draw.Vec3d = {
              X: 45,
              Y: 0,
              Z: 0,
            };
            let wheelSizeX = 80;
            let leftWheel = await this.getModelObject(wheelPath);
            await this.replaceMaterials(leftWheel, []);
            let rightWheel = leftWheel.clone(true);

            leftWheel.position.set(offset.X, offset.Y, offset.Z);
            rightWheel.position.set(
              door.width - offset.X - wheelSizeX,
              offset.Y,
              offset.Z,
            );

            wheelsGrp.add(leftWheel, rightWheel);
          }
        }
        doorGrp.add(wheelsGrp);
      }

      // Grips
      let gripProduct = door.doorSubSection.grip;
      if (gripProduct) {
        let gripGroup = new THREE.Group();
        gripGroup.name = 'Grips';

        let frontGrips = await this.getItemGroup(door.gripItemsFront, 'doors');

        let backGrips = await this.getItemGroup(door.gripItemsBack, 'doors');
        backGrips.children.forEach((obj) => obj.rotateY(Math.PI));

        gripGroup.add(frontGrips, backGrips);
        doorGrp.add(gripGroup);
      }

      doorGrp.position.set(door.position.X, door.position.Y, door.position.Z);
      return doorGrp;
    }

    private async getBackingFittingGroup(
      section: Client.CabinetSection,
      name: D3Service.ItemGroupName,
    ): Promise<THREE.Group> {
      let fittingGroup = new THREE.Group();
      fittingGroup.name = name;
      let visible = this.itemVisibilities[name];
      if (visible === undefined) visible = true;
      fittingGroup.visible = visible;

      for (let fitting of section.backing.fittingCubes) {
        let fitting3d = await this.getBox(
          { X: fitting.Width, Y: fitting.Height, Z: fitting.Depth },
          { X: fitting.X, Y: fitting.Y, Z: fitting.Z },
          section.backing.fittingMaterial,
          1,
        );
        fitting3d.name = 'fitting';

        let backingFittingSpacer = this.backingIsFittingPanelSpacer(
          fitting,
          section,
        );
        if (backingFittingSpacer) {
          fitting3d = await this.getBox(
            { X: fitting.Width, Y: fitting.Height, Z: fitting.Depth },
            { X: fitting.X, Y: fitting.Y, Z: fitting.Z },
            backingFittingSpacer.Material,
            1,
          );
          fitting3d.name = 'interior';
        }

        fittingGroup.add(fitting3d);
      }

      return fittingGroup;
    }

    private async getInteriorItemGroup(
      items: Client.ConfigurationItem[],
      name: D3Service.ItemGroupName,
    ): Promise<THREE.Group> {
      let grp = new THREE.Group();
      grp.name = name;
      let visible = this.itemVisibilities[name];

      if (visible === undefined) {
        visible = true;
      }
      grp.visible = visible;

      for (let item of items) {
        if (item.isTemplate) continue;
        let obj = await this.getObject3D(item, 'interior');
        grp.add(obj);
      }
      return grp;
    }

    private getItemIntersectBox(
      dimensions: Interface_DTO_Draw.Cube,
    ): THREE.Object3D {
      let result = this.getSurroundingBox(
        dimensions,
        this.materials.intersect,
        { setPosition: false },
      );
      result.name = Renderer.itemPartNames.intersectBox;
      return result;
    }

    private getItemSelectBox(
      dimensions: Interface_DTO_Draw.Cube,
    ): THREE.Object3D {
      let result = this.getSurroundingBox(dimensions, this.materials.select, {
        setPosition: false,
      });
      result.name = Renderer.itemPartNames.selectBox;
      result.visible = false;
      return result;
    }

    private getErrorBox(item: Client.ConfigurationItem): THREE.Object3D | null {
      let mat;
      if (item.maxErrorLevel === Interface_Enums.ErrorLevel.Info) {
        mat = this.materials.errorLevelInfo;
      } else if (item.maxErrorLevel === Interface_Enums.ErrorLevel.Warning) {
        mat = this.materials.errorLevelWarning;
      } else if (item.maxErrorLevel === Interface_Enums.ErrorLevel.Critical) {
        mat = this.materials.errorLevelCritical;
      } else {
        return null;
      }

      let result = this.getSurroundingBox(item, mat!.material, {
        setPosition: false,
      });
      result.name = Renderer.itemPartNames.errorLevel;
      return result;
    }

    private getItemCollisionBoxes(item: Client.ConfigurationItem): THREE.Group {
      let collisionObjects = item.collisions
        .filter(
          (c) =>
            c.severity === Enums.CollisionSeverity.Recommended ||
            c.severity === Enums.CollisionSeverity.Required,
        )
        .map((c) => {
          let material =
            c.severity === Enums.CollisionSeverity.Recommended
              ? this.materials.collisionRecommended
              : this.materials.collisionRequired;
          let result = this.getSurroundingBox(c, material, {
            setPosition: false,
          });
          if (c.space === Enums.CollisionSpacePosition.Bottom) {
            result.position.set(0, -c.Height, 0);
          } else if (c.space === Enums.CollisionSpacePosition.Top) {
            result.position.set(0, item.Height, 0); //Untested...
          } else {
            if (item.isMirrored) {
              result.position.set(-c.Width, 0, 0); //Untested...
            } else {
              result.position.set(item.Width, 0, 0); //Untested...
            }
          }
          return result;
        })
        .filter((o) => !!o);
      let result = new THREE.Group();
      if (collisionObjects.length > 0) {
        result.add(...collisionObjects);
      }
      result.name = 'collision';
      result.visible = this.getVisible('collision');
      return result;
    }

    private getSurroundingBox(
      dimensions: Interface_DTO_Draw.Cube,
      material: THREE.Material,
      options: {
        setPosition?: boolean;
      } = {},
    ): THREE.Object3D {
      if (options.setPosition === undefined) {
        options.setPosition = true;
      }

      let margin = 1;
      let geometry = new THREE.BoxGeometry(
        dimensions.Depth + 2 * margin,
        dimensions.Height + 2 * margin,
        dimensions.Width + 2 * margin,
      );
      geometry.rotateY(Math.PI / 2);
      geometry.translate(
        (margin + dimensions.Width) / 2,
        (margin + dimensions.Height) / 2,
        (margin + dimensions.Depth) / 2,
      ); //set origin of object to be lower left back corner instead of middle
      let result = new THREE.Mesh(geometry, material);
      if (options.setPosition) {
        result.position.set(dimensions.X, dimensions.Y, dimensions.Z);
      }
      return result;
    }

    private async getItemGroup(
      items: Client.ConfigurationItem[],
      name: D3Service.ItemGroupName,
    ): Promise<THREE.Group> {
      let grp = new THREE.Group();
      grp.name = name;
      let visible = this.itemVisibilities[name];

      if (visible === undefined) {
        visible = true;
      }
      grp.visible = visible;

      for (let item of items) {
        if (item.isTemplate) continue;
        let obj = await this.getObject3D(item, name);
        grp.add(obj);
      }
      return grp;
    }

    private async getBox(
      size: Interface_DTO_Draw.Vec3d,
      pos: Interface_DTO_Draw.Vec3d,
      dtoMat: Interface_DTO.Material | null,
      edgeRadius: number,
    ): Promise<THREE.Object3D> {
      let result = new THREE.Group();
      let sizes = {
        side: { X: size.Z, Y: size.Y },
        top: { X: size.Z, Y: size.X },
        front: { X: size.X, Y: size.Y },
      };
      let mats = {
        side: this.getObjectMaterial(dtoMat, { size: sizes.side }),
        top: this.getObjectMaterial(dtoMat, { size: sizes.top }),
        front: this.getObjectMaterial(dtoMat, { size: sizes.front }),
      };
      let planes = {
        sidePlus: await this.getPlane(sizes.side, mats.side),
        sideMinus: await this.getPlane(sizes.side, mats.side),
        topPlus: await this.getPlane(sizes.top, mats.top),
        topMinus: await this.getPlane(sizes.top, mats.top),
        frontPlus: await this.getPlane(sizes.front, mats.front),
        frontMinus: await this.getPlane(sizes.front, mats.front),
      };

      let x = size.X / 2;
      let y = size.Y / 2;
      let z = size.Z / 2;
      planes.sidePlus.rotateY(Math.PI / 2);
      planes.sidePlus.position.set(2 * x, y, z);
      planes.sideMinus.rotateY(-Math.PI / 2);
      planes.sideMinus.position.set(0, y, z);

      planes.topPlus.rotateX(-Math.PI / 2);
      planes.topPlus.rotateZ(-Math.PI / 2);
      planes.topPlus.position.set(x, 2 * y, z);
      planes.topMinus.rotateX(Math.PI / 2);
      planes.topMinus.rotateZ(-Math.PI / 2);
      planes.topMinus.position.set(x, 0, z);

      planes.frontPlus.rotateX(0);
      planes.frontPlus.position.set(x, y, 2 * z);
      planes.frontMinus.rotateX(Math.PI);
      planes.frontMinus.position.set(x, y, 0);

      let allPlanes: THREE.Mesh[] = (<any>Object).values(planes);
      for (let p of allPlanes) {
        p.receiveShadow = true;
        p.castShadow = true;
      }
      result.add(...allPlanes);

      result.position.set(pos.X, pos.Y, pos.Z);
      return result;
    }
    private async getPlane(
      size: Interface_DTO_Draw.Vec2d,
      matPromise: Promise<THREE.Material>,
    ): Promise<THREE.Mesh> {
      let geo = new THREE.PlaneGeometry(size.X, size.Y);
      let mat = await matPromise;
      let result = new THREE.Mesh(geo, mat);
      return result;
    }

    private async getObject3D(
      item: Client.ConfigurationItem,
      groupName: D3Service.ItemGroupName,
    ): Promise<THREE.Object3D> {
      let rotationGroup = new THREE.Group();

      rotationGroup.add(await this.getActualObject3D(item));
      rotationGroup.add(this.getItemIntersectBox(item));
      rotationGroup.add(this.getItemSelectBox(item));
      let errorBox = this.getErrorBox(item);
      if (errorBox) {
        rotationGroup.add(errorBox);
      }
      rotationGroup.add(this.getItemCollisionBoxes(item));

      if (item.rotation === Enums.ItemRotation.Clockwise90DegreesZ) {
        rotationGroup.rotateZ(-Math.PI / 2); // remember: positive number = ccw
        rotationGroup.position.set(0, item.Width, 0);
      } else if (
        item.rotation === Enums.ItemRotation.CounterClockwise90DegreesZ
      ) {
        rotationGroup.rotateZ(Math.PI / 2);
        rotationGroup.position.set(item.Height, 0, 0);
      } else {
        if (item.rotation !== Enums.ItemRotation.None) {
          console.warn(`Unknown item rotation value: ${item.rotation}`);
        }
        /* Do nothing */
      }

      let positionGroup = new THREE.Group();
      positionGroup.add(rotationGroup);
      positionGroup.name = this.getName(item);
      positionGroup.userData['groupName'] = groupName;

      let itemX = item.X;
      if (item.ItemType === Interface_Enums.ItemType.SwingFlexDoor)
        itemX -= item.frontExtraWidthLeft;

      positionGroup.position.set(itemX, item.Y, item.Z);

      return positionGroup;
    }

    private getName(item: Client.ConfigurationItem): string {
      const name =
        'item:' +
        item.CabinetIndex +
        ':' +
        item.CabinetSectionIndex +
        ':' +
        item.ConfigurationItemIndex;
      console.assert(Renderer.itemNameRegex.test(name));
      return name;
    }

    private async getActualObject3D(
      item: Client.ConfigurationItem,
    ): Promise<THREE.Object3D> {
      let obj: THREE.Object3D;
      if (item.ProductData?.Model3DPath) {
        const targetSize = {
          X: item.Width,
          Y: item.Height,
          Z: item.Depth,
        };
        const originalSize = {
          X: item.ProductData.Model3DWidth,
          Y: item.Height,
          Z: item.ProductData.Model3DDepth,
        };
        obj = await this.getModelObject(
          item.ProductData.Model3DPath,
          targetSize,
          originalSize,
        );

        this.disableVariantGroups(obj, item);

        await this.replaceMaterials(
          obj,
          item.allMaterials.map((mv) => mv.material),
          targetSize,
        );

        if (item.isMirrored) {
          console.warn(
            'Mirrored items are turned inside out, and may not look good',
            item,
          );
          var mS = new THREE.Matrix4().identity();
          mS.elements[0] = -1;
          obj.applyMatrix4(mS);

          obj.position.set(item.Width, 0, 0);
          let grp = new THREE.Group();
          grp.add(obj);
          obj = grp;
        }
      } else if (item.isCornerDiagonalCut || item.isCorner2WayFlex) {
        obj = await this.getCornerShelf(item);
      } else {
        let itemZ = 0;
        if (item.ItemType === Interface_Enums.ItemType.Backing) {
          // Tiny hack (moving backing items forward by 0.1 mm), to prevent flickering when using visible backing against a wall (which you should not do anyway...)
          itemZ += 0.1;
        }

        let itemWidth = item.Width;
        if (item.ItemType === Interface_Enums.ItemType.SwingFlexDoor) {
          itemWidth += item.frontExtraWidthLeft;
          itemWidth += item.frontExtraWidthRight;
        }

        obj = await this.getBox(
          { X: itemWidth, Y: item.Height, Z: item.Depth },
          { X: 0, Y: 0, Z: itemZ },
          item.Material,
          1,
        );
      }

      obj.name = 'actualItem';
      obj.updateMatrix();
      return obj;
    }

    private disableVariantGroups(
      obj: THREE.Object3D,
      item: Client.ConfigurationItem,
    ) {
      const disablableVariantNames = [
        VariantNumbers.DrawerFrontWidth,
        VariantNumbers.DrawerFrontExtraWidth,
      ];
      const itemVariants = item.VariantOptions.filter(
        (vo) =>
          vo.variant &&
          vo.variantOption &&
          disablableVariantNames.indexOf(vo.variant?.Number) >= 0,
      );
      if (itemVariants.length === 0) return;
      const visibleGroupNameFragments = itemVariants.map(
        (iv) => `${iv.variant!.Number}:${iv.variantOption!.Number}`,
      );
      for (let group of obj.children) {
        if (!group.name.startsWith('KA:')) continue;
        const groupNameFragments = group.name.substring(3).split('&');
        const isVisible = groupNameFragments.every(
          (fragment) => visibleGroupNameFragments.indexOf(fragment) >= 0,
        );
        group.visible = isVisible;
      }
    }

    private getCornerShelf(
      item: Client.ConfigurationItem,
    ): Promise<THREE.Object3D> {
      if (item.Product) {
        if (item.isCornerRounded) {
          return this.getRoundedCornerShelf(item);
        } else if (item.isCornerTwoPart) {
          return this.getTwoPartCornerShelf(item);
        }
      }
      //Old product data types may end up here. This is keept only for backwards comp. with old solutions.
      return this.getNormalCornerShelf(item);
    }

    private async getNormalCornerShelf(
      item: Client.ConfigurationItem,
    ): Promise<THREE.Object3D> {
      let rearDiagonal = item.isCornerDiagonalCut ? 200 : 0;

      let coords: [number, number][] = [
        [item.Width - rearDiagonal, 0],
        [item.Width, rearDiagonal],
        [item.Width, item.width2],
        [item.Width - item.depth2, item.width2],
        [item.Width - item.depth2, item.Depth],
        [0, item.Depth],
        [0, 0],
      ];

      //create a shape that fits inside [0,1],[0,1]
      //then apply material and scale to fit.
      let shape = new THREE.Shape();
      shape.moveTo(0, 0);
      for (let coord of coords) {
        shape.lineTo(coord[0] / item.Width, coord[1] / item.width2);
      }

      let extrudeOptions: THREE.ExtrudeGeometryOptions & { amount: number } = {
        amount: 1,
        bevelEnabled: false,
      };

      let geometry = new THREE.ExtrudeGeometry(shape, extrudeOptions);
      let material = await this.getObjectMaterial(item.Material);
      let obj = new THREE.Mesh(geometry, material);

      //stretch the shape into the correct dimensions so the texture matches
      obj.scale.set(item.Width, item.width2, item.Height);

      obj.rotateX(Math.PI / 2);

      return obj;
    }

    private async getRoundedCornerShelf(
      item: Client.ConfigurationItem,
    ): Promise<THREE.Object3D> {
      let geometry = this.getRoundedCornerShelfGeometry(item);
      let material = await this.getObjectMaterial(item.Material);

      let obj = new THREE.Mesh(geometry, material);
      let scaleFactor = [item.Width, item.width2];
      obj.scale.set(scaleFactor[0], scaleFactor[1], item.Height);
      obj.rotateX(Math.PI / 2);
      obj.position.set(0, item.Height, 0);

      return obj;
    }

    private getRoundedCornerShelfGeometry(
      item: Client.ConfigurationItem,
    ): THREE.ExtrudeGeometry {
      let rotateTexture = item.width2 < item.Width;
      let roundingRadius = 50;
      let roundingCenter = [
        item.Width - item.depth2 - roundingRadius,
        item.Depth + roundingRadius,
      ];
      let scaleFactor = [item.Width, item.width2];

      let coords: ([number, number] | null)[];
      coords = [
        [0, 0],
        [item.Width, 0],
        [item.Width, item.width2],
        [item.Width - item.depth2, item.width2],
        [item.Width - item.depth2, roundingCenter[1]],
        null, //rounding here
        [0, item.Depth],
      ];

      let shape = new THREE.Shape();
      let startCoord = coords[0];

      for (let coord of coords) {
        if (coord) {
          shape.lineTo(coord[0] / scaleFactor[0], coord[1] / scaleFactor[1]);
        } else {
          //rounding
          shape.absellipse(
            roundingCenter[0] / scaleFactor[0],
            roundingCenter[1] / scaleFactor[1],
            roundingRadius / scaleFactor[0],
            roundingRadius / scaleFactor[1],
            -Math.PI * 2,
            -Math.PI / 2,
            true,
            0,
          );
        }
      }
      shape.closePath();

      let extrudeOptions: THREE.ExtrudeGeometryOptions & { amount: number } = {
        amount: 1,
        bevelEnabled: false,
      };

      if (rotateTexture) {
        extrudeOptions.UVGenerator =
          D3Service.Renderer.getRotatingUVGenerator();
      }

      let geometry = new THREE.ExtrudeGeometry(shape, extrudeOptions);
      return geometry;
    }

    private static getRotatingUVGenerator(): THREE.UVGenerator {
      // adapted from three.js WorldUVGenerator
      // GenerateTopUV is slightly tweaked
      return {
        generateSideWallUV: function (
          geometry,
          vertices,
          indexA,
          indexB,
          indexC,
          indexD,
        ) {
          var a_x = vertices[indexA * 3];
          var a_y = vertices[indexA * 3 + 1];
          var a_z = vertices[indexA * 3 + 2];
          var b_x = vertices[indexB * 3];
          var b_y = vertices[indexB * 3 + 1];
          var b_z = vertices[indexB * 3 + 2];
          var c_x = vertices[indexC * 3];
          var c_y = vertices[indexC * 3 + 1];
          var c_z = vertices[indexC * 3 + 2];
          var d_x = vertices[indexD * 3];
          var d_y = vertices[indexD * 3 + 1];
          var d_z = vertices[indexD * 3 + 2];

          if (Math.abs(a_y - b_y) < 0.01) {
            return [
              new THREE.Vector2(a_x, 1 - a_z),
              new THREE.Vector2(b_x, 1 - b_z),
              new THREE.Vector2(c_x, 1 - c_z),
              new THREE.Vector2(d_x, 1 - d_z),
            ];
          } else {
            return [
              new THREE.Vector2(a_y, 1 - a_z),
              new THREE.Vector2(b_y, 1 - b_z),
              new THREE.Vector2(c_y, 1 - c_z),
              new THREE.Vector2(d_y, 1 - d_z),
            ];
          }
        },
        generateTopUV: function (geometry, vertices, indexA, indexB, indexC) {
          var a_x = vertices[indexA * 3];
          var a_y = vertices[indexA * 3 + 1];
          var b_x = vertices[indexB * 3];
          var b_y = vertices[indexB * 3 + 1];
          var c_x = vertices[indexC * 3];
          var c_y = vertices[indexC * 3 + 1];

          return [
            // X and Y coordinates are flipped compared to original threejs version
            new THREE.Vector2(a_y, a_x),
            new THREE.Vector2(b_y, b_x),
            new THREE.Vector2(c_y, c_x),
          ];
        },
      };
    }

    private async getTwoPartCornerShelf(
      item: Client.ConfigurationItem,
    ): Promise<THREE.Object3D> {
      let part1OwnsCorner = item.Width < item.width2;
      let part1: THREE.Object3D;
      let part2: THREE.Object3D;

      if (part1OwnsCorner) {
        part1 = await this.getBox(
          { X: item.Width, Y: item.Height, Z: item.Depth },
          { X: 0, Y: 0, Z: 0 },
          item.Material,
          0,
        );
        part2 = await this.getBox(
          { X: item.width2 - item.Depth, Y: item.Height, Z: item.depth2 },
          { X: item.Width, Y: 0, Z: item.Depth },
          item.Material,
          0,
        );
      } else {
        part1 = await this.getBox(
          { X: item.Width - item.depth2, Y: item.Height, Z: item.Depth },
          { X: 0, Y: 0, Z: 0 },
          item.Material,
          0,
        );
        part2 = await this.getBox(
          { X: item.width2, Y: item.Height, Z: item.depth2 },
          { X: item.Width, Y: 0, Z: 0 },
          item.Material,
          0,
        );
      }
      part2.rotateY(-Math.PI / 2);
      let grp = new THREE.Group();
      grp.add(part1, part2);
      return grp;
    }

    private async getModelObject(
      modelPath: string,
      targetSize?: Partial<Interface_DTO_Draw.Vec3d>,
      originalSize?: Partial<Interface_DTO_Draw.Vec3d>,
    ): Promise<THREE.Object3D> {
      try {
        const result = await loadObj(modelPath, targetSize, originalSize);
        result.traverse((g) => (g.receiveShadow = true));
        return result;
      } catch (ex) {
        this.errorService.reportError(
          'modelLoadError',
          ex instanceof Error ? ex : null,
          { modelPath: modelPath },
        );
        let errorGeo = Renderer.getBoxGeometry({ X: 500, Y: 500, Z: 500 }, 0);
        let errorMat = new THREE.MeshPhongMaterial({
          color: 0x00ffff,
        });
        let errorMesh = new THREE.Mesh(errorGeo, errorMat);
        return errorMesh as THREE.Object3D;
      }
    }

    private async replaceMaterials(
      item: THREE.Object3D,
      materials: (Interface_DTO.Material | null)[] | null,
      size?: Interface_DTO_Draw.Vec3d,
    ): Promise<void> {
      let replaceMats: { [name: string]: THREE.Material } = {
        steel: this.specialMaterials.steel,
        glas: this.specialMaterials.glas,
      };
      if (materials) {
        for (let material of materials) {
          if (!material) continue;
          let threeMat = await this.getObjectMaterial(
            material,
            size ? { size: size } : undefined,
          );
          if (material.Type === Interface_Enums.MaterialType.Normal) {
            replaceMats['melmin'] = threeMat;
          } else if (material.Type === Interface_Enums.MaterialType.Frame) {
            replaceMats['steel'] = threeMat;
          } else if (material.Type === Interface_Enums.MaterialType.Grip) {
            replaceMats['handle'] = threeMat;
          }
        }
      }
      this.replaceNamedMaterials(item, replaceMats);
    }

    private replaceNamedMaterials(
      item: THREE.Object3D,
      replacements: { [name: string]: THREE.Material },
    ): void {
      for (let name in replacements) {
        let lowerName = name.toLowerCase();

        item.traverse((child) => {
          if (child.type !== 'Mesh') return;
          let mesh = child as THREE.Mesh;
          if (mesh.name.toLowerCase() === lowerName) {
            // Technically wrong, but kept for wackbard compatibility.
            // I have not confirmed it is needed, but it doesn't hurt
            mesh.material = replacements[name];
          } else if (Array.isArray(mesh.material)) {
            let materials = mesh.material as THREE.Material[];
            for (let i = 0; i < materials.length; i++) {
              const mat = materials[i];
              if (mat.name.toLowerCase() === lowerName) {
                materials[i] = replacements[name];
              }
            }
          } else if (mesh.material.name.toLowerCase() === lowerName) {
            mesh.material = replacements[name];
          }
        });
      }
    }

    private async getObjectMaterial(
      dtoMat: Interface_DTO.Material | null,
      settings?: {
        size: Interface_DTO_Draw.Vec2d;
      },
    ): Promise<THREE.Material> {
      if (dtoMat && dtoMat.TexturePath && this.useHqTextures) {
        return await this.getMaterialV2(dtoMat, settings);
      }
      let imagePath: string | null;
      if (dtoMat) {
        imagePath = dtoMat.ImagePath;
      } else {
        imagePath = null;
      }

      if (!imagePath) {
        return new THREE.MeshBasicMaterial({
          color: 0xff00ff,
        });
      }

      let mat = await this.getMaterial(imagePath);
      if (dtoMat && dtoMat.Opacity !== 100) {
        let opacity = dtoMat.Opacity / 100.0;
        mat.opacity = opacity;
        mat.transparent = true;
      }
      return mat;
    }

    private readonly materialPromises: {
      [url: string]: Promise<THREE.Material>;
    } = {};

    private getMaterial(url: string): Promise<THREE.Material> {
      let materialPromise: Promise<THREE.Material> | undefined;
      materialPromise = this.materialPromises[url];
      if (!materialPromise) {
        let texturePromise = this.getTexture(url);
        materialPromise = texturePromise
          .then(
            (texture) =>
              new THREE.MeshPhongMaterial({
                map: texture,
                shininess: 60,
              }),
          )
          .catch((e) => {
            this.errorService.reportError(
              'textureLoadError',
              e instanceof Error ? e : null,
              { url: url },
            );
            return new THREE.MeshBasicMaterial({
              color: 0x00ffff,
            });
          });
        this.materialPromises[url] = materialPromise;
      }
      return materialPromise;
    }

    private async getMaterialV2(
      dtoMat: Interface_DTO.Material,
      settings?: {
        size: Interface_DTO_Draw.Vec2d;
      },
    ): Promise<THREE.Material> {
      if (!dtoMat.TexturePath) {
        throw new Error('material does not have a materialV2 definition');
      }
      let texturePromises: Partial<
        Record<Client.MaterialPart, Promise<THREE.Texture | undefined>>
      > = {}!;
      let texturePartPaths: Record<Client.MaterialPart, string[]> = {
        map: ['map.jpg', 'map.png'],
        bumpMap: ['bumpmap.jpg', 'bumpmap.png'],
        roughnessMap: ['roughnessMap.jpg', 'roughnessMap.png'],
      };
      let repeatX = 1;
      let repeatY = 1;
      if (
        settings &&
        settings.size &&
        dtoMat.TextureWidthMm &&
        dtoMat.TextureHeightMm
      ) {
        repeatX = settings.size.X / dtoMat.TextureWidthMm;
        repeatY = settings.size.Y / dtoMat.TextureHeightMm;
      }
      for (let part in texturePartPaths) {
        let part2 = <Client.MaterialPart>part;

        let fileNames = texturePartPaths[part2];
        let pathNames = fileNames.map(
          (fn) =>
            '/StaticAssets/images/Materials2/' + dtoMat.TexturePath + '/' + fn,
        );
        let texturePromise = this.getFirstTexture(pathNames);
        texturePromises[part2] = texturePromise;
      }
      let materialPromise = this.generateMaterialV2(
        texturePromises,
        repeatX,
        repeatY,
      );
      return materialPromise;
    }

    private async getFirstTexture(
      paths: string[],
    ): Promise<THREE.Texture | undefined> {
      for (let path of paths) {
        try {
          let result = await this.getTexture(path);
          return result;
        } catch (e: any) {
          //ignore error, try next path
        }
      }
      return undefined;
    }

    private async generateMaterialV2(
      materialParts: Partial<
        Record<Client.MaterialPart, Promise<THREE.Texture | undefined>>
      >,
      repeatX: number,
      repeatY: number,
    ): Promise<THREE.Material> {
      let props: THREE.MeshStandardMaterialParameters = {};
      for (let part in materialParts) {
        let part2 = <Client.MaterialPart>part;
        let tex = await materialParts[part2];
        if (tex) {
          tex.wrapT = THREE.MirroredRepeatWrapping;
          tex.wrapS = THREE.MirroredRepeatWrapping;
          tex.repeat.set(repeatX, repeatY);
          props[part2] = tex;
        }
      }
      let result = new THREE.MeshStandardMaterial(props);
      return result;
    }

    private textureCache: { [url: string]: Promise<THREE.Texture> } = {};
    private getTexture(url: string): Promise<THREE.Texture> {
      let result = this.textureCache[url];
      if (!result) {
        result = new Promise<THREE.Texture>((resolver, rejector) => {
          let textureLoader = new THREE.TextureLoader();
          textureLoader.load(
            url,
            (texture) => resolver(texture),
            undefined, //progress is not relevant
            (error) => rejector(error),
          );
        });
        this.textureCache[url] = result;
      }
      return result.then((tex) => {
        let out = tex.clone();
        out.wrapS = THREE.MirroredRepeatWrapping;
        out.wrapT = THREE.MirroredRepeatWrapping;
        out.needsUpdate = true;
        return out;
      });
    }

    private static getBoxGeometry(
      size: Interface_DTO_Draw.Vec3d,
      edgeRadius: number,
    ): THREE.BufferGeometry {
      if (edgeRadius > 0 && App.debug.d3RoundedEdges) {
        let minSize = 5;
        let smoothness = 1;
        if (size.X > minSize && size.Y > minSize && size.Z > minSize) {
          return Renderer.createBoxWithRoundedEdges(
            size,
            edgeRadius,
            smoothness,
          );
        }
      }
      let result = new THREE.BoxGeometry(size.Z, size.Y, size.X);
      result.rotateY(Math.PI / 2);
      result.translate(size.X / 2, size.Y / 2, size.Z / 2); //set origin of object to be lower left back corner instead of middle
      return result;
    }

    private static createBoxWithRoundedEdges(
      size: Interface_DTO_Draw.Vec3d,
      radius0: number,
      smoothness: number,
    ): THREE.BufferGeometry {
      let shape = new THREE.Shape();
      let eps = 0.00001;
      let radius = radius0 - eps;
      shape.absarc(eps, eps, eps, -Math.PI / 2, -Math.PI, true);
      shape.absarc(eps, size.Y - radius * 2, eps, Math.PI, Math.PI / 2, true);
      shape.absarc(
        size.X - radius * 2,
        size.Y - radius * 2,
        eps,
        Math.PI / 2,
        0,
        true,
      );
      shape.absarc(size.X - radius * 2, eps, eps, 0, -Math.PI / 2, true);
      let geometry = new THREE.ExtrudeGeometry(shape, {
        //amount: size.Z - radius0 * 2,
        bevelEnabled: true,
        bevelSegments: smoothness * 2,
        steps: 1,
        bevelSize: radius,
        bevelThickness: radius0,
        curveSegments: smoothness,
        depth: size.Z - radius0 * 2,
      });

      geometry.center();
      geometry.translate(size.X / 2, size.Y / 2, size.Z / 2);

      return geometry;
    }

    private static getPlaneGeometry(
      size: Interface_DTO_Draw.Vec2d,
    ): THREE.PlaneGeometry {
      let result = new THREE.PlaneGeometry(size.X, size.Y);
      result.translate(size.X / 2, size.Y / 2, 0);
      return result;
    }
    // Other cabinets is used to hide all other solutions than the one selected
    private async getPartitionGroup(
      partition: Partition,
      sections: D3ViewSection[],
      profiles: Profile[],
      rails: Rail[],
    ): Promise<THREE.Group> {
      let partitionGroup = new THREE.Group();
      let modelPromises: Promise<THREE.Group>[] = [];
      for (let section of sections) {
        modelPromises.push(this.getPartitionSectionGroup(section));
      }
      for (let profile of profiles) {
        modelPromises.push(this.getPartitionProfileGroup(profile));
      }
      modelPromises.push(this.getPartitionRails(rails));
      let sectionGroup = await Promise.all(modelPromises);
      for (let section of sectionGroup) {
        partitionGroup.add(section);
      }

      partitionGroup.name = 'partitions';
      partitionGroup.updateMatrix();

      return partitionGroup;
    }

    private async getPartitionProfileGroup(profile: Profile) {
      let profileGroup = new THREE.Group();

      let section = this.partitionQueryService.getSection(
        profile.sections[0].sectionId,
      );

      let profileProduct = this.editorAssets.productsDict[profile.productId];
      let model = await this.get3DModelWithMaterial(
        profileProduct.model3dPath!,
        this.editorAssets.materialsDict[section.references.profileMaterialId],
        'profile-' + profile.id,
        profileProduct.model3dWidth!,
        profile.height,
        false,
        true,
      );
      let posY =
        profile.type == ProfileType.Niche
          ? 21.5
          : Profile.profileRailReduction / 2; //Reduced on both sides, therefore offset with half the amount to center
      model.position.set(
        profile.posX,
        posY,
        profile.posY, //Y used as Z, since 3D view has Y on vertical axis
      );
      model.rotateY(MathUtils.degToRad(-profile.rotation));

      profileGroup.add(model);

      profileGroup.name = 'profile-' + profile.id;
      profileGroup.updateMatrix();
      return profileGroup;
    }

    private async getPartitionSectionGroup(
      section: D3ViewSection,
    ): Promise<THREE.Group> {
      let sectionGroup = new THREE.Group();
      sectionGroup.position.set(
        section.posX,
        0,
        section.posY, //Y used as Z, since 3D view has Y on vertical axis
      );
      sectionGroup.rotateY(-section.rotation * (Math.PI / 180));

      let moduleGroupPromise: Promise<THREE.Group>[] = [];
      for (let wall of section.walls) {
        moduleGroupPromise.push(this.getSectionWallGroup(section, wall));
      }
      for (let door of section.doors) {
        moduleGroupPromise.push(this.getSectionDoorGroup(section, door));
      }
      let moduleGroup = await Promise.all(moduleGroupPromise);
      for (let module of moduleGroup) {
        sectionGroup.add(module);
      }

      sectionGroup.name = 'section-' + section.id;
      sectionGroup.updateMatrix();
      return sectionGroup;
    }

    private async getSectionWallGroup(
      section: D3ViewSection,
      wall: D3ViewWall,
    ): Promise<THREE.Group> {
      let moduleGroup = new THREE.Group();
      moduleGroup.position.set(
        wall.posX,
        Section.spacingFromFloorToBottomModuleFrame(ModuleType.Wall),
        wall.posY,
      );
      moduleGroup.name = 'wall-' + wall.id;

      let subModulePromise: Promise<THREE.Group>[] = [];

      subModulePromise.push(this.getModuleFillings(wall));
      subModulePromise.push(this.getModuleBars(section, wall));
      subModulePromise.push(this.getModuleProfiles(section, wall));

      let subGroups = await Promise.all(subModulePromise);
      for (let group of subGroups) {
        moduleGroup.add(group);
      }

      moduleGroup.updateMatrix();
      return moduleGroup;
    }

    private async getSectionDoorGroup(
      section: D3ViewSection,
      door: D3ViewDoor,
    ): Promise<THREE.Group> {
      let moduleGroup = new THREE.Group();
      let zOffset =
        door.placement == DoorPlacement.Front
          ? this.editorAssets.railSetsDict[section.wallRailSetId].topProduct!
              .model3dDepth!
          : -this.editorAssets.railSetsDict[section.wallRailSetId].topProduct!
              .model3dDepth!;
      moduleGroup.position.set(
        door.posX,
        Section.spacingFromFloorToBottomModuleFrame(ModuleType.Door),
        door.posY + zOffset,
      );
      moduleGroup.name = 'door-' + door.id;

      let subModulePromise: Promise<THREE.Group>[] = [];

      subModulePromise.push(this.getModuleFillings(door));
      subModulePromise.push(this.getModuleBars(section, door));
      subModulePromise.push(this.getModuleProfiles(section, door));

      // If deployed always load wheels, nad locally only load if not turned of in debug.
      if (!App.useDebug || !App.debug.noDoorWheels) {
        subModulePromise.push(this.getModuleDoorWheels(door));
      }

      let subGroups = await Promise.all(subModulePromise);
      for (let group of subGroups) {
        moduleGroup.add(group);
      }

      moduleGroup.updateMatrix();
      return moduleGroup;
    }

    private async getModuleFillings(module: D3ViewModule) {
      let moduleSubsGroup = new THREE.Group();
      moduleSubsGroup.name = 'fillings';

      if (!App.debug.noDoorFillings) {
        for (let filling of module.fillings) {
          let modelObj;
          if (false /*filling.isDesignFilling*/) {
            // modelObj = await this.getBox(
            //     { X: filling.height, Y: filling.width, Z: filling.depth },
            //     { X: filling.positionX + filling.width, Y: filling.positionY, Z: filling.positionZ },
            //     filling.material,
            //     0
            // );
            // modelObj.rotateZ(Math.PI / 2);
          } else {
            let material = this.editorAssets.materialsDict[filling.materialId];
            modelObj = await this.getBox(
              {
                X: filling.width,
                Y: filling.height,
                Z: Constants.fillingDepth,
              },
              {
                X: filling.posX,
                Y: filling.posY,
                Z: Module.fullJointWidth / 2 - Constants.fillingDepth / 2,
              },
              material,
              0,
            );
          }
          modelObj.name = 'filling-' + filling.id;
          moduleSubsGroup.add(modelObj);
        }
      }

      moduleSubsGroup.updateMatrix();
      return moduleSubsGroup;
    }

    private async getModuleBars(section: D3ViewSection, module: D3ViewModule) {
      let moduleSubsGroup = new THREE.Group();
      moduleSubsGroup.name = 'bars';

      let barData = this.getBarData(section);
      if (!barData) return moduleSubsGroup;

      let barProduct = this.editorAssets.productsDict[barData?.ProductId];

      // Horizontal Bars
      for (let bar of module.bars) {
        let model = await this.get3DModelWithMaterial(
          barProduct.model3dPath!,
          this.editorAssets.materialsDict[section.profileMaterialId],
          `module-${module.id}-bar-${bar.id}`,
          bar.width,
          0,
          true,
          false,
        );
        model.position.set(
          bar.posX,
          bar.posY,
          Module.fullJointWidth / 2 - barProduct.model3dDepth! / 2,
        );
        moduleSubsGroup.add(model);
      }

      // Vertical Bars
      let positions = this.partitionGeometryQueryService.verticalBarsPositions(
        module.id,
      );
      let index = 0;

      let doorData = this.editorAssets.productDoorData.find(
        (pdd) =>
          pdd.ProductId == this.partitionQueryService.getDoorModuleProductId(),
      );
      let wallData = this.editorAssets.productDoorData.find(
        (pdd) =>
          pdd.ProductId == this.partitionQueryService.getWallModuleProductId(),
      );

      for (let position of positions) {
        let barHeight = module.isDoor
          ? module.height -
            (doorData!.FrameWidthTop + doorData!.FrameWidthBottom)
          : module.height -
            (wallData!.FrameWidthTop + wallData!.FrameWidthBottom);

        // We strech out the x-axis, then rotate it vertical
        let model = await this.get3DModelWithMaterial(
          barProduct.model3dPath!,
          this.editorAssets.materialsDict[section.profileMaterialId],
          `module-${module.id}-vert-bar-${index}`,
          barHeight,
          0,
          true,
          false,
        );
        model.rotateZ(Math.PI / 2);
        model.position.set(
          position + barData.Height,
          module.isDoor
            ? doorData!.FrameWidthBottom
            : wallData!.FrameWidthBottom,
          (Module.fullJointWidth - barProduct.model3dDepth!) / 2,
        );

        moduleSubsGroup.add(model);

        index++;
      }

      moduleSubsGroup.updateMatrix();
      return moduleSubsGroup;
    }

    private getBarData(
      section: D3ViewSection,
    ): Interface_DTO.ProductBarData | undefined {
      let floorPlan = this.floorPlan;
      let fullCatalog =
        floorPlan.editorAssets.fullCatalog &&
        floorPlan.FullCatalogAllowOtherProducts;

      let doorData = Enumerable.from(
        floorPlan.editorAssets.productDoorData,
      ).firstOrDefault(
        (pdd) =>
          pdd.ProductId === this.partitionQueryService.getDoorModuleProductId(),
      );
      if (!doorData) return undefined;

      let availableBars = Enumerable.from(doorData.Bars).where(
        (bar) => fullCatalog || !bar.ChainOverride,
      );

      let barHeight = section.barHeight;
      let barType = Interface_Enums.BarType.Fixed; //door.forceFixedBars ? Interface_Enums.BarType.Fixed : Interface_Enums.BarType.Loose;

      // Find the ProductBarData with the right type and height
      return availableBars.firstOrDefault(
        (b) => b.BarType === barType && b.Height === barHeight,
      );
    }

    private async getModuleProfiles(
      section: D3ViewSection,
      module: D3ViewModule,
    ) {
      let product = this.editorAssets.productsDict[module.productId];
      let profileGroup = new THREE.Group();
      profileGroup.name = 'profiles';

      //Left
      let leftModel = await this.get3DModelWithMaterial(
        product.model3dPath + '_Left',
        this.editorAssets.materialsDict[section.profileMaterialId],
        'left-frame-profile',
        module.width,
        module.height,
        false,
        true,
      );
      let leftOffset = module.isDoor
        ? 0
        : -this.editorAssets.productDoorData.find(
            (pdd) =>
              pdd.ProductId ==
              this.partitionQueryService.getWallModuleProductId(),
          )!.FrameWidthLeft;
      leftModel.position.set(leftOffset, 0, 0);
      profileGroup.add(leftModel);

      //Right
      let rightModel = await this.get3DModelWithMaterial(
        product.model3dPath + '_Right',
        this.editorAssets.materialsDict[section.profileMaterialId],
        'right-frame-profile',
        module.width,
        module.height,
        false,
        true,
      );
      let rightOffset = module.isDoor
        ? this.editorAssets.productDoorData.find(
            (pdd) =>
              pdd.ProductId ==
              this.partitionQueryService.getDoorModuleProductId(),
          )!.FrameWidthRight
        : this.editorAssets.productDoorData.find(
            (pdd) =>
              pdd.ProductId ==
              this.partitionQueryService.getWallModuleProductId(),
          )!.FrameWidthRight;
      rightModel.position.set(module.width - rightOffset, 0, 0);
      profileGroup.add(rightModel);

      let topBottomFrameWidthReduction =
        Module.fillingInsertionDepth +
        (module.isDoor
          ? Door.fillingWidthReduction
          : Module.fillingWidthReduction);
      //Bottom
      let bottomModel = await this.get3DModelWithMaterial(
        product.model3dPath + '_Bottom',
        this.editorAssets.materialsDict[section.profileMaterialId],
        'bottom-frame-profile',
        module.width - topBottomFrameWidthReduction * 2,
        module.height,
        true,
        false,
      );
      let size = this.get3DModelSize(bottomModel);
      bottomModel.position.set(topBottomFrameWidthReduction, 0, 0);
      profileGroup.add(bottomModel);

      //Top
      let topModel = await this.get3DModelWithMaterial(
        product.model3dPath + '_Top',
        this.editorAssets.materialsDict[section.profileMaterialId],
        'top-frame-profile',
        module.width - topBottomFrameWidthReduction * 2,
        module.height,
        true,
        false,
      );
      topModel.position.set(
        topBottomFrameWidthReduction,
        module.isDoor
          ? module.height -
              this.editorAssets.productDoorData.find(
                (pdd) =>
                  pdd.ProductId ==
                  this.partitionQueryService.getDoorModuleProductId(),
              )!.FrameWidthTop
          : module.height -
              this.editorAssets.productDoorData.find(
                (pdd) =>
                  pdd.ProductId ==
                  this.partitionQueryService.getWallModuleProductId(),
              )!.FrameWidthTop,
        0,
      );
      //Removed this strange hack
      /*
      if (module instanceof D3ViewDoor) {
          bottomModel.position.add(new THREE.Vector3(
              0,
              3, // Gotten from partition system subtract pdf
              product.model3dDepth! / 2 - (15 / 2) // Magic number gotten from 3d model viewer
          ))

    topModel.position.add(new THREE.Vector3(
        0,
        0,
        product.model3dDepth! / 2 - (13.2 / 2) // Magic number gotten from 3d model viewer
    ))
}
    */
      profileGroup.add(topModel);

      profileGroup.updateMatrix();
      return profileGroup;
    }

    private async getModuleDoorWheels(door: D3ViewDoor): Promise<THREE.Group> {
      let doorWheelGroup = new THREE.Group();
      doorWheelGroup.name = 'door-wheels';

      let product = this.editorAssets.productsDict[door.productId];
      if (product) {
        let productData = product.getProductData();
        let model3dPath = productData ? productData.Model3DPath : null;
        if (model3dPath) {
          let wheelPath = model3dPath + '_Wheel';
          let offset: Interface_DTO_Draw.Vec3d = {
            X: 45,
            Y: 2,
            Z: 0,
          };
          let wheelSizeX = 80;
          let leftWheel = await this.getModelObject(wheelPath);
          await this.replaceMaterials(leftWheel, []);
          let rightWheel = leftWheel.clone(true);

          leftWheel.position.set(offset.X, offset.Y, offset.Z);
          rightWheel.position.set(
            door.width - offset.X - wheelSizeX,
            offset.Y,
            offset.Z,
          );

          doorWheelGroup.add(leftWheel, rightWheel);
        }
      }

      doorWheelGroup.updateMatrix();
      return doorWheelGroup;
    }

    private async getPartitionRails(rails: Rail[]): Promise<THREE.Group> {
      let railGroup = new THREE.Group();
      railGroup.name = 'partition-rails';

      let promises = rails.map(async (rail) => {
        let materialId = rail.materialId;
        let product = this.editorAssets.productsDict[rail.productId];
        let railModel = await this.get3DModelWithMaterial(
          product.model3dPath!,
          this.editorAssets.materialsDict[materialId],
          'rail',
          rail.width,
          0,
          true,
          false,
        );
        railModel.position.set(
          rail.posX,
          this.getRailPosY(rail, railModel),
          rail.posY,
        );
        railModel.rotateY(-rail.rotation * (Math.PI / 180));

        return railModel;
      });

      let models = await Promise.all(promises);
      models.forEach((model) => railGroup.add(model));

      railGroup.updateMatrix();
      return railGroup;
    }

    public getRailPosY(rail: Rail, railModel: THREE.Object3D<THREE.Event>) {
      if (rail.placement == RailPlacement.Ceiling)
        return this.floorPlan?.Size.Z! - this.get3DModelSize(railModel).y;
      else if (rail.placement == RailPlacement.Floor) return 0;

      //Will never happen, only to make error go away in VSC
      return 0;
    }

    private async get3DModelWithMaterial(
      model3DPath: string,
      material: Client.Material,
      name: string,
      width?: number,
      height?: number,
      scaleX?: boolean,
      scaleY?: boolean,
    ) {
      let obj = await this.getModelObject(model3DPath);

      let bbox = new Box3().setFromObject(obj);
      let size = bbox.getSize(new Vector3());
      if (scaleX && width) {
        let widthScale = width / size.x;
        obj.scale.setX(widthScale);
      }
      if (scaleY && height) {
        let heightScale = height / size.y;
        obj.scale.setY(heightScale);
      }

      await this.replaceMaterials(obj, [material]);

      obj.name = name;
      obj.updateMatrix();
      return obj;
    }

    private get3DModelSize(model: THREE.Object3D<THREE.Event>): THREE.Vector3 {
      let bbox = new THREE.Box3().setFromObject(model);
      return bbox.getSize(new THREE.Vector3());
    }

    private backingIsFittingPanelSpacer(
      fitting: Interface_DTO_Draw.Cube,
      section: Client.CabinetSection,
    ): Client.ConfigurationItem | undefined {
      let gableFittingPanelSpacer = Enumerable.from(
        section.interior.gables,
      ).firstOrDefault(
        (g) =>
          g.isFittingPanel &&
          (g.X === fitting.X + fitting.Width || g.X === fitting.X - g.Width) &&
          g.Y === fitting.Y,
      );

      return gableFittingPanelSpacer;
    }

    // private getlowestYcord(): number {
    //     let lowestY = Infinity;

    //     // Iterate through all the objects in the scene
    //     this.scene.traverse((object) => {
    //     // Check if the object has a position
    //     if (object instanceof THREE.Object3D && object.position) {
    //         // Update the lowestZ if the object's z-coordinate is lower than the current lowestZ
    //         if (object.position.y < lowestY) {
    //             if(object.position.y < -700){

    //             } else {
    //                 lowestY = object.position.y;
    //             }
    //         }
    //     }
    //     });
    //     console.log(lowestY);
    //     return lowestY;
    // }
  }
}
type SpotLightInfo = {
  spot: THREE.SpotLight;
  pos: Interface_DTO.Vec3d;
  targetPos: Interface_DTO.Vec3d;
  helper?: THREE.SpotLightHelper;
  castShadow: boolean;
  penumbra: number;
  decay: number;
  angle: number;
  mapWidth?: number;
  mapHeight?: number;
  shadowBias?: number;
};
