import * as Enums from 'app/ts/clientDto/Enums';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import * as Client from 'app/ts/clientDto/index';
import { SubSection } from 'app/ts/clientDto/SubSection';
import { ProductHelper } from 'app/ts/util/ProductHelper';

import * as VariantNumbers from 'app/ts/VariantNumbers';
import { Material } from 'app/ts/clientDto/Material';
import { Product } from 'app/ts/clientDto/Product';
import { CabinetSectionHelper } from 'app/ts/util/CabinetSectionHelper';
import { ChainSettingHelper } from 'app/ts/util/ChainSettingHelper';
import { ConfigurationItemHelper } from 'app/ts/util/ConfigurationItemHelper';
import { MaterialHelper } from 'app/ts/util/MaterialHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { Pickable } from 'app/ts/interfaces/Pickable';
import { FilterService } from 'app/ts/services/FilterService';
export class Corpus implements SubSection {
  // Manuel joint positions
  manualJointPositionsTop: Client.ConstrainedNumber[] = [];
  manualJointPositionsBottom: Client.ConstrainedNumber[] = [];

  useManualJointsTop: boolean = false;
  useManualJointsBottom: boolean = false;

  // Joints
  jointsTop: number[] = [];
  jointsBottom: number[] = [];
  jointsLeft: number[] = [];
  jointsRight: number[] = [];

  //corpusPanel Ids
  private _corpusPanelTopId: number = -1;
  private _corpusPanelBottomId: number = -1;
  private _corpusPanelLeftId: number = -1;
  private _corpusPanelRightId: number = -1;

  // Corpus items
  public itemsTop: Client.ConfigurationItem[] = [];
  public itemsBottom: Client.ConfigurationItem[] = [];
  public itemsLeft: Client.ConfigurationItem[] = [];
  public itemsRight: Client.ConfigurationItem[] = [];

  // Lighting items
  public lightingItems: Client.ConfigurationItem[] = [];

  // Lighting
  private _lightingMaterialId: number | null = null;
  private _numberOfLights: number = 0;

  // Dimensions
  private _heightTop: number = 0;
  private _heightBottom: number = 0;
  private _widthLeft: number = 0;
  private _widthRight: number = 0;

  //#endregion Actual values, backing variables

  //#region Availables, backing variables

  //this cache is cleared any time a property on floorPlan is changed
  private cache: {
    pickableMaterials?: Pickable<Client.Material>[];

    availablePanelsTop?: Client.CorpusPanel[];
    availablePanelsBottom?: Client.CorpusPanel[];
    availablePanelsLeft?: Client.CorpusPanel[];
    availablePanelsRight?: Client.CorpusPanel[];

    availableLightingProducts?: Product[];
    pickableLightingProducts?: Pickable<Product | null>[];
    availableLightingMaterials?: Client.Material[];
    pickableLightingMaterials?: Pickable<Client.Material | null>[];

    panelTop?: Client.CorpusPanel;
    panelBottom?: Client.CorpusPanel;
    panelLeft?: Client.CorpusPanel;
    panelRight?: Client.CorpusPanel;

    lightingProduct?: Client.Product | null;
    lightingMaterial?: Interface_DTO.Material | null;

    itemVariantsTop?: Client.ItemVariant[];
    itemVariantsBottom?: Client.ItemVariant[];
    itemVariantsLeft?: Client.ItemVariant[];
    itemVariantsRight?: Client.ItemVariant[];
  } = {};

  //#endregion Availables, backing variables

  constructor(
    public readonly cabinetSection: Client.CabinetSection,
    private dtoSection: Interface_DTO.CabinetSection,
  ) {
    this.loadFrom(dtoSection);
  }

  public loadFrom(dtoSection: Interface_DTO.CabinetSection) {
    this.dtoSection = dtoSection;

    this.clearBackingVariables();

    this._corpusPanelTopId = this.getCorpusPanel(
      Interface_Enums.CorpusPanelPosition.Top,
      dtoSection.CorpusTopItems,
    ).Id;
    this._corpusPanelBottomId = this.getCorpusPanel(
      Interface_Enums.CorpusPanelPosition.Bottom,
      dtoSection.CorpusBottomItems,
    ).Id;
    this._corpusPanelLeftId = this.getCorpusPanel(
      Interface_Enums.CorpusPanelPosition.Side,
      dtoSection.CorpusLeftItems,
    ).Id;
    this._corpusPanelRightId = this.getCorpusPanel(
      Interface_Enums.CorpusPanelPosition.Side,
      dtoSection.CorpusRightItems,
    ).Id;

    this.itemsTop = dtoSection.CorpusTopItems.map(
      (dtoItem) => new Client.ConfigurationItem(dtoItem, this.cabinetSection),
    );
    this.itemsBottom = dtoSection.CorpusBottomItems.map(
      (dtoItem) => new Client.ConfigurationItem(dtoItem, this.cabinetSection),
    );
    this.itemsLeft = dtoSection.CorpusLeftItems.map(
      (dtoItem) => new Client.ConfigurationItem(dtoItem, this.cabinetSection),
    );
    this.itemsRight = dtoSection.CorpusRightItems.map(
      (dtoItem) => new Client.ConfigurationItem(dtoItem, this.cabinetSection),
    );

    this.setPanelPropertiesFromItems(
      this.itemsTop,
      Enums.CorpusPanelPosition.Top,
    );
    this.setPanelPropertiesFromItems(
      this.itemsBottom,
      Enums.CorpusPanelPosition.Bottom,
    );
    this.setPanelPropertiesFromItems(
      this.itemsLeft,
      Enums.CorpusPanelPosition.Left,
    );
    this.setPanelPropertiesFromItems(
      this.itemsRight,
      Enums.CorpusPanelPosition.Right,
    );

    this.setLightingPropertiesFromItems(dtoSection.LightingItems);
  }

  public saveTo(dtoSection: Interface_DTO.CabinetSection) {
    dtoSection.CorpusTopItems = this.itemsTop.map((i) => i.save());
    dtoSection.CorpusBottomItems = this.itemsBottom.map((i) => i.save());
    dtoSection.CorpusLeftItems = this.itemsLeft.map((i) => i.save());
    dtoSection.CorpusRightItems = this.itemsRight.map((i) => i.save());

    dtoSection.LightingItems = this.lightingItems.map((i) => i.save());

    dtoSection.CorpusPanelIdTop = this.CorpusPanelTopId;
    dtoSection.CorpusPanelIdBottom = this.CorpusPanelBottomId;
    dtoSection.CorpusPanelIdLeft = this.CorpusPanelLeftId;
    dtoSection.CorpusPanelIdRight = this.CorpusPanelRightId;
  }

  private getCorpusPanel(
    corpusPanelPosition: Interface_Enums.CorpusPanelPosition,
    items: Interface_DTO.ConfigurationItem[],
  ): Client.CorpusPanel {
    let corpusPanels = this.editorAssets.corpusPanels.filter(
      (cp) => cp.Position === corpusPanelPosition,
    );

    //primary detection method
    let firstItem = items[0];
    if (firstItem && firstItem.ParentItemNo) {
      for (let cp of corpusPanels) {
        if (firstItem.ParentItemNo === cp.Number) {
          return cp;
        }
      }
    }

    //this method should not be nessecary, but keep it just in case
    let itemProductIds = items.map((i) => i.ProductId);
    let panel;
    for (let cp of corpusPanels) {
      if (cp.products.length !== itemProductIds.length) {
        continue;
      }
      let allItemsMatch = true;
      for (let product of cp.products) {
        if (itemProductIds.indexOf(product.Id) < 0) {
          allItemsMatch = false;
          break;
        }
      }
      if (allItemsMatch) {
        panel = cp;
        break;
      }
    }
    if (!panel) {
      panel = corpusPanels[0];
    }
    return panel;
  }

  public setMaterialVariants() {
    if (!this.material) {
      return;
    }

    for (let item of this.allCorpusItems) {
      ConfigurationItemHelper.addVariantOptionByNumbers(
        item,
        VariantNumbers.Color,
        this.material.Number,
      );
    }
  }

  private setPanelPropertiesFromItems(
    items: Client.ConfigurationItem[],
    panelPosition: Enums.CorpusPanelPosition,
  ) {
    //, allVariants: { [variantId: number]: Client.ItemVariant }
    // Find CorpusPanel
    let firstItem: Interface_DTO.ConfigurationItem;

    if (items.length > 0) {
      firstItem = items[0];
    }

    let addManueljoints = (top: boolean) => {
      if (!firstItem) {
        return;
      }

      let product = Enumerable.from(this.editorAssets.products).firstOrDefault(
        (p) => p.Id === firstItem.ProductId,
      );
      if (product) {
        let jointVariant = Enumerable.from(
          product.getVariants(this.cabinetSection.cabinet.ProductLineId),
        ).firstOrDefault((v) => v.Number === VariantNumbers.JointPosition);
        if (jointVariant) {
          let options = Enumerable.from(firstItem.VariantOptions)
            .where((vo) => vo.VariantId === jointVariant!.Id)
            .toArray();
          for (let option of options) {
            if (option) {
              (top
                ? this.manualJointPositionsTop
                : this.manualJointPositionsBottom
              ).push(new Client.ConstrainedNumber(Number(option.ActualValue)));
            }
          }
        }
      } else {
        console.error(
          'Cannot find Product matching id: ' + firstItem.ProductId,
        );
      }
    };

    let panelHeight =
      items.length > 0
        ? Math.max(...items.map((i) => i.Y + i.Height)) -
          Math.min(...items.map((i) => i.Y))
        : 0;

    switch (panelPosition) {
      case Enums.CorpusPanelPosition.Top:
        this.jointsTop = [];
        this.manualJointPositionsTop = [];

        if (this.panelTop) {
          this.heightTop = panelHeight;

          if (this.cabinetSection.ManualFishJointPositioning) {
            addManueljoints(true);
          }
        } else {
          this.heightTop = 0;
        }

        break;

      case Enums.CorpusPanelPosition.Bottom:
        this.jointsBottom = [];
        this.manualJointPositionsBottom = [];

        if (this.panelBottom) {
          this.heightBottom = panelHeight;

          if (this.cabinetSection.ManualFishJointPositioning) {
            addManueljoints(false);
          }
        } else {
          this.heightBottom = 0;
        }

        break;

      case Enums.CorpusPanelPosition.Left:
        this.widthLeft = this.panelLeft
          ? Enumerable.from(items).sum((i) => i.Width)
          : 0;
        break;

      case Enums.CorpusPanelPosition.Right:
        this.widthRight = this.panelRight
          ? Enumerable.from(items).sum((i) => i.Width)
          : 0;
        break;
    }
  }

  public get editorAssets() {
    return this.cabinetSection.editorAssets;
  }

  private setLightingPropertiesFromItems(
    items: Interface_DTO.ConfigurationItem[],
  ) {
    if (items.length > 0) {
      let item = items[0];
      this._lightingMaterialId = item.MaterialId;
      this.numberOfLights = items
        .map((i) => i.Quantity)
        .reduce((prev, curr) => prev + curr, 0);
    } else {
      this._lightingMaterialId = null;
      this.numberOfLights = 0;
    }
  }

  public clearBackingVariables() {
    this.cache = {};
  }

  public reset() {
    this.resetDeductions();
    this.resetCorpusItems();
    this.resetLighting();

    this.jointsTop = new Array<number>();
    this.jointsBottom = new Array<number>();
    this.jointsLeft = new Array<number>();
    this.jointsRight = new Array<number>();
  }

  public resetDeductions() {
    this.topDeductionLeft = 0;
    this.topDeductionRight = 0;
    this.bottomDeductionLeft = 0;
    this.bottomDeductionRight = 0;
    this.leftDeductionTop = 0;
    this.leftDeductionBottom = 0;
    this.rightDeductionTop = 0;
    this.rightDeductionBottom = 0;
  }

  public resetCorpusItems() {
    this.itemsTop = new Array<Client.ConfigurationItem>();
    this.itemsBottom = new Array<Client.ConfigurationItem>();
    this.itemsLeft = new Array<Client.ConfigurationItem>();
    this.itemsRight = new Array<Client.ConfigurationItem>();
  }

  public resetLighting() {
    this.lightingItems = new Array<Client.ConfigurationItem>();
  }

  public get allItems() {
    return this.allCorpusItems.concat(this.lightingItems);
  }

  public get allCorpusItems() {
    return this.itemsTop
      .concat(this.itemsBottom)
      .concat(this.itemsLeft)
      .concat(this.itemsRight);
  }

  //#region Actual values, public properties

  public get material(): Client.Material | null {
    if (this.cabinetSection.CorpusMaterialId === null) return null;
    return this.editorAssets.materialsDict[
      this.cabinetSection.CorpusMaterialId
    ];
  }
  public set material(value: Client.Material | null) {
    if (ObjectHelper.equals(value, this.material)) {
      return;
    }

    this.cabinetSection.cabinet.setCorpusMaterial(this.cabinetSection, value);
  }

  public get panelTop(): Client.CorpusPanel {
    if (this.cache.panelTop === undefined) {
      this.cache.panelTop =
        this.editorAssets.corpusPanelsDict[this.CorpusPanelTopId];
    }
    return this.cache.panelTop;
  }
  public set panelTop(value: Client.CorpusPanel) {
    if (value.Id === this.CorpusPanelTopId) {
      return;
    }
    this.CorpusPanelTopId = value.Id;
    this.cabinetSection.cabinet.setCorpusPanelTopId(
      this.cabinetSection,
      value.Id,
    );

    if (value.isFlexHeight) {
      this.heightTop = value.MaxSize;
    }

    if (!this.isMaterialValid()) this.setDefaultMaterial();
  }

  public get panelBottom(): Client.CorpusPanel {
    if (this.cache.panelBottom === undefined) {
      this.cache.panelBottom =
        this.editorAssets.corpusPanelsDict[this.CorpusPanelBottomId];
    }
    return this.cache.panelBottom;
  }
  public set panelBottom(value: Client.CorpusPanel) {
    if (value.Id === this.CorpusPanelBottomId) {
      return;
    }
    this.CorpusPanelBottomId = value.Id;
    this.cabinetSection.cabinet.setCorpusPanelBottomId(
      this.cabinetSection,
      value.Id,
    );

    if (value.isFlexHeight) {
      this.heightBottom = value.MaxSize;
    }

    if (!this.isMaterialValid()) this.setDefaultMaterial();
  }

  public get panelLeft(): Client.CorpusPanel {
    if (this.cache.panelLeft === undefined) {
      this.cache.panelLeft =
        this.editorAssets.corpusPanelsDict[this.CorpusPanelLeftId];
    }
    return this.cache.panelLeft;
  }
  public set panelLeft(value: Client.CorpusPanel) {
    if (value.Id === this.CorpusPanelLeftId) {
      return;
    }
    this.CorpusPanelLeftId = value.Id;

    if (value.isFlexWidth) {
      this.widthLeft = value.MaxSize;
    }

    if (!this.isMaterialValid()) this.setDefaultMaterial();
  }

  public get panelRight(): Client.CorpusPanel {
    if (this.cache.panelRight === undefined) {
      this.cache.panelRight =
        this.editorAssets.corpusPanelsDict[this.CorpusPanelRightId];
    }
    return this.cache.panelRight;
  }
  public set panelRight(value: Client.CorpusPanel) {
    if (value.Id === this.CorpusPanelRightId) {
      return;
    }
    this.CorpusPanelRightId = value.Id;

    if (value.isFlexWidth) {
      this.widthRight = value.MaxSize;
    }

    if (!this.isMaterialValid()) this.setDefaultMaterial();
  }

  public get CorpusPanelTopId(): number {
    return this._corpusPanelTopId;
  }
  public set CorpusPanelTopId(val: number) {
    this._corpusPanelTopId = val;
    this.clearBackingVariables();
  }
  public get CorpusPanelBottomId(): number {
    return this._corpusPanelBottomId;
  }
  public set CorpusPanelBottomId(val: number) {
    this._corpusPanelBottomId = val;
    this.clearBackingVariables();
  }
  public get CorpusPanelLeftId(): number {
    return this._corpusPanelLeftId;
  }
  public set CorpusPanelLeftId(val: number) {
    this._corpusPanelLeftId = val;
    this.clearBackingVariables();
  }
  public get CorpusPanelRightId(): number {
    return this._corpusPanelRightId;
  }
  public set CorpusPanelRightId(val: number) {
    this._corpusPanelRightId = val;
    this.clearBackingVariables();
  }

  public get lightingProduct(): Client.Product | null {
    if (this.cache.lightingProduct === undefined) {
      this.cache.lightingProduct =
        this.dtoSection.LightingProductId === null
          ? null
          : this.editorAssets.productsDict[this.dtoSection.LightingProductId];
    }

    return this.cache.lightingProduct;
  }
  public set lightingProduct(value: Client.Product | null) {
    if (ObjectHelper.equals(value, this.lightingProduct)) {
      return;
    }

    this.dtoSection.LightingProductId = value === null ? null : value.Id;
    this.clearBackingVariables();
  }

  public get lightingMaterial(): Interface_DTO.Material | null {
    if (this.cache.lightingMaterial === undefined) {
      this.cache.lightingMaterial =
        this._lightingMaterialId === null
          ? null
          : this.editorAssets.materialsDict[this._lightingMaterialId];
    }

    return this.cache.lightingMaterial;
  }
  public set lightingMaterial(value: Interface_DTO.Material | null) {
    if (ObjectHelper.equals(value, this.lightingMaterial)) {
      return;
    }

    this._lightingMaterialId = value ? value.Id : null;
    this.clearBackingVariables();
  }

  public get numberOfLights(): number {
    return this._numberOfLights;
  }
  public set numberOfLights(value: number) {
    if (value === this._numberOfLights) {
      return;
    }

    this._numberOfLights = value;
    this.clearBackingVariables();
  }

  public get heightTop(): number {
    return this._heightTop;
  }
  public set heightTop(value: number) {
    if (value === this._heightTop) {
      return;
    }

    this.cabinetSection.cabinet.setCorpusPanelHeightTop(
      this.cabinetSection,
      value,
    );
  }
  public setHeightTop(newHeight: number) {
    this._heightTop = newHeight;
    this.clearBackingVariables();
  }

  public get heightBottom(): number {
    return this._heightBottom;
  }
  public set heightBottom(value: number) {
    if (value === this._heightBottom) {
      return;
    }

    this.cabinetSection.cabinet.setCorpusPanelHeightBottom(
      this.cabinetSection,
      value,
    );
  }
  public setHeightBottom(newHeight: number) {
    this._heightBottom = newHeight;
    this.clearBackingVariables();
  }

  public get widthLeft(): number {
    return this._widthLeft;
  }
  public set widthLeft(value: number) {
    if (value === this._widthLeft) {
      return;
    }

    this._widthLeft = value;
    this.clearBackingVariables();
  }

  public get widthRight(): number {
    return this._widthRight;
  }
  public set widthRight(value: number) {
    if (value === this._widthRight) {
      return;
    }

    this._widthRight = value;
    this.clearBackingVariables();
  }

  public get heightReductionLeft(): boolean {
    return this.cabinetSection.HeightReductionCorpusLeft;
  }
  public set heightReductionLeft(value: boolean) {
    if (value === this.cabinetSection.HeightReductionCorpusLeft) {
      return;
    }

    this.cabinetSection.HeightReductionCorpusLeft = value;
    this.clearBackingVariables();
  }

  public get heightReductionRight(): boolean {
    return this.cabinetSection.HeightReductionCorpusRight;
  }
  public set heightReductionRight(value: boolean) {
    if (value === this.cabinetSection.HeightReductionCorpusRight) {
      return;
    }

    this.cabinetSection.HeightReductionCorpusRight = value;
    this.clearBackingVariables();
  }

  //#endregion Actual values, public properties

  //#region Availables and min/max/default values, public getters

  public get pickableMaterials(): Pickable<Client.Material>[] {
    if (this.cache.pickableMaterials === undefined) {
      let result: Pickable<Client.Material>[] = [];
      let fullCatalog =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherMaterials;

      let corpusProductIds = this.getCorpusPanelProductIds();
      let corpusProducts = corpusProductIds.map(
        (id) => this.editorAssets.productsDict[id],
      );

      let corpusPickablesEnumerable: Enumerable.IEnumerable<
        Pickable<Material>
      > = Enumerable.from(corpusProducts)
        .where((product) => !!product)
        .where((product) =>
          product.PossibleMaterialIds.some(
            (pm) => pm.IsEnabled && (fullCatalog || !pm.IsOverride),
          ),
        )
        .selectMany((product) => product.PossibleMaterialIds)
        .where((matId) => matId.IsEnabled)
        .concat(
          this.material
            ? [
                {
                  Id: this.material.Id,
                  IsEnabled: true,
                  DesignType: Interface_Enums.MaterialDesignType.Normal,
                  IsOverride: false,
                },
              ]
            : [],
        )
        .groupBy((matId) => matId.Id)
        .select((matGrp) => ({
          ...MaterialHelper.getPickable(
            this.editorAssets.materialsDict[matGrp.key()],
            undefined,
            matGrp.any((matId) => matId.IsOverride || !matId.IsEnabled),
          ),
          isSelected: this.material ? matGrp.key() === this.material.Id : false,
        }));

      if (!fullCatalog) {
        corpusPickablesEnumerable = corpusPickablesEnumerable.where(
          (pickableMat) => pickableMat.isSelected || !pickableMat.override,
        );
      }
      let corpusPickables = corpusPickablesEnumerable.toArray();

      for (let pickable of corpusPickables) {
        for (let product of this.allCorpusItems
          .map((i) => i.Product!)
          .filter((p) => !!p)) {
          if (
            product.PossibleMaterialIds.map((m) => m.Id).indexOf(
              pickable.item.Id,
            ) < 0
          ) {
            pickable.warnReason =
              this.editorAssets.translationService.translate(
                'corpus_material_not_available_for_product',
                'Material is not available for all selected corpus panels',
              );
            break;
          }
        }

        pickable.isSelected =
          !!this.material && this.material.Id === pickable.item.Id;
      }
      this.cache.pickableMaterials = corpusPickables;

      MaterialHelper.sortPickableMaterials(this.cache.pickableMaterials);
    }
    return this.cache.pickableMaterials;
  }

  public get availablePanelsTop(): Client.CorpusPanel[] {
    if (this.cache.availablePanelsTop === undefined) {
      this.cache.availablePanelsTop = FilterService.filterCorpusPanels(
        this.editorAssets,
        this.cabinetSection.cabinet.ProductLineId,
        Interface_Enums.CorpusPanelPosition.Top,
        this.cabinetSection.CabinetType,
        this.CorpusPanelTopId,
        this.editorAssets.fullCatalog &&
          this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts,
      );
    }
    return this.cache.availablePanelsTop;
  }
  public get availablePanelsBottom(): Client.CorpusPanel[] {
    if (this.cache.availablePanelsBottom === undefined) {
      this.cache.availablePanelsBottom = FilterService.filterCorpusPanels(
        this.editorAssets,
        this.cabinetSection.cabinet.ProductLineId,
        Interface_Enums.CorpusPanelPosition.Bottom,
        this.cabinetSection.CabinetType,
        this.CorpusPanelBottomId,
        this.editorAssets.fullCatalog &&
          this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts,
      );
    }
    return this.cache.availablePanelsBottom;
  }
  public get availablePanelsLeft(): Client.CorpusPanel[] {
    if (this.cache.availablePanelsLeft === undefined) {
      this.cache.availablePanelsLeft = FilterService.filterCorpusPanels(
        this.editorAssets,
        this.cabinetSection.cabinet.ProductLineId,
        Interface_Enums.CorpusPanelPosition.Side,
        this.cabinetSection.CabinetType,
        this.CorpusPanelLeftId,
        this.editorAssets.fullCatalog &&
          this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts,
      );
    }
    return this.cache.availablePanelsLeft;
  }
  public get availablePanelsRight(): Client.CorpusPanel[] {
    if (this.cache.availablePanelsRight === undefined) {
      this.cache.availablePanelsRight = FilterService.filterCorpusPanels(
        this.editorAssets,
        this.cabinetSection.cabinet.ProductLineId,
        Interface_Enums.CorpusPanelPosition.Side,
        this.cabinetSection.CabinetType,
        this.CorpusPanelRightId,
        this.editorAssets.fullCatalog &&
          this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts,
      );
    }
    return this.cache.availablePanelsRight;
  }

  public get availableLightingProducts(): Client.Product[] {
    if (!this.cache.availableLightingProducts) {
      let currentProductLineId = this.cabinetSection.cabinet.ProductLineId;
      let productIds = this.getLightingProductIds();
      this.cache.availableLightingProducts = this.editorAssets.products.filter(
        (p) =>
          productIds.some((id) => id == p.Id) &&
          p.materials.length > 0 &&
          p.ProductLineIds.some((plid) => plid === currentProductLineId),
      );
    }

    return this.cache.availableLightingProducts;
  }
  public get pickableLightingProducts(): Pickable<Client.Product | null>[] {
    if (!this.cache.pickableLightingProducts) {
      let fullCatalog =
        this.editorAssets.fullCatalog &&
        this.cabinetSection.cabinet.floorPlan.FullCatalogAllowOtherProducts;
      this.cache.pickableLightingProducts = this.availableLightingProducts
        .filter(
          (p) =>
            (p.Enabled && (!p.overrideChain || fullCatalog)) ||
            (this.lightingProduct && p.Id === this.lightingProduct.Id),
        )
        .map<Pickable<Product>>((product, index) => {
          let pickable: Pickable<Product> = {
            ...ProductHelper.getPickable(product),
            isSelected: this.lightingProduct
              ? this.lightingProduct.Id === product.Id
              : false,
            disabledReason: null,
          };

          return pickable;
        })
        .filter((pp) => pp !== undefined);

      // Insert "None" Pickable at the start of the list
      this.cache.pickableLightingProducts.unshift({
        name: this.editorAssets.translationService.translate(
          'pickable_lighting_name_none',
          'No Lighting',
        ),
        disabledReason: null,
        groupName: this.editorAssets.translationService.translate(
          'pickable_lighting_group_none',
          'No Lighting',
        ),
        isSelected: !this.lightingProduct,
        item: null,
        override: false,
      });
    }

    return this.cache.pickableLightingProducts;
  }

  public get availableLightingMaterials(): Client.Material[] {
    if (!this.cache.availableLightingMaterials) {
      if (this.lightingProduct !== null) {
        this.cache.availableLightingMaterials =
          this.lightingProduct.materials.filter(
            (m) => m.Type === Interface_Enums.MaterialType.Frame,
          );
      } else {
        this.cache.availableLightingMaterials = new Array<Client.Material>();
      }
    }

    return this.cache.availableLightingMaterials;
  }
  public get pickableLightingMaterials(): Pickable<Client.Material | null>[] {
    if (!this.cache.pickableLightingMaterials) {
      this.cache.pickableLightingMaterials =
        this.availableLightingMaterials.map<Pickable<Client.Material>>(
          (mat, index) => {
            let pickable: Pickable<Client.Material> = {
              ...MaterialHelper.getPickable(mat),
              isSelected: this.lightingMaterial
                ? this.lightingMaterial.Id === mat.Id
                : false,
            };

            return pickable;
          },
        );
    }

    return this.cache.pickableLightingMaterials;
  }

  public get numberOfLightsMin(): number {
    return 0;
  }
  public get numberOfLightsMax(): number {
    return 20;
  }

  public get minHeightTop(): number {
    if (this.panelTop === null || this.material === null) {
      return 0;
    }

    return this.panelTop.minHeight(this.material);
  }
  public get maxHeightTop(): number {
    if (this.panelTop === null || this.material === null) {
      return 0;
    }

    return this.panelTop.maxHeight(this.material);
  }
  public get defaultHeightTop(): number {
    if (this.panelTop === null || this.material === null) {
      return 0;
    }

    return this.panelTop.defaultHeight(this.material);
  }

  public get minHeightBottom(): number {
    if (this.panelBottom === null || this.material === null) {
      return 0;
    }

    return this.panelBottom.minHeight(this.material);
  }
  public get maxHeightBottom(): number {
    if (this.panelBottom === null || this.material === null) {
      return 0;
    }

    return this.panelBottom.maxHeight(this.material);
  }
  public get defaultHeightBottom(): number {
    if (this.panelBottom === null || this.material === null) {
      return 0;
    }

    return this.panelBottom.defaultHeight(this.material);
  }

  public get minWidthLeft(): number {
    if (this.panelLeft === null || this.material === null) {
      return 0;
    }

    return this.panelLeft.minWidth(this.material);
  }
  public get maxWidthLeft(): number {
    if (this.panelLeft === null || this.material === null) {
      return 0;
    }

    return this.panelLeft.maxWidth(this.material);
  }
  public get defaultWidthLeft(): number {
    if (this.panelLeft === null || this.material === null) {
      return 0;
    }

    return this.panelLeft.defaultWidth(this.material);
  }

  public get minWidthRight(): number {
    if (this.panelRight === null || this.material === null) {
      return 0;
    }

    return this.panelRight.minWidth(this.material);
  }
  public get maxWidthRight(): number {
    if (this.panelRight === null || this.material === null) {
      return 0;
    }

    return this.panelRight.maxWidth(this.material);
  }
  public get defaultWidthRight(): number {
    if (this.panelRight === null || this.material === null) {
      return 0;
    }

    return this.panelRight.defaultWidth(this.material);
  }

  public get heightReductionLeftPossible(): boolean {
    if (this.panelLeft === null) {
      return false;
    }

    return this.panelLeft.isHeightReductionPossible(
      this.cabinetSection.cabinet.ProductLineId,
      this.cabinetSection.cabinet.floorPlan.FullCatalogAllowHeightReduction,
    );
  }

  public get heightReductionRightPossible(): boolean {
    if (this.panelRight === null) {
      return false;
    }

    return this.panelRight.isHeightReductionPossible(
      this.cabinetSection.cabinet.ProductLineId,
      this.cabinetSection.cabinet.floorPlan.FullCatalogAllowHeightReduction,
    );
  }

  public get showHeightReductionOverrideWarningLeft(): boolean {
    if (this.panelLeft === null) {
      return false;
    }

    return !this.panelLeft.isHeightReductionPossible(
      this.cabinetSection.cabinet.ProductLineId,
      false,
    );
  }

  public get showHeightReductionOverrideWarningRight(): boolean {
    if (this.panelRight === null) {
      return false;
    }

    return !this.panelRight.isHeightReductionPossible(
      this.cabinetSection.cabinet.ProductLineId,
      false,
    );
  }
  //#endregion Availables and min/max/default values, public getters

  //#region Set by UI

  //#endregion Set by UI

  //#region Calculated

  topDeductionLeft: number = -1;
  topDeductionRight: number = -1;
  bottomDeductionLeft: number = -1;
  bottomDeductionRight: number = -1;
  leftDeductionTop: number = -1;
  leftDeductionBottom: number = -1;
  rightDeductionTop: number = -1;
  rightDeductionBottom: number = -1;

  public get sightHeight(): number {
    let sightHeight = this.cabinetSection.Height;

    if (this.panelTop != null) {
      sightHeight -= this.heightTop;
    }

    if (this.panelBottom != null) {
      sightHeight -= this.heightBottom;
    }

    return sightHeight;
  }
  public get sightWidth(): number {
    return CabinetSectionHelper.getSightWidth(this.cabinetSection);
  }

  public get sightRect(): Client.Rectangle {
    const rect = new Client.Rectangle();

    rect.X = this.widthLeft;
    rect.Y = this.heightBottom;
    rect.Width = this.sightWidth;
    rect.Height = this.sightHeight;

    return rect;
  }

  public get depthTop(): number {
    if (this.CorpusPanelTopId !== null && this.itemsTop.length > 0) {
      return Enumerable.from(this.itemsTop).max((ci) => ci.Depth);
    }
    return 0;
  }
  public get depthTopIncludingExtra(): number {
    if (this.CorpusPanelTopId !== null && this.itemsTop.length > 0) {
      return Enumerable.from(this.itemsTop).max((ci) => ci.depthIncludingExtra);
    }
    return 0;
  }
  public get depthBottom(): number {
    if (this.CorpusPanelBottomId !== null && this.itemsBottom.length > 0) {
      return Enumerable.from(this.itemsBottom).max((ci) => ci.Depth);
    }
    return 0;
  }
  public get depthBottomIncludingExtra(): number {
    if (this.CorpusPanelBottomId !== null && this.itemsBottom.length > 0) {
      return Enumerable.from(this.itemsBottom).max(
        (ci) => ci.depthIncludingExtra,
      );
    }
    return 0;
  }
  public get depthLeft(): number {
    if (this.CorpusPanelLeftId !== null && this.itemsLeft.length > 0) {
      return Enumerable.from(this.itemsLeft).max((ci) => ci.Depth);
    }
    return 0;
  }
  public get depthRight(): number {
    if (this.CorpusPanelRightId !== null && this.itemsRight.length > 0) {
      return Enumerable.from(this.itemsRight).max((ci) => ci.Depth);
    }
    return 0;
  }

  public get isDrilledLeft(): boolean {
    return Corpus.isDrilled(this.itemsLeft);
  }

  public get isDrilledRight(): boolean {
    return Corpus.isDrilled(this.itemsRight);
  }

  private static isDrilled(
    corpusSideItems: Client.ConfigurationItem[],
  ): boolean {
    if (corpusSideItems.length < 1) return false;

    let hasDrillingVariant = corpusSideItems.some(
      (item) =>
        !!item.Product &&
        item.Product.getVariants(
          item.cabinetSection.cabinet.ProductLineId,
        ).some((v) => v.Number === VariantNumbers.Drilling),
    );
    if (!hasDrillingVariant) {
      return corpusSideItems.some(
        (i) =>
          i.isGable &&
          !!i.Product &&
          !(
            i.Product.getProductData()!.Type &
            Interface_Enums.ProductDataType.RequiresManualDrilling
          ), //product doesn't require manual drilling
      );
    }

    return corpusSideItems.some((item) =>
      ConfigurationItemHelper.hasDrilling(item),
    );
  }

  public get isEmpty(): boolean {
    return this.allItems.length === 0;
  }

  //#endregion Calculated

  //#region Helper functions

  /**
   * Returns distinct product ids for actual and/or available corpus panels
   */
  private getCorpusPanelProductIds(): number[] {
    let allAvailableCorpusItems: Interface_DTO.CorpusPanel[] =
      new Array<Interface_DTO.CorpusPanel>();

    // Top
    if (this.panelTop.CorpusPanelProducts.length > 0) {
      allAvailableCorpusItems = allAvailableCorpusItems.concat(this.panelTop);
    } else {
      this.availablePanelsTop.forEach((cp) => {
        if (cp != null) allAvailableCorpusItems.push(cp);
      });
    }

    // Bottom
    if (this.panelBottom.CorpusPanelProducts.length > 0) {
      allAvailableCorpusItems = allAvailableCorpusItems.concat(
        this.panelBottom,
      );
    } else {
      this.availablePanelsBottom.forEach((cp) => {
        if (cp != null) allAvailableCorpusItems.push(cp);
      });
    }

    // Left
    if (this.panelLeft.CorpusPanelProducts.length > 0) {
      allAvailableCorpusItems = allAvailableCorpusItems.concat(this.panelLeft);
    } else {
      this.availablePanelsLeft.forEach((cp) => {
        if (cp != null) allAvailableCorpusItems.push(cp);
      });
    }

    // Right
    if (this.panelRight.CorpusPanelProducts.length > 0) {
      allAvailableCorpusItems = allAvailableCorpusItems.concat(this.panelRight);
    } else {
      this.availablePanelsRight.forEach((cp) => {
        if (cp != null) allAvailableCorpusItems.push(cp);
      });
    }

    let corpusPanelProducts = Enumerable.from(
      allAvailableCorpusItems,
    ).selectMany((ci) => ci.CorpusPanelProducts);

    let productIds = corpusPanelProducts
      .select((cpp) => cpp.ProductId)
      .distinct();
    return productIds.toArray();
  }

  private getLightingProductIds(): number[] {
    if (this.panelTop.LightingProducts.length > 0) {
      return Enumerable.from(this.panelTop.LightingProducts)
        .select((lp) => lp.ProductId)
        .toArray();
    }

    return [];
  }

  private isMaterialValid(): boolean {
    if (this.material === null) {
      return false;
    } else {
      let id = this.material.Id;
      return this.pickableMaterials.some(
        (pm) => pm.item !== null && pm.item.Id === id,
      );
    }
  }
  public setDefaultMaterial(this: Client.Corpus) {
    let material;

    let defaultMaterialNumber =
      ChainSettingHelper.getDefaultCorpusMaterialNumber(this.editorAssets);
    if (defaultMaterialNumber) {
      let tmp = defaultMaterialNumber;
      material = Enumerable.from(this.pickableMaterials)
        .select((pm) => pm.item)
        .firstOrDefault((pm) => pm.Number === tmp);
    }

    if (!material) {
      material = Enumerable.from(this.pickableMaterials)
        .select((pm) => pm.item)
        .firstOrDefault();
    }

    if (material === undefined) material = null;

    this.material = material;
  }

  //#endregion Helper functions
}
