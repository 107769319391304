import { Component, Input } from '@angular/core';
import * as Client from 'app/ts/clientDto/index';
import { BaseVmService } from 'app/ts/services/BaseVmService';
import { BaseVm } from 'app/ts/viewmodels/BaseVm';

@Component({
  selector: 'error-info',
  templateUrl: 'errorInfo.html',
  styleUrls: ['../../../style/errorInfo.scss'],
})
export class ErrorInfoVm extends BaseVm {
  public static readonly Name: string = 'errorInfo';

  @Input()
  public errorInfo!: Client.ErrorInfo;

  @Input('showLink')
  public showLinkAttribute = true;

  public get showLink() {
    return this.showLinkAttribute && this.errorInfo.url;
  }

  public get url(): string {
    return this.errorInfo?.url?.url ?? '';
  }

  public get fragment() {
    return this.errorInfo?.url?.fragment ?? '';
  }

  constructor(baseVmService: BaseVmService) {
    super(baseVmService);
  }
}
