import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import Enumerable from 'linq';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import { ProductHelper } from 'app/ts/util/ProductHelper';
import { ISnapInfoService } from 'app/ts/services/snap/ISnapInfoService';
import { VectorHelper } from '@Util/VectorHelper';

export abstract class BaseSnapService<T extends Client.SnapInfo>
  implements ISnapInfoService
{
  protected lastSnapInfo: T | undefined = undefined;

  constructor(
    protected readonly cabinetSection: Client.CabinetSection,
    protected readonly item: Client.ConfigurationItem,
    protected readonly dragStartPoint: Interface_DTO_Draw.Vec2d,
    private readonly fallbackSnapService: ISnapInfoService,
  ) {}

  public getSnapInfo(pos: Interface_DTO_Draw.Vec2d): Client.SnapInfo {
    this.lastSnapInfo = this.overriddenGetSnapInfo(pos);
    if (this.lastSnapInfo) return this.lastSnapInfo;
    else return this.fallbackSnapService.getSnapInfo(pos);
  }

  public place(): {
    items: [Client.ConfigurationItem];
    recalculationMessages: Client.RecalculationMessage[];
  } {
    if (!this.lastSnapInfo || !this.item.Product) {
      return this.fallbackSnapService.place();
    }

    if (App.debug.showSnapInfo) console.debug('snapped by me', this);

    this.item.X += this.lastSnapInfo.dropOffset.X;
    this.item.Y += this.lastSnapInfo.dropOffset.Y;
    this.item.Z += this.lastSnapInfo.dropOffset.Z;

    if (this.item.cabinetSection !== this.cabinetSection) {
      let oldIndex = this.item.cabinetSection.interior.items.indexOf(this.item);
      if (oldIndex >= 0) {
        this.item.cabinetSection.interior.items.splice(oldIndex, 1);
      }
    }
    if (this.cabinetSection.interior.items.indexOf(this.item) < 0) {
      this.cabinetSection.interior.items.push(this.item);
    }

    if (this.lastSnapInfo.newSize) {
      if (this.lastSnapInfo.newSize.X) {
        let newWidth = this.lastSnapInfo.newSize.X;
        if (newWidth && newWidth !== this.item.Width) {
          let newProduct = Enumerable.from(this.item.Product.productGroup)
            .where(
              (prod) =>
                ProductHelper.minWidth(prod) <= newWidth &&
                ProductHelper.maxWidth(prod) >= newWidth,
            )
            .orderBy((prod) => (prod.Enabled && !prod.OverrideChain ? 0 : 1)) // prefer products that are enabled and not overrideChain
            .thenBy((prod) => prod.SortOrder) // prefer products with lower sortOrder
            .thenBy((prod) =>
              ProductHelper.defaultWidth(prod) === newWidth ? 0 : 1,
            ) // prefer products with correct defaultWidth
            .firstOrDefault();

          if (newProduct) {
            this.item.Product = newProduct;
            this.item.Width = newWidth;
            this.item.trySetWidth(newWidth);
          } else {
            console.error(
              'could not find a suitable product for width ' + newWidth,
              this.item,
            );
          }
        }
      }

      if (!this.item.IsLocked) {
        if (this.lastSnapInfo.newSize.Y) {
          this.item.Height = this.lastSnapInfo.newSize.Y;
        }
        if (this.lastSnapInfo.newSize.Z) {
          this.item.Depth = this.lastSnapInfo.newSize.Z;
        }
      }
    }

    if (this.cabinetSection.isSwingFlex) {
      const area = this.cabinetSection.swingFlex.areas.find((a) =>
        a.insideRect.isPointInside({
          X: this.item.centerX,
          Y: this.item.centerY,
        }),
      );
      if (!!area) {
        this.item.swingFlexAreaIndex = area.index;
      }
    }

    return {
      items: this.overriddenPlace(this.lastSnapInfo),
      recalculationMessages: [],
    };
  }

  protected abstract overriddenGetSnapInfo(
    pos: Interface_DTO_Draw.Vec2d,
  ): T | undefined;
  protected abstract overriddenPlace(
    lastSnapInfo: T,
  ): [Client.ConfigurationItem];

  public dispose() {}
}
