import * as Enums from 'app/ts/clientDto/Enums';
import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import * as Interface_DTO_FloorPlan from 'app/ts/Interface_DTO_FloorPlan';
import * as Interface_DTO_FloorPlan_CopyRequest from 'app/ts/Interface_DTO_FloorPlanCopyRequest';
import * as Interface_DTO_FloorPlan_MoveRequest from 'app/ts/Interface_DTO_FloorPlanMoveRequest';
import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Constants from 'app/ts/InterfaceConstants';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import Enumerable from 'linq';
import { Constants } from 'app/ts/Constants';
import * as Client from 'app/ts/clientDto/index';
import * as App from 'app/ts/app';
import * as Exception from 'app/ts/exceptions';
import { AssetService } from 'app/ts/services/AssetService';
import { CustomerService } from 'app/ts/services/CustomerService';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { DateHelper } from 'app/ts/util/DateHelper';
import { FloorPlanHelper } from 'app/ts/util/FloorPlanHelper';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { UndoStack } from 'app/ts/util/UndoStack';
import { FloorplanValidationService } from 'app/ts/services/Validation/FloorplanValidationService';
import { CabinetService } from 'app/ts/services/CabinetService';
import { ErrorService } from 'app/ts/services/ErrorService';
import { NotificationService } from 'app/ts/services/NotificationService';
import { TranslationService } from 'app/ts/services/TranslationService';
import { Inject, Injectable } from '@angular/core';
import {
  ActiveStore,
  ActiveStoreInjector,
} from 'app/functional-core/ambient/stores/ActiveStore';
import {
  ActiveUser,
  ActiveUserInjector,
} from 'app/functional-core/ambient/activeUser/ActiveUser';
import { OpenFloorPlanWarningNotAccepted } from 'app/ts/exceptions';
import { PermissionService } from 'app/identity-and-access/permission.service';
import {
  UseFullCatalog,
  UseFullCatalogInjector,
} from 'app/functional-core/ambient/clientSetting/UseFullCatalog';
import { Store } from 'app/ts/Interface_DTO';
import { StoreService } from './StoreService';
import { User } from 'app/identity-and-access/User';
import { BackendService } from 'app/backend-service/backend-service';
import {
  Observable,
  debounceTime,
  switchMap,
  map,
  shareReplay,
  retry,
  combineLatest,
} from 'rxjs';
import { NavigateService } from 'app/routing/navigate-service';
import { PartitionPlanQueryService } from 'app/partition/partition-plan-query.service';
import { PartitionPlanCommandService } from 'app/partition/partition-plan-command.service';
import { MeasurementService } from 'app/measurement/measurement.service';
import { PartitionCabinetService } from 'app/partition/partition-cabinet.service';
import { PartitionPlanGeometryQueryService } from 'app/partition/partition-plan-geometry-query.service';
import { PartitionPlanDataService } from 'app/partition/partition-plan-data.service';
import { PartitionValidationCalculationService } from 'app/partition/partition-validation-calculation.service';
import {
  CustomPriceDateInjector,
  CustomPriceDate,
} from 'app/functional-core/ambient/clientSetting/CustomPriceDate';
import { FunctionalCoreService } from 'app/functional-core/functional-core.service';

@Injectable({ providedIn: 'root' })
export class FloorPlanService {
  private floorPlanCache:
    | {
        id: number;
        promise: Promise<Client.FloorPlan>;
      }
    | undefined = undefined;

  private oldCabinetCache:
    | {
        id: number;
        promise: Promise<Client.FloorPlan>;
      }
    | undefined = undefined;

  private readonly undoStack = new UndoStack<Interface_DTO.FloorPlan>();
  private get useDebug() {
    return App.useDebug && false;
  }

  private store!: Store;

  private user!: User;

  constructor(
    private readonly httpClient: BackendService,
    private readonly $http: PromisingBackendService,
    private readonly assetService: AssetService,
    private readonly customerService: CustomerService,
    private readonly translationService: TranslationService,
    private readonly cabinetService: CabinetService,
    private readonly notificationService: NotificationService,
    private readonly permissions: PermissionService,
    private readonly errorService: ErrorService,
    private readonly navigate: NavigateService,
    private readonly stores: StoreService,
    private readonly partitionQueryService: PartitionPlanQueryService,
    private readonly partitionValidationCalculationService: PartitionValidationCalculationService,
    private readonly partitionDataService: PartitionPlanDataService,
    private readonly partitionCabinets: PartitionCabinetService,
    //Provide injector for floorPlanValidationService....
    //private readonly floorPlanValidationService: FloorplanValidationService,

    private readonly measurementService: MeasurementService,
    @Inject(ActiveStoreInjector) private readonly activeStore: ActiveStore,
    @Inject(UseFullCatalogInjector)
    private readonly useFullCatalog: UseFullCatalog,
    @Inject(ActiveUserInjector) activeUser: ActiveUser,
    private functionalCore: FunctionalCoreService,
  ) {
    assetService.editorAssets$.subscribe((editorAssets) =>
      this.updateCacheEditorAssets(editorAssets),
    );

    activeStore.subscribe((store) => {
      this.store = store;
    });

    activeUser.subscribe((user) => {
      if (user != null) this.user = user;
    });

    this.updateVersion1Autosaves();
  }

  private async updateCacheEditorAssets(editorAssets: Client.EditorAssets) {
    if (!editorAssets) return;
    for (let cache of [this.floorPlanCache, this.oldCabinetCache]) {
      if (!cache) continue;
      let floorplan = await cache.promise;
      floorplan.editorAssets = editorAssets;
      this.recalculate(floorplan, false);
      floorplan.triggerUpdate();
    }
  }

  /** Loads a floorplan from both old and new ids */
  public async loadFloorplan(
    floorplanId: string,
  ): Promise<Client.FloorPlan | null> {
    try {
      let floorPlanPromise;

      let oldIdMatch = Constants.editor.oldFloorplanIdRegex.exec(floorplanId);
      if (oldIdMatch) {
        floorPlanPromise = this.getFloorPlanFromCabinetId(
          parseInt(oldIdMatch[1]),
        );
      } else {
        let id = parseInt(floorplanId);
        floorPlanPromise = this.getFloorPlan(id, true);
      }
      this.notificationService.loadPromise(floorPlanPromise);

      let floorPlan = await floorPlanPromise;
      floorPlan.isDirty = false;

      return floorPlan;
    } catch (e: any) {
      if (e instanceof OpenFloorPlanWarningNotAccepted) {
        //nothing
        console.debug('floorplan open rejected');
        this.navigate.floorPlans({
          selected: [floorplanId],
        });
        return null;
      } else if (
        e instanceof Exception.InsufficientRights &&
        e.userRight === Interface_Enums.UserRight.UseFullCatalog
      ) {
        this.notificationService.userError(
          'floor_plan_load_full_catalog_not_allowed',
          "This solution contains items that are not part of the current store's standard catalog. You do not have permission to change this type of solution.",
        );
      } else {
        this.notificationService.exception(
          'floor_plan_load_error',
          e,
          'Could not load floor plan',
        );
      }
      this.navigate.floorPlans({
        selected: [floorplanId],
      });
      throw e;
    }
  }

  public async getFloorPlanFromCabinetId(
    id: number,
  ): Promise<Client.FloorPlan> {
    if (this.oldCabinetCache) {
      if (this.oldCabinetCache.id === id) {
        return await this.oldCabinetCache.promise;
      } else {
        this.clearFloorPlanCache();
      }
    }
    this.oldCabinetCache = {
      id: id,
      promise: this.createFromOldCabinet(id),
    };
    return await this.oldCabinetCache.promise;
  }

  private _currentConsumerFloorPlan: Client.FloorPlan | undefined = undefined;
  public async getCurrentConsumerFloorPlan(): Promise<Client.FloorPlan> {
    if (!this._currentConsumerFloorPlan) {
      let result = await this.createConsumerFloorPlan();
      this._currentConsumerFloorPlan = result;
    }
    return this._currentConsumerFloorPlan;
  }

  public async loadConsumerFloorPlan(floorPlanId: number) {
    this._currentConsumerFloorPlan = await this.getFloorPlan(floorPlanId);
    this._currentConsumerFloorPlan.hasSeenConsumerDoorModal = true;
  }

  public addToCache(id: number, floorplan: Promise<Client.FloorPlan>) {
    this.floorPlanCache = {
      id: id,
      promise: floorplan,
    };
  }

  public async getFloorPlan(
    id: number,
    addToUndoStack: boolean = false,
  ): Promise<Client.FloorPlan> {
    if (typeof id !== 'number' || isNaN(id))
      throw new Error("floorplan is not a number: '" + id);

    let resultPromise: Promise<Client.FloorPlan> | null;
    let result: Client.FloorPlan | null = null;
    if (this.floorPlanCache) {
      if (this.floorPlanCache.id === id) {
        resultPromise = this.floorPlanCache.promise;
      } else {
        this.clearFloorPlanCache();
        resultPromise = null;
      }
    } else {
      resultPromise = null;
    }
    if (resultPromise) {
      try {
        result = await resultPromise;
      } catch (e: any) {
        //the error is an old one that has been reported before. Retry download.
        resultPromise = null;
      }
    }
    if (!resultPromise) {
      resultPromise = this.downloadFloorPlan(id);

      this.floorPlanCache = {
        id: id,
        promise: resultPromise,
      };
      result = await resultPromise;
      let recalulationMessages = this.recalculate(result, true);
      this.ruleSetUpgrade(result);
      this.notificationService.displayRecalulationMessages(
        recalulationMessages,
      );
      result.isDirty = false;

      //only wanted the first time it gets the floorplan
      // if (addToUndoStack) {
      //     this.addToUndoStack(result);
      // }
    }

    if (!result) throw new Error('Unknown error trying to download floorPlan');

    return result;
  }

  private clearFloorPlanCache() {
    if (this.floorPlanCache) {
      this.floorPlanCache.promise.then((fp) => fp.dispose());
      this.floorPlanCache = undefined;
    }
    if (this.oldCabinetCache) {
      this.oldCabinetCache.promise.then((fp) => fp.dispose());
      this.oldCabinetCache = undefined;
    }
  }

  private async createConsumerFloorPlan(): Promise<Client.FloorPlan> {
    await this.assetService.loadEditorAssets();
    let editorAssets = await this.assetService.editorAssetsPromise();
    let fp = await this.getNewFloorPlan('', null, editorAssets, undefined);
    fp.Id = null;
    fp.CreatedDate = DateHelper.toIsoString(new Date(Date.now()));

    let cabinet = this.cabinetService.createCabinet(
      fp,
      {
        CabinetIndex: null,
        CabinetType: Interface_Enums.CabinetType.SingleCabinet,
        FlipDirection: false,
        FlipSide: false,
        Height: fp.Size.Y,
        ItemType: Interface_DTO_FloorPlan.ItemType.SingleCabinet,
        Width: 3000,
        X: 0,
        Y: 0,
      },
      Interface_Enums.ProductLineId.mm19ReferencePlus, //UNSILVANIZE
    );
    cabinet.UseRoof = false;
    cabinet.UnevenFloor = false;
    cabinet.UnevenLeftWall = false;
    cabinet.UnevenRightWall = false;
    this.insertEmptyFloorPlanItem(fp, cabinet);
    this.cabinetService.setAlignment(
      cabinet,
      Interface_Enums.CabinetAlignment.FreeStanding,
    );
    this.setChanged(fp);

    let recalulationMessages = this.recalculate(fp, true);
    this.ruleSetUpgrade(fp);
    this.addToUndoStack(fp);
    this.notificationService.displayRecalulationMessages(recalulationMessages);
    fp.isDirty = false;

    return fp;
  }

  public async downloadFloorPlan(id: number): Promise<Client.FloorPlan> {
    let cpfPromise = this.$http.get<Interface_DTO.FloorPlan>(
      'api/floorPlan/' + id,
    );

    let completeFloorPlan = await cpfPromise;

    let deliveryInfo = await this.getDeliveryInfo(
      completeFloorPlan.DeliveryInfoId,
    );
    if (App.debug.askForFloorPlanJSON) {
      let floorPlanJson = window.prompt(
        'Enter FloorPlan JSON for debugging\nCancel to load normally\nUse App.debug.askForFloorPlanJSON to disable this dialog',
      );
      if (floorPlanJson) {
        let otherCompleteFloorPlan: Interface_DTO.FloorPlan =
          JSON.parse(floorPlanJson);
        let keepProps: (keyof Interface_DTO.FloorPlan)[] = [
          'Id',
          'DeliveryInfoId',
          'DeliveryStatus',
          'CreatedDate',
          'InternalNote',
          'ProjectDeliveryAddressId',
          'StoreId',
          'SupporterId',
          'UserId',
        ];
        for (let prop of keepProps) {
          (otherCompleteFloorPlan[prop] as any) = completeFloorPlan[prop];
        }
        completeFloorPlan = otherCompleteFloorPlan;
      }
    }

    if (App.debug.outputFloorPlanJSONOnLoad) {
      console.log(JSON.stringify(completeFloorPlan));
    }

    this.checkBlockedDeliveryStatus(completeFloorPlan.DeliveryStatus);
    this.checkFullCatalog(completeFloorPlan.UsesFullCatalog);

    let editorAssets = await this.getEditorAssets(completeFloorPlan.StoreId);
    if (completeFloorPlan.UsesFullCatalog && !editorAssets.fullCatalog) {
      throw new Error('could not activate full catalog');
    }

    if (completeFloorPlan.StoreId !== editorAssets.storeId) {
      throw new Error('could not change store?');
    }

    let clientFloorPlan = this.createFloorPlanFromDTO(
      completeFloorPlan,
      deliveryInfo,
      editorAssets,
    );

    return clientFloorPlan;
  }

  private async getDeliveryInfo(
    deliveryInfoId: number,
  ): Promise<Interface_DTO.DeliveryInfo> {
    return this.$http.get<Interface_DTO.DeliveryInfo>(
      'api/deliveryInfo/' + deliveryInfoId,
    );
  }

  private checkBlockedDeliveryStatus(
    deliveryStatus: Interface_Enums.DeliveryStatus,
  ): void {
    if (deliveryStatus === Interface_Enums.DeliveryStatus.Blocked) {
      let question = this.translationService.translate(
        'floorplan_load_overridden_floorplan',
        'This solution has been changed by KA Customer Service. These changes will be abandoned if you change the solution. Do you want to continue?',
      );
      let answer = window.confirm(question);
      if (!answer) {
        throw new Exception.OpenFloorPlanWarningNotAccepted();
      }
    }
  }

  private checkFullCatalog(usesFullCatalog: boolean): void {
    if (!usesFullCatalog) {
      return;
    }
    if (!this.permissions.canUseFullCatalog) {
      throw new Exception.InsufficientRights(
        Interface_Enums.UserRight.UseFullCatalog,
      );
    }
    if (!this.useFullCatalog.data) {
      let question = this.translationService.translate(
        'floorplan_load_fullCatalog_not_active',
        "This solution uses items which are not part of the store's standard catalog. Do you want to activate full catalog?",
      );
      let answer = window.confirm(question);
      if (!answer) {
        throw new Exception.OpenFloorPlanWarningNotAccepted();
      }
      this.useFullCatalog.set(true);
    }
  }

  private async getEditorAssets(storeId: number): Promise<Client.EditorAssets> {
    const assets = await this.assetService.editorAssetsPromise();
    if (storeId !== this.store.Id || assets.storeId != storeId) {
      if (storeId != this.store.Id) {
        const newActiveStore = await this.stores.loadStoreById(storeId);
        this.activeStore.set(newActiveStore);
        await this.assetService.loadEditorAssets();
      }
    }
    return this.assetService.editorAssetsPromise();
  }

  private createFloorPlanFromDTO(
    completeFloorPlan: Interface_DTO.FloorPlan,
    deliveryInfo: Interface_DTO.DeliveryInfo | undefined,
    editorAssets: Client.EditorAssets,
  ): Client.FloorPlan {
    const floorPlan = new Client.FloorPlan(
      completeFloorPlan,
      editorAssets,
      (floorPlan) => this.downloadPieceList$(floorPlan),
      {
        data: this.partitionDataService,
        query: this.partitionQueryService,
        validationCalculation: this.partitionValidationCalculationService,
      },
    );
    const cabinets = completeFloorPlan.Cabinets.filter(
      (cabinet) =>
        cabinet.CabinetType != Interface_Enums.CabinetType.Partition &&
        cabinet.CabinetType != Interface_Enums.CabinetType.PartitionTemp,
    );

    for (let cabinet of cabinets) {
      let clientCabinet = new Client.Cabinet(floorPlan, cabinet);
      floorPlan.cabinets.push(clientCabinet);
    }
    floorPlan.deliveryInfo = deliveryInfo;
    floorPlan.finishInitialization();
    return floorPlan;
  }

  /**
   * Creates an observable that retrieves an item list for the floor plan.
   * @param floorPlan
   * The floor plan for which to retrieve an item list.
   */
  private downloadPieceList$(
    floorPlan: Client.FloorPlan,
  ): Observable<Interface_DTO.PieceList> {
    return combineLatest([
      floorPlan.floorPlan$,
      this.functionalCore.ambient.clientSetting.customPriceDate$,
    ]).pipe(
      debounceTime(1000),
      switchMap(([floorPlan, date], index) => {
        let dtoFloorPlan = this.getCompleteFloorPlan(floorPlan);
        dtoFloorPlan.UsesFullCatalog = this.useFullCatalog.data;
        return this.httpClient.post<Interface_DTO.PieceList>(
          'api/pieceList',
          dtoFloorPlan,
          { timeout: 60000 },
        );
      }),
      shareReplay(1), // https://stackoverflow.com/a/56237293/48779
      map((r) => r.body as Interface_DTO.PieceList),
      retry({ delay: 3000, resetOnSuccess: true }),
    );

    // return floorPlan
    //     .floorPlan$
    //     .pipe(
    //         debounceTime(1000),
    //         switchMap((floorPlan, index) => {
    //             console.log('PIECELIST');
    //             let dtoFloorPlan = this.getCompleteFloorPlan(floorPlan);
    //             dtoFloorPlan.UsesFullCatalog = this.useFullCatalog.data;
    //             return this.httpClient.post<Interface_DTO.PieceList>("api/pieceList", dtoFloorPlan, { timeout: 60000 })
    //         }),
    //         shareReplay(1), // https://stackoverflow.com/a/56237293/48779
    //         map(r => r.body as Interface_DTO.PieceList),
    //         retry({ delay: 3000, resetOnSuccess: true })
    //     )
  }

  public async getNewFloorPlan(
    name: string,
    projectDeliveryAddressId: number | null,
    editorAssets: Client.EditorAssets,
    template?: Interface_DTO.FloorPlanTemplate,
  ): Promise<Client.FloorPlan> {
    let sfp = template
      ? await this.getFloorPlanFromTemplate(template)
      : FloorPlanHelper.createStandardFloorPlan();
    sfp.Name = ObjectHelper.truncateString(
      name,
      Constants.maxfloorPlanNameLength,
    );
    sfp.ProjectDeliveryAddressId = projectDeliveryAddressId;
    sfp.UserId = this.getUserId();
    sfp.UsesFullCatalog = editorAssets.fullCatalog;
    const result = this.createFloorPlanFromDTO(sfp, undefined, editorAssets);
    result.CreatedDate = DateHelper.toIsoString(new Date(Date.now()));
    this.getOrCreateSharedCabinet(result);
    this.recalculate(result, true);

    return result;
  }
  private async getFloorPlanFromTemplate(
    template: Interface_DTO.FloorPlanTemplate,
  ): Promise<Interface_DTO.FloorPlan> {
    let tfp = await this.$http.get<Interface_DTO.FloorPlan>(
      'api/floorPlanTemplate/' + template.Id,
    );
    tfp.CreatedDate = DateHelper.toIsoString(new Date());
    tfp.CorrectionDeadline = DateHelper.toIsoString(
      new Date(2099, 11, 24, 13, 33, 37),
    );
    tfp.DeliveryStatus = Interface_Enums.DeliveryStatus.Quote;
    tfp.InternalNote = '';
    tfp.Id = null;
    tfp.DeliveryInfoId = 0;
    tfp.StoreId = -1;
    tfp.SupporterId = null;
    tfp.MaxErrorLevel = 0;

    return tfp;
  }

  private getUserId(): number {
    return this.user ? this.user.UserId : -1;
  }

  public insertCabinetIntoFloorPlan(
    clientFloorPlan: Client.FloorPlan,
    oldCabinet: Interface_DTO.Cabinet,
  ): Client.Cabinet {
    let dtoCabinet = ObjectHelper.copy(oldCabinet);
    let cabinetIndex =
      Math.max(
        0,
        ...clientFloorPlan.actualCabinets.map((cab) => cab.CabinetIndex),
      ) + 1;
    dtoCabinet.CabinetIndex = cabinetIndex;
    let sectionIndex = 1;
    for (let section of dtoCabinet.CabinetSections) {
      section.CabinetIndex = cabinetIndex;
      section.CabinetSectionIndex = sectionIndex;

      let itemIndex = 1;
      for (let item of section.AddedByServiceItems.concat(
        section.AddedByServiceItems,
      )
        .concat(section.BackingItems)
        .concat(section.CorpusBottomItems)
        .concat(section.CorpusLeftItems)
        .concat(section.CorpusRightItems)
        .concat(section.CorpusTopItems)
        .concat(section.CustomItems)
        .concat(section.DoorItems)
        .concat(section.InteriorItems)
        .concat(section.LightingItems)
        .concat(section.ManuallyAddedItems)
        .concat(section.RailItems)) {
        item.CabinetIndex = 1;
        item.CabinetSectionIndex = sectionIndex;
        item.ConfigurationItemIndex = itemIndex;
        itemIndex++;
      }

      sectionIndex++;
    }

    clientFloorPlan.TopWall.Items.push({
      CabinetIndex: cabinetIndex,
      CabinetType: dtoCabinet.CabinetType,
      Height: dtoCabinet.CabinetSections[0].Height,
      FlipDirection: false,
      FlipSide: false,
      Width: dtoCabinet.CabinetSections[0].Width,
      X: 0,
      Y: 0,
      ItemType: Interface_DTO_FloorPlan.ItemType.SingleCabinet,
    });
    let clientCabinet = new Client.Cabinet(clientFloorPlan, dtoCabinet);
    clientFloorPlan.cabinets.push(clientCabinet);
    let recalcMessages = this.recalculate(clientFloorPlan, true);
    this.ruleSetUpgrade(clientFloorPlan);
    this.notificationService.displayRecalulationMessages(recalcMessages);
    return clientCabinet;
  }

  public async createFromOldCabinet(
    oldCabinetId: number,
  ): Promise<Client.FloorPlan> {
    var oldCabinet = await this.$http.get<Interface_DTO.OldCabinet>(
      'api/cabinet/' + oldCabinetId,
    );
    let cabinet = oldCabinet.Cabinet;

    this.checkBlockedDeliveryStatus(oldCabinet.DeliveryInfo.CurrentStatus);
    this.checkFullCatalog(oldCabinet.Cabinet.OverrideChain);

    let sfp = FloorPlanHelper.createStandardFloorPlan();
    sfp.Name = cabinet.Name;
    sfp.UserId = cabinet.UserId;
    sfp.SupporterId =
      cabinet.SupporterId && cabinet.SupporterId > 0
        ? cabinet.SupporterId
        : null;
    sfp.DeliveryStatus = oldCabinet.DeliveryInfo.CurrentStatus;
    sfp.CorrectionDeadline = oldCabinet.DeliveryInfo.CorrectionDeadlineDate;

    const editorAssets = await this.assetService.editorAssets;
    let clientFloorPlan = new Client.FloorPlan(
      sfp,
      editorAssets,
      (floorPlan) => this.downloadPieceList$(floorPlan),
      {
        data: this.partitionDataService,
        query: this.partitionQueryService,
        validationCalculation: this.partitionValidationCalculationService,
      },
    );

    clientFloorPlan.oldCabinetId = oldCabinetId;
    this.insertCabinetIntoFloorPlan(clientFloorPlan, cabinet);
    this.getOrCreateSharedCabinet(clientFloorPlan);
    clientFloorPlan.isDirty = false;

    this.customerService.updateCustomerList(false);
    return clientFloorPlan;
  }

  public async copyFloorPlans(
    floorPlanIds: Interface_DTO_FloorPlan_CopyRequest.FloorPlanId[],
    projectDeliveryAddressIds: number[],
    numCopies: number,
  ) {
    let request: Interface_DTO.FloorPlanCopyRequest = {
      FloorPlanIds: floorPlanIds,
      TargetProjectDeliveryAddressIds: projectDeliveryAddressIds,
      NumCopies: numCopies,
    };
    await this.$http.post<void>('api/floorPlan/copy', request, {
      responseType: 'void',
    });
    this.customerService.updateCustomerList(true);
  }

  public async moveFloorPlans(
    floorPlanIds: Interface_DTO_FloorPlan_MoveRequest.FloorPlanId[],
    projectDeliveryAddressId: number,
    confirmOrderChangeFunc: (
      deliveryInfo: Interface_DTO.DeliveryInfo,
    ) => Promise<boolean>,
  ): Promise<boolean> {
    let request: Interface_DTO.FloorPlanMoveRequest = {
      FloorPlanIds: floorPlanIds,
      ProjectDeliveryAddressId: projectDeliveryAddressId,
    };
    let deliveryInfo = await this.$http.post<Interface_DTO.DeliveryInfo | null>(
      'api/floorPlan/validateMove',
      request,
      { responseType: 'void' },
    );
    if (deliveryInfo) {
      let userIsSure = await confirmOrderChangeFunc(deliveryInfo);
      if (!userIsSure) return false;
    }
    await this.$http.post<void>('api/floorPlan/move', request, {
      responseType: 'void',
    });
    this.customerService.updateCustomerList(true);
    return true;
  }

  public getCompleteFloorPlan(
    floorPlan: Client.FloorPlan,
    includePartitionCabinets: boolean = true,
  ): Interface_DTO.FloorPlan {
    const basicFloorPlan = floorPlan.save();

    floorPlan.cabinets = this.removePartitionCabinet(floorPlan.cabinets);
    const floorPlanCabinets = floorPlan.cabinets.map((cabinet) =>
      cabinet.getDtoObject(),
    );

    const partitionCabinets = includePartitionCabinets
      ? this.partitionCabinets.getPartitionCabinets(
          floorPlan,
          this.partitionQueryService.plan,
        )
      : null;

    const allCabinets =
      partitionCabinets == null
        ? floorPlanCabinets
        : [...floorPlanCabinets, ...partitionCabinets];

    const completeFloorPlan: Interface_DTO.FloorPlan = {
      ...basicFloorPlan,
      Cabinets: allCabinets,
      UserId: basicFloorPlan.UserId,
      PartitionJsonData: JSON.stringify(
        this.partitionQueryService.getPlanDataForSave(),
      ),
      MeasurementJsonData: JSON.stringify(
        this.measurementService.getDataForSave(),
      ),
    };
    return completeFloorPlan;
  }

  private removePartitionCabinet(cabinets: Client.Cabinet[]): Client.Cabinet[] {
    return cabinets.filter(
      (cabinet) => cabinet.CabinetType != Interface_Enums.CabinetType.Partition,
    );
  }

  public deleteCabinet(cabinet: Client.Cabinet) {
    let cabIdx = cabinet.floorPlan.cabinets.indexOf(cabinet);
    if (cabIdx > -1) {
      cabinet.floorPlan.cabinets.splice(cabIdx, 1);
    } else {
      console.error("tried to delete a cabinet that doesn't exist");
    }
    for (let wall of cabinet.floorPlan.walls) {
      for (let itemIndex = wall.Items.length - 1; itemIndex >= 0; itemIndex--) {
        let item = wall.Items[itemIndex];
        if (item.CabinetIndex === cabinet.CabinetIndex) {
          wall.Items.splice(itemIndex, 1);
        }
      }
    }
  }

  public async deleteFloorPlans(floorPlanIds: number[]) {
    if (floorPlanIds.length === 0) return;

    await this.$http.post<void>('api/floorplan/delete', floorPlanIds, {
      responseType: 'void',
    });
  }

  public async deleteOldCabinet(cabinetId: number) {
    await this.$http.delete('api/floorplan/old/' + cabinetId);
  }

  public deleteItem(
    floorPlan: Client.FloorPlan,
    itemToDelete: Interface_DTO_FloorPlan.Item,
  ) {
    let selectedItemWall: Interface_DTO_FloorPlan.WallSection | undefined =
      undefined;
    for (let wall of floorPlan.walls) {
      for (let item of wall.Items) {
        if (item === itemToDelete) {
          selectedItemWall = wall;
          break;
        }
      }
      if (selectedItemWall) break;
    }

    if (!selectedItemWall)
      throw new Error(
        "Could not delete item because I don't know which wall it's placed against",
      );
    if (itemToDelete.CabinetIndex !== null) {
      //find cabinet
      for (let cabinet of floorPlan.cabinets) {
        if (cabinet.CabinetIndex === itemToDelete.CabinetIndex) {
          this.deleteCabinet(cabinet);
          break;
        }
      }
    }
    let itemIndex = selectedItemWall.Items.indexOf(itemToDelete);
    if (itemIndex > -1) selectedItemWall.Items.splice(itemIndex, 1);
  }

  public async discardUnsavedChanges(
    floorPlan: Client.FloorPlan,
  ): Promise<void> {
    this.autosaveDeleteFromStorage(floorPlan.Id);
    if (this.floorPlanCache && this.floorPlanCache.id === floorPlan.Id) {
      this.clearFloorPlanCache();
    }
    if (!floorPlan.isAddressSet) {
      await this.deleteFloorPlans([floorPlan.Id!]);
      await this.customerService.updateCustomerList(true);
    }
  }

  //TODO un-asyncify
  public async setChanged(floorPlan: Client.FloorPlan): Promise<boolean> {
    let timerKey = 'floorPlan setChanged';
    floorPlan.isDirty = true;
    let messages: Client.RecalculationMessage[];

    if (App.debug.showTimings) console.time(timerKey);
    try {
      do {
        try {
          messages = this.recalculate(floorPlan, false);
        } catch (e: any) {
          let recalculationError = e as Client.RecalculationError;
          if (recalculationError.recalculationMessage) {
            let message = {
              ...recalculationError.recalculationMessage,
              severity: Enums.RecalculationMessageSeverity.Failure,
            };
            this.notificationService.displayRecalulationMessages([message]);
          } else {
            this.errorService.reportError(
              'floorPlanService.setChanged failed',
              e,
            );
            this.notificationService.exception(
              'editor_unknown_recalculation_error',
              e,
              'Unknown error while performing the requested changes. The changes have been rolled back',
            );
          }

          this.restoreAutosave(floorPlan, true);
          floorPlan.triggerUpdate();
          return false;
        }
        this.autosave(floorPlan);
        this.addToUndoStack(floorPlan);
        floorPlan.triggerUpdate();
      } while (this.notificationService.displayRecalulationMessages(messages));

      let errorInfos = FloorplanValidationService.validate(
        floorPlan,
        true,
        true,
      );
      if (errorInfos.length) {
        console.debug({ errorInfos });
      }
      return true;
    } finally {
      if (App.debug.showTimings) console.timeEnd(timerKey);
    }
  }

  public removeFromCache(floorPlanId: number) {
    if (this.floorPlanCache && this.floorPlanCache.id === floorPlanId) {
      this.clearFloorPlanCache();
    }
  }

  //#region autosave

  public getAutosaveDate(floorPlan: Client.FloorPlan): Date | null {
    if (!floorPlan.Id) {
      return null;
    }

    let autosavedFloorplan = this.autosaveLoadFromStorage(floorPlan.Id);
    if (!autosavedFloorplan) {
      return null;
    }

    let dateStr = autosavedFloorplan.CreatedDate;
    return DateHelper.fromIsoString(dateStr);
  }

  public restoreAutosave(
    floorPlan: Client.FloorPlan,
    useClientVersion: boolean,
  ) {
    if (useClientVersion) {
      let newCompleteFloorPlan = this.autosaveLoadFromStorage(floorPlan.Id);
      if (!newCompleteFloorPlan) {
        return;
      }

      floorPlan.restoreFrom(newCompleteFloorPlan, (fp) => {
        this.recalculate(fp, true);
      });
      this.partitionDataService.hydratePartition(
        floorPlan,
        newCompleteFloorPlan.PartitionJsonData,
      );
      this.measurementService.hydrateData(
        newCompleteFloorPlan.MeasurementJsonData,
      );
    } else {
      this.autosaveDeleteFromStorage(floorPlan.Id);
    }
  }

  public async temporarilyDisableAutosave(
    floorPlan: Client.FloorPlan,
  ): Promise<void> {
    let testRestore = false;
    if (App.useDebug && App.debug.alwaysNagAboutAutosave) {
      console.warn('the autosave feature is in test mode, please disable it');
    } else {
      //Disable autosave for a short while. When asking if user wants to leave the site,
      //there is no way to know what the user answered.
      //Assume that user chose to stay if the site is still running after a short time
      //(remembering that the timer is paused while dialog box is open).
      //the timer should be big enough that the site has been unloaded, but not so
      //big that the autosave becomes permanently disabled.
      let autosave = this.autosaveLoadFromStorage(floorPlan.Id);
      if (!autosave) {
        return;
      }
      this.autosaveDeleteFromStorage(floorPlan.Id);
      window.setTimeout(() => {
        if (!autosave) return;
        this.autosave(floorPlan);
      }, 2000);
    }
  }

  private autosave(floorPlan: Client.FloorPlan) {
    let completeFloorPlan = this.getCompleteFloorPlan(floorPlan, false);

    let autosaveList: FloorPlanService.AutoSave[] = [];

    let autosaveJson = window.localStorage.getItem(
      Constants.localStorageKeys.autoSaved,
    );
    if (autosaveJson !== null) {
      autosaveList = JSON.parse(autosaveJson) as FloorPlanService.AutoSave[];
    }

    if (!autosaveList) {
      autosaveList = [];
    }

    let autosaveExisting = autosaveList?.find(
      (fp) => fp.floorPlanId === floorPlan.Id,
    );

    if (autosaveExisting) {
      autosaveExisting.dateSaved = new Date();
      autosaveExisting.floorPlan = completeFloorPlan;
    } else {
      if (floorPlan.Id) {
        autosaveList.push({
          floorPlanId: floorPlan.Id,
          dateSaved: new Date(),
          floorPlan: completeFloorPlan,
        });
      }
    }

    // Keep only the latest 10 autosavings
    let autosaveListTop10 = Enumerable.from(autosaveList)
      .orderByDescending((c) => c.dateSaved)
      .take(10)
      .toArray();

    let jsonFloorPlan = JSON.stringify(autosaveListTop10);
    window.localStorage.setItem(
      Constants.localStorageKeys.autoSaved,
      jsonFloorPlan,
    );
  }

  private autosaveLoadFromStorage(
    floorPlanId: number | undefined | null,
  ): Interface_DTO.FloorPlan | undefined {
    if (!floorPlanId) {
      return;
    }

    let autosaveList: FloorPlanService.AutoSave[] = [];
    let autosaveJson = window.localStorage.getItem(
      Constants.localStorageKeys.autoSaved,
    );
    if (autosaveJson !== null) {
      autosaveList = JSON.parse(autosaveJson);
    }

    let autosaveExisting = autosaveList.find(
      (asl) => asl.floorPlanId === floorPlanId,
    );

    return autosaveExisting?.floorPlan;
  }

  public autosaveDeleteFromStorage(
    floorPlanId: number | undefined | null,
  ): void {
    if (!floorPlanId) {
      return;
    }

    let autosaveList: FloorPlanService.AutoSave[] = [];

    let autosaveJson = window.localStorage.getItem(
      Constants.localStorageKeys.autoSaved,
    );
    if (autosaveJson !== null) {
      autosaveList = JSON.parse(autosaveJson);
    }

    autosaveList.splice(
      autosaveList.findIndex((x) => x.floorPlanId === floorPlanId),
      1,
    );

    let jsonFloorPlan = JSON.stringify(autosaveList);
    window.localStorage.setItem(
      Constants.localStorageKeys.autoSaved,
      jsonFloorPlan,
    );
  }

  private updateVersion1Autosaves() {
    // Prior to task #824 each autosave had its own key in local storage.
    // This function updates all those old autosaves and deletes the old version.
    // this function can be removed when most clients are upgraded to version 2

    let autosaveList: FloorPlanService.AutoSave[] = [];

    let autosaveJson = window.localStorage.getItem(
      Constants.localStorageKeys.autoSaved,
    );
    if (autosaveJson !== null) {
      autosaveList = JSON.parse(autosaveJson);
    }

    var oldAutosaveRegex = /autosavedFloorplan(\d+)/;
    for (const key of Object.keys(localStorage)) {
      if (oldAutosaveRegex.test(key)) {
        const autosaveJson = localStorage.getItem(key)!;
        const autosave = JSON.parse(autosaveJson) as Interface_DTO.FloorPlan;
        autosaveList.push({
          floorPlan: autosave,
          floorPlanId: autosave.Id!,
          dateSaved: new Date(),
        });

        localStorage.removeItem(key);
      }
    }
    localStorage.setItem(
      Constants.localStorageKeys.autoSaved,
      JSON.stringify(autosaveList),
    );
  }

  //#endregion autosave

  //#region undo

  public beginUndoStack(floorPlan: Client.FloorPlan) {
    //let cfp = this.getCompleteFloorPlan(floorPlan);
    this.undoStack.begin(floorPlan.Id);
  }

  public addToUndoStack(floorPlan: Client.FloorPlan) {
    let cfp = this.getCompleteFloorPlan(floorPlan);
    cfp.Cabinets = cfp.Cabinets.filter(
      (cab) => cab.CabinetType != Interface_Enums.CabinetType.Partition,
    );
    this.undoStack.push(floorPlan.Id, cfp);
  }

  public undo(floorPlan: Client.FloorPlan): boolean {
    let lastState = this.undoStack.back(floorPlan.Id);
    if (!lastState) return false;
    floorPlan.restoreFrom(lastState, (fp) => {
      this.recalculate(fp, false);
    });
    this.partitionDataService.hydratePartition(
      floorPlan,
      lastState.PartitionJsonData,
    );
    this.measurementService.hydrateData(lastState.MeasurementJsonData);
    this.autosave(floorPlan);
    return true;
  }

  public redo(floorPlan: Client.FloorPlan): boolean {
    let nextState = this.undoStack.forward(floorPlan.Id);
    if (!nextState) return false;
    floorPlan.restoreFrom(nextState, (fp) => {
      this.recalculate(fp, false);
    });
    this.partitionDataService.hydratePartition(
      floorPlan,
      nextState.PartitionJsonData,
    );
    this.measurementService.hydrateData(nextState.MeasurementJsonData);
    this.autosave(floorPlan);
    return true;
  }

  public isUndoPossible(floorPlan: Client.FloorPlan): boolean {
    return this.undoStack.canUndo(floorPlan.Id);
  }

  public isRedoPossible(floorPlan: Client.FloorPlan): boolean {
    return this.undoStack.canRedo(floorPlan.Id);
  }

  //#endregion undo

  //#region upgrade

  private ruleSetUpgrade(floorPlan: Client.FloorPlan) {
    if (floorPlan.RuleSet < Interface_Constants.CurrentRuleSet) {
      for (let cabinet of floorPlan.cabinets) {
        this.cabinetService.upgrade(cabinet, floorPlan.RuleSet);
      }
      floorPlan.RuleSet = Interface_Constants.CurrentRuleSet;
    }
  }

  //#endregion upgrade

  //#region recalculations

  public recalculate(
    floorPlan: Client.FloorPlan,
    initialLoad: boolean,
  ): Client.RecalculationMessage[] {
    let wasDirty = floorPlan.isDirty;

    let result: Client.RecalculationMessage[] = [];

    FloorPlanHelper.updateWalls(floorPlan.dtoObject);

    floorPlan.Name = ObjectHelper.truncateString(
      floorPlan.Name,
      Constants.maxfloorPlanNameLength,
    );

    for (let cabinet of floorPlan.cabinets) {
      if (cabinet.CabinetType != Interface_Enums.CabinetType.Partition)
        result.push(...this.cabinetService.recalculate(cabinet, initialLoad));
    }
    result.push(...this.ensureEveryCabinetHasAFloorPlanItem(floorPlan));
    result.push(...this.recalculatePositionNumbers(floorPlan));
    result.push(...this.recalculateIndexes(floorPlan));
    this.getOrCreateSharedCabinet(floorPlan);

    if (!wasDirty) floorPlan.isDirty = false;

    if (App.useDebug && App.debug.showRecalculationMessages) {
      console.debug(
        'floorPlan recalculated, recalculation messages here:',
        result,
      );
    }
    return result;
  }

  private recalculatePositionNumbers(floorPlan: Client.FloorPlan) {
    let result: Client.RecalculationMessage[] = [];

    let allItems = Enumerable.from(floorPlan.cabinets)
      .selectMany((cab) => cab.cabinetSections)
      .selectMany((section) => section.configurationItems);

    //group items according to the string created below.
    //items in the same group should get the same position number
    //using a string because I can't find another way to do it easily in JS
    let ungroupableCounter = 0;

    let itemGroups = allItems.groupBy((item) => {
      let groupKey: FloorPlanService.PositionNumberKey = {
        ungroupableId:
          item.ItemType === Interface_Enums.ItemType.CustomPercent
            ? ungroupableCounter++
            : 0,
        productId: Math.max(0, item.ProductId),
        materialId: Math.max(0, item.MaterialId),
        size: {
          X: item.Width,
          Y: item.Height,
          Z: item.Depth,
        },
        itemVariantOptions: ObjectHelper.toLookup(
          item.VariantOptions,
          (vo) => vo.VariantId,
          (vo) => vo.VariantOptionId.toString() + '/' + vo.ActualValue,
        ),
        customItemString:
          item.ItemType === Interface_Enums.ItemType.Custom
            ? item.Description + item.ActualHeight
            : '',
      };
      return JSON.stringify(groupKey);
    });

    itemGroups = itemGroups
      .orderBy((itemGroup) => this.getSortOrder(itemGroup.first()))
      .thenBy((itemGroup) => this.getDoorNumber(itemGroup.first()))
      .thenBy((itemGroup) => itemGroup.min((i) => i.CabinetIndex))
      .thenBy((itemGroup) => itemGroup.min((i) => i.CabinetSectionIndex))
      .thenBy((itemGroup) =>
        itemGroup.any((item) => this.swingItemTypes.indexOf(item.ItemType) >= 0)
          ? itemGroup.min((item) => item.X)
          : 0,
      )
      .thenBy((itemGroup) =>
        itemGroup.any((item) => this.swingItemTypes.indexOf(item.ItemType) >= 0)
          ? itemGroup.min((item) => item.Y)
          : 0,
      )
      .thenBy((itemGroup) => itemGroup.first().ItemNo)
      .thenBy((itemGroup) => itemGroup.first().ConfigurationItemIndex)
      .thenBy((itemGroup) => itemGroup.key());

    itemGroups.forEach((itemGroup, groupIndex) => {
      itemGroup.forEach((item, _) => {
        item.PositionNumber = groupIndex + 1;
      });
    });

    return result;
  }

  private getSortOrder(item: Client.ConfigurationItem): number {
    let spacer = 100;
    let typeIndex = this.itemTypeOrdering.indexOf(item.ItemType);
    if (typeIndex < 0) {
      console.warn(
        `Sorting item failed: item type ${item.ItemType} is not defined in itemTypeOrdering `,
      );
    }
    let orderNumber = typeIndex * spacer;
    if (item.ItemType === Interface_Enums.ItemType.Door) {
      //door-related items should be sorted like this: doors, corner mouldings, top rail, bottom rail
      let doorData = item.cabinetSection.doors.doorData;
      let editorAssets = item.editorAssets;
      if (
        editorAssets.productDoorData.some(
          (pdd) => pdd.CornerMouldingProductId === item.ProductId,
        )
      ) {
        orderNumber += 1;
      } else if (
        !!doorData &&
        item.ProductId === doorData.PositionStopProductId
      ) {
        orderNumber += 2;
      } else if (
        !!doorData &&
        doorData.SoftCloseProducts.map((scp) => scp.ProductId).indexOf(
          item.ProductId,
        ) >= 0
      ) {
        orderNumber += 3;
      } else if (
        editorAssets.railSets.some((rs) => rs.TopProductId === item.ProductId)
      ) {
        orderNumber += 4;
      } else if (
        editorAssets.railSets.some(
          (rs) => rs.BottomProductId === item.ProductId,
        )
      ) {
        orderNumber += 5;
      }
      //else {
      //    orderNumber += 0;
      //}
    }
    if (this.swingItemTypes.indexOf(item.ItemType) >= 0) {
      let placement = item.swingModuleProduct
        ? item.swingModuleProduct.Placement
        : undefined;
      let swingOrder = placement
        ? this.swingPlacementOrdering.indexOf(placement)
        : -1;
      if (swingOrder < 0) {
        swingOrder = spacer - 1;
      }
      orderNumber += swingOrder;
    }
    return orderNumber;
  }

  private getDoorNumber(item: Client.ConfigurationItem): number {
    let doorItemVariant = Enumerable.from(item.VariantOptions).firstOrDefault(
      (iv) =>
        (iv.variant && iv.variant.Number === VariantNumbers.DoorNumber) ||
        false,
    );
    if (!doorItemVariant) return 0;
    return parseInt(doorItemVariant.ActualValue);
  }

  private readonly swingItemTypes: Interface_Enums.ItemType[] = [
    Interface_Enums.ItemType.SwingDoor,
    Interface_Enums.ItemType.SwingCorpus,
    Interface_Enums.ItemType.SwingBacking,
    Interface_Enums.ItemType.SwingExtra,
    Interface_Enums.ItemType.SwingModule,

    Interface_Enums.ItemType.SwingFlexDoor,
    Interface_Enums.ItemType.SwingFlexCorpus,
    Interface_Enums.ItemType.SwingFlexBacking,
    Interface_Enums.ItemType.SwingFlexCorpusMovable,
  ];
  private readonly itemTypeOrdering: Interface_Enums.ItemType[] = [
    Interface_Enums.ItemType.Door,
    Interface_Enums.ItemType.Corpus,

    Interface_Enums.ItemType.SwingDoor,
    Interface_Enums.ItemType.SwingCorpus,
    Interface_Enums.ItemType.SwingBacking,
    Interface_Enums.ItemType.SwingExtra,
    Interface_Enums.ItemType.SwingModule,

    Interface_Enums.ItemType.SwingFlexDoor,
    Interface_Enums.ItemType.SwingFlexCorpus,
    Interface_Enums.ItemType.SwingFlexBacking,
    Interface_Enums.ItemType.SwingFlexCorpusMovable,

    Interface_Enums.ItemType.Interior,
    Interface_Enums.ItemType.Backing,
    Interface_Enums.ItemType.ManuallyAdded,
    Interface_Enums.ItemType.Custom,
    Interface_Enums.ItemType.CustomPercent,
    Interface_Enums.ItemType.AddedByService,
  ];
  private readonly swingPlacementOrdering: Interface_Enums.SwingModuleProductPlacement[] =
    [
      Interface_Enums.SwingModuleProductPlacement.Door,
      Interface_Enums.SwingModuleProductPlacement.LeftGable,
      Interface_Enums.SwingModuleProductPlacement.RightGable,
      Interface_Enums.SwingModuleProductPlacement.MiddleGable,
      Interface_Enums.SwingModuleProductPlacement.TopShelf,
      Interface_Enums.SwingModuleProductPlacement.BottomShelf,
      Interface_Enums.SwingModuleProductPlacement.SubModuleGable,
      Interface_Enums.SwingModuleProductPlacement.SubModuleTopShelf,
      Interface_Enums.SwingModuleProductPlacement.SubModuleExtraItem,
      Interface_Enums.SwingModuleProductPlacement.InteriorShelf,
      Interface_Enums.SwingModuleProductPlacement.InteriorCoatHanger,
      Interface_Enums.SwingModuleProductPlacement.Backing,
    ];

  private recalculateIndexes(
    floorPlan: Client.FloorPlan,
  ): Client.RecalculationMessage[] {
    FloorPlanService.recalculateIndexesGeneric(
      floorPlan.cabinets,
      (cab) => cab.CabinetIndex,
      (cab, newIndex) => (cab.CabinetIndex = newIndex),
    );
    for (let cabinet of floorPlan.cabinets) {
      FloorPlanService.recalculateIndexesGeneric(
        cabinet.cabinetSections,
        (section) => section.CabinetSectionIndex,
        (section, newIndex) => (section.CabinetSectionIndex = newIndex),
      );

      for (let section of cabinet.cabinetSections) {
        FloorPlanService.recalculateIndexesGeneric(
          section.configurationItems,
          (item) => item.ConfigurationItemIndex,
          (item, newIndex) => (item.ConfigurationItemIndex = newIndex),
        );
      }
    }
    return [];
  }

  private static recalculateIndexesGeneric<T>(
    items: T[],
    indexGetter: (item: T) => number,
    indexSetter: (item: T, newIndex: number) => void,
  ) {
    let indexDict: { [index: number]: true } = {};
    let maxIndex = Math.max(1, ...items.map(indexGetter));
    for (let item of items) {
      let index = indexGetter(item);
      if (indexDict[index]) {
        maxIndex++;
        index = maxIndex;
        indexSetter(item, maxIndex);
      }
      indexDict[index] = true;
    }
  }

  private ensureEveryCabinetHasAFloorPlanItem(
    floorPlan: Client.FloorPlan,
  ): Client.RecalculationMessage[] {
    let result: Client.RecalculationMessage[] = [];
    for (let cabinet of floorPlan.cabinets) {
      let found = false;
      for (let wall of floorPlan.walls) {
        if (found) break;
        for (let fpItem of wall.Items) {
          if (fpItem.CabinetIndex == cabinet.CabinetIndex) {
            found = true;
            break;
          }
        }
      }
      if (!found) {
        this.insertEmptyFloorPlanItem(floorPlan, cabinet);
        result.push({
          editorSection: Enums.EditorSection.FloorPlan,
          key: 'floorplan_load_missing_cabinet_position',
          defaultValue:
            'The position of cabinet ' +
            cabinet.CabinetIndex +
            ' was not saved. Please place it again.',
          severity: Enums.RecalculationMessageSeverity.Warning,
        });
      }
    }
    return result;
  }

  private insertEmptyFloorPlanItem(
    floorPlan: Client.FloorPlan,
    cabinet: Client.Cabinet,
  ): void {
    let newItem: Interface_DTO_FloorPlan.Item = {
      CabinetIndex: cabinet.CabinetIndex,
      CabinetType: cabinet.CabinetType,
      FlipDirection: false,
      FlipSide: false,
      Height: cabinet.actualCabinetSections[0].Height,
      ItemType: this.convertCabinetTypeToItemType(cabinet.CabinetType),
      Width: cabinet.actualCabinetSections[0].Width,
      X: 0,
      Y: 0,
    };
    floorPlan.walls[0].Items.push(newItem);
  }

  private convertCabinetTypeToItemType(
    type: Interface_Enums.CabinetType,
  ): Interface_DTO_FloorPlan.ItemType {
    switch (type) {
      case Interface_Enums.CabinetType.CornerCabinet:
        return Interface_DTO_FloorPlan.ItemType.CornerCabinet;
      case Interface_Enums.CabinetType.Doors:
      case Interface_Enums.CabinetType.SingleCabinet:
        return Interface_DTO_FloorPlan.ItemType.SingleCabinet;
      case Interface_Enums.CabinetType.Swing:
        return Interface_DTO_FloorPlan.ItemType.Swing;
      case Interface_Enums.CabinetType.SwingFlex:
        return Interface_DTO_FloorPlan.ItemType.SwingFlex;
      case Interface_Enums.CabinetType.WalkIn:
        return Interface_DTO_FloorPlan.ItemType.WalkIn;
      case Interface_Enums.CabinetType.Partition:
        return Interface_DTO_FloorPlan.ItemType.Partition;

      default:
        return Interface_DTO_FloorPlan.ItemType.None;
    }
  }

  //#endregion recalculations

  //#region Custom and manually added items

  public addCustomItem(
    floorPlan: Client.FloorPlan,
    item: Client.ConfigurationItem,
  ) {
    this.addCustomOrManualItem(floorPlan, item, true);
  }

  public addManualItem(
    floorPlan: Client.FloorPlan,
    item: Client.ConfigurationItem,
  ) {
    this.addCustomOrManualItem(floorPlan, item, false);
  }

  private addCustomOrManualItem(
    floorPlan: Client.FloorPlan,
    item: Client.ConfigurationItem,
    custom: boolean,
  ) {
    let cabinet = this.getOrCreateSharedCabinet(floorPlan);

    item.cabinetSection = cabinet.cabinetSections[0];

    let items = custom
      ? cabinet.cabinetSections[0].CustomItems
      : cabinet.cabinetSections[0].ManuallyAddedItems;
    items.push(item);
  }

  private getOrCreateSharedCabinet(
    floorPlan: Client.FloorPlan,
  ): Client.Cabinet {
    let cabinet = Enumerable.from(floorPlan.cabinets).firstOrDefault(
      (c) => c.CabinetType === Interface_Enums.CabinetType.SharedItems,
    );
    if (!cabinet) {
      let cabinetIndex = 0;

      let floorplanItem: Interface_DTO_FloorPlan.Item = {
        CabinetIndex: cabinetIndex,
        CabinetType: Interface_Enums.CabinetType.SharedItems,
        FlipDirection: false,
        FlipSide: false,
        ItemType: Interface_DTO_FloorPlan.ItemType.None,
        Height: 0,
        Width: 0,
        X: 0,
        Y: 0,
      };
      floorPlan.walls[0].Items.push(floorplanItem);

      cabinet = this.cabinetService.createCabinet(
        floorPlan,
        floorplanItem,
        floorPlan.editorAssets.productLines[0].Id,
      );
      cabinet.Name = this.translationService.translate(
        'Shared items {0}',
        'Shared items {0}',
        cabinetIndex.toString(),
      );
      cabinet.CabinetIndex = cabinetIndex;
      floorplanItem.CabinetIndex = cabinetIndex;
    }
    return cabinet;
  }

  public floorPlanHydratePartition(floorPlan: Client.FloorPlan) {
    this.partitionDataService.hydratePartition(
      floorPlan,
      floorPlan.dtoObject.PartitionJsonData,
    );
  }

  //#endregion Custom and manually added items
}

export module FloorPlanService {
  export interface PositionNumberKey {
    ungroupableId: number;
    productId: number;
    materialId: number;
    itemVariantOptions: { [variantId: number]: string[] };
    size: Interface_DTO_Draw.Vec3d;
    customItemString: string;
  }

  export interface AutoSave {
    dateSaved: Date;
    floorPlan: Interface_DTO.FloorPlan;
    floorPlanId: number;
  }
}
