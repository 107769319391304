import { Injectable } from '@angular/core';
import {
  BackingType,
  BarType,
  CabinetAlignment,
  CabinetType,
  ItemType,
  ProductLineId,
} from 'app/ts/Interface_Enums';
import {
  Cabinet,
  CabinetSection,
  ConfigurationItem,
  DoorWidth,
  ItemVariant,
  ProductBarData,
  VariantOption,
} from 'app/ts/Interface_DTO';
import { FloorPlan } from 'app/ts/clientDto/FloorPlan';
import { PartitionPlan } from 'app/partition/partition-plan-data.service';
import { Door, Module, ModuleType } from 'app/partition/module';
import * as VariantNumbers from 'app/ts/VariantNumbers';
import { AssetService } from '../ts/services/AssetService';
import { Filling } from 'app/partition/filling';
import { Product } from '../ts/clientDto';
import { TranslationService as TranslateService } from 'app/ts/services/TranslationService';
import { Section } from 'app/partition/section';
import Enumerable from 'linq';
import { Profile, ProfileType } from 'app/partition/profile';
import { Rail, RailPlacement } from 'app/partition/rail';
import { ProductHelper } from '../ts/util/ProductHelper';
import { Partition, PartitionId } from 'app/partition/partition';
import { Bar } from 'app/partition/bar';
import { PartitionPlanQueryService } from 'app/partition/partition-plan-query.service';
import { ConfigurationItemHelper } from 'app/ts/util/ConfigurationItemHelper';
import * as App from 'app/ts/app';
import { ProductLineIds } from 'app/ts/InterfaceConstants';
import * as Interface_Enums from 'app/ts/Interface_Enums';

/**
 * We want to keep our partition data structures as clean and
 * separate as possible, wherefore this
 * service is used to generate the old-style
 * ConfigurationItem elements which is used by
 * piecelist, order transfer and probably other stuff, as well.
 */
@Injectable({ providedIn: 'root' })
export class PartitionCabinetService {
  constructor(
    private readonly assets: AssetService,
    private readonly partitionQueryService: PartitionPlanQueryService,
    private readonly translations: TranslateService,
  ) {}

  /**
   * Creates a list of "Cabinet", which resembles each Partition on the floor plan.
   * It is necessary to make piecelist and order transfer and probably
   * other stuff work.
   * @param floorPlan - should probably be changed to simple parameters or an interface
   * with specific fields
   * @returns
   */
  public getPartitionCabinets(
    floorPlan: FloorPlan,
    partitionPlan: PartitionPlan,
  ): Cabinet[] | undefined {
    const materialNumbersById: { [id: number]: string } = Object.assign(
      {},
      ...this.assets.editorAssets.materials.map((material) => ({
        [material.Id]: material.Number,
      })),
    );

    if (partitionPlan.sections.length == 0) return undefined;

    const moduleLookup: { [id: number]: Module } = Object.assign(
      {},
      ...partitionPlan.modules.map((module) => ({ [module.id]: module })),
    );

    const variantId = (variantNumber: string) =>
      this.assets.editorAssets.variantsByNumber[variantNumber].Id;
    let partitionCabinetIndex =
      floorPlan.cabinets
        .map((c) => c.CabinetIndex)
        .sort((a, b) => Number(a) - Number(b))
        .reverse()[0] + 1;

    const posNumbers = floorPlan.cabinets
      .flatMap((cab) => cab.cabinetSections)
      .flatMap((sec) => sec.configurationItems)
      .map((ci) => ci.PositionNumber);
    let posNumber = posNumbers.length > 0 ? Math.max(...posNumbers) + 1 : 1;

    const nextPosNumber = () => {
      return posNumber++;
    };

    const partitions =
      partitionPlan.partitions.length == 0
        ? [
            {
              id: -1 as PartitionId,
              name: 'Partition',
              sections: partitionPlan.sections.map((s) => s.id),
            },
          ]
        : partitionPlan.partitions;

    const cabinets = partitions.map((partition) => {
      return this.createCabinetForPartition(
        partition,
        partitionPlan,
        partitionCabinetIndex++,
        materialNumbersById,
        moduleLookup,
        variantId,
        nextPosNumber,
        floorPlan.Id,
        floorPlan.CreatedDate,
        floorPlan.UserId,
        floorPlan.SupporterId,
      );
    });

    return cabinets;
  }

  private createCabinetForPartition(
    partition: Partition,
    partitionPlan: PartitionPlan,
    partitionCabinetIndex: number,
    materialNumbersById: { [id: number]: string },
    moduleLookup: { [id: number]: Module },
    variantId: (number: string) => number,
    nextPosNumber: () => number,
    floorPlanId: number | null,
    createDate: string,
    userId: number,
    supporterId: number | null,
  ) {
    const partitionSections = partitionPlan.sections.filter(
      (section) =>
        partition.sections.find(
          (partitionSection) => partitionSection == section.id,
        ) != null,
    );

    const sections: CabinetSection[] = partitionSections.map((section) => {
      let sectionIndex =
        this.partitionQueryService
          .getPartitionForSection(section.id)
          .sections.findIndex((sec) => sec == section.id) + 1;
      const sectionModules = section.modules.map(
        (moduleId) => moduleLookup[moduleId],
      );

      const interiorItems = this.mapModulesToConfigurationItems(
        section,
        sectionModules,
        partitionCabinetIndex,
        sectionIndex,
        variantId,
        materialNumbersById,
        nextPosNumber,
      );

      const stopStartProfiles = partitionPlan.profiles.filter((profile) =>
        profile.sections.find(
          (secCon) =>
            secCon.sectionId == section.id &&
            (profile.type == ProfileType.StartStop ||
              profile.type == ProfileType.Niche),
        ),
      );

      const profileItems = this.createProfileConfigurationItems(
        partitionCabinetIndex,
        sectionIndex,
        stopStartProfiles,
        section.references.profileMaterialId,
        materialNumbersById,
        nextPosNumber,
      );

      const cabinetSection = this.createCabinetSection(
        partitionCabinetIndex,
        sectionIndex,
        [...interiorItems, ...profileItems],
      );

      cabinetSection.Width = Math.trunc(section.width);
      cabinetSection.Height = Math.trunc(section.height);
      cabinetSection.Depth = Module.fullJointWidth;

      return cabinetSection;
    });

    const partitionProfiles = partitionPlan.profiles.filter((profile) =>
      partitionSections.find((partitionSection) =>
        profile.sections.find(
          (profileSection) => profileSection.sectionId == partitionSection.id,
        ),
      ),
    );

    const partitionRails = partitionSections
      .flatMap((sec) => sec.rails)
      .filter((railId, index, arr) => arr.indexOf(railId) === index)
      .map((railId) => this.partitionQueryService.getRail(railId));

    let partitionJointProfiles = partitionProfiles.filter((profile) =>
      this.partitionQueryService.isJointProfile(profile.id),
    );
    if (partitionJointProfiles.length > 0 || partitionRails.length > 0) {
      const profileSection: CabinetSection = this.createSharedSection(
        partitionCabinetIndex,
        partitionSections.length + 1,
        partitionJointProfiles,
        partitionRails,
        partitionSections[0].references.profileMaterialId,
        materialNumbersById,
        nextPosNumber,
      );
      sections.push(profileSection);
    }

    const partitionCabinet: Cabinet = {
      CabinetIndex: partitionCabinetIndex,
      CabinetSections: sections,
      CabinetType: CabinetType.Partition,
      CreatedDate: createDate,
      FloorPlanId: floorPlanId,
      Name: partition.name,
      OverrideChain: false,
      ProductLineId: ProductLineId.Partition,
      SupporterId: supporterId,
      UserId: userId,

      // Properties to probably be ignored
      Alignment: CabinetAlignment.Unknown,
      Description: '',
      EndUserSetupId: null,
      Rotation: 0,
      UnevenFloor: false,
      UnevenLeftWall: false,
      UnevenRightWall: false,
      UseRoof: false,
    };
    return partitionCabinet;
  }

  /**
   * Each module, whether a door or wall, should result in a
   * corresponding ConfigurationItem.
   *
   * This code is modelled over generateDoorItems in
   * @see DoorLogic.
   */
  public mapModulesToConfigurationItems(
    section: Section,
    modules: Module[],
    partitionCabinetIndex: number,
    sectionIndex: number,
    variantId: (number: string) => number,
    materialNumbersById: { [id: number]: string },
    nextPosNumber: () => number,
  ): ConfigurationItem[] {
    const doorData = this.assets.editorAssets.productDoorData.find(
      (productDoorData) =>
        productDoorData.ProductId ===
        this.partitionQueryService.getDoorModuleProductId(),
    );

    const possibleBars =
      doorData?.Bars.find((bar) => !bar.ChainOverride) ?? null;

    let moduleIndex = 1;
    const configItems: ConfigurationItem[] = modules.map((module) => {
      const moduleProduct =
        this.assets.editorAssets.productsDict[module.references.productId];

      let children = [
        ...this.createFillingConfigurationItemsForModule(
          module,
          moduleProduct,
          partitionCabinetIndex,
        ),
      ];

      module.positionNo = nextPosNumber();

      const moduleItem = {
        CabinetIndex: partitionCabinetIndex,
        CabinetSectionIndex: sectionIndex,
        Children: children,
        ConfigurationItemIndex: moduleIndex,
        ItemNo: moduleProduct.ProductNo,
        ItemType: ItemType.Partition,
        MaterialId: -1,
        Quantity: 1,
        VariantOptions: [],
        PositionNumber: module.positionNo,
        ProductId: module.references.productId,

        Description: moduleProduct?.Name,

        ActualHeight: module.height,
        Depth: module.depth,
        Height: module.height,

        Width: module.width,

        ExternalItemNo: '',
        HeightReduction: false,
        IsHidden: false,
        IsLocked: false,
        ParentItemNo: '',
        ParentModuleItemIndex: null,
        ParentModuleProductId: -1,
        Price: 0,
        X: -1,
        Y: -1,
        Z: -1,
      } as ConfigurationItem;

      moduleItem.VariantOptions = this.createVariantsForModule(
        section,
        module,
        moduleProduct,
        moduleIndex,
        variantId,
        materialNumbersById,
        possibleBars,
      );

      this.recalculateItemNo(moduleItem, moduleProduct);

      moduleIndex++;
      return moduleItem;
    });

    return configItems;
  }

  /**
   * Each endstop should result in a corresponding
   * ConfigurationItem.
   *
   * This code is modelled over generateDoorItems in
   * @see DoorLogic.
   */
  public createEndStopConfigurationItemsForDoor(
    door: Door,
    partitionCabinetIndex: number,
    sectionIndex: number,
    section: Section,
    materialNumbersById: { [id: number]: string },
  ): ConfigurationItem[] {
    let endStopProduct =
      this.assets.editorAssets.productsDict[
        this.partitionQueryService.getEndStopProductId()
      ];

    const frameColorVariant = this.createVariantWithOption(
      this.variantId(VariantNumbers.FrameColor),
      this.variantOptionFromNumber(
        this.variantId(VariantNumbers.FrameColor),
        materialNumbersById[section.references.profileMaterialId],
      )!.Id,
    );

    let item = {
      ActualHeight: 0,
      Depth: ProductHelper.defaultDepth(
        endStopProduct,
        ProductLineIds.Partition,
        null,
      ),
      Description: endStopProduct.Name,
      ExternalItemNo: '',
      Height: ProductHelper.defaultHeight(endStopProduct),
      HeightReduction: false,
      IsHidden: false,
      IsLocked: false,
      ItemNo:
        endStopProduct.ProductNo +
        materialNumbersById[section.references.profileMaterialId],
      ItemType: ItemType.Door,
      MaterialId: -1,
      ParentItemNo: '',
      ParentModuleItemIndex: null,
      ParentModuleProductId: 0,
      PositionNumber: -1,
      Price: 0,
      ProductId: this.partitionQueryService.getEndStopProductId(),
      Quantity: 0,
      Width: ProductHelper.defaultWidth(endStopProduct),
      X: 0,
      Y: 0,
      Z: 0,
      CabinetIndex: partitionCabinetIndex,
      CabinetSectionIndex: sectionIndex,
      ConfigurationItemIndex: 1,
      Children: [],
      VariantOptions: [frameColorVariant],
    };

    if (door.hasLeftEndStop) {
      item.Quantity++;
    }
    if (door.hasRightEndStop) {
      item.Quantity++;
    }

    return item.Quantity > 0 ? [item] : [];
  }

  /**
   * Each filling should result in a corresponding
   * ConfigurationItem.
   *
   * This code is modelled over generateDoorItems in
   * @see DoorLogic.
   */
  public createFillingConfigurationItemsForModule(
    module: Module,
    moduleProduct: Product,
    partitionCabinetIndex: number,
  ) {
    let fillingIndex = module.fillings.length;
    return [...module.fillings].reverse().map((filling) => {
      return this.createFillingItem(
        module,
        moduleProduct,
        filling,
        partitionCabinetIndex,
        this.partitionQueryService.getModuleSectionIndex(module.id),
        fillingIndex--,
      );
    });
  }

  /**
   * Creates a Filling ConfigurationItem
   */
  private createFillingItem(
    module: Module,
    moduleProduct: Product,
    filling: Filling,
    partitionIndex: number,
    moduleIndex: number,
    fillingIndex: number,
  ): ConfigurationItem {
    const description = this.translations.translate(
      'piecelist_door_filling_name',
      '{0} #{1}-{2}',
      moduleProduct.Name,
      moduleIndex.toString(),
      fillingIndex.toString(),
    );

    return {
      ActualHeight: filling.height,
      Depth: 0,
      Description: description,
      ExternalItemNo: '',
      Height: filling.height,
      HeightReduction: false,
      IsHidden: false,
      IsLocked: false,
      ItemNo: '', //moduleProduct.ProductNo,
      ItemType: ItemType.Interior,
      MaterialId: filling.materialId,
      ParentItemNo: '',
      ParentModuleItemIndex: null,
      ParentModuleProductId: 0,
      PositionNumber: -1,
      Price: 0,
      ProductId: module.references.productId,
      Quantity: 1,
      Width: filling.width,
      X: 0,
      Y: 0,
      Z: 0,
      CabinetIndex: partitionIndex,
      CabinetSectionIndex: moduleIndex,
      ConfigurationItemIndex: fillingIndex,
      Children: [],
      VariantOptions: [],
    };
  }

  /**
   * Creates the variants that should be attached to a module configuration item.
   */
  public createVariantsForModule(
    section: Section,
    module: Module,
    moduleProduct: Product,
    moduleIndex: number,
    variantId: (number: string) => number,
    materialNumbersById: { [id: number]: string },
    possibleBars: ProductBarData | null,
  ): ItemVariant[] {
    const fillingVariants = this.createFillingVariants(
      module,
      moduleProduct,
      variantId,
      materialNumbersById,
      possibleBars,
    );

    const fillingCountVariantId = variantId(VariantNumbers.DoorFillingCount);
    const fillingCountVariant = this.createDimensionVariantWithNumberValue(
      fillingCountVariantId,
      module.fillings.length,
    );

    const barVariants = this.createBarRelatedVariantsForModule(
      section,
      module,
      moduleProduct,
    );

    const indexVariant = this.createDimensionVariantWithNumberValue(
      this.variantId(VariantNumbers.DoorNumber),
      moduleIndex,
      0,
    );

    let widthNumber = this.getWidthNumber(
      this.assets.editorAssets.doorWidths,
      module.width,
      false /*module.isStandard*/,
    );
    const widthMaxVariant = this.createVariantWithOption(
      this.variantId(VariantNumbers.DoorWidthMax),
      this.variantOptionFromNumber(
        this.variantId(VariantNumbers.DoorWidthMax),
        widthNumber,
      )!.Id,
    );

    const widthVariant = this.createDimensionVariantWithNumberValue(
      this.variantId(VariantNumbers.DoorWidth),
      Math.ceil(module.width),
      1,
    );

    const heightVariant = this.createDimensionVariantWithNumberValue(
      this.variantId(VariantNumbers.DoorHeight),
      module.height,
      1,
    );

    const frameColorVariant = this.createVariantWithOption(
      this.variantId(VariantNumbers.FrameColor),
      this.variantOptionFromNumber(
        this.variantId(VariantNumbers.FrameColor),
        materialNumbersById[section.references.profileMaterialId],
      )!.Id,
    );

    const designFillingCount = module.fillings.filter(
      (f) =>
        this.assets.editorAssets.materialsDict[f.materialId]
          .MaterialGroupIsDesign,
    ).length;
    const designFillingVariant = this.createDimensionVariantWithNumberValue(
      this.variantId(VariantNumbers.DoorDesignFillingCount),
      designFillingCount,
    );

    const visibleFillingVariant = this.createDimensionVariantWithNumberValue(
      this.variantId(VariantNumbers.VisibleFillingSizes),
      this.partitionQueryService.getTotalVisibleFillingsHeightForModule(
        module.id,
      ),
      1,
    );

    // VerticalBarCount/SPROS_LODR
    const verticalBarVariant = this.createVerticalBarVariant(module);

    // DoorFillingColor/PANELTYPE
    const mostExpensiveFillingMaterial =
      this.getMostExpensiveFillingMaterial(module);
    const doorFillingColorVariant = mostExpensiveFillingMaterial
      ? this.createVariantWithOption(
          this.variantId(VariantNumbers.DoorFillingColor),
          this.variantOptionFromNumber(
            this.variantId(VariantNumbers.DoorFillingColor),
            materialNumbersById[mostExpensiveFillingMaterial.Id],
          )!.Id,
        )
      : undefined;

    const variants = [
      doorFillingColorVariant,
      ...fillingVariants,
      fillingCountVariant,
      frameColorVariant,
      designFillingVariant,
      visibleFillingVariant,
      ...barVariants,
      indexVariant,
      verticalBarVariant,
      widthMaxVariant,
      widthVariant,
      heightVariant,
    ];

    return variants.filter((v) => v != null) as ItemVariant[];
  }

  public createVerticalBarVariant(module: Module) {
    const moduleProduct =
      this.assets.editorAssets.productsDict[module.references.productId];
    const possibleVerticalBarVariants = moduleProduct.getVariants(
      ProductLineId.Partition,
    );
    const possibleOptions = possibleVerticalBarVariants.filter(
      (variant) => variant.Number == VariantNumbers.VerticalBarCount,
    )[0].VariantOptions;

    const actualVerticalBarOption = possibleOptions.filter(
      (option) => option.Id == module.references.verticalBarOptionId,
    )[0];

    return this.createVariantWithOption(
      this.variantId(VariantNumbers.VerticalBarCount),
      actualVerticalBarOption.Id,
    );
  }

  /**
   * Creates the variants for the bars for a module
   */
  public createBarRelatedVariantsForModule(
    section: Section,
    module: Module,
    moduleProduct: Product,
  ): ItemVariant[] {
    const barHeightVariant = this.createDimensionVariantWithNumberValue(
      this.variantId(VariantNumbers.BarHeight),
      section.barHeight,
    );

    const fixedValue = module.forceFixedBars
      ? VariantNumbers.Values.Yes
      : VariantNumbers.Values.No;
    const fixedOption = this.assets.editorAssets.variantsByNumber[
      VariantNumbers.ForceFixedBars
    ].VariantOptions.filter(
      (variantOption) => variantOption.Number == fixedValue,
    )[0];

    const forceFixedBarsVariant = this.createVariantWithOption(
      this.variantId(VariantNumbers.ForceFixedBars),
      fixedOption.Id,
    );

    const looseBarCount = module.bars.filter(
      (bar) => bar.bartype == BarType.Loose,
    ).length;
    const looseBarCountVariant = this.createDimensionVariantWithNumberValue(
      this.variantId(VariantNumbers.LooseBarCount),
      looseBarCount,
    );

    const fixedBarCount = module.bars.filter(
      (bar) => bar.bartype == BarType.Fixed,
    ).length;
    const fixedBarCountVariant = this.createDimensionVariantWithNumberValue(
      this.variantId(VariantNumbers.FixedBarCount),
      fixedBarCount,
    );

    // This is taken directly from DoorLogic, although it is in no way logical.
    // Especially the if (!isDesignFilling) logic; I have no idea what it does.
    let isDesignFilling = false;
    let barPlacementCount = 0;
    const barVariants = module.bars
      .map((bar, index) => {
        bar.bartype;
        const bars: ItemVariant[] = [];
        if (!isDesignFilling) {
          let variantBarPos = Enumerable.from(
            moduleProduct.getVariants(ProductLineIds.Partition),
          ).firstOrDefault((v) => v.Number === VariantNumbers.BarPlacement);
          if (variantBarPos) {
            if (variantBarPos.VariantOptions.length >= barPlacementCount) {
              let option = variantBarPos.VariantOptions[barPlacementCount];
              if (option) {
                bars.push(
                  this.createVariantWithOptionValue(
                    variantBarPos.Id,
                    option.Id,
                    this.barTypeString(bar.bartype, false),
                  ),
                );
              }
            }
          }

          isDesignFilling = bar.bartype === Interface_Enums.BarType.Design;
          barPlacementCount++;
        } else {
          isDesignFilling = false;
        }

        bars.push(
          this.createDimensionVariantWithStringValue(
            this.variantId(VariantNumbers.BarPlacementPositionTypes[index]),
            this.barTypeString(bar.bartype, true),
          ),
        );

        bars.push(
          this.createDimensionVariantWithNumberValue(
            this.variantId(VariantNumbers.BarPlacementPositions[index]),
            module.height - bar.position,
            2,
          ),
        );

        return bars;
      })
      .flat();

    return [
      barHeightVariant,
      looseBarCountVariant,
      fixedBarCountVariant,
      ...barVariants,
      forceFixedBarsVariant,
    ];
  }

  private variantId(variantNumber: string) {
    return this.assets.editorAssets.variantsByNumber[variantNumber].Id;
  }

  private variantOptionFromNumber(
    variantId: number,
    variantOptionNumber: string,
  ) {
    return this.assets.editorAssets.variantsDict[variantId].VariantOptions.find(
      (vo) => vo.Number == variantOptionNumber,
    );
  }

  private variantOption(variantId: number, variantOptionId: number) {
    return this.assets.editorAssets.variantsDict[variantId].VariantOptions.find(
      (vo) => vo.Id == variantOptionId,
    )!;
  }

  /**
   * Creates a "virtual" section, containing
   * the T/Corner profiles.
   */
  private createSharedSection(
    partitionCabinetIndex: number,
    sectionIndex: number,
    profiles: Profile[],
    rails: Rail[],
    profileMaterialId: number,
    materialNumbersById: { [id: number]: string },
    nextPosNumber: () => number,
  ): CabinetSection {
    const railConfigurationItems = this.createRailConfigurationItems(
      partitionCabinetIndex,
      sectionIndex,
      rails,
      materialNumbersById,
      nextPosNumber,
    );

    const profileConfigurationItems = this.createProfileConfigurationItems(
      partitionCabinetIndex,
      sectionIndex,
      profiles,
      profileMaterialId,
      materialNumbersById,
      nextPosNumber,
    );

    let cabinetSection = this.createCabinetSection(
      partitionCabinetIndex,
      sectionIndex,
      profileConfigurationItems,
      true,
    );
    cabinetSection.RailItems = railConfigurationItems;

    return cabinetSection;
  }

  private createProfileConfigurationItems(
    partitionCabinetIndex: number,
    partitionCabinetSectionIndex: number,
    profiles: Profile[],
    profileMaterialId: number,
    materialNumbersById: { [id: number]: string },
    nextPosNumber: () => number,
  ): ConfigurationItem[] {
    let profileIndex = 1;
    return Enumerable.from(profiles)
      .groupBy((profile) => profile.type)

      .select((group) => {
        let profile = group.first();
        const profileProduct =
          this.assets.editorAssets.productsDict[profile.productId];

        let posNumber = nextPosNumber();
        group.forEach((profile) => {
          profile.positionNo = posNumber;
        });

        let configItem = {
          CabinetIndex: partitionCabinetIndex,
          CabinetSectionIndex: partitionCabinetSectionIndex,
          Children: [],
          ConfigurationItemIndex: profileIndex++,
          ItemNo: profileProduct.ProductNo,
          ItemType: ItemType.Interior,
          MaterialId: profileMaterialId,
          Quantity: group.toArray().length,
          VariantOptions: this.createProfileVariants(
            profile,
            materialNumbersById[profileMaterialId],
          ),
          PositionNumber: posNumber,
          ProductId: profile.productId,

          Description: profileProduct?.Name,

          Width: profile.width,
          Height: profile.height,
          ActualHeight: profile.height,
          Depth: profile.depth,

          ExternalItemNo: '',
          HeightReduction: false,
          IsHidden: false,
          IsLocked: false,
          ParentItemNo: '',
          ParentModuleItemIndex: null,
          ParentModuleProductId: -1,
          Price: 0,
          X: -1,
          Y: -1,
          Z: -1,
        } as ConfigurationItem;

        this.recalculateItemNo(configItem, profileProduct);

        return configItem;
      })
      .toArray();
  }

  private createProfileVariants(
    profile: Profile,
    profileMaterialNumber: string,
  ): ItemVariant[] {
    const heightVariant = this.createDimensionVariantWithNumberValue(
      this.variantId(VariantNumbers.Height),
      profile.height ?? 0,
      1,
    );

    const frameColorVariant = this.createVariantWithOption(
      this.variantId(VariantNumbers.FrameColor),
      this.variantOptionFromNumber(
        this.variantId(VariantNumbers.FrameColor),
        profileMaterialNumber,
      )!.Id,
    );

    return [heightVariant, frameColorVariant];
  }

  private createRailConfigurationItems(
    partitionCabinetIndex: number,
    cabinetSectionIndex: number,
    rails: Rail[],
    materialNumbersById: { [id: number]: string },
    nextPosNumber: () => number,
  ): ConfigurationItem[] {
    let railIndex = 1;

    return rails.map((rail) => {
      const railProduct = this.assets.editorAssets.productsDict[rail.productId];

      let posNumber = nextPosNumber();
      rail.positionNo = posNumber;

      let configItem = {
        CabinetIndex: partitionCabinetIndex,
        CabinetSectionIndex: cabinetSectionIndex,
        Children: [],
        ConfigurationItemIndex: railIndex++,
        ItemNo: railProduct.ProductNo,
        ItemType: ItemType.Door,
        MaterialId: rail.materialId,
        Quantity: 1,
        VariantOptions: this.createRailVariants(
          railProduct,
          rail,
          materialNumbersById[rail.materialId],
        ),
        PositionNumber: posNumber,
        ProductId: rail.productId,

        Description: railProduct?.Name,

        ActualHeight: rail.height,
        Depth: rail.depth,
        Height: rail.height,

        Width: rail.width,

        ExternalItemNo: '',
        HeightReduction: false,
        IsHidden: false,
        IsLocked: false,
        ParentItemNo: '',
        ParentModuleItemIndex: null,
        ParentModuleProductId: -1,
        Price: 0,
        X: rail.posX,
        Y: rail.posY,
        Z: -1,
      } as ConfigurationItem;

      this.recalculateItemNo(configItem, railProduct);

      return configItem;
    });
  }

  private createRailVariants(
    railProduct: Product,
    rail: Rail,
    railMaterialNumber: string,
  ): ItemVariant[] {
    let lengthVariantId = -1;
    if (rail.placement == RailPlacement.Ceiling) {
      lengthVariantId = this.variantId(VariantNumbers.LengthTop);
    } else {
      //Floor
      if (rail.moduleType == ModuleType.Door) {
        lengthVariantId = this.variantId(VariantNumbers.LengthBottom);
      } else {
        lengthVariantId = this.variantId(VariantNumbers.Length);
      }
    }

    railProduct.ProductVariants.find(
      (productVariant) => productVariant.Id == lengthVariantId,
    );
    const lengthVariant = this.createDimensionVariantWithNumberValue(
      lengthVariantId,
      rail.width,
      1,
    );

    const endStopVariant = ProductHelper.hasVariant(
      railProduct,
      VariantNumbers.RailEndStop,
    )
      ? this.createVariantWithOption(
          this.variantId(VariantNumbers.RailEndStop),
          this.variantOptionFromNumber(
            this.variantId(VariantNumbers.RailEndStop),
            rail?.hasLeftEndStop && rail?.hasRightEndStop
              ? '4'
              : rail?.hasLeftEndStop
                ? '2'
                : rail?.hasRightEndStop
                  ? '3'
                  : '0',
          )!.Id,
        )
      : undefined;

    const frameColorVariant = ProductHelper.hasVariant(
      railProduct,
      VariantNumbers.FrameColor,
    )
      ? this.createVariantWithOption(
          this.variantId(VariantNumbers.FrameColor),
          this.variantOptionFromNumber(
            this.variantId(VariantNumbers.FrameColor),
            railMaterialNumber,
          )!.Id,
        )
      : undefined;

    return [lengthVariant, frameColorVariant, endStopVariant].filter(
      (variant) => variant != null,
    ) as ItemVariant[];
  }

  private getMostExpensiveFillingMaterial(module: Module) {
    if (module.fillings.length > 0) {
      const mostExpensiveMaterial = Enumerable.from(module.fillings)
        .select(
          (filling) =>
            this.assets.editorAssets.materialsDict[filling.materialId],
        )
        .where((mat) => !mat.MaterialGroupIsDesign)
        .orderByDescending((material) => material.PriceLevel)
        .firstOrDefault();
      //Maybe needs reverse

      return mostExpensiveMaterial;
    }

    return null;
  }

  private recalculateItemNo(item: ConfigurationItem, product: Product) {
    if (!product) return;
    let productNo = product.ProductNo;
    if (product.AddToProductNo) {
      let addVariants = [
        product.AddVariant1,
        product.AddVariant2,
        product.AddVariant3,
      ];
      for (let av of addVariants) {
        if (av < 0) break;

        let variantOptionId = item.VariantOptions.find(
          (vo) => vo.VariantId == av,
        )?.VariantOptionId;
        if (!variantOptionId) {
          if (App.useDebug) {
            console.warn(
              'Could not find variant on item. variant is required to make a correct itemNo',
              item,
              av,
            );
          }
          break;
        }

        let itemVariantOption = this.variantOption(av, variantOptionId);

        productNo += itemVariantOption.Number;
      }
    }
    item.ItemNo = productNo;
  }

  /**
   * Taken from DoorBar
   */
  private barTypeString(
    barType: BarType,
    forBarPlacementPositionType: boolean,
  ): string {
    let typeString = '';

    switch (barType) {
      case BarType.Loose:
        typeString = 'L';
        break;

      case BarType.Fixed:
        typeString = 'F';
        break;

      case BarType.Design:
        typeString = forBarPlacementPositionType ? 'F' : 'D';
        break;
    }

    return typeString;
  }

  /**
   * This is modelled over (part of) generateDoorItems in @see DoorLogic
   */
  private createFillingVariants(
    module: Module,
    moduleProduct: Product,
    variantId: (number: string) => number,
    materialNumbersById: { [id: number]: string },
    possibleBars: ProductBarData | null,
  ): ItemVariant[] {
    const fillingVariants: ItemVariant[] = [];
    let fillingIndex = 0;
    for (let filling of module.fillings) {
      const materialNumber = materialNumbersById[filling.materialId];

      let variant = Enumerable.from(
        moduleProduct.getVariants(ProductLineIds.Partition),
      ).firstOrDefault(
        (v) => v.Number === VariantNumbers.DoorFillingColorDetailed,
      );
      if (variant) {
        if (variant.VariantOptions.length >= fillingIndex) {
          let option = variant.VariantOptions[fillingIndex];
          if (option) {
            fillingVariants.push(
              this.createVariantWithOptionValue(
                variant.Id,
                option.Id,
                materialNumber,
              ),
            );
          }
        }
      }

      const sizeVariantId = variantId(
        VariantNumbers.FillingSizes[fillingIndex],
      );
      fillingVariants.push(
        this.createDimensionVariantWithNumberValue(
          sizeVariantId,
          filling.height,
        ),
      );

      fillingIndex++;
    }

    const visibleHeightVariantId = variantId(
      VariantNumbers.VisibleFillingSizes,
    );

    const visibleHeightVariant = moduleProduct
      .getVariants(ProductLineId.Partition)
      .find((variant) => variant.Number == VariantNumbers.VisibleFillingSizes);

    if (visibleHeightVariant == null || possibleBars == null)
      return fillingVariants;

    const visualHeightVariants = module.fillings.map((_, index) => {
      const visualHeight = this.partitionQueryService.getVisibleFillingHeight(
        module.id,
        index,
      );

      const visualHeightVariant = this.createVariantWithOptionValue(
        visibleHeightVariantId,
        visibleHeightVariant.VariantOptions[index].Id,
        this.numberValueAsString(visualHeight, 2),
      );
      return visualHeightVariant;
    });

    return [...fillingVariants, ...visualHeightVariants];
  }

  private numberValueAsString(value: number, digits: number = 1): string {
    return value.toLocaleString('en-GB', {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
      useGrouping: false,
    });
  }

  /**
   * A dimension variant is (I think) a variant that is given a specific value, e.g. a
   * measurement, instead of a value from a fixed set of option values
   */
  private createDimensionVariantWithNumberValue(
    variantId: number,
    value: number,
    digits: number = 1,
  ): ItemVariant {
    const valueAsString = value.toLocaleString('en-GB', {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
      useGrouping: false,
    });
    return this.createDimensionVariantWithStringValue(variantId, valueAsString);
  }

  private createDimensionVariantWithStringValue(
    variantId: number,
    value: string,
  ) {
    return { VariantId: variantId, VariantOptionId: -1, ActualValue: value };
  }

  /**
   * Creates a variant where the option is the only needed value.
   */
  private createVariantWithOption(
    variantId: number,
    variantOptionId: number,
  ): ItemVariant {
    return {
      ActualValue: '',
      VariantId: variantId,
      VariantOptionId: variantOptionId,
    };
  }

  /**
   * Creates a variant with a value that extends the fixed options for the variant.
   */
  private createVariantWithOptionValue(
    variantId: number,
    variantOptionId: number,
    value: string,
  ): ItemVariant {
    return {
      ActualValue: value,
      VariantId: variantId,
      VariantOptionId: variantOptionId,
    };
  }

  /**
   * Gets the category/number for the given moduleWidth.
   * Modelled on DoorHelper.getWidthNumber. Should standardModule always be true??
   * @see https://dev.azure.com/jlf0502/KA%20Online/_wiki/wikis/KA%20Online%20Wiki/185/BRED_MAX
   */
  private getWidthNumber(
    doorWidths: DoorWidth[],
    moduleWidth: number,
    standardModule: boolean,
  ): string {
    const doorWidth = Enumerable.from(doorWidths)
      .where((dw) => dw.IsStandardDoor === standardModule)
      .orderBy((dw) => dw.Width)
      .firstOrDefault((dw) => dw.Width >= moduleWidth);
    if (doorWidth) {
      return doorWidth.Number;
    }

    return '';
  }

  /**
   * Creates a CabinetSection where the most of the fiels
   * have som relative sane defaults
   */
  private createCabinetSection(
    cabinetIndexForPartition: number,
    sectionIndex: number,
    interiorItems: ConfigurationItem[],
    isSharedSection: boolean = false,
  ): CabinetSection {
    return {
      CabinetIndex: cabinetIndexForPartition,
      CabinetSectionIndex: sectionIndex++,
      InteriorItems: interiorItems,
      CabinetType: CabinetType.Partition,

      AddedByServiceItems: [],
      BackingItems: [],
      BackingMaterialId: null,
      BackingType: BackingType.None,
      BottomHeight: 0,
      CorpusBottomItems: [],
      CorpusLeftItems: [],
      CorpusMaterialId: null,
      CorpusMaterialNumber: '',
      CorpusPanelIdBottom: -1,
      CorpusPanelIdLeft: -1,
      CorpusPanelIdRight: -1,
      CorpusPanelIdTop: -1,
      CorpusRightItems: [],
      CorpusTopItems: [],
      CustomItems: [],
      DoorItems: [],
      Depth: 0,
      Description: isSharedSection
        ? this.translations.translate(
            'sync_cabinet_section_partition_shared',
            'Profiles and Rails',
          )
        : '',
      ExtraRailWidthLeft: 0,
      ExtraRailWidthRight: 0,
      Height: 0,
      HeightReductionBacking: false,
      HeightReductionCorpusLeft: false,
      HeightReductionCorpusRight: false,
      HeightReductionInterior: false,
      Id: -1,
      Images: [],
      InteriorDepth: 0,
      InteriorFreeSpaceLeft: 0,
      InteriorFreeSpaceRight: 0,
      InteriorHeight: 0,
      InteriorMaterialId: null,
      InteriorMaterialNumber: '',
      InteriorWidth: 0,
      LeftWidth: 0,
      LightingItems: [],
      LightingProductId: null,
      ManualFishJointPositioning: false,
      ManuallyAddedItems: [],
      Name: isSharedSection ? 'Shared' : '',
      NumberOfDoors: 0,
      NumberOfOverlaps: 0,
      OverlapWidth: 0,
      PositionX: 0,
      PositionY: 0,
      PreviousConfigurationId: 0,
      RailItems: [],
      RightWidth: 0,
      Rotation: 0,
      SightHeight: 0,
      SightWidth: 0,
      SwingItems: [],
      ConfigurationItems: [],
      Width: 0,
    };
  }
}
