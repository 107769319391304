import { Injectable } from '@angular/core';
import * as Client from 'app/ts/clientDto/index';
import { MaterialType } from 'app/ts/Interface_Enums';
import { Pickable } from 'app/ts/interfaces';
import { MaterialHelper } from 'app/ts/util/MaterialHelper';
import { Rail, RailId, RailPlacement } from './rail';
import { PartitionPlanDataService } from './partition-plan-data.service';
import { PartitionPlanQueryService } from './partition-plan-query.service';
import { Section, SectionId } from './section';
import { PartitionPlanGeometryQueryService } from './partition-plan-geometry-query.service';
import { AssetService } from 'app/ts/services/AssetService';
import {
  CornerProfile,
  NicheProfile,
  StartStopProfile,
  TProfile,
} from './profile';
import { Door, DoorPlacement, Module, ModuleType } from './module';
import { Side, DoorOpenSide } from 'app/ts/clientDto/Enums';
import Enumerable from 'linq';
import { EditorTypeService } from 'app/ts/viewmodels/editor-type.service';
import { RailBuilder } from './rail-builder';

@Injectable({
  providedIn: 'root',
})
export class PartitionRailService {
  constructor(
    private data: PartitionPlanDataService,
    private query: PartitionPlanQueryService,
    private queryGeometry: PartitionPlanGeometryQueryService,
    private assetService: AssetService,
    private editorTypeService: EditorTypeService,
  ) {}

  public addRail(railData: Omit<Rail, 'id'>): RailId {
    let id = this.data.nextRailId();
    this.data.plan.rails.push({
      id,
      ...railData,
    });
    return id;
  }

  public recalculateRailPositions(sectionId: SectionId) {
    let section = this.query.getSection(sectionId);

    section.rails
      .map((railId) => this.query.getRail(railId) as Rail)
      .forEach((rail) => {
        let relSection = this.query.getSection(rail.positionedFromSection);

        let { relativeX, relativeY } = this.calculateRailRelativeOffsets(
          relSection as Section,
          rail.relativeX,
          rail.relativeY,
          rail.depth,
        );
        let pos = this.queryGeometry.calculateSectionPosMovedAlongRelativeAxes(
          rail.positionedFromSection,
          relativeX,
          relativeY,
        );

        rail.posX = pos.X;
        rail.posY = pos.Y;
        rail.rotation = relSection.isHorizontal ? 0 : 90;
      });
  }

  public recalculateSectionRails(sectionId: SectionId) {
    let affectedSections = this.findAffectedSections(sectionId);
    this.clearRails(affectedSections);

    this.recalculateWallTopRails(affectedSections);
    this.recalculateWallBottomRails(affectedSections);

    affectedSections.forEach((section) =>
      this.recalculateDoorRails(section.id),
    );
  }

  private findAffectedSections(sectionId: SectionId): Section[] {
    // Find left/top edge section
    let section = this.query.getLeftOrTopMostConnectedSection(sectionId);

    let sections: Section[] = [section as Section];
    // Traverse right
    let profile = section.isInverted
      ? this.query.getSectionLeftProfile(section.id)
      : this.query.getSectionRightProfile(section.id);
    while (profile instanceof TProfile) {
      if (profile.leftSection.sectionId == section.id)
        section = this.query.getSection(profile.rightSection.sectionId);
      else if (profile.rightSection.sectionId == section.id)
        section = this.query.getSection(profile.leftSection.sectionId);
      else break; // Middle section

      sections.push(section as Section);
      profile = section.isInverted
        ? this.query.getSectionLeftProfile(section.id)
        : this.query.getSectionRightProfile(section.id);
    }

    return sections;
  }

  private clearRails(sections: Section[]) {
    let allRailsToRemove = sections.flatMap((sec) => sec.rails);

    sections.forEach((sec) => sec.clearRails());

    this.data.plan.rails = this.data.plan.rails.filter(
      (r) => !allRailsToRemove.includes(r.id),
    );
  }

  private recalculateWallTopRails(sections: Section[]) {
    let section = this.query.getSection(sections[0].id);
    let topRailProduct =
      this.assetService.editorAssets.railSetsDict[
        section.references.wallRailSetId
      ].topProduct!;

    let railBuilder = new RailBuilder(
      this,
      this.query,
      this.queryGeometry,
      topRailProduct,
      sections[0],
      RailPlacement.Ceiling,
    );

    // Get edge rail extensions
    let firstModuleExtensions = this.query.getSectionRailExtensions(
      sections[0].id,
      RailPlacement.Ceiling,
    );
    let startExtension = sections[0].isInverted
      ? firstModuleExtensions.rightExtension
      : firstModuleExtensions.leftExtension;
    let lastModuleExtensions = this.query.getSectionRailExtensions(
      sections[sections.length - 1].id,
      RailPlacement.Ceiling,
    );
    let endExtension = sections[sections.length - 1].isInverted
      ? lastModuleExtensions.leftExtension
      : lastModuleExtensions.rightExtension;

    railBuilder.startRail(sections[0].id, -startExtension);
    railBuilder.addLength(sections[0].id, startExtension);

    let secIdx = 0;
    while (secIdx < sections.length) {
      let sec = sections[secIdx];
      let secLength: number = sec.width;

      if (secIdx < sections.length - 1) {
        secLength += TProfile.size + Module.elementSpacing * 2; // include profile size and spacing, for all but the last section
      } else if (secIdx == sections.length - 1) {
        secLength += endExtension; // For the last include the extension
      }

      railBuilder.addLength(sec.id, secLength);
      secIdx++;
    }

    railBuilder.endRail();
  }

  public calculateRailRelativeOffsets(
    section: Section,
    curXOffset: number,
    curYOffset: number,
    railDepth: number,
  ): { relativeX: number; relativeY: number } {
    return {
      relativeX: section.isInverted ? section.width - curXOffset : curXOffset,
      relativeY: section.isInverted ? railDepth - curYOffset : -curYOffset,
    };
  }

  private recalculateWallBottomRails(sections: Section[]) {
    let originSection = this.query.getSection(sections[0].id);
    let bottomRailProduct =
      this.assetService.editorAssets.railSetsDict[
        originSection.references.wallRailSetId
      ].bottomProduct!;

    let railBuilder = new RailBuilder(
      this,
      this.query,
      this.queryGeometry,
      bottomRailProduct,
      sections[0],
      RailPlacement.Floor,
    );

    let allModules = sections.flatMap((sec) => {
      let modules = this.query.getModulesForSection(sec.id);
      if (sec.isInverted) return [...modules].reverse();

      return modules;
    });

    allModules.forEach((module, index) => {
      let section = this.query.getSection(module.sectionId);

      this.handleSmallBottomRails(
        index,
        allModules,
        module,
        section as Section,
        originSection as Section,
        railBuilder,
      );

      if (module.isDoor) {
        return;
      }

      // Handle absolute left side niche profile
      if (index == 0 && this.hasAbsoluteUnevenLeftWall(section.id)) {
        railBuilder.startRail(section.id, 0); //-NicheProfile.distFromWallToStartStop
        railBuilder.addLength(section.id, NicheProfile.distFromWallToStartStop);

        let extension = this.query.getModuleRailExtension(
          module.id,
          section.isInverted ? Side.Right : Side.Left,
          RailPlacement.Floor,
        );
        railBuilder.addLength(section.id, extension);
      }

      // Handle start rail
      if (
        (index == 0 && !this.hasAbsoluteUnevenLeftWall(section.id)) ||
        (index > 0 && allModules[index - 1].isDoor)
      ) {
        let extension = this.query.getModuleRailExtension(
          module.id,
          section.isInverted ? Side.Right : Side.Left,
          RailPlacement.Floor,
        );

        // Include t-joint + start/stop on opposite side on section change
        if (index > 0 && module.sectionId != allModules[index - 1].sectionId) {
          extension *= 2; // Cover 2 * spacing + t-joint
          extension += StartStopProfile.size;
        }

        let originSectionCoord = this.getSectionLeftOrTopCoordinate(
          originSection as Section,
        );
        let sectionCoord = this.getSectionLeftOrTopCoordinate(
          section as Section,
        );
        let diff = sectionCoord - originSectionCoord;

        let modulePosX = section.isInverted
          ? module.posX + module.width - diff
          : module.posX + diff;
        railBuilder.startRail(
          section.id,
          this.getSectionLeftOrTopOffset(section as Section, modulePosX) -
            extension,
        );
        railBuilder.addLength(section.id, extension);
      }

      // Add Module
      railBuilder.addLength(section.id, module.width);

      // If next module is a wall within the same section
      if (
        index < allModules.length - 1 &&
        module.sectionId == allModules[index + 1].sectionId &&
        allModules[index + 1].isWall
      ) {
        railBuilder.addLength(module.sectionId, Module.elementSpacing);
      }
      // If next module is a wall in another section (there is a t-joint in between)
      else if (
        index < allModules.length - 1 &&
        module.sectionId != allModules[index + 1].sectionId &&
        allModules[index + 1].isWall
      ) {
        let spacing = Module.elementSpacing * 2 + TProfile.size;
        railBuilder.addLength(module.sectionId, spacing);
      }

      // Handle end rail
      if (
        (index == allModules.length - 1 &&
          !this.hasAbsoluteUnevenRightWall(section.id)) ||
        (index < allModules.length - 1 && allModules[index + 1].isDoor)
      ) {
        let extension = this.query.getModuleRailExtension(
          module.id,
          section.isInverted ? Side.Left : Side.Right,
          RailPlacement.Floor,
        );

        // Include t-joint + start/stop on opposite side on section change
        if (
          index < allModules.length - 1 &&
          module.sectionId != allModules[index + 1].sectionId
        ) {
          extension *= 2; // Cover 2 * spacing + t-joint
          extension += StartStopProfile.size;
        }

        railBuilder.addLength(section.id, extension);
        railBuilder.endRail();
      }

      // Handle absolute right side niche profile
      if (
        index == allModules.length - 1 &&
        this.hasAbsoluteUnevenRightWall(section.id)
      ) {
        let extension = this.query.getModuleRailExtension(
          module.id,
          section.isInverted ? Side.Left : Side.Right,
          RailPlacement.Floor,
        );
        railBuilder.addLength(
          section.id,
          extension + NicheProfile.distFromWallToStartStop,
        );
        railBuilder.endRail();
      }
    });
  }

  private handleSmallBottomRails(
    index: number,
    allModules: Module[],
    module: Module,
    section: Section,
    originSection: Section,
    railBuilder: RailBuilder,
  ) {
    if (module.isDoor) {
      let railDiff =
        (railBuilder.railProductData.DefaultDepth - Module.fullJointWidth) / 2;

      let originSectionCoord = this.getSectionLeftOrTopCoordinate(
        originSection as Section,
      );
      let sectionCoord = this.getSectionLeftOrTopCoordinate(section as Section);
      let diff = sectionCoord - originSectionCoord;

      // Door specific cases

      if (index == 0) {
        let profile = section.isInverted
          ? this.query.getSectionRightProfile(section.id)
          : this.query.getSectionLeftProfile(section.id);

        if (profile) {
          if (
            profile instanceof TProfile &&
            profile.middleSection.sectionId == section.id
          ) {
            let extension = Module.elementSpacing - railDiff;

            let modulePosX = section.isInverted
              ? module.posX + module.width - diff
              : module.posX + diff;

            railBuilder.startRail(
              section.id,
              this.getSectionLeftOrTopOffset(section as Section, modulePosX) -
                extension,
            );
            railBuilder.addLength(
              section.id,
              Module.elementSpacing + StartStopProfile.size - railDiff,
            );
            railBuilder.endRail(true);
          } else if (profile instanceof CornerProfile) {
            let extension = StartStopProfile.size;

            let modulePosX = section.isInverted
              ? module.posX + module.width - diff
              : module.posX + diff;

            railBuilder.startRail(
              section.id,
              this.getSectionLeftOrTopOffset(section as Section, modulePosX) -
                extension,
            );
            railBuilder.addLength(
              section.id,
              Module.elementSpacing +
                StartStopProfile.size +
                CornerProfile.size +
                2,
            );
            railBuilder.endRail(true);
          }
        }
      } else if (index == allModules.length - 1) {
        let profile = section.isInverted
          ? this.query.getSectionLeftProfile(section.id)
          : this.query.getSectionRightProfile(section.id);

        if (profile) {
          if (
            profile instanceof TProfile &&
            profile.middleSection.sectionId == section.id
          ) {
            let extension = StartStopProfile.size;

            let modulePosX = section.isInverted
              ? module.posX - diff
              : module.posX + module.width + diff;

            railBuilder.startRail(
              section.id,
              this.getSectionLeftOrTopOffset(section as Section, modulePosX) -
                extension,
            );
            railBuilder.addLength(
              section.id,
              Module.elementSpacing + StartStopProfile.size - railDiff,
            );
            railBuilder.endRail(true);
          } else if (profile instanceof CornerProfile) {
            let extension = StartStopProfile.size;

            let modulePosX = section.isInverted
              ? module.posX - diff
              : module.posX + module.width + diff;

            railBuilder.startRail(
              section.id,
              this.getSectionLeftOrTopOffset(section as Section, modulePosX) -
                extension,
            );
            railBuilder.addLength(
              section.id,
              Module.elementSpacing +
                StartStopProfile.size +
                CornerProfile.size +
                2,
            );
            railBuilder.endRail(true);
          }
        }
      } else if (
        allModules[index + 1].isDoor &&
        module.sectionId != allModules[index + 1].sectionId
      ) {
        // Doors on both sides of the t-joint

        let extension = StartStopProfile.size;

        let modulePosX = section.isInverted
          ? module.posX + diff
          : module.posX + module.width - diff;

        railBuilder.startRail(
          section.id,
          this.getSectionLeftOrTopOffset(section as Section, modulePosX) -
            extension,
        );
        railBuilder.addLength(
          section.id,
          2 * Module.elementSpacing + TProfile.size + 2 * StartStopProfile.size,
        );
        railBuilder.endRail();
      }
    }
  }

  private hasAbsoluteUnevenLeftWall(sectionId: SectionId): boolean {
    let section = this.query.getSection(sectionId);
    return (
      (!section.isInverted && section.unevenLeftWall) ||
      (section.isInverted && section.unevenRightWall)
    );
  }

  private hasAbsoluteUnevenRightWall(sectionId: SectionId): boolean {
    let section = this.query.getSection(sectionId);
    return (
      (!section.isInverted && section.unevenRightWall) ||
      (section.isInverted && section.unevenLeftWall)
    );
  }

  private getSectionLeftOrTopOffset(section: Section, offset: number): number {
    return section.isInverted ? section.width - offset : offset;
  }

  private getSectionLeftOrTopCoordinate(section: Section): number {
    if (section.isHorizontal) {
      return section.isInverted ? section.posX - section.width : section.posX;
    } else {
      return section.isInverted ? section.posY - section.width : section.posY;
    }
  }

  private recalculateDoorRails(sectionId: SectionId) {
    let section = this.query.getSection(sectionId);
    let sectionModules = this.query.getModulesForSection(sectionId);
    let topRailProduct =
      this.assetService.editorAssets.railSetsDict[
        section.references.doorRailSetId
      ].topProduct;
    let bottomRailProduct =
      this.assetService.editorAssets.railSetsDict[
        section.references.doorRailSetId
      ].bottomProduct;
    let topProductData = topRailProduct?.getProductData();
    let bottomProductData = bottomRailProduct?.getProductData();

    let doorIndex = sectionModules.findIndex((md) => md.isDoor);
    while (doorIndex != -1) {
      let door = sectionModules[doorIndex] as Door;
      let yOffset =
        door.placement == DoorPlacement.Front
          ? -topProductData!.Model3DDepth
          : topProductData!.Model3DDepth;

      let leftEndStop = door.hasLeftEndStop ? Door.endStopExtraWidth : 0;
      let rightEndStop = door.hasRightEndStop ? Door.endStopExtraWidth : 0;
      let topWidth: number;
      let bottomWidth: number;

      switch (door.openDirection) {
        case DoorOpenSide.Left: {
          topWidth =
            door.leftSlideExtensionAmount +
            door.width +
            leftEndStop +
            rightEndStop;
          bottomWidth = door.leftSlideExtensionAmount + door.width;
          break;
        }
        case DoorOpenSide.Right: {
          topWidth =
            door.rightSlideExtensionAmount +
            door.width +
            leftEndStop +
            rightEndStop;
          bottomWidth = door.rightSlideExtensionAmount + door.width;
          break;
        }
        case DoorOpenSide.Both: {
          topWidth =
            door.leftSlideExtensionAmount +
            door.rightSlideExtensionAmount +
            door.width +
            leftEndStop +
            rightEndStop;
          bottomWidth =
            door.leftSlideExtensionAmount +
            door.rightSlideExtensionAmount +
            door.width;
          break;
        }
      }

      // Handle double doors
      if (
        sectionModules.length > doorIndex + 1 &&
        sectionModules[doorIndex + 1].isDoor
      ) {
        let secondDoor = sectionModules[doorIndex + 1] as Door;
        if (door.openDirection == DoorOpenSide.Left) {
          topWidth += secondDoor.width + secondDoor.rightSlideExtensionAmount;
          bottomWidth +=
            secondDoor.width + secondDoor.rightSlideExtensionAmount;
        } else {
          topWidth += secondDoor.width + secondDoor.leftSlideExtensionAmount;
          bottomWidth += secondDoor.width + secondDoor.leftSlideExtensionAmount;
        }
        doorIndex++;

        let rightEndStopOther = secondDoor.hasRightEndStop
          ? Door.endStopExtraWidth
          : -rightEndStop;
        topWidth += rightEndStopOther;
      }

      let posX = 0; //door.openDirection == Side.Left ? door.posX - door.slideExtensionAmount - leftEndStop : door.posX + width
      if (section.isInverted) {
        // Offset when inverted double doors
        if (this.query.getNeighborModule(door.id, Side.Right)?.isDoor) {
          let neighborModule = this.query.getNeighborModule(
            door.id,
            Side.Right,
          ) as Door;
          posX =
            door.posX +
            door.width * 2 +
            neighborModule.rightSlideExtensionAmount;
        } else if (door.openDirection == DoorOpenSide.Both)
          posX = door.posX + door.width + door.rightSlideExtensionAmount;
        else
          posX =
            door.openDirection == DoorOpenSide.Right
              ? door.posX +
                door.width +
                door.rightSlideExtensionAmount +
                leftEndStop
              : door.posX + door.width;
      } else {
        posX =
          door.openDirection == DoorOpenSide.Right
            ? door.posX
            : door.posX - door.leftSlideExtensionAmount - leftEndStop;
      }

      let xOffset = this.getSectionLeftOrTopOffset(section as Section, posX);

      let topY = (topProductData!.Model3DDepth - Module.fullJointWidth) / 2;
      this.addRailsSplitted(
        sectionId,
        xOffset,
        yOffset + topY,
        topWidth,
        topProductData!.MinWidth,
        topProductData!.MaxWidth,
        RailPlacement.Ceiling,
        ModuleType.Door,
        topRailProduct!.Id,
        topProductData!.DefaultDepth,
        topProductData!.DefaultHeight,
        door.hasLeftEndStop,
        door.hasRightEndStop,
        door.placement,
      );
      let bottomY =
        (bottomProductData!.Model3DDepth - Module.fullJointWidth) / 2;
      this.addRailsSplitted(
        sectionId,
        xOffset,
        yOffset + bottomY,
        bottomWidth,
        bottomProductData!.MinWidth,
        bottomProductData!.MaxWidth,
        RailPlacement.Floor,
        ModuleType.Door,
        bottomRailProduct!.Id,
        bottomProductData!.DefaultDepth,
        topProductData!.DefaultHeight,
        door.hasLeftEndStop,
        door.hasRightEndStop,
        door.placement,
      );

      doorIndex = sectionModules.findIndex(
        (md, idx) => md.isDoor && idx > doorIndex,
      );
    }
  }

  private addRailsSplitted(
    sectionId: SectionId,
    startX: number,
    startY: number,
    totalWidth: number,
    minWidth: number,
    maxWidth: number,
    placement: RailPlacement,
    moduleType: ModuleType,
    productId: number,
    defaultDepth: number,
    defaultHeight: number,
    hasLeftEndStop: boolean,
    hasRightEndStop: boolean,
    doorPlacement?: DoorPlacement,
  ) {
    let section = this.query.getSection(sectionId);
    let numberOfProfilesNeeded = Math.ceil(totalWidth / maxWidth);

    let isSectionMaterialValid = this.isMaterialValidForRail(
      sectionId,
      productId,
    );

    let rotation = section.isHorizontal ? 0 : 90;

    let rails: RailId[] = [];
    let posX = startX;
    for (let i = 0; i < numberOfProfilesNeeded - 1; i++) {
      let { relativeX, relativeY } = this.calculateRailRelativeOffsets(
        section as Section,
        posX,
        startY,
        defaultDepth,
      );
      let pos = this.queryGeometry.calculateSectionPosMovedAlongRelativeAxes(
        sectionId,
        relativeX,
        relativeY,
      );

      rails.push(
        this.addRail({
          relativeX: posX,
          relativeY: startY,
          posX: pos.X,
          posY: pos.Y,
          positionedFromSection: sectionId,
          width: maxWidth,
          depth: defaultDepth,
          height: defaultHeight,
          rotation,
          productId: productId,
          placement,
          doorPlacement,
          hasLeftEndStop,
          hasRightEndStop,
          moduleType,
          materialId: isSectionMaterialValid
            ? section.references.profileMaterialId
            : this.getDefaultRailMaterial()?.Id,
          positionNo: -1,
        }),
      );
      posX += maxWidth;
    }

    // Make sure the last peice is not smaller than minWidth
    if (totalWidth % maxWidth < minWidth) {
      let diff = minWidth - (totalWidth % maxWidth);
      this.data.plan.rails[rails.length - 1].width -= diff;

      let newX = posX - diff;
      let { relativeX, relativeY } = this.calculateRailRelativeOffsets(
        section as Section,
        newX,
        startY,
        defaultDepth,
      );
      let pos = this.queryGeometry.calculateSectionPosMovedAlongRelativeAxes(
        sectionId,
        relativeX,
        relativeY,
      );

      rails.push(
        this.addRail({
          relativeX: newX,
          relativeY: startY,
          posX: pos.X,
          posY: pos.Y,
          positionedFromSection: sectionId,
          width: minWidth,
          depth: defaultDepth,
          height: defaultHeight,
          rotation,
          productId: productId,
          placement: placement,
          doorPlacement,
          hasLeftEndStop,
          hasRightEndStop,
          moduleType,
          materialId: isSectionMaterialValid
            ? section.references.profileMaterialId
            : this.getDefaultRailMaterial()?.Id,
          positionNo: -1,
        }),
      );
    } else {
      let { relativeX, relativeY } = this.calculateRailRelativeOffsets(
        section as Section,
        posX,
        startY,
        defaultDepth,
      );
      let pos = this.queryGeometry.calculateSectionPosMovedAlongRelativeAxes(
        sectionId,
        relativeX,
        relativeY,
      );

      rails.push(
        this.addRail({
          relativeX: posX,
          relativeY: startY,
          posX: pos.X,
          posY: pos.Y,
          positionedFromSection: sectionId,
          width: totalWidth % maxWidth,
          depth: defaultDepth,
          height: defaultHeight,
          rotation,
          productId: productId,
          placement: placement,
          doorPlacement,
          hasLeftEndStop,
          hasRightEndStop,
          moduleType,
          materialId: isSectionMaterialValid
            ? section.references.profileMaterialId
            : this.getDefaultRailMaterial()?.Id,
          positionNo: -1,
        }),
      );
    }

    rails.forEach((rl) => section.rails.push(rl));
  }

  public isMaterialValidForRail(sectionId: SectionId, productId: number) {
    let section = this.query.getSection(sectionId);
    let pickableMats = this.getPickableRailMaterials(productId);
    return (
      pickableMats.findIndex(
        (pick) => pick.item.Id == section.references.profileMaterialId,
      ) != -1
    );
  }

  private getPickableRailMaterials(
    productId: number,
  ): Pickable<Client.ProductMaterial>[] {
    let allMaterials = this.assetService.editorAssets.productsDict[
      productId
    ].materials.filter((mat) => mat.Type == MaterialType.Frame);

    let fullCatalog =
      this.assetService.editorAssets.fullCatalog &&
      this.editorTypeService.floorPlan.FullCatalogAllowOtherMaterials;
    let pickable = allMaterials
      .filter((mat) => !mat.isDiscontinued && (!mat.isOverride || fullCatalog))
      .map((mat) => {
        let pickable: Pickable<Client.ProductMaterial> = {
          ...MaterialHelper.getPickable(mat),
          isSelected: false,
          override: mat.isOverride,
        };

        return pickable;
      });

    MaterialHelper.sortPickableMaterials(pickable);

    return pickable;
  }

  public getRailMaterialId(sectionIds: SectionId[], productId: number): number {
    if (sectionIds.length == 0) return this.getDefaultRailMaterial().Id;

    let isValidForAllSections = sectionIds.every((secId) =>
      this.isMaterialValidForRail(secId, productId),
    );
    let sections = sectionIds.map((secId) => this.query.getSection(secId));
    let isSameForAllSections = sections.every(
      (sec) =>
        sec.references.profileMaterialId ==
        sections[0].references.profileMaterialId,
    );

    if (isSameForAllSections && isValidForAllSections)
      return sections[0].references.profileMaterialId;
    else return this.getDefaultRailMaterial().Id;
  }

  public getDefaultRailMaterial() {
    let allMaterials = this.assetService.editorAssets.materials.filter(
      (mat) => mat.Type == MaterialType.Frame,
    );

    // Find pickables, that actually have a material
    let pickableMaterials = Enumerable.from(allMaterials);

    // Sort the materials, and take the first one
    let railsetMaterial = pickableMaterials
      .orderBy((m) => m!.SortOrder)
      .firstOrDefault();
    return railsetMaterial ? railsetMaterial : allMaterials[0];
  }
}
