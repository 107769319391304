import * as Client from '@ClientDto/index';
import * as Enums from '@ClientDto/Enums';
import { ConfigurationItem } from '../Interface_DTO';
import * as Interface_DTO_Draw from 'app/ts/Interface_DTO_Draw';
import Enumerable from 'linq';
import { ItemType } from '../Interface_Enums';
import { SwingFlexSubArea } from './SwingFlexSubArea';
import { CabinetSectionProperties } from '../properties/CabinetSectionProperties';
import { MaterialHelper } from '@Util/MaterialHelper';

export class SwingFlexArea {
  public index: number = -1;

  public desiredInsideWidth: number = -1;
  public widthAdjustedByUser: boolean = false;

  private _items: Client.ConfigurationItem[] = [];

  public get items(): Readonly<Client.ConfigurationItem[]> {
    return this._items;
  }

  private _areaSplitItems: Client.ConfigurationItem[] = [];
  public get areaSplitItems(): Readonly<Client.ConfigurationItem[]> {
    return this._areaSplitItems;
  }
  public get drawers(): Client.ConfigurationItem[] {
    return this.areaSplitItems.filter((i) => i.isPullout);
  }

  private _gripItems: Client.ConfigurationItem[] = [];
  public get gripItems(): Readonly<Client.ConfigurationItem[]> {
    return this._gripItems;
  }

  public hinges: SwingFlexArea.Hinge[] = [];

  public get doorMaterial(): Client.Material | null {
    return this.section?.swingFlex.doorMaterial ?? null;
  }

  public doorCount: number = 2; // Loading middle gable fails, if this is less than 2. Door count gets recalculated later, so it should not be a problem.
  public get openingMethod(): Enums.SwingFlexOpeningMethod {
    return this.section!.swingFlex.doorOpeningMethod;
  }

  public possibleHingeSides: Enums.HingeSide[] = [];
  public hingeSide: Enums.HingeSide = Enums.HingeSide.None;
  public get doorGrip(): Client.Product | null {
    return this.section.swingFlex.doorGrip;
  }
  public get doorGripMaterial(): Client.Material | null {
    return this.section.swingFlex.doorGripMaterial;
  }
  public subAreas: SwingFlexSubArea[] = [];

  public insideRect: Client.Rectangle = new Client.Rectangle();

  public get fullHeightRect(): Client.Rectangle {
    if (!this.section) return this.insideRect;

    let fullHeightRect = new Client.Rectangle(this.insideRect);
    fullHeightRect.Y = 0;
    fullHeightRect.Height = this.section.Height;
    return fullHeightRect;
  }

  public hasPlinth: boolean = false;

  public leftGableInfo: CabinetSectionProperties.SwingFlexGableInfo;
  public leftGableItem?: Client.ConfigurationItem;

  public middlePanelItem?: Client.ConfigurationItem | null;

  constructor(
    private readonly section: Client.CabinetSection,
    areaIndex: number,
    areaItems: Client.ConfigurationItem[],
  ) {
    let properties = { ...CabinetSectionProperties.SwingFlexArea.defaultValue };
    if (section && areaIndex !== undefined && areaItems) {
      this.index = areaIndex;

      this._items = areaItems.filter(
        (item) => item.ItemType !== ItemType.SwingFlexCorpusMovable,
      );
      this._areaSplitItems = areaItems.filter(
        (item) =>
          item.ItemType === ItemType.SwingFlexCorpusMovable && !item.isGrip,
      );

      let areas = section.properties.swingFlex?.areas;
      if (areas && areaIndex < areas.length) {
        properties = areas[areaIndex];
      } else {
        // !KGN
        console.warn(
          '!KGN Could not restore SwingFlexArea - missing areas in section',
        );
      }
    }

    this.desiredInsideWidth = properties.desiredWidth ?? -1;
    this.widthAdjustedByUser = properties.desiredWidth !== null;
    this.hingeSide = properties.hingeSide;
    this.leftGableInfo = { ...properties.leftGable };

    this.subAreas = properties.subAreas.map(
      (sa) => new SwingFlexSubArea(this, sa, section?.swingFlex),
    );
  }

  public save(): CabinetSectionProperties.SwingFlexArea {
    return {
      desiredWidth: this.widthAdjustedByUser ? this.desiredInsideWidth : null,
      hingeSide: this.hingeSide,
      leftGable: { ...this.leftGableInfo },
      subAreas: this.subAreas.map((s) => s.save()),
    };
  }

  public get insideWidth(): number {
    return this.insideRect.Width;
  }

  public get insideHeight(): number {
    return this.insideRect.Height;
  }

  public get gables(): ConfigurationItem[] {
    return this.items.filter((item) => item.isGable).sort((a, b) => a.X - b.X);
  }

  get areaItems(): Client.ConfigurationItem[] {
    let itemTypeOrder: ItemType[] = [
      ItemType.SwingFlexCorpus,
      ItemType.SwingFlexBacking,
    ];

    let result = Enumerable.from(this.items)
      .where((item) => item.ItemType !== ItemType.SwingFlexDoor)
      .concat(this.areaSplitItems)
      .orderBy((item) => itemTypeOrder.indexOf(item.ItemType))
      .thenByDescending((item) => item.topY)
      .thenBy((item) => item.X)
      .toArray();

    return result;
  }

  public removeGeneratedItems() {
    this.removeAllItems();
    this.removeAllGripItems();
    this.hinges.length = 0;
    this.hasPlinth = false;
  }

  public addItem(item: Client.ConfigurationItem) {
    if (!item) return;
    item.swingFlexAreaIndex = this.index;
    if (this._items.indexOf(item) < 0) {
      //only add item if it's not already there
      this._items.push(item);
    } else {
      // !KGN
      console.warn(
        '!KGN Attempt to add item to a SwingFlexArea twice - this is likely an error',
      );
    }
  }
  public removeItem(item: Client.ConfigurationItem): boolean {
    let index = this._items.indexOf(item);
    if (index < 0) return false;
    this._items.slice(index, 1);
    return true;
  }
  public removeAllItems() {
    this._items = [];
  }

  public addAreaSplitItem(item: Client.ConfigurationItem) {
    item.swingFlexAreaIndex = this.index;
    if (this._areaSplitItems.indexOf(item) < 0) {
      //only add item if it's not already there
      this._areaSplitItems.push(item);
      this.sortAreaSplitItems();
    } else {
      // !KGN
      console.warn(
        '!KGN Attempt to add AreaSplitItem to a SwingFlexArea twice - this is likely an error',
      );
    }
  }
  public sortAreaSplitItems() {
    this._areaSplitItems.sort((i1, i2) => i1.Y - i2.Y);
  }
  public removeAreaSplitItem(item: Client.ConfigurationItem): boolean {
    let i = this._areaSplitItems.indexOf(item);
    if (i < 0) return false;
    this._areaSplitItems.splice(i, 1);
    return true;
  }

  public addGripItem(item: Client.ConfigurationItem) {
    item.swingFlexAreaIndex = this.index;
    if (this._gripItems.indexOf(item) < 0) {
      this._gripItems.push(item);
    } else {
      // !KGN
      console.warn(
        '!KGN Attempt to add GripItem to a SwingFlexArea twice - this is likely an error',
      );
    }
  }
  public removeAllGripItems() {
    this._gripItems = [];
  }

  public setDrawerGripProduct(
    drawer: Client.ConfigurationItem | undefined,
    gripProduct: Client.Product | null,
  ) {
    if (!drawer) {
      for (let d of this.drawers) {
        if (!d) continue;
        this.setDrawerGripProduct(d, d.canHaveGrip ? gripProduct : null);
      }
      return;
    }
    drawer.gripProduct = drawer.canHaveGrip ? gripProduct : null;
    drawer.gripMaterial = MaterialHelper.getDefaultMaterial(
      gripProduct?.materials,
    );
  }
  public getDrawerGripProduct(
    drawer: Client.ConfigurationItem,
  ): Client.Product | null {
    return drawer.gripProduct ?? null;
  }
  public setDrawerGripMaterial(
    drawer: Client.ConfigurationItem | undefined,
    gripMaterial: Client.Material | null,
  ) {
    if (!drawer) {
      for (let d of this.drawers) {
        if (!d) continue;
        this.setDrawerGripMaterial(d, gripMaterial);
      }
      return;
    }
    drawer.gripMaterial = gripMaterial;
  }
  public getDrawerGripMaterial(
    drawer: Client.ConfigurationItem,
  ): Client.Material | null {
    return (
      drawer.gripMaterial ?? this.section?.swingFlex.drawerGripMaterial ?? null
    );
  }
}

export module SwingFlexArea {
  export interface Hinge extends Interface_DTO_Draw.Cube {
    hingeType: Enums.SwingFlexHingeType;
    centerX: number;
    centerY: number;
    doorOffset: number;
  }
}
