import * as Interface_DTO from 'app/ts/Interface_DTO';
import * as Interface_Enums from 'app/ts/Interface_Enums';
import * as Client from 'app/ts/clientDto/index';
import * as Exception from 'app/ts/exceptions';
import { ObjectHelper } from 'app/ts/util/ObjectHelper';
import { ScreenshotService } from 'app/screenshooting/screenshot.service';
import { Inject, Injectable } from '@angular/core';
import { FloorPlanService } from './FloorPlanService';
import { PromisingBackendService } from 'app/backend-service/promising-backend-service';
import { TranslationService } from './TranslationService';
import { PermissionService } from 'app/identity-and-access/permission.service';
import { NotificationService } from './NotificationService';
import { CustomerService } from './CustomerService';
import { ErrorService } from './ErrorService';
import { AssetService } from './AssetService';
import {
  ActiveUser,
  ActiveUserInjector,
} from 'app/functional-core/ambient/activeUser/ActiveUser';
import { User } from 'app/identity-and-access/User';
import { calculateExtraVariants } from '../../floor-plan-editing/VariantCalculation';
import { PartitionPlanQueryService } from 'app/partition/partition-plan-query.service';
import { PartitionPlanCommandService } from 'app/partition/partition-plan-command.service';
import { MeasurementService } from 'app/measurement/measurement.service';
import { PartitionPlanDataService } from 'app/partition/partition-plan-data.service';
type SaveFloorPlanOptions = {
  deliveryInfoEditor: (
    deliveryInfo: Interface_DTO.DeliveryInfo,
  ) => Promise<Client.DeliveryInfoChanges>;
  deliveryInfoAcceptor: (
    deliveryInfo: Interface_DTO.DeliveryInfo,
    requestedDeliveryWeek: number,
  ) => Promise<boolean>;
  showNotifications: boolean;
};

/**
 * Probably not the best name. It works in close cooperation
 * with @see FloorPlanService and @see ScreenshotService
 * to save floor plans with matching screen shots.
 *
 * It was introduced to break an indirect circular
 * reference between @see FloorPlanService and
 * @see ScreenshotService and basically moved some
 * functionality from both those services into itself.
 */
@Injectable()
export class FloorPlanSaveService {
  private user!: User;
  constructor(
    private readonly floorPlanService: FloorPlanService,
    private readonly screenshotService: ScreenshotService,
    private readonly translationService: TranslationService,
    private readonly customerService: CustomerService,
    private readonly assetService: AssetService,

    private readonly permissions: PermissionService,

    private readonly notificationService: NotificationService,
    private readonly errorService: ErrorService,
    private readonly partition: PartitionPlanQueryService,
    private readonly partitionData: PartitionPlanDataService,
    private readonly partitionCommands: PartitionPlanCommandService,
    private readonly measurementService: MeasurementService,
    private readonly httpClient: PromisingBackendService,
    @Inject(ActiveUserInjector) activeUser: ActiveUser,
  ) {
    activeUser.subscribe((user) => {
      if (user != null) this.user = user;
    });
  }

  public async createDefaultFloorPlan(
    name?: string | null,
    template?: Interface_DTO.FloorPlanTemplate,
  ): Promise<Client.FloorPlan> {
    let pdaId =
      await this.customerService.getDefaultProjectDeliveryAddressIdForCurrentStore();
    if (!name) {
      let suggestedName = template
        ? template.Name
        : this.translationService.translate(
            'floorPlan_new_floorPlan_defaultName',
            'New Room',
          );
      name = window.prompt(
        this.translationService.translate(
          'floorPlan_new_floorPlan_name',
          'Name: ',
        ),
        suggestedName,
      );
    }
    if (!name) throw new Exception.CancelException();
    let result = await this.createFloorPlan(name, pdaId, template);
    result.isAddressSet = false;
    return result;
  }

  public async createFloorPlan(
    name: string,
    projectDeliveryAddressId: number,
    template?: Interface_DTO.FloorPlanTemplate,
  ): Promise<Client.FloorPlan> {
    name = ObjectHelper.sanitizeString(name);
    const editorAssets = await this.assetService.editorAssetsPromise();

    // Find on floorplanservice
    const result: Client.FloorPlan =
      await this.floorPlanService.getNewFloorPlan(
        name,
        projectDeliveryAddressId,
        editorAssets,
        template,
      );

    this.partitionData.newPartitionPlan(result);
    this.measurementService.newPlan();

    await this.saveFloorPlan(result, {
      deliveryInfoAcceptor: (
        di: Interface_DTO.DeliveryInfo,
        requestedDeliveryWeek: number,
      ) => Promise.resolve(true),
      deliveryInfoEditor: (di: Interface_DTO.DeliveryInfo) =>
        Promise.resolve({
          ...di,
          InternalNote: result.InternalNote,
          UserId: result.UserId,
          Name: result.Name,
        }),
      showNotifications: false,
    });

    if (result.Id) {
      this.floorPlanService.addToCache(result.Id, Promise.resolve(result));
    }

    //this.customerService.updateCustomerList();
    return result;
  }

  public async saveFloorPlan(
    floorPlan: Client.FloorPlan,
    options: SaveFloorPlanOptions,
  ): Promise<void> {
    let result = await this.saveFloorPlanInternal(floorPlan, options);
    this.customerService.updateCustomerList(false);
  }

  private async saveFloorPlanInternal(
    floorPlan: Client.FloorPlan,
    options: SaveFloorPlanOptions,
  ): Promise<Client.FloorPlan> {
    let now = new Date();
    let correctionDeadline = floorPlan.correctionDeadline;
    if (correctionDeadline && correctionDeadline < now)
      throw new Exception.CorrectionDeadlineExceeded(correctionDeadline);

    if (this.permissions.canSetSupporter) {
      floorPlan.SupporterId = this.user.UserId;
    }

    if (floorPlan.editorAssets.fullCatalog) {
      if (!floorPlan.UsesFullCatalog) {
        let question = this.translationService.translate(
          'floorPlan_save_downgrade_full_catalog',
          'You have activated full catalog. If you save the solution with full catalog, normal users will no longer be able to open the solution. Do you want to save?',
        );
        var answer = window.confirm(question);
        if (!answer) {
          throw new Exception.CancelException();
        }
      }
      floorPlan.UsesFullCatalog = true;
    }

    //Calculate extra variants - floorplan, cabinet, section
    calculateExtraVariants(floorPlan);

    // Get on floorplanservice
    let dtoFloorPlan = this.floorPlanService.getCompleteFloorPlan(floorPlan);

    let saveRequest: Interface_DTO.FloorPlanSaveRequest = {
      FloorPlan: dtoFloorPlan,
      DeliveryInfo: null,
      UpgradeFromOldCabinetId: floorPlan.oldCabinetId || null,
    };

    let recommitOrder =
      floorPlan.DeliveryStatus !== Interface_Enums.DeliveryStatus.Quote;

    let partitionSectionSelection = this.partitionData.selectedSection;
    let partitionModuleSelection = this.partitionData.selectedModule;
    this.partitionCommands.deselectSection();

    let selectedMeasurement = this.measurementService.selectedMeasurement;
    this.measurementService.deselect();

    let acceptOrderPromise: Promise<boolean> | null = null;
    if (recommitOrder) {
      let deliveryInfo = await this.httpClient.get<Interface_DTO.DeliveryInfo>(
        'api/order/deliveryInfo/' + dtoFloorPlan.DeliveryInfoId,
      );

      let updatedDeliveryInfo = await options.deliveryInfoEditor(deliveryInfo);
      saveRequest.DeliveryInfo = updatedDeliveryInfo;
      dtoFloorPlan.InternalNote = updatedDeliveryInfo.InternalNote;
      dtoFloorPlan.UserId = updatedDeliveryInfo.UserId;
      dtoFloorPlan.Name = updatedDeliveryInfo.Name;

      acceptOrderPromise = this.httpClient
        .post<Interface_DTO.DeliveryInfo>(
          'api/order/validateFloorplan',
          saveRequest,
        )
        .then((deliveryInfo) =>
          options.deliveryInfoAcceptor(
            deliveryInfo,
            updatedDeliveryInfo.DeliveryWeek,
          ),
        );
    }

    let floorPlanIdResolver: (c: number) => void;
    let screenshotUploadRejector: () => void;
    let floorPlanIdPromise = new Promise<number>((resolver, rejector) => {
      floorPlanIdResolver = resolver;
      screenshotUploadRejector = rejector;
    });

    //don't send screenshots when the solution is still in status quote

    var shouldSendAllScreenshots = recommitOrder;
    let screenshotsPromise = shouldSendAllScreenshots
      ? this.sendScreenshots(floorPlan, floorPlanIdPromise)
      : this.sendFloorPlanScreenshot(floorPlan, floorPlanIdPromise);

    let floorPlanId: number;
    if (acceptOrderPromise) {
      let acceptOrder = await acceptOrderPromise;
      if (!acceptOrder) {
        screenshotUploadRejector!();
        throw new Exception.CancelException();
      }

      //This is what actually saves the floorplan:
      floorPlanId = await this.httpClient.post<number>(
        'api/order/recommit',
        saveRequest,
        { timeout: 30000 },
      );

      floorPlan.Name = saveRequest.FloorPlan.Name;
      floorPlan.InternalNote = saveRequest.FloorPlan.InternalNote;
      floorPlan.UserId = saveRequest.FloorPlan.UserId;
    } else {
      let newCompleteFloorPlan =
        await this.httpClient.post<Interface_DTO.FloorPlan>(
          'api/floorPlan',
          saveRequest,
          { timeout: 30000 },
        );
      floorPlanId = floorPlan.Id = newCompleteFloorPlan.Id!;
      floorPlan.DeliveryInfoId = newCompleteFloorPlan.DeliveryInfoId;
    }

    try {
      // Check if the floorplan has been saved and returned valid floorplan id. If not, do not proceed and call other services.
      if (isNaN(floorPlanId)) {
        throw new Error(floorPlanId.toString());
      }

      floorPlanIdResolver!(floorPlanId);
      await screenshotsPromise;
      floorPlan.isDirty = false;
      floorPlan.oldCabinetId = undefined;

      // find on floorplanservice
      this.floorPlanService.autosaveDeleteFromStorage(floorPlan.Id);

      if (options.showNotifications) {
        if (recommitOrder) {
          this.notificationService.success(
            'floorPlan_save_recommit_order_success',
            'The order has been changed',
          );
        } else {
          this.notificationService.success(
            'floorPlan_save_success',
            'The solution has been saved',
          );
        }
      }
    } catch (e: any) {
      let errorId = await this.errorService.reportError(
        'floorPlan_save_screenshot_failed',
        e,
      );
      if (options.showNotifications) {
        if (typeof errorId == 'number') {
          this.notificationService.warning(
            'floorPlan_save_screenshot_failed_error_reported',
            'Saving failed. Please try again. Error ID {0}',
            errorId.toString(),
          );
        } else {
          this.notificationService.warning(
            'floorPlan_save_screenshot_failed',
            'Saving failed. Please try again.',
          );
        }
      }
    }

    if (partitionSectionSelection != null)
      this.partitionCommands.setSelection(
        partitionSectionSelection.id,
        partitionModuleSelection?.id,
      );

    if (selectedMeasurement != null)
      this.measurementService.selectMeasurement(selectedMeasurement.id);

    return floorPlan;
  }

  /**
   * Generates and uploads screenshots to the server.
   * @param floorPlanId
   * @param confirmer if not null, waits for the confirmer promise before actually uploading the screenshots, so you can abort the sending if needed
   */
  public sendScreenshots(
    floorPlanId: number,
    floorPlanIdPromise?: Promise<number>,
  ): Promise<void>;
  /**
   * Generates and uploads screenshots to the server.
   * @param floorPlan
   * @param confirmer if not null, waits for the confirmer promise before actually uploading the screenshots, so you can abort the sending if needed
   */
  public sendScreenshots(
    floorPlan: Client.FloorPlan,
    floorPlanIdPromise?: Promise<number>,
  ): Promise<void>;
  public async sendScreenshots(
    val: number | Client.FloorPlan,
    floorPlanIdPromise?: Promise<number>,
  ) {
    console.time('sendScreenshots');
    let floorPlanId: number | null = null;
    let floorPlan: Client.FloorPlan;
    if (typeof val === 'number') {
      floorPlanId = val;
      floorPlan = await this.floorPlanService.getFloorPlan(floorPlanId);
    } else {
      floorPlan = val;
      floorPlanId = floorPlan.Id;
    }

    let reloadPartition = typeof val === 'number'; //Reload if ordered from overview
    let screenshotPromises = this.screenshotService.getAllScreenshots(
      floorPlan,
      reloadPartition,
    );

    if (floorPlanIdPromise) {
      try {
        floorPlanId = await floorPlanIdPromise;
      } catch (e: any) {
        return;
      }
    }
    if (!floorPlanId || isNaN(floorPlanId)) return;

    await this.httpClient.delete<void>(
      'api/floorplan/' + floorPlanId + '/screenshots',
      { responseType: 'void' },
    );

    let screenshotSendPromises = screenshotPromises.map((ssp) =>
      ssp.then((screenshot) => {
        return this.httpClient.post<void>(
          'api/floorplan/' + floorPlanId + '/screenshot',
          screenshot,
          { responseType: 'void' },
        );
      }),
    );
    await Promise.all(screenshotSendPromises);
    await this.httpClient.post<void>(
      'api/floorplan/' + floorPlanId + '/screenshotCompleted',
      null,
      { responseType: 'void' },
    );
    console.timeEnd('sendScreenshots');
  }

  private async sendFloorPlanScreenshot(
    floorPlan: Client.FloorPlan,
    floorPlanIdPromise: Promise<number>,
  ): Promise<void> {
    let screenshotPromises = this.screenshotService.getScreenshots(floorPlan, [
      Interface_Enums.ImageSubject.FloorPlan,
    ]);

    let floorPlanId = await floorPlanIdPromise;
    let screenshotSendPromises = screenshotPromises.map((ssp) =>
      ssp.then((screenshot) =>
        this.httpClient.post<void>(
          'api/floorplan/' + floorPlanId + '/screenshot',
          screenshot,
          { responseType: 'void' },
        ),
      ),
    );
    await Promise.all(screenshotSendPromises);
  }

  public getCompleteFloorPlan(
    floorPlan: Client.FloorPlan,
  ): Interface_DTO.FloorPlan {
    let basicFloorPlan = floorPlan.save();
    let completeFloorPlan: Interface_DTO.FloorPlan = {
      ...basicFloorPlan,
      Cabinets: floorPlan.cabinets.map((cabinet) => cabinet.getDtoObject()),
      UserId: basicFloorPlan.UserId,
    };
    return completeFloorPlan;
  }
}
