export const enum AdditionalPriceFormula {
  PricePerUnit = 0,
  PricePerCentimeter = 1,
  PriceDeduction = 2,
  PriceAddition = 3,
  ProductAddition = 4,
}
export const enum AdditionalPriceQualifier {
  None = 0,
  Equal = 1,
  GreaterThan = 2,
  LessThan = 3,
}
export const enum App {
  RetailSilverlight = 1,
  EndUserHtml5 = 2,
  RetailHtml5 = 3,
}
export const enum AppCompatibility {
  RetailSilverlight = 2,
  EndUserHtml5 = 4,
  RetailHtml5 = 8,
  All = 14,
}
export const enum BackingType {
  None = 0,
  Visible = 1,
  Hidden = 2,
}
export const enum BarType {
  Loose = 1,
  Fixed = 2,
  Design = 3,
}
export const enum CabinetAlignment {
  Unknown = 0,
  FreeStanding = 1,
  LeftAligned = 2,
  RightAligned = 3,
  WallToWall = 4,
}
export const enum CabinetType {
  Doors = 0,
  SingleCabinet = 1,
  CornerCabinet = 2,
  WalkIn = 3,
  WalkThrough = 4,
  Swing = 5,
  Partition = 7,
  SharedItems = 9,
  Template = 10,

  SwingFlex = 15,
  SwingFlexCornerCabinet = 16,
  SwingFlexUShapedCabinet = 17,

  PartitionTemp = 500, //temporary to allow already saved floorplans to work

  None = 999,
}
export const enum ChainSettingKey {
  AllowPrintItemsWithPrice = 8,
  AllowPrintItemsWithoutPrice = 9,
  AllowPrintInterior = 10,
  AllowPrintInteriorWithMeasure = 11,
  AllowPrintDoorAppearance = 12,
  AllowDoorsWithMeasure = 13,
  AllowPrintInteriorWithDummies = 24,
  AllowPrintBackingWithMeasure = 25,
  AllowPrintSwing = 27,
  DefaultCorpusMaterial = 14,
  DefaultInteriorMaterial = 15,
  DefaultBackingMaterial = 16,
  LoginInfo = 23,
  UseExternalPrices = 26,
  EndUserDefaultDoorProductNo = 101,
}
export const enum ChainType {
  Global = 1,
  Chain = 2,
  SalesChain = 3,
  Store = 4,
}
export const enum ConfigurationCategory {
  None = 0,
  Bedroom = 1,
  Kitchen = 2,
  Utility = 4,
  KidsRoom = 8,
  Office = 16,
  DoorsOnly = 32,
  Utility2 = 64,
  Utility3 = 128,
  Utility4 = 256,
  Utility5 = 512,
}
export const enum CornerPosition {
  TopLeft = 0,
  TopRight = 1,
  BottomRight = 2,
  BottomLeft = 3,
}
export const enum CornerType {
  Normal = 0,
  Half = 1,
  Inverted = 2,
}
export const enum CorpusPanelPosition {
  Top = 0,
  Bottom = 1,
  Side = 2,
}
export const enum CustomerImportSource {
  AuboIntegrator = 1,
  Test = 2,
}
export enum DeliveryStatus {
  Quote = 0,
  PendingConfirmation = 1,
  Order = 2,
  Cancel = 3,
  Overridden = 4,
  Template = 5,
  Received = 10,
  Confirmed = 11,
  Cancelled = 12,
  Manufactured = 13,
  Delivered = 14,
  Invalid = 15,
  Blocked = 17,
  Rejected = 18,
  Unknown = 19,
}
export const enum Direction {
  Top = 0,
  Bottom = 1,
  Left = 2,
  Right = 3,
}
export const enum ErrorLevel {
  None = 0,
  Info = 5,
  Warning = 10,
  Critical = 20,
}
export const enum ImageSubject {
  Doors = 1,
  DoorsWithMeasurements = 2,
  Interior = 3,
  InteriorWithMeasurements = 4,
  InteriorWithDummyItems = 5,
  ThreeD = 6,
  BackingDrawingWithMeasurements = 7,
  FloorPlan = 8,
  ThreeDNoDoors = 9,
  Swing = 10,
  SwingWithMeasurements = 11,
  SwingDoors = 12,
  FloorPlanPartitionsOnly = 13,
  ThreeDPartition = 14,
  PartitionFillings = 15,
  PartitionFillingsWithMeasurements = 16,
  SwingFlexDoors = 17,
  SwingFlexMeasurements = 18,
  SwingFlexDoorMeasurements = 19,
}
export const enum IntegrationType {
  None1 = -1,
  None2 = 0,
  FTP = 1,
  Webservice = 2,
  Aubo = 3,
  FetimFtp = 4,
}
export const enum ItemType {
  ManuallyAdded = 0,
  Corpus = 1,
  Door = 2,
  Interior = 3,
  Backing = 4,
  Custom = 5,
  AddedByCondition = 6,
  Fee = 7,
  CustomPercent = 8,
  Module = 9,
  AddedByService = 10,

  SwingModule = 21,
  SwingDoor = 22,
  SwingCorpus = 23,
  SwingBacking = 24,
  SwingExtra = 25,
  //SwingCorpusMovable = 26,    //unused

  //SwingFlexModule = 31,       //unused
  SwingFlexDoor = 32,
  SwingFlexCorpus = 33,
  SwingFlexBacking = 34,
  //SwingFlexExtra = 35,        //unused
  SwingFlexCorpusMovable = 36,

  Partition = 50,
}
export const enum LanguageItemTranslationTable {
  Category = 0,
  CorpusPanel = 1,
  DoorRailSet = 2,
  Folder = 3,
  Material = 4,
  MaterialGroup = 5,
  Product = 6,
  ProductGrouping = 7,
  SalesType = 8,
  Variant = 9,
  VariantOption = 10,
}
export const enum LogSeverity {
  Debug = 0,
  Info = 1,
  Warning = 2,
  Error = 3,
}
export const enum MaterialDesignType {
  Normal = 0,
  Design = 1,
  DesignAndNormal = 2,
}
export const enum MaterialGroupType {
  Normal = 0,
  Design = 1,
  None = 2,
}
export const enum MaterialType {
  Normal = 0,
  Frame = 1,
  Grip = 2,
}
export const enum PaymentStatus {
  Unused = 0,
  Created = 1,
  ReservationCreated = 2,
  CheckoutCompleted = 3,
}
export const enum PrintType {
  Normal = 0,
  Offer = 1,
  Order = 2,
  Summary = 3,
}
export const enum ProductConditionDomain {
  PerFloorPlan = 0,
  JoinedSections = 1,
  SingleSection = 2,
  PerCorner = 3,
}
export const enum ProductConditionType {
  Quantity = 0,
  Width = 1,
  Height = 2,
  Depth = 3,
  Coli = 4,
  ManualSystem = 5,
}
export const enum ProductDataStatus {
  Discontinued = 1,
}
export const enum ProductDataType {
  Gable = 1 << 0,
  DrilledLeft = 1 << 1,
  DrilledRight = 1 << 2,
  Drilling600Left = 1 << 3,
  Drilling600Right = 1 << 4,
  Corner2WayFlex = 1 << 5,
  CornerDiagonalCut = 1 << 6,
  CoatHanger = 1 << 7,
  AdditionalProductInfo = 1 << 8,
  AdditionalGroupInfo = 1 << 9,
  UnavailableInV2 = 1 << 10,
  RequiresManualDrilling = 1 << 11,
  SupportsMiter = 1 << 12,
  RequiredSpaceOnlyOnPullout = 1 << 13,
  FittingPanel = 1 << 14,
  GripForDoor = 1 << 15,
  GripForDrawer = 1 << 16,
  VerticalDivider = 1 << 17,
  CanHaveGrip = 1 << 18,
  SwingFlexDoorBreaker = 1 << 19,
  CornerRounded = 1 << 20,
  CornerTwoPart = 1 << 21,

  // when adding new types, remember to add it in the following locations:
  // - /src/interface/Enums/ProductDataType.cs
  // - /src/PdfDto/ProductDataType.cs
  // - /src/frontend/src/app/ts/Interface_Enums.ts
  // - /sec/WebEntities/Db/Enums.cs (in repository: Admin)
}

export const enum DrillingPattern {
  None = 1 << 0,
  _37_261 = 1 << 1,
  _37_453 = 1 << 2,

  // when adding new types, remember to add it in the following locations:
  // - /src/interface/Enums/DrillingPattern.cs
  // - /src/frontend/src/app/ts/Interface_Enums.ts
  // - /sec/WebEntities/Db/Enums.cs (in repository: Admin)
}

export const enum ProductLineId {
  Mm19 = 1,
  Mm22 = 2,
  Mm19light = 4,
  WalkIn = 5,
  Swing = 6,
  mm19ReferencePlus = 7,
  mm19Premium = 8,

  Partition = 9,
  SwingFlex22 = 10,
  SwingFlex19 = 11,
}

export const enum ProductType {
  Door = 1,
  DoorAccessory = 2,
  DoorStandard = 5,
  Corpus = 10,
  SwingCorpus = 15,
  Backing = 80,
  Grip = 91,
  Shelf = 110,
  ModuleParent = 190,
  SwingDoor = 410,
  SwingExternalDrawerSoft = 420,
  SwingExternalDrawerPush = 430,
  StylingStart = 900,
  StylingEnd = 1000,
}
export const enum RailPosition {
  Top = 0,
  Bottom = 1,
}
export const enum SalesChainSettingKey {
  DefaultProductline = 2,
  DefaultConfigurationMode = 3,
  MailWelcomeTemplate = 4,
  MailWelcomeSubject = 5,
  DefaultWizardDoorType = 6,
  ShowCarryIn = 7,
  ShowSms = 8,
  AllowDoorOnlyConfiguration = 9,
  AllowSingleCabinetConfiguration = 10,
  AllowCornerCabinetConfiguration = 11,
  AllowWalkInConfiguration = 12,
  AllowWalkThroughConfiguration = 13,
  DefaultConfigurationType = 16,
  SaveOrdersAsPendingConfirmation = 17,
  DisableOrderChangeAndCancellation = 18,
  RequireSMSWhenOrdering = 19,
  OverrideVatPercentOnFreight = 20,
  FloorFeeItemNumber = 21,
  OnlyAllowOrderChangesWhenAmountIsTheSame = 22,
  AllowModules = 23,
  ForceOnlyDoors = 24,
  MailNotifyTemplate1 = 25,
  MailNotifyTemplate2 = 26,
  MailNotifyTemplate3 = 27,
  ExtraDeliveryInfo = 28,
  SendMailNotifications = 29,
  MailNotifyHeader1 = 30,
  MailNotifyHeader2 = 31,
  MailNotifyHeader3 = 32,
  AllowWebImport = 33,
  RequireRequisitionWhenOrdering = 34,
  RequireEmailWhenOrdering = 35,
  AllowIndividualDoorWidths = 36,
  ShowIntegrationInputFields = 37,
  IntegrationType = 38,
  AllowFloorSelection = 39,
  ExternalCustomerSearchServers = 40,
  AllowOrderConfirmer = 41,
  ShowMarketOrderNumber = 42,
  PendingOrderAdvisoryEmail = 43,
  PendingOrderAdvisoryHours = 44,
  PendingOrderAdvisoryTemplate = 45,
  PendingOrderAdvisorySubject = 46,
  FittingBoxProductNo = 47,
  AvailableCustomerCountries = 48,
  DiscountAmountXml = 49,
  RequireMarketOrder = 50,
  ReferenceFieldReadOnly = 51,
  EarliestDeliveryDate = 52,
  FloorplanTemplateDeliveryAddressId = 53,
  OrderReminderDaysAhead = 54,
  OrderReminderSubject = 55,
  OrderReminderBodyA = 56,
  OrderReminderBodyB = 57,
  OrderReminderBodyC = 58,
  BasketIdKey = 59,
  BasketPostUrl = 60,
  BasketSupplierId = 61,
  BasketRedirectUrl = 62,
  StartOrderApiKey = 63,
  Setting64 = 64,
  Setting65 = 65,
  Setting66 = 66,
  Setting67 = 67,
  Setting68 = 68,
  Setting69 = 69,
  EndUserMailFromAddress = 111,
  EndUserMailBccAddress = 112,
  EndUserMailResetSubject = 113,
  EndUserMailResetTemplate = 114,
}
export const enum SnapPlacementType {
  Left = 1 << 0,
  Right = 1 << 1,
  LeftAndRight = 1 << 2,
  Top = 1 << 3,
  DepthMiddle = 1 << 4,
  BottomTopOrAboveGables = 1 << 5,
  Front = 1 << 6,
  AboveGables = 1 << 7,
  IgnoreSnapPointValidation = 1 << 8,
  CoatHanger = 1 << 9,
  RotateOnDoor = 1 << 10,
  AgainstOpposite = 1 << 11,

  // when adding new types, remember to add it in the following locations:
  // - /src/interface/Enums/SnapPlacementType.cs
  // - /src/frontend/src/app/ts/Interface_Enums.ts
  // - /sec/WebEntities/Db/Enums.cs (in repository: Admin)
}
export const enum SoftCloseType {
  Unused = 0,
  MagneticIncluded = 1,
  Magnetic = 2,
  SpringPowered = 3,
  SpringPoweredIncluded = 4,
}
export const enum SwingModuleProductPlacement {
  Unknown = 0,
  Door = 1,
  LeftGable = 2,
  MiddleGable = 3,
  RightGable = 4,
  BottomShelf = 5,
  TopShelf = 6,
  Backing = 7,
  InteriorShelf = 8,
  InteriorCoatHanger = 9,
  SubModuleGable = 10,
  SubModuleTopShelf = 11,
  SubModuleExtraItem = 12,
}
export const enum SwingModuleType {
  StartModule = 0,
  ExtendModule = 1,
  SubModule = 2,
}
export const enum TemplateType {
  Both = 0,
  Advanced = 1,
  Wizard = 2,
  Module = 3,
  FullModuleWizard = 4,
}
export const enum UserRight {
  Login = 0,
  PasswordReset = 1,
  ReadStoreFloorPlans = 2,
  EditStoreFloorPlans = 3,
  ChangeStore = 4,
  UseFullCatalog = 5,
  ShowOnlyAdminVariants = 6,
  UseCustomPriceDate = 7,
  SaveFavouriteInGroup = 8,
  SeeAllStores = 9,
  SeeChainStores = 10,
  SeeSalesChainStores = 11,
  SetManualDeliveryStatus = 12,
  UseOrderConfirmer = 13,
  ResendScreenshots = 14,
  EditOrderComment = 15,
  EditLogisticsComment = 16,
  EditUseManualOrderHandling = 17,
  SetSupporter = 18,
  SeeOrders = 19,
  IssueSubTokens = 20,
  UseExternalApi = 21,
  ChangeUserSettings = 22,
  ChangeStoreSettings = 23,
  OverwriteFavourites = 24,
  EditSendReminderEmails = 25,
  EditFloorPlanOwner = 26,
}
export const enum UserType {
  Normal = 0,
  StoreAdmin = 1,
  Support = 2,
  Admin = 3,
  ChainSupport = 4,
  SalesChainSupport = 5,
  IntegrationUser = 6,
  ConsumerUser = 7,
}
export const enum VariantType {
  Variant = 0,
  Dimension2 = 1,
}
